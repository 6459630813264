
const Spinner = () => {
    return (
        <div style={{ width: '100%', margin: '10px auto', textAlign: 'center'}}>
           <p>Cargando...</p>
           <i className="fa fa-spinner fa-spin"></i>
        </div>
    )
}

export default Spinner
