import axios from "axios";

const CM_API = process.env.REACT_APP_API_URL_CM


/**
 * Fetches all quick-replies interactions where the user's answer couldn't be recognized
 * @param search
 * @param page
 * @returns resolves with the list of serialized interactions
 */
export const getUnrecognized = (search = "", page = 1): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/sessions_interactions/get_unrecognized/?search=${search}&page=${page}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}

/**
 * Fetches the interaction with asociated quick-reply data
 * @param id interaction id
 * @returns resolves with the list of serialized interaction
 */
export const getSpecificUnrecognized = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/sessions_interactions/unrecognized/?id=${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Corrects the unrecognized interaction with the selected values
 * @param id interaction id
 * @param payload data to be updated
 * @returns void
 */
export const correctUnrecognized = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${CM_API}/sessions_interactions/correct_unrecognized/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}

