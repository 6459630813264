import {
    getListArticles,
    getListPosts,
    postIntentCreate,
    articleIntentCreate
} from 'data/intents';
import { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import validator from 'validator';
// @ts-ignore
import Select from 'react-select';
import swal from 'sweetalert';


interface IProps {
    id: string;
}

const AddContent = ({ match }: RouteComponentProps<IProps>) => {
    const [type, setType] = useState("");
    const [value, setValue] = useState<any>(null);
    const [values, setValues] = useState([]);
    const [errorType, setErrorType] = useState("");
    const [errorValue, setErrorValue] = useState("");
    const [selectRef, setSelectRef] = useState<any>(null);
    const id = match.params.id;
    const history = useHistory();

    useEffect(() => {
        // TODO: load post or articles
        setValues([]);
        if (selectRef) {
            selectRef.select.clearValue()
        }

        if (validator.isEmpty(type)) {
            return;
        }

        if (type === 'articulo') {
            // here article
            getListArticles()
                .then(resp => setValues(resp.data))
        }

        if (type === 'actividad') {
            // here post
            getListPosts()
                .then(resp => setValues(resp.data))
        }
    }, [type, selectRef]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setErrorType("");
        setErrorValue("");

        if (validator.isEmpty(type)) {
            setErrorType("Type is required");
            return;
        }

        if (validator.isEmpty(String(value))) {
            setErrorValue("Value is required");
            return;
        }

        try {
            if (type === "actividad") {
                await postIntentCreate({
                    intent_id: id,
                    post: value
                });
            } else {
                await articleIntentCreate({
                    intent_id: id,
                    article: value
                });
            }
            swal("Afinidata", "Contenido agregado correctamente", "success")
                .then(() => {
                    history.push("/admin/nlu");
                })
        } catch(err:any) {
            console.log(err);
            swal("Afinidata", "Ocurrio un error al guardar", "error");
        }
    }

    return (
        <div className="row">
            <div className="col-sm-6 mx-auto">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h5>Add content</h5>
                            </div>
                            <div className="col-sm-6 text-right">
                                <Link to="/admin/nlu"
                                    className="btn btn-secondary">Volver</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="">Type</label>
                                <select name="type" className={`form-control ${errorType && 'is-invalid'}`}
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}>
                                    <option value="">Select type content</option>
                                    <option value="actividad">Actividad</option>
                                    <option value="articulo">Artículo</option>
                                </select>
                                {errorType && (
                                    <span className="help-block text-danger mt-4">{errorType}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Value</label>
                                <Select
                                    ref={(ref: any) => setSelectRef(ref)}
                                    options={values.map((item: any) => (
                                        {
                                            label: `${item.id} - ${item.name}`,
                                            value: item.id
                                        }
                                    ))}
                                    defaultValue={value || ""}
                                    onChange={(selected: any) => setValue(selected?.value)} />
                                {errorValue && (
                                    <span className="help-block text-danger mt-4">{errorValue}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit">
                                    <i className="fa fa-save"></i> Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddContent
