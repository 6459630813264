import { IRoute } from '../interfaces/Route';
import HotTriggers from 'pages/hot-triggers/HotTriggers';
import SequencesPage from 'pages/hot-triggers/SequencesPage';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import HotTriggersPage from 'pages/hot-triggers/HotTriggersPage';
import AddSequencePage from 'pages/hot-triggers/AddSequencePage';
import DeleteSequencePage from 'pages/hot-triggers/DeleteSequencePage';

export const HOTTRIGGERS_ROUTES:IRoute[] = [
    {
        path: '/admin/sequences',
        component: SequencesPage,
        codename:'hot_triggers_list_sequence',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/sequences/create',
        component: AddSequencePage,
        codename:'hot_triggers_add_sequence',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/sequences/:id/edit',
        component: AddSequencePage,
        codename:'hot_triggers_change_sequence',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/sequences/:id/delete',
        component: DeleteSequencePage,
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/sequences/:id/hot-triggers',
        component: HotTriggers,
        codename:'hot_triggers_list_hot_triggers',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/sequences/:id/hot-triggers-2',
        component: HotTriggersPage,
        codename:'hot_triggers_list_hot_triggers',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    }
]
