import {useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom'
import SearchForm from '../../components/SearchForm';
import Pagination from 'react-js-pagination';
import { getAttributesList } from '../../data/attributes';
import Loading from 'components/Loading';
import { Attribute } from 'interfaces/Attribute';

const ListEntity = () => {

    const [ attribute, setAttribute ] = useState<Attribute[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);
    
    const attribute_view:any = {
        0: "service",
        1: "data"
    }

    const tipos = [
        {
            'value':'numeric',
            'label':'Numeric'
        },
        {
            'value':'string',
            'label':'String'
        },
        {
            'value':'date',
            'label':'Date'
        },
        {
            'value':'boolean',
            'label':'Boolean'
        }
    ];

    useEffect(() => {

        let mounted = true;

        getAttributesList(search)
        .then( (resp: any) => {
            if(mounted){
                let count : number = 0;
                let results : any = [];
                if(resp.data.hasOwnProperty('results')){
                    count  =  resp.data.count
                    results  =  resp.data.results
                }
                else{
                    results = resp.data;
                    count = resp.data.length;
                }
                setAttribute(results)
                setLoading(false);
                setCount(count)
                console.log("count entities:");
                console.log(results);
            }
        })
        .catch( (err: any) => console.log(err));

        return function cleanup(){
            mounted = false;
        }

    },[history,search])

    const handlePageChange = async (page: number) => {
        console.log("handlepagechange:");
        console.log(page);
        setLoading(true);
        setActivePage(page)
        const resp:any = await getAttributesList(search)
        const {results, count} = resp.data;
        setCount(count);
        setAttribute(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h4>Listado de atributos</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Link to="/admin/attributes/add" className="btn btn-primary">
                                <i className="fas fa-list-alt"></i> Crear atributo
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    <div className="table-responsive">
                        {attribute.length > 0 &&
                            <>
                                <table className="table" width="100%" id="data">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Tipo</th>
                                            <th>Vista atributo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attribute.map( item => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{ (tipos.find(t => t.value === item.type )?.label )}</td>
                                                <td>{ attribute_view[item.attribute_view]}</td>
                                                
                                                <td className="text-right">
                                                    <a href={`/admin/attributes/${item.id}/show`} className="btn btn-warning btn-sm ml-1"><i className="fa fa-edit"></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={2}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    innerClass="pagination justify-content-center"
                                />
                            </>
                        }

                        {/* {users.length === 0 &&
                            <div className="alert alert-info">No hay usuarios</div>
                        } */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListEntity;
