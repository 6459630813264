import AddEntity from "pages/entities/AddEntity";
import ListEntity from "pages/entities/ListEntity";
import EntityDetail from "pages/entities/EntityDetail";
import { IRoute } from '../interfaces/Route';

export const ENTITY_ROUTES:IRoute[] = [
    {
        path: '/admin/entity/add',
        component: AddEntity,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/entity/:id/show',
        component: EntityDetail,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/entity/list',
        component: ListEntity,
        exact: true,
        requiredRoles: []
    }
];