import { useEffect, useState } from 'react';
import { RouteComponentProps } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Post, Materiales, Area } from '../../../interfaces/Post';
import { getProgramPostList } from '../../../data/programs';
import { getMaterials, getAreas } from '../../../data/posts';
import Pagination from 'react-js-pagination';
import Loading from 'components/Loading';

type Props = {
    program_id: string
}

const ListProgramPost = ({ match }: RouteComponentProps<Props>) => {
    const [count, setCount] = useState(1);
    const [activePage, setActivePage] = useState(Number(localStorage.getItem('postsCurrentPage')) || 1);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [area, setArea] = useState<string>('');
    const [material, setMaterial] = useState<string>('');
    const [posts, setPosts] = useState<Post[]>([]);
    const [allAreas, setAllAreas] = useState<Materiales[]>([]);
    const [allMaterials, setAllMaterials] = useState<Area[]>([]);
    const programId = match.params.program_id;

    useEffect(() => {
        setArea('');
        setMaterial('');
        const currentPage = Number(localStorage.getItem('postsCurrentPage')) || 1;
        getProgramPostList(programId, currentPage, {})
            .then((resp: any) => {
                localStorage.setItem('postsCurrentPage', "1");
                const { results, count } = resp.data;
                setPosts(results);
                setCount(count);
                setLoading(false);
            }).catch(console.error);

        getMaterials()
            .then((resp: any) => {
                setAllMaterials(resp.data.results);
            }).catch(console.error);

        getAreas()
            .then((resp: any) => {
                setAllAreas(resp.data.results);
            }).catch(console.error);

    }, [programId])

    const getParams = () => {
        return {
            search: search,
            materiales: material,
            area: area
        }
    }

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        localStorage.setItem('postsCurrentPage', String(page));
        const resp: any = await getProgramPostList(programId, page, getParams());
        const { results } = resp.data;
        setPosts(results);
        setLoading(false);
    }

    const handleSearch = async (event: any) => {
        setLoading(true);
        setActivePage(1);
        localStorage.setItem('postsCurrentPage', String(1));
        const resp: any = await getProgramPostList(programId, 1, getParams());
        const { results } = resp.data;
        setPosts(results);
        setLoading(false);
    }

    const handleFilterChange = async (event: any) => {
        setLoading(true);
        setActivePage(1);
        console.log(event);
        localStorage.setItem('postsCurrentPage', String(1));
        const resp: any = await getProgramPostList(programId, 1, getParams());
        const { results } = resp.data;
        setPosts(results);
        setLoading(false);
    }


    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="row mb-3 d-flex justify-content-end">
                <select name="materiales"
                    value={material}
                    onChange={(e) => { handleFilterChange(e) }}>
                    <option value="">-- select material --</option>
                    {allMaterials.map((item, i) => (
                        <option value={item.id} key={i}>{item.name}</option>
                    ))}
                </select>
                <select name="area"
                    value={area}
                    onChange={(e) => { handleFilterChange(e) }}>
                    <option value="">-- select area --</option>
                    {allAreas.map((item, i) => (
                        <option value={item.id} key={i}>{item.name}</option>
                    ))}
                </select>
                <div>
                    <input type="text"
                        name="search"
                        placeholder="search"
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                    <button
                        type="button" className="btn btn-outline-primary"
                        onClick={(e) => { handleSearch(e) }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
            </div>
            <table className="table bg-white"
                style={{ width: "100%" }}>
                <thead>
                    <tr className="text-center">
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Area</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map(item => (
                        <tr className="p1" key={item.id}>
                            <th scope="row">{item.id}</th>
                            <td>{item.name}</td>
                            <td>{item.status}</td>
                            <td>{item.type}</td>
                            <td>{item.area.name}</td>
                            <td className="text-right">
                                <div className="row">
                                    <div className="col-xs-4 col-md-12">
                                        <button
                                            className="btn btn-outline-danger btn-sm mb-1"
                                            style={{ width: '50px' }}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                activePage={activePage}
                itemsCountPerPage={25}
                totalItemsCount={count}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-center"
            />
        </>
    )
}

export default ListProgramPost

