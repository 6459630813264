// import React from 'react';
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SinglePushModal from "./Push-modal";
import {
  getAllCreatedPushGroup,
  getAllPushEvents,
} from "services/nodejs.service";
import { PushDeleteModal } from "./PushDeleteModal";

const sendSchedule = [
  "1 vez a la semana",
  "2 veces a la semana",
  "3 veces a la semana",
  "1 vez cada 2 semanas",
  "cada inicio de mes",
  "al fin de mes",
];
const channels:any = {
    0:  "APP POR PROGRAMA",
    1:  "APP BRAZIL",
    2:  "APP BOTNAR",
    50: "AFINIAPP",
    100:"WHATSAPP PROGRAMA",
    101:"WHATSAPP BRAZIL",
    102:"WHATSAPP BOTNAR",
}

function PushEvents() {
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [pushEvents, setPushEvents] = useState([]);
  const [pushFragments, setPushFragments] = useState<any[]>([]);
  const columns = [
    {
      field: "id",
      headerName: "id",
      editable: false,
      width: 10,
    },
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: false,
    },
    {
      field: "frequency",
      headerName: "Frecuencia",
      valueGetter: (el: any) => sendSchedule[el],
      flex: 1,
      editable: false,
    },
    {
      field: "channel",
      headerName: "Channel",
      valueGetter: (el: any) => channels[el],
      flex: 1,
      editable: false,
    },
    {
      field: "total_push",
      headerName: "Pushes Asociadas",
      flex: 1,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Opciones",
      width: 140,
      editable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-warning btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModal(true);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModalDelete(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPush = async () => {
    let allObjs = await getAllPushEvents();

    let createdFractPush = await getAllCreatedPushGroup();
    console.log('xxx', createdFractPush)

    let reducedPush: any = { wa: [], app: [] };
    createdFractPush.data.result.forEach((push: any) => {
      let pushToAdd = {
        label: push.name,
        value: push.id,
        age: false,
        min: 0,
        max: 0,
        type: "groups",
      };
      if (push.age_min != null) {
        pushToAdd.age = true;
        pushToAdd.min = push.age_min;
        pushToAdd.max = push.age_max;
      }
      if (push.channel == 100) {
        reducedPush.wa.push(pushToAdd);
      } else {
        reducedPush.app.push(pushToAdd);
      }
    });
    setPushFragments(reducedPush);
    // console.log('xxx', allObjs.data.result.push_groups)
    // let tempPushEvents = {
    //     ...allObjs.data.result,
    //     push_groups:allObjs.data.result.push_groups?JSON.parse(allObjs.data.result.push_groups):[]
    // }
    setPushEvents(allObjs.data.result);
  };

  useEffect(() => {
    getPush();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => {
            setToEdit({ reset: true });
            setModal(true);
          }}
        >
          Crear nuevo evento
        </Button>
      </div>
      <DataGrid
        rows={pushEvents}
        //@ts-ignore
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "date_to_send", sort: "asc" }],
          },
        }}
      />
      <SinglePushModal
        visible={modal}
        close={() => {
          setModal(false);
          getPush();
          setToEdit({});
        }}
        refeshPush={getPush}
        pushFragments={pushFragments}
        toEdit={toEdit}
      />
      <PushDeleteModal
        visible={modalDelete}
        close={setModalDelete}
        refresh={getPush}
        pushToDelete={toEdit}
      />
    </div>
  );
}

// const horizontal = {
//     display: 'flex',
//     flexDirection: 'column',
//   }

export default PushEvents;
