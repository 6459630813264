import Loading from "components/Loading";
import { HitoI, LanguagesI, MilestoneConfigI } from "interfaces/MilestoneAdmin";
import { useEffect, useState, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom"
import {
    deleteTraduccion,
    getHitoById,
    getMilestoneConfigById,
    getTraducciones
} from "services/milestones-services";
import swal from "sweetalert";
import moment from 'moment';
import 'moment/locale/es';
import Pagination from 'react-js-pagination'

type Params = {
    id: string
}

const Hito = () => {
    const {id} = useParams<Params>();
    const [loading, setLoading] = useState(true);
    const [hito, setHito] = useState<HitoI>();
    const [areas, setAreas] = useState<string[]>([]);
    const [milestoneConfig, setMilestoneConfig] = useState<MilestoneConfigI[]>([]);
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [activePage1, setActivePage1] = useState(1);
    const [activePage2, setActivePage2] = useState(1);

    const [traducciones, setTraducciones] = useState<LanguagesI[]>([]);
    const mountRef = useRef(false);

    const getTraduccionesList = useCallback(() => {
        getTraducciones(id, activePage1)
        .then(resp => {
            const {count, results} = resp.data
            setTraducciones(results);
            setCount1(count)
        });
    },[id, activePage1]);

    const getConfiguracionesList = useCallback(() => {
        getMilestoneConfigById(id, activePage2)
        .then(resp => {
            const {count, results} = resp.data
            setMilestoneConfig(results);
            setCount2(count)
        })
    },[id, activePage2]);

    useEffect(() => {
        if(!mountRef.current){
            async function fetchData(){
                try{
                    const resp = await getHitoById(id);
                    setHito(resp.data.results[0]);
                    setAreas(resp.data.results[0].areas.map((item:any)=>item.name));
                } catch(err:any){
                    console.error(err);
                } finally{
                    setLoading(false);
                    mountRef.current = true;
                }
            }

            fetchData();
        }

        return function cleanUp(){
            mountRef.current = false;
        }
    },[id]);

    useEffect(() => {
        getTraduccionesList();
    },[getTraduccionesList])

    useEffect(() => {
        getConfiguracionesList();
    },[getConfiguracionesList])

    const handleDeleteTraduccion = async (id:number) => {
        try{
            const newData = traducciones.filter((item:any) => item.id !== id);
            setTraducciones(newData);
            await deleteTraduccion(id);
            swal("Traducción eliminada",{icon: "success"});
        } catch(err:any){
            console.error(err);
            swal("Ocurrión un error",{icon: "error"});
        }
    }

    const handlePageChangeTraducciones = (page: number) => {
        setActivePage1(page)
    }

    const handlePageChangeConfiguracion = (page: number) => {
        setActivePage2(page);
    }

    if(loading){
        return <Loading />
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h3>Hito: {id}</h3>
                    </div>
                    <div className="col-sm-6 text-right">
                        <Link to='/admin/milestones/listado-hitos' className="btn btn-secondary btn-sm"><i className="fa fa-arrow-left"></i> Regresar al listado</Link>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row mb-3">
                    <div className="col-sm-12">
                        {hito &&
                            <div className="row">
                                <div className="col-sm-3">
                                    <p><b>Id: </b>{hito.id}</p>
                                </div>
                                <div className="col-sm-3">
                                    <p><b>Status: </b>{hito.draft ? `Published` : `Draft`}</p>
                                </div>
                                <div className="col-sm-3">
                                    <p><b>Área: </b>{areas.join(", ")}</p>
                                </div>
                                <div className="col-sm-3">
                                    <p><b>Source: </b>{hito.source.name}</p>
                                </div>
                            </div>
                        }
                        <div className="row text-right">
                            <div className="col-sm-12">
                                <Link to={{pathname: `/admin/milestones/editar-hito/${id}`, state: {from: 'detail'}}}
                                className="btn btn-warning btn-sm"><i className="fa fa-edit"></i> Editar hito</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row mb-3">
                    <div className="col-sm-6">
                       <h4>Traducciones</h4>
                    </div>
                    <div className="col-sm-6 text-right">
                        <Link className="btn btn-primary btn-sm"
                        to={`/admin/milestones/hito/${id}/crear-traduccion`}
                        >
                            <i className="fa fa-plus"></i> Crear traducción
                        </Link>
                    </div>
                </div>

                <div className="table-responsive mb-3">
                    <table className="table table-sm mt-2">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Language</th>
                                <th>Language Code</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {traducciones.length === 0 &&
                                <tr>
                                    <td colSpan={4} className="text-center">No hay datos</td>
                                </tr>
                            }
                            { traducciones.length > 0 &&
                                traducciones.map((item:LanguagesI) =>(
                                    <tr key={item.id}>
                                        <td>
                                            {item.question}
                                        </td>
                                        <td>
                                            {item.language.name}
                                        </td>
                                        <td>
                                            {item.language.code}
                                        </td>
                                        <td>
                                            <Link to={`/admin/milestones/hito/${id}/editar-traduccion/${item.id}`} className="btn btn-warning btn-sm"><i className="fa fa-edit"></i></Link>
                                            <button className="btn btn-danger btn-sm ml-2"
                                            onClick={() => handleDeleteTraduccion(item.id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <Pagination
                        activePage={activePage1}
                        itemsCountPerPage={20}
                        totalItemsCount={count1}
                        pageRangeDisplayed={10}
                        onChange={handlePageChangeTraducciones}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                    />
                </div>

                <hr />
                <div className="mt-5">
                    <h4 className="mb-4">Configuración</h4>
                    <table className="table table-sm mt-2">
                        <thead>
                            <tr>
                                <th>Configuración</th>
                                <th>Code</th>
                                <th>Value</th>
                                <th>Difficult</th>
                                <th>Programa</th>
                                <th>Created at</th>
                                <th>Last updated at</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        { milestoneConfig.map( (item:MilestoneConfigI) => (
                                <tr key={item.id}>
                                    <td>{ item.config_name }</td>
                                    <td>{ item.code }</td>
                                    <td>{ item.average }</td>
                                    <td>{ item.difficulty }</td>
                                    <td>{ item.programs.map((item:any) => item.name).join(", ") }</td>
                                    <td>{ moment(item.created_at).fromNow() }</td>
                                    <td>{ moment(item.updated_at).fromNow() }</td>
                                    <td>
                                        <button className="btn btn-warning btn-sm" type="button"><i className="fa fa-edit"></i></button>
                                        <button className="btn btn-danger btn-sm ml-2" type="button"><i className="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        activePage={activePage2}
                        itemsCountPerPage={20}
                        totalItemsCount={count2}
                        pageRangeDisplayed={10}
                        onChange={handlePageChangeConfiguracion}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                    />
                </div>


            </div>
        </div>
    )
}

export default Hito
