import UserAdd from 'pages/users/UserAdd';
import UserDetail from 'pages/users/UserDetail';
import UsersList from 'pages/users/UsersList';
import { IRoute } from 'interfaces/Route';
import PushEvents from 'pages/users/premium/push-events';

export const USERS_ROUTES:IRoute[] = [
    {
        path:'/admin/users',
        component: UsersList,
        exact: true,
    },
    {
        path:'/admin/users/:id/show',
        component: UserDetail,
        exact: true,
    },
    {
        path: '/admin/users/create',
        component: UserAdd,
        exact: true,
    },
    {
        path: '/admin/users/premium',
        component: PushEvents,
        exact: true,
    }
];


