import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import ListProgram from 'pages/programs/ListProgram';
import ConfigureProgram from 'pages/programs/ConfigureProgram';
import DeleteProgram from 'pages/programs/DeleteProgram';

import EditProgramAttributeType from 'pages/program-attribute-types/EditProgramAttributeType';
import DeleteProgramAttributeType from 'pages/program-attribute-types/DeleteProgramAttributeType';
import AddProgramAttributeType from 'pages/program-attribute-types/AddProgramAttributeType';

import EditProgramAttribute from 'pages/program-attributes/EditProgramAttribute';
import ConfigureProgramAttributes from 'pages/program-attributes/ConfigureProgramAttributes';
import DeleteProgramAttribute from 'pages/program-attributes/DeleteProgramAttribute';
import AddProgramAttribute from 'pages/program-attributes/AddProgramAttribute';

import ListProgramPost from 'pages/programs/posts-programs/ListProgramPost';

import { IRoute } from '../interfaces/Route';
import ListActivitiesContainer from '../pages/programs/list_activities/ListActivitiesContainer';
import ProgramReports from 'pages/programs/reports';

export const PROGRAMS_ROUTES: IRoute[] = [
    /** Programs */
    {
        path: '/admin/programs',
        component: ListProgram,
        exact: true,
        codename: 'webhook_list_all_program',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/programs/report',
        component: ProgramReports,
        exact: true,
        codename: 'program_reports',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/programs/:id/delete',
        component: DeleteProgram,
        exact: true,
        codename: 'webhook_delete_program',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/programs/configure/:id',
        component: ConfigureProgram,
        exact: true,
        codename: 'webhook_change_program',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    /** Program Attributes Types */
    {
        path: '/admin/program_attribute_types/edit/:programAttributeTypeId/program/:programId',
        component: EditProgramAttributeType,
        exact: true,
        codename: 'webhook_change_program_attribute_types',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attribute_types/:id/delete',
        component: DeleteProgramAttributeType,
        exact: true,
        codename: 'webhook_delete_program_attribute_types',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attribute_types/:id/activities',
        component: ListActivitiesContainer,
        exact: true,
        codename: 'webhook_activities_by_program',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attribute_types/add/:programId',
        component: AddProgramAttributeType,
        exact: true,
        codename: 'webhook_add_program_attribute_types',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    /** Program Attributes*/
    {
        path: '/admin/program_attributes/configure/:programAttributeTypeId',
        component: ConfigureProgramAttributes,
        exact: true,
        codename: 'webhook_list_all_program_attributes',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attributes/edit/:programAttribute/type/:programAttributeTypeId',
        component: EditProgramAttribute,
        exact: true,
        codename: 'webhook_change_program_attributes',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attributes/:id/delete',
        component: DeleteProgramAttribute,
        exact: true,
        codename: 'webhook_delete_program_attributes',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/program_attributes/add/:programAttributeTypeId',
        component: AddProgramAttribute,
        exact: true,
        codename: 'webhook_add_program_attributes',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    /** Program Posts */
    {
        path: '/admin/program_posts',
        component: ListProgramPost,
        exact: true,
        codename: 'webhook_list_all_program',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
];


