import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "components/Loading"
import { useState } from "react"
import { Button, InputGroup } from "react-bootstrap"
import { AsyncPaginate } from "react-select-async-paginate"
import { getActivitiesForSelect, setActivitiesByProgram } from "services/content-manager.service"


interface IProps {
  program: number,
  onAddedActivity: () => void
}
interface IItem {
  value: number,
  label: string
}

const AddActivityComponent = ({program, onAddedActivity}: IProps) => {
    const [item, setItem] = useState<IItem | null>(null)
    const [loading, setLoading] = useState(false)


    const handleGetActivities = async (search: string, _loadedOptions: any, extras: any) => {
        const response = await getActivitiesForSelect(search, extras? extras.page : 1)
        const responseJSON = response.data;
        return {
        options: responseJSON.results.map((item: any) => {return {value: item.id, label: item.name}}),
        hasMore: !!responseJSON.next,
        additional: { page: Number(responseJSON.next?.match(/pagenumber=(?<page>\d+)/)?.groups?.page || 1)},
        };
    }

    const handleSetActivity = () => {
      if (item !== null ) {
        setLoading(true);
        setActivitiesByProgram(program, item.value )
          .then(() => {
            setItem(null)
            onAddedActivity()
          })
          .finally(() => setLoading(false))

      }
    }

    return loading? (<Loading/>) : (
        <InputGroup className="mb-3">
         <AsyncPaginate 
                        className={`async-select form-control`}
                        placeholder="Selecciona actividad"
                        debounceTimeout='400'
                        value={item}
                        onChange={(value: any) => setItem( value)}
                        loadOptions={handleGetActivities}/>
        <InputGroup.Append>{/* 
                        // @ts-ignore */}
          <Button variant="primary" disabled={item === null} onClick={handleSetActivity}><FontAwesomeIcon icon={faPlus} /></Button>
        </InputGroup.Append>
      </InputGroup>
    )
}

export default AddActivityComponent