import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_CM;

export const getInstanceAttributesById = (instanceId: string, search: string = ""): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/instances_attributevalue/?instance_id=${instanceId}&search=${search}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getInstancesAttributesByUser = (userId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/instances_attributevalue/?user_id=${userId}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const storeInstanceAttribute = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/instances_attributevalue/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


export const updateInstanceAttribute = (id: string, value: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.patch(`${URL}/instances_attributevalue/${id}/`, { value });
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


/**
 * Description: obtener posibles values por attribute
 * @param attributeId
 *
 */
export const getPossibleValuesByAttributeInstance = (attributeId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/instances/${attributeId}/get_possible_values/`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

