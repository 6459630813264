
const Logo = () => {
    return (
        <>
            <img src="/img/logo.png" alt=""
            width="290" height="auto"
            style={{ display:'block', margin: '10px auto'}}/>
        </>
    )
}

export default Logo
