import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import Loading from '../../components/Loading';
import { Sequence } from 'interfaces/Sequence';
import { getDateAttributesList } from 'data/attributes';
import { getSessionsList } from 'data/sessions';
import { getSequenceById, updateHotTriggerPositions, getTagsList } from 'data/sequences';
import "react-datepicker/dist/react-datepicker.css";
import { RouteComponentProps } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { deleteHotTrigger, getHotTriggersList, storeHotTrigger, updateHotTrigger } from 'data/hot-triggers';
import { ReactSortable } from "react-sortablejs";
// @ts-ignore
import Select from 'react-select';
import './style.css';

interface Props {
    id: string;
}

const HotTriggers = ({ match }: RouteComponentProps<Props>) => {
    const id = match.params.id;

    const initialValue = {
        sequence: id,
        position: 1,
        session_id: 1,
        session_start_position: 0,
        trigger_type: 2,
        date: null,
        interval: 4,
        delta: 1,
        repeat: 1,
        tags: [],
        base_date_attribute: null,
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingS, setLoadingS] = useState<boolean>(true);
    const [tags, setTags] = useState<any[]>([]);
    const [dateAttributes, setDateAttributes] = useState<any[]>([]);
    const [hotTriggers, setHotTriggers] = useState<any[]>([]);
    const [sequence, setSequence] = useState<Sequence>({ name: '', description: '' });
    const [sessions, setSessions] = useState<any[]>([]);
    // const [sessionOptions, setSessionsOptions] = useState<any>([]);

    const fetchHotTriggers = useCallback(() => {
        setLoading(true);
        getHotTriggersList(id).then(resp => {
            const { results } = resp.data;
            setHotTriggers(results.map((item: any) => ({ ...item, edit: false })));
            setLoading(false);
        })
            .catch(err => {
                console.log("aqui", err)
            });
    }, [id])

    useEffect(() => {
        let mounted = true;

        if (mounted) {

            fetchHotTriggers();

            getSequenceById(id)
                .then(resp => setSequence(resp.data))
                .catch(err => console.log(err));

            getTagsList()
                .then(resp => setTags(resp.data.results))
                .catch(err => console.log(err));

            getDateAttributesList()
                .then((resp: any) => setDateAttributes(resp.data.results))
                .catch(err => console.log(err));

            getSessionsList()
                .then(resp => {
                    setSessions(resp.data)
                    setLoadingS(false);
                })
                .catch(err => console.log(err));
        }

        return function cleanUp() {
            mounted = false;
        }

    }, [fetchHotTriggers, id]);

    const handleAddtriggers = async () => {
        try {
            const lastEl = hotTriggers[hotTriggers.length - 1];
            if (lastEl) {
                initialValue.position = lastEl.position + 1;
            }
            const resp = await storeHotTrigger(initialValue);
            setHotTriggers([...hotTriggers, resp.data]);
            await swal('Afinidata', "Hot trigger agregado!", 'success');
        } catch(err:any) {
            console.log(err)
        }
    }

    const handleTriggerTypeChange = (evt: any, idx: number) => {
        const elemento = hotTriggers[idx];
        setHotTriggers(hotTriggers.map((item: any) => item.id === elemento.id ? { ...item, trigger_type: Number(evt.target.value) } : item))
    }

    const handleChangeDate = (date: any, item: any) => {
        const elemento = { ...item, date: new Date(date) };
        setHotTriggers(hotTriggers.map(item => item.id === elemento.id ? elemento : item));
    }

    const handleChange = (e: any, item: any) => {
        // const regexp = /^[0-9\b]+$/
        // if(e.target.name === 'delta' && !regexp.test(e.target.value)){
        //     return;
        // }
        const elemento = { ...item, [e.target.name]: e.target.value };
        setHotTriggers(hotTriggers.map(item => item.id === elemento.id ? elemento : item));
    }

    const handleMultipleChange = (val: any, element: any, item: any) => {
        const elemento = { ...item, [element.name]: val };
        setHotTriggers(hotTriggers.map(h_trigger => h_trigger.id === elemento.id ? elemento : h_trigger));
    }

    const toogleBtnEdit = (idx: number) => {
        const elemento = hotTriggers[idx];
        if (elemento.edit) {
            updateHotTrigger(elemento)
                .then(() => swal("Afinidata", "Hot triggers actualizado!", "success"))
                .catch(err => console.log(err));
        }
        setHotTriggers(hotTriggers.map((item: any) => item.id === elemento.id ? { ...item, edit: !item.edit } : item));
    }

    const handleDelete = async (item: any) => {
        try {
            await deleteHotTrigger(item);
            await swal("Afinidata", "Hot triggers eliminado!", "success");
            setHotTriggers(hotTriggers.filter(i => i.id !== item.id));
        } catch(err:any) {
            console.log(err);
        }
    }

    const handleUpdateList = async () => {
        try {
            let newData = hotTriggers.map((item, i) => ({ ...item, position: i + 1 }))
            await updateHotTriggerPositions(id, hotTriggers)
            await swal('Afinidata', "Actualizado correctamente", "success");
            setHotTriggers(newData);
        } catch(err:any) {
            console.log(err)
        }
    }

    const isNumeric = (evt:any) => {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
          return;
        } else {
          return true;
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Hot triggers</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>Sequence: {sequence.id}</p>
                                    <p>Name: {sequence.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right mb-4">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleAddtriggers}
                                >
                                    <i className="fa fa-plus"></i>
                                    <span>&nbsp;Añadir nuevo</span>
                                </button>
                            </div>
                            {hotTriggers && hotTriggers.length > 0 &&
                                <ReactSortable
                                    list={hotTriggers} onEnd={handleUpdateList} setList={setHotTriggers}>
                                    {hotTriggers.map((item: any, index: number) => (
                                        <div key={item.id} onMouseEnter={(e) => {
                                            let el = e.target as HTMLInputElement;
                                            el.style.cursor = 'grabbing'
                                        }} className="item-container">
                                            Id: {item.id}
                                            <div className="row mb-2">
                                                <div className="col-md-12 text-right">
                                                    {item.edit &&
                                                        <button className="btn btn-success btn-sm"
                                                            title="Editar"
                                                            onClick={() => toogleBtnEdit(index)}>
                                                            <i className="fa fa-save"></i> Guardar
                                                        </button>
                                                    }
                                                    {!item.edit &&
                                                        <button className="btn btn-warning btn-sm"
                                                            title="Editar"
                                                            onClick={() => toogleBtnEdit(index)}>
                                                            <i className="fa fa-edit"></i> Editar
                                                        </button>
                                                    }
                                                    <button className="btn btn-danger btn-sm ml-2"
                                                        title="Borrar"
                                                        onClick={() => handleDelete(item)}>
                                                        <i className="fa fa-trash"></i> Borrar
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label htmlFor="">Session</label>
                                                    {loadingS && (
                                                        <Loading text="Cargando sessions..." size="2rem" />
                                                    )}
                                                    {sessions && sessions.length > 0 &&
                                                        <select name="session_id" className="form-control"
                                                            disabled={!item.edit}
                                                            defaultValue={item.session_id}
                                                            onChange={(e) => handleChange(e, item)}>
                                                            {sessions.map(s => (
                                                                <option value={s.id} key={s.id}>{s.name}</option>
                                                            ))}
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="">Trigger type</label>
                                                    <div className="form-group">
                                                        <select name="trigger_type" className="form-control"
                                                            disabled={!item.edit}
                                                            defaultValue={item.trigger_type}
                                                            onChange={(e) => handleTriggerTypeChange(e, index)}>
                                                            <option value="1">Recurrent</option>
                                                            <option value="2">Time interval</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    {item.trigger_type === 2 &&
                                                        <div className="form-group row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="">Duration</label>
                                                                <input type="number"
                                                                    name="delta"
                                                                    min="1"
                                                                    step="1"
                                                                    className="form-control"
                                                                    pattern="^[0-9]"
                                                                    onKeyPress={isNumeric}
                                                                    value={item.delta}
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    disabled={!item.edit} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="">Interval</label>
                                                                <select
                                                                    name="interval"
                                                                    className="form-control"
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    defaultValue={item.interval}
                                                                    disabled={!item.edit}>
                                                                    <option value="1">Seconds</option>
                                                                    <option value="2">Minutes</option>
                                                                    <option value="3">Hours</option>
                                                                    <option value="4">Days</option>
                                                                    <option value="5">Weeks</option>
                                                                    <option value="6">Months</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item.trigger_type === 1 &&
                                                        <div className="form-group">
                                                            <label htmlFor="" className="d-block">Date</label>
                                                            <DatePicker
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={item.date ? new Date(item.date) : new Date()}
                                                                onChange={date => handleChangeDate(date, item)}
                                                                disabled={!item.edit}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row flex-wrap">
                                                <div className="col-md">
                                                    <label htmlFor="">Position</label>
                                                    <p className="static-control">{item.position}</p>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="">Start position</label>
                                                    <input type="number" name="session_start_position" className="form-control"
                                                        disabled={!item.edit}
                                                        value={item.session_start_position || 0}
                                                        onChange={(e) => handleChange(e, item)} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="">Repeat</label>
                                                    <input type="number" name="repeat" className="form-control"
                                                        value={item.repeat || 1}
                                                        disabled={!item.edit}
                                                        onChange={(e) => handleChange(e, item)} />
                                                </div>
                                                <div className="col-md-2" style={{ minWidth: '150px' }}>
                                                    <label htmlFor="">Base Date Attribute</label>
                                                    <select name="base_date_attribute" className="form-control"
                                                        disabled={!item.edit}
                                                        defaultValue={item.base_date_attribute}
                                                        onChange={(e) => handleChange(e, item)}>
                                                        <option value=''> select a date attribute</option>
                                                        {dateAttributes.map(attribute => (
                                                            <option value={attribute.id} key={attribute.id}>{attribute.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-2" style={{ minWidth: '250px' }}>
                                                    <label htmlFor="">Tags</label>
                                                    <Select
                                                        name="tags"
                                                        placeholder="Select tag"
                                                        onChange={(val: any, element: any) => handleMultipleChange(val, element, item)}
                                                        value={item.tags}
                                                        isDisabled={!item.edit}
                                                        isMulti={true}
                                                        options={tags} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </ReactSortable>
                            }

                            {hotTriggers && hotTriggers.length === 0 &&
                                <div className="alert alert-info">No hay secuencias</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HotTriggers
