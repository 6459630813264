import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSequences } from 'data/sequences';
import { Sequence } from 'interfaces/Sequence';
import Loading from '../../components/Loading';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';

const SequencesPage = () => {
    const [sequences, setSequences] = useState<Sequence[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);

    useEffect( () => {
        let mounted = true;
        if(mounted){
            getSequences(search, 1)
            .then( resp => {
                const { count, results } = resp.data
                setSequences(results);
                setCount(count);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
        }

        return function cleanUp() {
            mounted = false;
        }
    },[search]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        const resp:any = await getSequences(search, page)
        const {results, count} = resp.data;
        setCount(count);
        setSequences(results);
        setLoading(false);
    }

    if(loading){
        return <Loading />
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h4>Catálogo de secuencias</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <a href="/admin/sequences/create" className="btn btn-primary"><i className="fa fa-plus"></i> Crear secuencia</a>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    { sequences && sequences.length > 0 ?(
                            <>
                                <div className="table-responsive">
                                    <table className="table table-sm"
                                    id="data"
                                    width="100%">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { sequences.map( (item:Sequence, index: number) => (
                                                <tr key={index}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.description}</td>
                                                    <td className="text-right">
                                                        <Link to={`/admin/sequences/${item.id}/hot-triggers`} className="btn btn-primary btn-sm"
                                                        title="Ver detalles">
                                                            <i className="fa fa-eye"></i> V1
                                                        </Link>
                                                        <Link to={`/admin/sequences/${item.id}/hot-triggers-2`} className="btn btn-success btn-sm ml-2"
                                                        title="Ver detalles">
                                                            <i className="fa fa-eye"></i> V2
                                                        </Link>
                                                        <Link to={`/admin/sequences/${item.id}/edit`} className="btn btn-success btn-warning btn-sm ml-2"
                                                        title="Editar">
                                                            <i className="fa fa-edit"></i>
                                                        </Link>
                                                        <Link
                                                        to={`/admin/sequences/${item.id}/delete`}
                                                        className="btn btn-danger btn-sm ml-2"
                                                        title="Borrar"
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={50}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    innerClass="pagination justify-content-center"
                                />
                            </>
                        ):(
                            <div className="alert alert-info">No hay data</div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default SequencesPage;
