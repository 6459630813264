import { Intent } from "interfaces/Intent";
import { useState, useEffect, useContext } from 'react';
import swal from "sweetalert";
import { IntentsContext } from "../IntentsContextProvider";
// @ts-ignore
import Select from "react-select";
import { destroyResponseSession, storeResponseSession } from "data/response-session";
import { getPostsIntent, getArticlesIntent, articleIntentDelete, postIntentDelete } from "data/intents";
import { Link } from "react-router-dom";

interface IProps {
    intent: Intent;
    intentId: string;
    isReadOnly?: boolean;
}

interface IResponse {
    id: string;
    sessionId: string;
    name: string;
}

interface ISelect {
    label: string;
    value: string;
}

const BotReplyWithSession = ({ intent, intentId, isReadOnly =false }: IProps) => {
    const [selectRef, setSelectRef] = useState<any>(null)
    const { responsesession } = intent;
    const [responses, setResponses] = useState<IResponse[]>([]);
    const [err, setErr] = useState("");
    const { sessions } = useContext(IntentsContext);
    const [currentSession, setCurrentSession] = useState<ISelect>({ value: '', label: '' });
    const [activities, setActivities] = useState<any[]>([]);
    const [articles, setArticles] = useState<any[]>([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                // activities
                const resp1 = await getPostsIntent(intent?.id);
                setActivities(resp1.data.results);

                const resp2 = await getArticlesIntent(intent?.id);
                setArticles(resp2.data.results);
            }

            fetchData()
        }

        return () => { mounted = false; }
    }, [intent]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {

            if (responsesession && responsesession.length > 0) {
                setResponses(responsesession.map((item: any) => ({
                    id: item.id,
                    sessionId: item.session_id,
                    name: item.session.length > 0 ? item.session[0].name : "",
                })));
            }
        }

        return () => { mounted = false }
    }, [responsesession]);

    const handleRemove = async (id: string) => {
        setResponses(responses.filter((item: IResponse) => item.id !== id));
        try {
            await destroyResponseSession(id);
            swal("Afinidata", 'Eliminado correctamento', 'success');
        } catch (err: any) {
            console.log(err);
            swal("Afinidata", 'Ha ocurrido un error, intente de nuevo.', 'error');
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const { value, label } = currentSession;
            const name = label.split("-")[1].trim();

            if (value === '') {
                setErr("Session is required");
                setTimeout(() => {
                    setErr("");
                }, 2000);
                return;
            }

            const payload = { intent: Number(intentId), session_id: value };
            const resp = await storeResponseSession(payload);
            const { data } = resp;
            setResponses([{ id: data.id, sessionId: value, name }, ...responses]);
            setErr("");
            swal('Afinidata', 'Agregado correctamente', 'success');
            selectRef.select.clearValue();
        } catch (err: any) {
            console.log(err);
            swal('Afinidata', 'Ha ocurrido un error, intente de nuevo', 'error');
        }
    }

    const handleDeletePost = async (item: any) => {
        // delete post
        try {
            await postIntentDelete(item.id);
            swal("afinidata", "Borrado exitosamente", "success");
            const resp = await getPostsIntent(intent?.id);
            setActivities(resp.data.results);
        } catch (err: any) {
            console.log(err);
            swal("afinidata", "Ocurrio un error", "error");
        }
    }

    const handleDeleteArticle = async (item: any) => {
        // delete article
        try {
            await articleIntentDelete(item.id);
            swal("afinidata", "Borrado exitosamente", "success");
            const resp = await getArticlesIntent(intent?.id);
            setArticles(resp.data.results);
        } catch (err: any) {
            console.log(err);
            swal("afinidata", "Ocurrio un error", "error");
        }
    }

    return (
        <div>
            {err &&
                <div className="alert alert-danger">
                    {err}
                </div>
            }
            {!isReadOnly && <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="">Session</label>
                    <Select
                        disabled={isReadOnly}
                        options={sessions.map((item: any) => ({ label: `${item.id} - ${item.name}`, value: item.id }))}
                        ref={(ref: any) => setSelectRef(ref)}
                        onChange={(value: ISelect) => setCurrentSession(value)}
                    />
                </div>
                <div className="form-group">
                    <button className="btn btn-outline-primary btn-sm btn-block"
                        type="submit">
                        <i className="fa fa-save"></i> Guardar
                    </button>
                </div>
            </form>}

            {responses.length > 0 &&
                <ul className="list-group">
                    {responses.map((item: IResponse) => (
                        <li className="list-group-item"
                            key={item.id}>
                            <span>{item.sessionId} - {item.name}</span>
                            {!isReadOnly && <div className="float-right">
                                <i
                                    style={{ cursor: 'pointer' }}
                                    className="fa fa-times"
                                    onClick={() => handleRemove(item.id)}></i>
                            </div>}
                        </li>
                    ))}
                </ul>
            }
            {!isReadOnly &&
            <div className="mt-2">
                <Link to={`/admin/nlu/add-content/${intent.id}`} className="btn btn-success btn-block btn-sm">Agregar contenido</Link>
            </div>
            }
            <hr />

            {/** TODO: delete actividad or article */}
            <div className="row mt-4">
                <div className="col-sm-12">
                    <h5 className="text-center">Contenido asociado</h5>
                    <div className="row">
                        <div className="col-sm-6">
                            <p>Actividades</p>
                            {activities.length > 0 ? (
                                <ul className="list-group">
                                    {activities.map((item: any) => (
                                        <li className="list-group-item"
                                            key={item.id}>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <a href={item.post.url} target="_blank"
                                                        rel="noreferrer">{item.post.id} - {item.post.name}</a>
                                                </div>
                                                {!isReadOnly &&
                                                <div className="col-sm-3 text-right">
                                                    <button className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeletePost(item)}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="alert-info alert">No hay data</div>
                            )}
                        </div>
                        <div className="col-sm-6">
                            <p>Artículos</p>
                            {articles.length > 0 ? (
                                <ul className="list-group">
                                    {articles.map((item: any) => (
                                        <li className="list-group-item"
                                            key={item.id}>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <a href={item.article.content} target="_blank"
                                                        rel="noreferrer">{item.article.id} - {item.article.name}</a>
                                                </div>
                                                {!isReadOnly &&
                                                <div className="col-sm-3 text-right">
                                                    <button className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteArticle(item)}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="alert alert-info">No hay data</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BotReplyWithSession
