import { useFormik } from "formik";
import swal from 'sweetalert';
import * as Yup from "yup";
import {useHistory} from 'react-router-dom';
import { createAuthGroup } from '../../data/auth-groups';

const validationSchema = Yup.object().shape({
    name: Yup.string().required()
});

const AddGroup = () => {
    const history = useHistory();

    const initialValues = {
        name: ""
    };

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            await createAuthGroup(payload);
            await swal('Afinidata','Grupo creado correctamente','success');
            history.push('/admin/roles');
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Crear nuevo role</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name"
                                     className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href="/admin/groups/" className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddGroup
