import { useParams } from 'react-router';
import {useState, useEffect, useRef} from 'react';
import { ConfigurationI } from 'interfaces/ProgramResponse';
import { getConfigurations, updateProgram, getProgramById } from 'services/milestones-programs.service';
import { ProgramMinimal } from 'interfaces/ProgramResponse';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';

type Params = {
    id: string;
    currentConfig: string;
}

export const EditarPrograma = () => {
    const {id, currentConfig} = useParams<Params>();

    const [configurations, setConfigurations] = useState<ConfigurationI[]>([]);
    const [newConfig, setNewConfig] = useState(currentConfig);
    const [program, setProgram] = useState<ProgramMinimal>();
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    const ref = useRef(false)

    useEffect(() => {

        async function fetchConfigurations() {
            try{
                const resp:any = await getConfigurations();
                const getProgram:any = await getProgramById(id);
                setConfigurations(resp.data);
                setProgram(getProgram.data.results[0]);
                setNewConfig(currentConfig)
            } catch(err:any){
                console.log(err);
            }
        }

        if(!ref.current){
            fetchConfigurations();
        }

        return () =>  {ref.current = true};
    },[id, currentConfig]);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try{
            setDisabled(true);
            if(program){
                program.config = newConfig;
            }
                
            await updateProgram(id, program);
            await swal('Programa actualizado',{icon: 'success'});
            history.push(`/admin/milestones/detalle-programa/${id}`);
        } catch(err:any){
            swal('Ocurrio un error intente, de nuevo',{icon: 'error'});
            console.log(err);
        } finally {
            if(!ref.current){
                setDisabled(false);
            }
        }
    }

    return (
        <div className="card">
           <div className="card-header">
               <h3>Editar configuración de programa {program && program.name}</h3>
           </div>
           <div className="card-body">
               <div className="row">
                    <div className="col-sm-4 mx-auto">
                        <p>Selecciona el algoritmo a utilizar para milestones para este programa: </p>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Configuración</label>
                                <select name="config" id="config"
                                value={Number(newConfig)}
                                onChange={(e:any) => {setNewConfig(e.target.value)}}
                                className="form-control">
                                    {configurations && configurations.map((item:any) => (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-sm"
                                disabled={disabled}
                                type="submit">Guardar</button>
                                <Link to={`/admin/milestones/detalle-programa/${id}`}
                                className="btn btn-secondary btn-sm ml-2">Volver</Link>
                            </div>
                        </form>
                    </div>
               </div>
           </div>
       </div>
    )
}
