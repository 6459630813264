import { IRoute } from '../interfaces/Route';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import QuickRepliesPage from 'pages/quick-replies/QuickRepliesPage';
import AddQuickReply from 'pages/quick-replies/AddQuickReply';
import DetailQuickReply from 'pages/quick-replies/DetailQuickReply';
import CopyReplyFrom from 'pages/quick-replies/CopyReplyFrom';

export const QUICKREPLIES_ROUTE:IRoute[] = [
    {
        path: '/admin/quick-replies',
        component: QuickRepliesPage,
        codename:'quick_replies_main',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/quick-replies/add',
        component: AddQuickReply,
        codename:'quick_replies_add',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/quick-replies/show',
        component: DetailQuickReply,
        codename:'quick_replies_show',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/quick-replies/:id/copy-reply-from',
        component: CopyReplyFrom,
        codename:'quick_replies_copy',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
]
