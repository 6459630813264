import {useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom'
import { Entity } from 'interfaces/Entity';
import SearchForm from '../../components/SearchForm';
import Pagination from 'react-js-pagination';
import { getEntitiesList } from '../../data/entities';
import Loading from 'components/Loading';

const ListEntity = () => {

    const [ entity, setEntity ] = useState<Entity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);
    

    useEffect(() => {

        let mounted = true;

        getEntitiesList(search, 1)
        .then( (resp: any) => {
            if(mounted){
                const { count, results} = resp.data
                setEntity(results)
                setLoading(false);
                setCount(count)
                console.log("count entities:");
                console.log(results);
            }
        })
        .catch( (err: any) => console.log(err));

        return function cleanup(){
            mounted = false;
        }

    },[history,search])

    const handlePageChange = async (page: number) => {
        console.log("handlepagechange:");
        console.log(page);
        setLoading(true);
        setActivePage(page)
        const resp:any = await getEntitiesList(search, page)
        const {results, count} = resp.data;
        setCount(count);
        setEntity(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h4>Listado de entidades</h4>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Link to="/admin/entity/add" className="btn btn-primary">
                                <i className="fas fa-archway"></i> Crear entidad
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    <div className="table-responsive">
                        {entity.length > 0 &&
                            <>
                                <table className="table" width="100%" id="data">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Atributos</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entity.map( item => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>{
                                                    item.attributes !== undefined &&
                                                    item.attributes.map( a => (
                                                        <p>{a.name}</p>
                                                    ))}</td>
                                                <td className="text-right">
                                                    <a href={`/admin/entity/${item.id}/show`} className="btn btn-warning btn-sm ml-1"><i className="fa fa-edit"></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={2}
                                    totalItemsCount={count}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    innerClass="pagination justify-content-center"
                                />
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default ListEntity;