import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  FormControl,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { createPushGroup, updatePushGroup } from "services/nodejs.service";

// import { createPushService } from "services/nodejs.service.ts";

interface LanguageData {
  on: boolean;
  id?: number | null;
  media: { id: number } | null;
  templete?: { id: number } | null;
  format?: number | null;
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}

export interface LanguageMap {
  spanish: LanguageData;
  english: LanguageData;
  portuges: LanguageData;
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type Props = {
  close: () => void;
  visible: boolean;
  pushFragments: any;
  refeshPush: () => void;
  toEdit: any;
};

function isEmpty(obj: any) {
  if (obj.reset) return true;
  return Object.keys(obj).length === 0;
}

function SinglePushModal({
  close,
  visible,
  pushFragments,
  refeshPush,
  toEdit,
}: Props) {
  const [minorModal, setMinorModal] = useState(false);
  const [ageModal, setAgeModal] = useState<any>({});
  const [type, setType] = useState<undefined | number>(0);
  const [channel, setChannel] = useState<undefined | number>(0);
  const [templete, setTemplete] = useState<any>([]);
  const [groupName, setGroupName] = useState("");
  const refreshModal = () => {
    setType(0);
    setChannel(0);
    setTemplete([]);
    setGroupName("");
  };
  useEffect(() => {
    if (!Object.keys(toEdit).length) {
      return;
    }
    if (toEdit.reset) {
      refreshModal();
      return;
    }
    setType(toEdit.type);
    setGroupName(toEdit.group_name);
    setChannel(toEdit.channel);
    let pushFragmentsTemp =
      (toEdit.channel as number) > 99 ? pushFragments.wa : pushFragments.app;
    // console.log('pushFragmentsTemp.filter((el:any)=>toEdit.push_id.find(el.value))', pushFragmentsTemp.filter((el:any)=>toEdit?.push_ids.find(el.value)))
    setTemplete(
      pushFragmentsTemp.filter((el: any) =>
        toEdit?.push_ids.find((ell: any) => ell == el.value)
      )
    );
  }, [toEdit]);

  function returnShownFragments() {
    let pushToShow;
    if (channel == 100) pushToShow = pushFragments.wa;
    else pushToShow = pushFragments.app;
    if (pushToShow == undefined) return {};
    if (type == 1) pushToShow = pushToShow.filter((el: any) => el.age);
    else pushToShow = pushToShow.filter((el: any) => !el.age);
    return pushToShow;
  }
  const usablePushFragments = useMemo(
    () => returnShownFragments(),
    [pushFragments, channel, type]
  );
  
  async function createPush() {
    let pushToSend: any = {
      type: type,
      name: groupName,
      channel: channel,
      pushToSend: templete.filter((el:any)=>el.type=='push').map((el: any) => el.value),
      groupToSend: templete.filter((el:any)=>el.type=='group').map((el: any) => el.value),
    };
    // console.log(pushToSend)
    // return
    let push;
    if (type == 1) {
      const toVerify = usablePushFragments.filter((el: any) =>
        pushToSend.pushToSend.includes(el.value)
      );
      let verified: any = [];
      for (const element of toVerify) {
        let ageRepeat = verified.find((el: any) => {
          // console.log(el.min,element.min,el.max,element.min)
          // console.log(el.min<=element.min,el.max>=element.min)
          // console.log(el.min<=element.min&&el.max>=element.min)
          // console.log(el.min,element.max,el.max,element.max)
          // console.log(el.min<=element.max,el.max>=element.max)
          // console.log(el.min<=element.max&&el.max>=element.max)
          return (
            (el.min <= element.min && el.max >= element.min) ||
            (el.min <= element.max && el.max >= element.max)
          );
        });
        if (ageRepeat) {
          setAgeModal({ prev: ageRepeat, repeat: element });
          break;
        }
        verified.push(element);
      }
    }
    if (isEmpty(toEdit)) {
      push = await createPushGroup(pushToSend);
    } else {
      pushToSend.id = toEdit.id;
      delete pushToSend.pushToSend;
      push = await updatePushGroup(pushToSend);
    }
    if (push.status == 201) {
      sleep(1000);
      refeshPush();
      refreshModal();
      close();
    }
  }

  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              style={{ display: "flex", flexDirection: "row" }}
              p={1}
            >
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="type">Tipo de Grupo</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    label="Tipo de Grupo"
                    value={type}
                    disabled={!isEmpty(toEdit)}
                    onChange={(v) => {
                      setType(v.target.value as number);
                      setTemplete([]);
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Grupo
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Edad
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="channel">Channel</InputLabel>
                  <Select
                    labelId="channel"
                    id="channel"
                    label="Channel"
                    value={channel}
                    disabled={!isEmpty(toEdit)}
                    onChange={(v) => {
                      setChannel(v.target.value as number);
                      setTemplete([]);
                    }}
                  >
                    <MenuItem key={1} value={0}>
                      APP
                    </MenuItem>
                    <MenuItem key={0} value={100}>
                      WHATSAPP
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nombre de push"
                  value={groupName}
                  onChange={(el) => setGroupName(el.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* <></> */}
            {isEmpty(toEdit) && (
              <Grid item xs={12} p={1}>
                {/* /#1 */}
                <Autocomplete
                  multiple
                  id=""
                  value={templete}
                  //@ts-ignore
                  options={usablePushFragments}
                  getOptionLabel={(option: any) => option.label}
                  onChange={(v, nv) => setTemplete(nv)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pushes a enviar"
                      placeholder=""
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={createPush}>
                {isEmpty(toEdit) ? "Agregar" : "Editar"}
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refreshModal();
                  close();
                }}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal open={minorModal}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error al guardar push.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => setMinorModal(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={!isEmpty(ageModal)}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error al guardar push: Edades se empalman
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    La pushes {ageModal.prev?.label} y{" "}
                    {ageModal.repeat?.label} empalman sus edades
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    La push: {ageModal.repeat?.label} tiene min:{" "}
                    {ageModal.repeat?.min} y max {ageModal.repeat?.max}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    La push: {ageModal.prev?.label} tiene min:{" "}
                    {ageModal.prev?.min} y max {ageModal.prev?.max}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button variant="contained" onClick={() => setAgeModal({})}>
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default SinglePushModal;
