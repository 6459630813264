import { CoreRequest } from "api/CoreRequest";
import { InstanceData } from "interfaces/Instance";

export const updateInstance = (id:number, instance: InstanceData) => {
    return CoreRequest.put(`/instance_only/${id}/`, instance);
}


export const updateUserResponses = (data: any) => {
    return CoreRequest.post(`/data/save_user_responses/`, data);
}