import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { deleteAPushEvent } from "services/nodejs.service";

const channel_map: any = {
  0: "APP POR PROGRAMA",
  1: "APP BRAZIL",
  2: "APP BOTNAR",
  50: "AFINIAPP",
  100: "WHATSAPP PROGRAMA",
  101: "WHATSAPP BRAZIL",
  102: "WHATSAPP BOTNAR",
};

const frequencyMap = [
  "1 vez a la semana" ,
  "1 ves cada 2 semanas" ,
  "cada inicio de mes" ,
  "al fin de mes" ,
];

function PushDeleteModal({ pushToDelete, close, visible, refresh }: any) {
  const deleteTemplete = async (id: any) => {
    await deleteAPushEvent({ id: id });
    await refresh();
    close();
  };
  

  return (
    <Modal open={visible}>
      <Box sx={minorStyle}>
        <Grid container flexDirection={"row"}>
          <Grid container p={1}>
            <Grid item>
              <Typography variant="h6" color={"black"}>
                Seguro que quieres borrar el evento:
              </Typography>
              <Typography variant="h6" color={"black"}>
                Nombre: {pushToDelete.name}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Canal: {channel_map[pushToDelete.channel]}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Frecuencia: {frequencyMap[pushToDelete.frequency]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => deleteTemplete(pushToDelete.id)}
              >
                Si
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={() => close()}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { PushDeleteModal };
