import {
  Autocomplete,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import TempleteModal from "./TempleteModal";
import { getAllObjects, updatePostwVideo } from "services/nodejs.service";
// import { DeleteModal } from "./TempleteDeleteModal";

const url =
  "https://metabase.afinidata.com/question/1539-look-by-id-for-pp?id=";

// const url_xyz =
// "https://metabase-green.afini.xyz/question/1538-look-by-id-for-pp?id=";


function ObjectPost() {
  const [post, setPost] = useState<any>("");
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState<any>({});
  const [updateVideoResult, setUpdateVideoResult] = useState<boolean | null>(
    null
  );

  const getObjects = async () => {
    let temps = await getAllObjects();
    setObjects(
      temps.data.result.map((el: any) => ({
        label: el.name,
        id: el.id,
        value: el.url,
      }))
    );
    console.log(temps.data);
  };

  const updateVideo = async () => {
    try {
        let update = await updatePostwVideo({
          id: +post,
          url: selectedObject.value,
        });
        if (update.status == 201) setUpdateVideoResult(true);
        else setUpdateVideoResult(false);
    } catch {
        setUpdateVideoResult(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getObjects();
  }, []);

  return (
    <div>
      <div style={{ justifyContent: "flex-end" }}>
        {/* <Button variant="contained" onClick={()=> setModal(true)}>Crear nuevo</Button> */}
        <Grid container display={"flex"}>
          <Grid item xs={4} p={1}>
            <Autocomplete
              disablePortal
              options={objects}
              defaultValue={null}
              onChange={(el, value) => {
                if (value) {
                  setSelectedObject(value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Objetos" />
              )}
            />
          </Grid>
          <Grid item xs={4} p={1}>
            <TextField
              label="Post a asociar"
              onChange={(v) => setPost(v.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} p={1} alignSelf={"center"}>
            <Button variant="contained" fullWidth onClick={() => updateVideo()}>
              Subir
            </Button>
          </Grid>
        </Grid>
        {updateVideoResult != null && (
            updateVideoResult == true ? (
                <Grid container display={"flex"} p={1}>
                  <Typography variant="h6" color={"black"}>
                    Post se a associado correctamente confirma aqui:
                  </Typography>
                  <Link href={url + post} variant="h6">
                    Click
                  </Link>
                </Grid>
            ) : (
                <Typography variant="h6" color={"black"}>
                    Error al vincular video con post
                  </Typography>
            )
        )}
      </div>
    </div>
  );
}

export default ObjectPost;
