import { useState } from "react";
import { Box, Button, InputLabel, MenuItem, Modal, Select,  FormControl, Grid, TextField, Typography } from "@mui/material";
import { pushTestTemplete } from "services/nodejs.service";

interface LanguageData {
    on: boolean;
    id?: number;
    media: 'Activity' | 'Article' | 'Session' | 'Milestones'| '';
    templete?:string
    format?: 'IMAGE' | 'TEXT' | 'VIDEO' | '';
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}
  
export interface LanguageMap {
    spanish: LanguageData;
    english: LanguageData;
    portuges: LanguageData;
}

type Props = {
    close: ()=> void;
    visible: boolean;
    toTest: any;
    refresh: ()=>void;
}

const lang_map = ["", "espanol", "ingles", "portuges"];

function PushTestModal({close, visible, refresh, toTest}:Props) {
    const [sendingPhone, setSendingPhone] = useState("")
    const [recievingPhone, setRecievingPhone] = useState("")
    const [templeteError, setTempleteError] = useState("")
    const [minorModal, setMinorModal] = useState(false)
    const [langToSend, setLangToSend] = useState<any>(0)
    async function testTemplete() {
        setMinorModal(true)
        setTempleteError('')
        if(recievingPhone=="" || !sendingPhone){
            setTempleteError("Falta llenar informacion antes de enviar la push");
            return
        }
        let templeteSend = await pushTestTemplete({
            languageId:+toTest?.templete[langToSend]?.lang,
            sendingPhone:+sendingPhone,
            recievingPhone:+recievingPhone,
            name:toTest?.templete[langToSend]?.name,
            type:+toTest?.templete[langToSend]?.type,
            url:toTest?.templete[langToSend]?.url
        })
        if(templeteSend.status==206) setTempleteError(templeteSend.data.err)
    }

    if(toTest.channel<100) {
        return (
            <Modal open={visible} >
                <Box sx={modalStyle}>
                    <Typography variant="h6" color={'black'}>App no se puede testear por ahora</Typography>
                    <Button variant="contained" onClick={()=>{refresh(); close()}}>close</Button>
                </Box>
            </Modal>
        )
    }
    console.log('toTest', toTest)
    return (
        <Modal open={visible} >
        <form>
            <Box sx={modalStyle}>
                <Grid container>
                    <Grid container spacing={1/2} display={'flex'} flexDirection={'row'} p={1}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="tqmt" >Lenguaje a probar</InputLabel>
                                <Select labelId="tqmtL" id="tqmt" label="Lenguaje a probar" value={langToSend} onChange={(e,v) => setLangToSend(e.target.value)}>
                                    {
                                        toTest?.templete?.map((el:any, key:number)=>{
                                            return <MenuItem key={key} value={key}>{lang_map[el.lang]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label='templete a mandar' value={typeof toTest?.templete == "object" && toTest?.templete[langToSend]?.name} disabled fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1/2}  style={{ display: 'flex', flexDirection: 'row' }} p={1}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="tqmt" >Telefono que mandara el templete</InputLabel>
                                <Select labelId="tqmtL" id="tqmt" label="Telefono que mandara el templete" value={sendingPhone} onChange={(v) => setSendingPhone(v.target.value)}>
                                    <MenuItem key={1} value={100499112882386}>(502) 476-09960</MenuItem>
                                    <MenuItem key={2} value={189898334205331}>(502) 476-26774</MenuItem>
                                    {/* <MenuItem key={3} value={256802684175549}>(502) 528-75196</MenuItem> */}
                                    <MenuItem key={4} value={155742407611819}>(502) 563-79681</MenuItem>
                                    {/* <MenuItem key={3} value={155742407611819}>50256379681</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label='Telefono al cual mandarle el templete' onChange={(v)=>setRecievingPhone(v.target.value)} fullWidth/>
                        </Grid>
                        <Grid item xs={4} alignSelf={'center'}>
                            <Button fullWidth variant="contained" onClick={()=> testTemplete()}>Probar templete</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} p={1}>
                        {/* <Button variant="contained" fullWidth onClick={registerTemplete}>Add</Button> */}
                    </Grid>
                    <Grid item xs={6} p={1}>
                        <Button variant="contained" fullWidth onClick={()=>{refresh(); close()}}>close</Button>
                    </Grid>
                
                </Grid>
        <Modal open={minorModal}>
            <Box sx={minorStyle}>
                <Grid container flexDirection={'row'}>
                    <Grid container p={1}>
                        <Grid item >
                            {!templeteError ? <Typography variant="h6" color={'black'}>Templete enviado correctamente</Typography> :<Typography variant="h6" color={'black'}>{templeteError}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={()=>setMinorModal(false)}>Cerrar</Button>
                        </Grid> 

                    </Grid>

                </Grid>
            </Box>
        </Modal>
            </Box>
        </form>
        </Modal>

    )

}


const modalStyle = {
    position: 'absolute',
    top:'50%',
    left:'50%',
    bgcolor: 'white',
    p:4,
    display:'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    minWidth:'70%'
}

const minorStyle = {
    position: 'absolute',
    top:'50%',
    left:'50%',
    bgcolor: 'white',
    p:4,
    display:'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    // minWidth:'70%'

}

export default PushTestModal