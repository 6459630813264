import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination'
import Loading from '../../../components/Loading';
import { advanceSearchForUsers, getMessengersUsers } from 'data/messenger-user';
import TableUserItem from './TableUserItem'
import PeopleModalUser from '../PeopleModalUser';
import PeopleModalInstance from '../PeopleModalInstance';
import SearchForm from '../../../components/SearchForm';
import AdvanceSearch from './AdvanceSearch';
import { Filtro } from 'interfaces/Filtro';
import '../style.css';
// @ts-ignore
import uuid from 'react-uuid';
declare var $:any;

const ListUsers = () => {
    const [instance, setInstance] = useState("");
    const [user, setUser] = useState<string>();
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(1);
    const [activePage, setActivePage] = useState(Number(localStorage.getItem('peopleCurrentPage')) || 1);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState("");
    const [filtros, setFiltros] = useState<Filtro[]>([]);
    const [loading2, setLoading2] = useState(true);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            async function fetchData(){
                try{
                    setLoading2(true)
                    let resp: any;
                    const currentPage = 1
                    if(filtros.length > 0){
                        resp = await advanceSearchForUsers(filtros, search, currentPage);
                    }else{
                        resp = await getMessengersUsers(search, currentPage)
                    }

                    localStorage.setItem('peopleCurrentPage',"1");
                    const {results, count} = resp.data;
                    setActivePage(currentPage);
                    setUsers(results);
                    setCount(count);
                    setLoading(false);
                    setLoading2(false)
                } catch(err:any){
                    console.log(err);
                }
            }
            fetchData();
        }

        return () => { mounted = false }
    },[search, filtros])

    const handlePageChange = async (page: number) => {
        setLoading2(true);
        setActivePage(page)

        localStorage.setItem('peopleCurrentPage',String(page));

        let resp:any;

        if(filtros.length > 0){
            resp = await advanceSearchForUsers(filtros, search, page);
        }else {
            resp = await getMessengersUsers(search, page)
        }

        const {results} = resp.data;
        setUsers(results);
        setLoading2(false);
    }

    const handleSubmit = (items: Filtro[]) => {
        setFiltros(items);
    }

    const handleSelecInstance = (id:string) => {
        setInstance(id);
        $('#instanceModal').modal("toggle");
    }


    if(loading){
        return <Loading />
    }

    return (
        <>
            { user && <PeopleModalUser userId={user} /> }
            { instance && <PeopleModalInstance instanceId={instance} /> }

            <AdvanceSearch
            type="users"
            onSubmit={(items:Filtro[]) => handleSubmit(items)}
            />

            <hr/>

            <SearchForm
            search={search}
            setSearch={setSearch}
            />

            { loading2 &&
                <Loading size="2rem" />
            }

            { !loading2 && users.length > 0 &&
                <>
                    <p className="small font-weight-bold text-right">Total registros: {count} Users</p>
                    <table className="table table-hover table-sm"
                    style={{width:"100%",fontSize:'0.8rem'}}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Last name</th>
                                <th>Bot id</th>
                                <th>Last message</th>
                                <th>Last interaction</th>
                                <th>24hr window</th>
                                <th>Created At</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map( (item:any) =>  (
                                <TableUserItem key={uuid()} user={item} handleClick={setUser}
                                onSelectInstance={handleSelecInstance}/>
                            ))}
                        </tbody>
                    </table>
                    <p className="small font-weight-bold text-right">Total registros: {count} Users</p>

                    <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                    />
                </>
            }

            { !loading2 && users.length === 0 &&
                <div className="alert alert-info">No hay data</div>
            }
        </>
    )
}

export default ListUsers
