import axios from 'axios';
import { UserGroup, UserGroupCode, GroupPlatformUsers } from 'interfaces/UserGroup';

const URL = process.env.REACT_APP_API_URL_CM;

// ROLES
export const ROLE_PARTNER_PROGRAM_MANAGER = Number(process.env.REACT_APP_PARTNER_PROGRAM_MANAGER);
export const ROLE_PARTNER_FIELD_PROFESSIONAL = Number(process.env.REACT_APP_PARTNER_FIELD_PROFESSIONAL);

/**
 * description: obtener los grupos
 * @param: search string
 * @param: page number
 */
export const getUserGroups = (search = '', page = 1): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/groups/?search=${search}&page=${page}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const getUserGroupsOptions = (): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/groups/?options=true`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const createUserGroup = (payload: UserGroup): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/groups/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const getUserGroupById = (id: string, detail = false):Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/groups/${id}/?detail=${detail}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const editUserGroup = (id:string, payload: UserGroup): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.put(`${URL}/groups/${id}/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const setUserGroupType = (id:number, userType: boolean = false): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/messenger_users/${id}/set_user_type/`, {user_type: userType})
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const deleteUserGroup = (id:string): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.delete(`${URL}/groups/${id}/`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

// GROUP RELATED ------------------------------------------------------------------------------------------

export const getUserGroupsUserOptions = (): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/group-auth-users/?options=true`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const getUserGroupsRoleOptions = (): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/group-auth-users/roles/`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

// PLATFORM USERS
export const getGroupPlatformUsers = (id: string, search = '', page = 1, detail = false, group = true):Promise<any> => {
    return new Promise(( resolve , reject) => {
        let id_type = group? `groupId=${id}`: `id=${id}`;
        
        axios.get(`${URL}/group-platform-users/?${id_type}&search=${search}&page=${page}&detail=${detail}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const createGroupPlatformUsers = (payload: GroupPlatformUsers): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/group-platform-users/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const editGroupPlatformUsers = (id:string, payload: GroupPlatformUsers): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.put(`${URL}/group-platform-users/${id}/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

// BOT USERS
export const getGroupBotUsers = (id: string, search = '', page = 1, detail = false, group = true):Promise<any> => {
    return new Promise(( resolve , reject) => {
        let id_type = group? `groupId=${id}`: `id=${id}`;
        
        axios.get(`${URL}/group-bot-users/?${id_type}&search=${search}&page=${page}&detail=${detail}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const deleteGroupBotUser = (id: string):Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.delete(`${URL}/group-bot-users/${id}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

// CODE
export const getGroupCode = (id: string, search = '', page = 1, detail = false, group = true):Promise<any> => {
    return new Promise(( resolve , reject) => {
        let id_type = group? `groupId=${id}`: `id=${id}`;
        
        axios.get(`${URL}/group-codes/?${id_type}&search=${search}&page=${page}&detail=${detail}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const createUserGroupCode = (payload: UserGroupCode): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/group-codes/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const editUserGroupCode = (id:string, payload: UserGroupCode): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.put(`${URL}/group-codes/${id}/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}
