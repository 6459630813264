import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { ArticleTraductionItem } from "interfaces/Article";
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import { getArticleTranslationById, updateArticleTranslation } from "data/articles";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    text_content: Yup.string().required(),
    content: Yup.string().required(),
    preview : Yup.string().required(),
    img_link: Yup.string().nullable()
});

const BACK_URL = '/admin/articles/traductions'

interface Props {
    id?: string
}

const EditTranslationPage = ({ match }: RouteComponentProps<Props>) => {

    const history = useHistory();
    const id = match.params.id || '';
    const [edit, setEdit] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<ArticleTraductionItem>({ name: '', text_content: '', content: '', id: -1, lang: '', preview: '', img_link:'', state: 0 })

    const handleSubmit = async (values: any) => {
        try {
            await updateArticleTranslation(id, values);
            await swal('Afinidata', "Secuencia editada", "success")
            history.push(BACK_URL);
        } catch (err:any) {
            if(err.response.data.name){
                if(err.response.data.name[0] === 'Ensure this field has no more than 100 characters.')
                swal('Afinidata', 'Max. 100 Caracteres', "error");
                return;
            }
            swal('Afinidata', 'Ocurrio un error al guardar, intente de nuevo', "error")
        }
    }

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (id) {
                getArticleTranslationById(id)
                    .then((resp) => {
                        setInitialValues(resp.data);
                        setEdit(true);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

        return () => { mounted = false; }
    }, [id]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values,) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-12 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>{edit ? 'Editar' : 'Añadir'} Traducci&oacute;n</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="custom-control custom-switch float-right">
                                    <input type="checkbox" 
                                    className="custom-control-input" 
                                    name="state"
                                    checked={formik.values.state === 1}
                                    onChange={(e) => {
                                        formik.setFieldValue('state', e.target.checked ? 1: 0)
                                    }}
                                    onBlur={formik.handleBlur}
                                    id="customSwitch1"/>
                                    <label className="custom-control-label" htmlFor="customSwitch1">Publicado</label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <input type="text" name="name"
                                        className={`form-control ${formik.errors.name && formik.touched.name ? "is-invalid" : ""}`}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        maxLength={100}
                                    />
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Summary</label>
                                    <textarea name="preview" 
                                        className={`form-control ${
                                            formik.errors.preview && formik.touched.preview ? "is-invalid" : ""
                                        }`}
                                        value={formik.values.preview}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                    {formik.errors.preview && formik.touched.preview ? (
                                        <div className="invalid-feedback">{formik.errors.preview}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Content</label>
                                    <textarea name="text_content" 
                                        className={`form-control ${
                                            formik.errors.text_content && formik.touched.text_content ? "is-invalid" : ""
                                        }`}
                                        rows={10}
                                        value={formik.values.text_content}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                    {formik.errors.preview && formik.touched.text_content ? (
                                        <div className="invalid-feedback">{formik.errors.text_content}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">URL</label>
                                    <input type="text" name="content"
                                        className={`form-control ${formik.errors.content && formik.touched.content ? "is-invalid" : ""}`}
                                        value={formik.values.content}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        maxLength={100}
                                    />
                                    {formik.errors.content && formik.touched.content ? (
                                        <div className="invalid-feedback">{formik.errors.content}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Image</label>
                                    <input type="text" name="img_link"
                                        className={`form-control ${formik.errors.img_link && formik.touched.img_link ? "is-invalid" : ""}`}
                                        value={formik.values.img_link}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        maxLength={100}
                                    />
                                    {formik.errors.img_link && formik.touched.img_link ? (
                                        <div className="invalid-feedback">{formik.errors.img_link}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            <Link to={BACK_URL} className="btn btn-secondary">
                                                <i className="fa fa-arrow-left"></i>
                                                <span className="ml-2">Volver</span>
                                            </Link>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button className="btn btn-primary"
                                                type="submit">
                                                <i className="fa fa-save"></i>
                                                {!edit ? (
                                                    <span className="ml-2">Guardar</span>
                                                ) : (
                                                        <span className="ml-2">Actualizar</span>
                                                    )}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditTranslationPage;
