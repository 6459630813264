import { /*useEffect,*/ useState } from 'react';
import { utils, writeFile } from 'xlsx';
import Loading from 'components/Loading';
import ReactDatePicker from 'react-datepicker';
import SearchField from 'components/search_field';
import axios from 'axios';

const Q_API = "https://queries-back-green.afinidata.com/v1/queries"
// const Q_API = "http://127.0.0.1:6768/v1/queries"




const TimeInWA = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [initialDate, setInitialDate] = useState<any>(new Date());
    const [finalDate, setFinalDate] = useState<any>(new Date());
    const [programId, setProgramId] = useState<string>('');
    const [programData, setProgramData] = useState<any>();
    async function getTimeInWhats() {
        setLoading(true)
        try {
            const resp = await axios.get(`${Q_API}/timeInWA?programId=${programId}&initialDate=${initialDate.toISOString().split('T')[0]}&finalDate=${finalDate.toISOString().split('T')[0]}`);
            setProgramData(resp.data.data)
        } catch {
            setLoading(false)
            setProgramData(false)
        } finally {
            setLoading(false)

        }          
    }
    console.log(loading)
    // useEffect(() => {
    //     return
    //     },
    //     [ search]);

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">Tiempo en WhatsApp</h4>
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <div className="col-md-2 col-sm-4">
                            <SearchField
                                search={programId} 
                                setSearch={setProgramId}
                                // className="form-control form-control-sm"
                                placeholder={"Program ID"}
                            />
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <ReactDatePicker
                                dateFormat="yyyy-MM-dd"
                                onChange={(value: any) => setInitialDate(value)}
                                // onChange={(value: any) => true}
                                className="form-control"
                                placeholderText="Fecha desde"
                                selected={initialDate}
                            />
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <ReactDatePicker
                                dateFormat="yyyy-MM-dd"
                                onChange={(value: any) => setFinalDate(value)}
                                className="form-control"
                                placeholderText="Fecha hasta"
                                selected={finalDate}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <button className="btn btn-outline-primary mx-3" title="Conseguir tiempos"  
                                disabled={loading}
                                onClick={()=>getTimeInWhats()}>
                                    Conseguir tiempos
                            </button>
                        </div>
                    </div>
                </div>
                {
                    programData ? 
                        <button className="btn btn-outline-primary mx-3" title="Conseguir tiempos"  
                                disabled={loading}
                                onClick={()=>{
                                    const ws = utils.json_to_sheet(programData);
                                    /* create workbook and append worksheet */
                                    const wb = utils.book_new();
                                    utils.book_append_sheet(wb, ws, "Data");
                                    /* export to XLSX */
                                    console.log(ws)
                                    writeFile(wb, `${programData}-${initialDate}-${finalDate}.xlsx`);
                                  }}>
                                    Descargar excel
                            </button>
                    : <></>
                }
            </div>
        </>
    )
}

export default TimeInWA

