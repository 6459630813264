import { useFormik } from 'formik';
import { useState } from 'react'
import FormUser from './UserForm';
import * as Yup from "yup";
import { Usuario } from 'interfaces/Usuario';
import {useEffect} from 'react';
import { getAuthGroups } from 'data/auth-groups';
import { AuthGroup } from 'interfaces/AuthGroup';
import { createUser } from 'data/users';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    username: Yup.string().required(),
    groups: Yup.array().required()
});

const UserAdd = () => {
    const [groups, setGroups] = useState<AuthGroup[]>([]);
    const history = useHistory();

    const initialValues:Usuario = {
        email: '',
        username: '',
        first_name:'',
        last_name:'',
        groups:[]
    }

    useEffect(() => {
        let mounted = true;

        if(mounted){
            getAuthGroups()
            .then( resp => {
               setGroups(resp.data.results)
            }).catch( err => console.log(err));
        }

        return function cleanUp(){
            mounted = false;
        }

    }, []);

    const handleSubmit = async (payload: any) => {
        try{
            await createUser(payload);
            await swal('Afinidata','Usuario creado correctamente','success');
            history.push('/admin/users');
        } catch( err ){
            console.log(err)
            swal('Afinidata','Error al crear el usuario, intente de nuevo','error');
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Crear usuario</div>
                            </div>
                            <div className="card-body">
                                <FormUser formik={formik} groups={groups}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAdd
