import { IRoute } from '../interfaces/Route';
import LiveChat from 'pages/live-chat/LiveChat';
import BotList from 'pages/live-chat/BotList';

export const LIVECHAT_ROUTES:IRoute[] = [
    {
        path: '/admin/live-chat/bots',
        component: BotList,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/live-chat/messages/:botid',
        component: LiveChat,
        exact: true,
        requiredRoles: []
    }
]
