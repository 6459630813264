import { useState, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

interface IProps {
    user: any;
    handleClick: (id:string) => void,
    onSelectInstance: (id:string) =>  void
}


const TableUserItem:FunctionComponent<IProps> = ({user,handleClick,onSelectInstance}) => {
    const [id, setId] = useState("");

    const handleInstanceViewAttrClic = (e:any) => {
        if(!id){
            swal("Afinidata","Selecciona la instance","warning");
            return;
        }

        onSelectInstance(id);
        e.preventDefault()
    }

    const handleSelectChange = (target:any) => {
        setId(target.value);
        localStorage.setItem("peopleCurrentInstanceValue",target.value)
    }

    return (
        <>
            <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.bot_id}</td>
                <td>{user.last_user_message && (moment(user.last_user_message).fromNow())}</td>
                <td>{user.last_channel_interaction && (moment(user.last_channel_interaction).fromNow())}</td>
                <td>{user.window}</td>
                <td>{user.created_at && (moment(user.created_at).fromNow())}</td>
                <td className="text-right">
                    <Link to={`/admin/people/${user.id}/user-attribute`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Add</Link>
                    <button className="btn btn-primary btn-sm ml-2"
                    data-toggle="modal" data-target="#userModal"
                    onClick={() => handleClick(user.id)}><i className="fa fa-search"></i></button>

                    <div className="table-users-buttons-row ml-auto mt-2">
                        <hr/>
                        <p>Associate instances</p>
                        <div className="form-group m-2" >
                            {user.instances.length > 0 ? (
                                <select className="form-control form-control-sm"
                                id={`select${user.id}`}
                                value={localStorage.getItem("peopleCurrentInstanceValue") || ""}
                                onChange={({target}) => handleSelectChange(target)}>
                                        <option value="" >-- Select instance --</option>
                                        {user.instances.map((item:{id: string, name:string}) => (
                                            <option value={item.id} key={item.id}
                                            >{item.id} | {item.name || ''}</option>
                                        ))}
                                </select>
                            ): (
                                <div className="alert alert-info">No hay instances asociadas</div>
                            )}
                        </div>
                        <div className="form-group m-0">
                            <button className="btn btn-primary btn-sm btn-block"
                            type="button"
                            onClick={handleInstanceViewAttrClic}><i className="fa fa-user"></i> View instance attr</button>
                        </div>
                    </div>

                </td>
            </tr>
        </>
    )
}

export default TableUserItem
