import { useEffect, useState } from 'react';
import {
    addTrainingText,
    deleteTrainingText,
    getQuickReplyById,
    updateQuickReply,
    getQuickReplyByLabel
} from 'data/quick-replies';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { QuickReply } from 'interfaces/QuickReply';
import Loading from 'components/Loading';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import validator from 'validator';

interface IProps {
    id: string
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const DetailQuickReply = ({ match }: RouteComponentProps<IProps>) => {
    const [quickReply, setQuickReply] = useState<QuickReply>();
    const [loading, setLoading] = useState(true);
    const [trainigText, setTrainingText] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [id, setId] = useState("");
    const query = useQuery();
    const history = useHistory();
    const [error, setError] = useState("");

    useEffect(() => {
        let mounted = true;

        if (mounted && loading === true) {
            async function fetchData() {
                try {
                    const label = query.get("label");
                    if (label) {
                        const resp = await getQuickReplyByLabel(label);
                        const { data } = resp;
                        if (data.results.length > 0) {
                            const values = data.results[0];
                            setQuickReply(values);
                            setId(values.id);
                            setLoading(false);
                        } else {
                            swal("Afinidata", "No se encontró data.", "error")
                                .then(() => {
                                    history.push('/admin/quick-replies');
                                })
                        }
                    } else {
                        history.push('/admin/quick-replies');
                    }
                } catch(err:any) {
                    console.log(err);
                    setLoading(false);
                }
            }
            fetchData();
        }

        return () => { mounted = false; }
    }, [id, history, loading, query]);

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await updateQuickReply(id, quickReply);
            swal("Afinidata", 'Actualizado correctamente', 'success');
        } catch(err:any) {
            swal("Afinidata", 'Ocurrio un error', 'error');
            console.log(err);
        }
    }

    const handleAddTrainingText = async (e: any) => {
        e.preventDefault();
        setDisabled(true)
        try {
            setError("");
            if (validator.isEmpty(trainigText)) {
                setError("The text is required");
                setDisabled(false);
                return;
            }

            await addTrainingText({
                text: trainigText,
                quick_reply: Number(id)
            });
            const resp = await getQuickReplyById(id);
            setQuickReply(resp.data);
            swal("Afinidata", 'Agregado correctamente', 'success');
            setTrainingText("");
            setDisabled(false);
        } catch(err:any) {
            swal("Afinidata", 'Ocurrio un error', 'error');
            console.log(err);
            setDisabled(false);
        }
    }

    const handleDeleteItem = async (itemId: string) => {
        try {
            await deleteTrainingText(itemId);
            const resp = await getQuickReplyById(id);
            setQuickReply(resp.data);
            swal("Afinidata", 'Eliminado correctamente', 'success');
        } catch(err:any) {
            swal("Afinidata", 'Ocurrio un error', 'error');
            console.log(err);
        }
    }


    if (loading) {
        return <Loading />
    }

    return (
        <div className="row">
            <div className="col-sm-3">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-6">
                                Edit quick reply
                            </div>
                            <div className="col-sm-6 text-right">
                                <Link to="/admin/quick-replies"
                                    className="btn btn-secondary btn-sm">
                                    <i className="fa fa-arrow-left"></i> Volver
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label htmlFor="">Id</label>
                                <p className="static-control">{quickReply?.id}</p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Label</label>
                                <input type="text"
                                    name="label"
                                    className="form-control"
                                    value={quickReply?.label}
                                    maxLength={100}
                                    disabled />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-sm-9">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-4">
                                <h5>Training text</h5>
                            </div>
                            <div className="col-sm-8">
                                <form onSubmit={handleAddTrainingText}>
                                    <div className="form-group row">
                                        <div className="col-sm-8">
                                            <input type="text"
                                                className={`form-control ${error && 'is-invalid'}`}
                                                placeholder="Enter training text"
                                                value={trainigText}
                                                onChange={({ target }) => setTrainingText(target.value)} />
                                            {error &&
                                                <div className="text-danger small mt-2">{error}</div>
                                            }
                                        </div>
                                        <div className="col-sm-4">
                                            <button className="btn btn-success btn-sm"
                                                disabled={disabled}>
                                                <i className="fa fa-plus"></i> Add new
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="mb-4 text-right">
                            <Link to={`/admin/quick-replies/${id}/copy-reply-from`} className="btn btn-warning btn-sm"><i className="fa fa-copy"></i> Copy replies from</Link>
                        </div>
                        {quickReply?.trainingtext_set && quickReply.trainingtext_set.length > 0 ? (
                            <>
                                <ul className="litst-group">
                                    {quickReply?.trainingtext_set.map((item: any) => (
                                        <li className="list-group-item" key={item.id}>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    {item.text}
                                                </div>
                                                <div className="col-sm-3 text-right">
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteItem(item.id)}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <div className="alert alert-info">No hay data</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailQuickReply
