import { useEffect, useState } from "react"

const PartnerProgressBar = ({item, onClick}:any) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if(item.users_in_risk > 0){
            setPercentage(Math.floor((item.users_in_risk * 100) / item.total))
        } else {
            setPercentage(0);
        }
    },[item]);

    const handleClick = () => {
        onClick(item.milestone_id ? 'milestone': 'attribute', item.milestone_id ? item.milestone_id: item.attribute, item.label);
    }

    return (
        <>
            <div className="row" onClick={handleClick} style={{cursor: 'pointer'}}>
                <div className="col-sm-4">
                    <p className="small text-muted">{item.label}</p>
                </div>
                <div className="col-sm-8">
                    <div style={{display:"flex", flexDirection: 'column', height: "100%", justifyContent: 'center'}}>
                        <div className="progress">
                            <div
                            className="progress-bar progress-bar-striped"
                            role="progressbar"
                            style={{width: `${percentage}%`}}
                            aria-valuenow={percentage}
                            aria-valuemin={0}
                            aria-valuemax={100}></div>
                        </div>
                        <p className="text-center small text-muted mt-2">{item.users_in_risk} total de casos</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnerProgressBar
