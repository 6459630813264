import { RouteComponentProps, useHistory } from "react-router"
import { useEffect, useState } from 'react';
// @ts-ignore
import AsyncSelect from 'react-select/async';
// @ts-ignore
import { copyRepliesFrom, getQuickReplyOptions, getQuickReplyById } from "data/quick-replies";
import { QuickReply } from "interfaces/QuickReply";
import Loading from '../../components/Loading';
import swal from "sweetalert";
import { Link } from "react-router-dom";

interface IProps {
    id: string;
}

const CopyReplyFrom = ({ match }: RouteComponentProps<IProps>) => {

    const id = match.params.id;
    const [quickReply, setQuickReply] = useState<QuickReply>({ label: "" });
    const [quickReplies, setQuickReplies] = useState<QuickReply[]>([]);
    const [defaultOptions, setDefaultOptions] = useState<QuickReply[]>([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState({ id: 0, label: "" });
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    const [error, setError] = useState("");

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                const resp = await getQuickReplyById(id);
                setQuickReply(resp.data);

                const { data } = await getQuickReplyOptions();
                setDefaultOptions(data.results);
                setLoading(false);
            }

            fetchData();
        }

        return () => { mounted = false; }
    }, [id]);

    const loadOptions = async (e: any) => {
        const { data } = await getQuickReplyOptions(e);
        if (data.results.length > 0) {
            setQuickReplies(data.results);
            return quickReplies;
        }
        return [];
    };

    const handleCopy = async (e: any) => {
        e.preventDefault();
        try {
            setError("");
            if (selected.id === 0) {
                setError("Quick reply from is required");
                return;
            }
            console.log(selected);
            setDisabled(true);
            await copyRepliesFrom({
                "from_quick_reply": selected.id,
                "to_quick_reply": Number(id)
            });
            setDisabled(false);
            swal("Afinidata", "Quick replies copied succesfully", "success")
                .then(() => {
                    history.push(`/admin/quick-replies/show?label=${quickReply.label}`);
                });
        } catch(err:any) {
            console.log(err);
            setDisabled(false);
            swal("Afinidata", "Error", "error");
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div className="row">
            <div className="col-sm-6 mx-auto">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h5>Copy replies from</h5>
                            </div>
                            <div className="col-sm-6 text-right">
                                <Link
                                    to={`/admin/quick-replies/show?label=${quickReply.label}`}
                                    className="btn btn-secondary btn-sm" >
                                    <i className="fa fa-arrow-left"></i> Volver
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleCopy}>
                            <div className="form-group">
                                <label htmlFor="">From quick reply</label>
                                <div>
                                    <h4>{selected.label}</h4>
                                    <AsyncSelect
                                        name="quick-reply"
                                        getOptionLabel={(option: any) => option.label}
                                        getOptionValue={(option: any) => option.id}
                                        value={selected.label}
                                        defaultOptions={defaultOptions}
                                        loadOptions={loadOptions}
                                        onChange={(newValue: any) => { setSelected(newValue); }}
                                    />
                                </div>
                                {error &&
                                    <span className="help-block text-danger mt-2">
                                        {error}
                                    </span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="">To quick reply</label>
                                <p className="static-control"> Id: {quickReply?.id} - Label: {quickReply?.label} </p>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block"
                                    disabled={disabled}>
                                    <i className="fa fa-copy"></i> Copy
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CopyReplyFrom
