import { useFormik } from 'formik';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';

import Loading from '../../components/Loading';
import { editUserGroup, getUserGroupById, getUserGroupsOptions } from '../../data/user-groups';
import { getLicenseOptions } from '../../data/licenses';
import { UserGroupEdit } from 'interfaces/UserGroup';
import { Option } from 'interfaces/shared';


const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    parent: Yup.number(),
    country: Yup.string().max(100),
    region: Yup.string().max(100),
    license: Yup.number()
});

type Props = {
    id: string
}

const EditUserGroup = ({match}:RouteComponentProps<Props>) => {
    const history = useHistory();
    const id = match.params.id
    const [loading, setLoading] = useState<boolean>(true);
    const [groupOptions, setGroupOptions] = useState<Option[]>([])
    const [licenseOptions, setLicenseOptions] = useState<Option[]>([])
    const [initialValues, setInitialValues] = useState<UserGroupEdit>({id: '', name: '', parent: '', license: ''})

    useEffect(()=> {
        let mounted = true;

        if(mounted){
            getData(id);
        }

        return function cleanUp(){
            mounted = false;
        }
    },[id])

    const getData = async (id:any):Promise<any> =>{
        const [resp, groups_resp, licenses_resp] = await Promise.all([
            getUserGroupById(id),
            getUserGroupsOptions(),
            getLicenseOptions()
        ]);

        let data = {
            'id' : resp.data['id'] || '',
            'name' : resp.data['name'] || '',
            'parent' : resp.data['parent'] || '',
            'country': resp.data['country'] || '',
            'region': resp.data['region'] || '',
            'license' : resp.data['license'] || '',
        }
        setInitialValues(data);
        setGroupOptions(groups_resp.data);
        // @ts-ignore
        setLicenseOptions(licenses_resp.data);
        setLoading(false);
    }

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            await editUserGroup(id, payload);
            await swal('Afinidata','Grupo actualizado correctamente','success');
            history.push('/admin/user-groups');
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Editar nombre grupo</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name"
                                     className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Parent</label>
                                    <select name="parent" className="form-control"
                                        defaultValue={formik.values.parent}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {groupOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <input type="text" name="country"
                                     className={`form-control ${
                                        formik.errors.country && formik.touched.country ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.country && formik.touched.country ? (
                                        <div className="invalid-feedback">{formik.errors.country}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Region</label>
                                    <input type="text" name="region"
                                     className={`form-control ${
                                        formik.errors.region && formik.touched.region ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.region}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.region && formik.touched.region ? (
                                        <div className="invalid-feedback">{formik.errors.region}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">License</label>
                                    <select name="license" className="form-control"
                                        defaultValue={formik.values.license}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {licenseOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href="/admin/user-groups/" className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUserGroup;
