import './style.css';
import { Bot } from 'interfaces/Bot';
import Chat from './components/Chat';
import { detailBot } from 'data/bots';
import { useEffect, useState, useContext, useCallback } from 'react';
import Loading from '../../components/Loading';
import MessagesUsers from './components/MessagesUsers';
import { RouteComponentProps } from 'react-router-dom';
import ManagementAttributes from './components/ManagementAttributes';
import { MessengerUserContext } from './MessengerUserContextProvider'
import SendToPosition from './actions/SendToPosition';
import AsignarHotTrigger from './actions/AsignarHotTrigger';
import { startBot, stopBot } from 'data/live-chat';
import swal from 'sweetalert';
import LiveChatOptions from './components/liveChatOptions';

interface IProps {
    botid: string;
}

const LiveChat = ({ match }: RouteComponentProps<IProps>) => {
    const botid = match.params.botid;
    const [bot, setBot] = useState<Bot>({ id: '', name: '', url: '', description: '' });
    const [loading, setLoading] = useState<boolean>(true);

    const { messengerUser, setMessengerUser } = useContext(MessengerUserContext);

    const [showAction1, setShowAction1] = useState(false);
    const [showAction2, setShowAction2] = useState(false);

    const resetUser = useCallback(() => {
        setMessengerUser({});
    }, [setMessengerUser])

    useEffect(() => {
        let mounted = true;
        
        if (mounted) {

            async function fetchData() {
                try {
                    const bots = await detailBot(botid);
                    setBot(bots.data);
                    setLoading(false)
                } catch(err:any) {
                    console.log(err);
                }
            }
            fetchData();
        }

        return () => { mounted = false; resetUser() }

    }, [botid, resetUser]);

   
    const startChatBot = async () => {
        try {
            await startBot(messengerUser.id, botid);
            swal('afinidata', 'El bot se ha activado', 'success');
        } catch(err:any) {
            console.log(err);
            swal('afinidata', 'Ocurrio un error, intente de nuevo', 'danger');
        }
    }

    const stopChatBot = async () => {
        try {
            await stopBot(messengerUser.id, botid);
            swal('afinidata', 'Se ha pausado el bot', 'success');
        } catch(err:any) {
            console.log(err);
            swal('afinidata', 'Ocurrio un error, intente de nuevo', 'danger');
        }
    }

    const sendToPositionInSession = async () => {
        setShowAction1(!showAction1);
    }

    const asignarSecuenciaHotTrigger = async () => {
        setShowAction2(!showAction2);
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="row bg-primary text-light mb-4"
                style={{ borderRadius: '10px', padding: '10px' }}>
                <LiveChatOptions bot={bot}/>
                <div className="col-md-4">
                    <p className="m-1"><b>Name</b>: {bot.name}</p>
                </div>
                <div className="col-md-4">
                    <p className="m-1"><b>Description</b>: {bot.description}</p>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-3">
                            {Object.keys(messengerUser).length > 0 && (
                                <>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-sm btn-success"
                                            onClick={startChatBot}><i className="fa fa-play"></i> Activar chatbot</button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-md-3">
                            {Object.keys(messengerUser).length > 0 && (
                                <>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-sm btn-danger"
                                            onClick={stopChatBot}><i className="fa fa-pause"></i> Pausar chatbot</button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-md-3">
                            {Object.keys(messengerUser).length > 0 && (
                                <>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-sm btn-warning"
                                            onClick={sendToPositionInSession}><i className="fa fa-forward"></i> Redirigir a sesión</button>
                                    </div>

                                    {showAction1 &&
                                        <SendToPosition onFinish={() => setShowAction1(false)} />
                                    }
                                </>
                            )}
                        </div>
                        <div className="col-md-3">
                            {Object.keys(messengerUser).length > 0 && (
                                <>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-sm btn-primary"
                                            onClick={asignarSecuenciaHotTrigger}><i className="fa fa-bolt"></i> Asignar a secuencia</button>
                                    </div>

                                    {showAction2 &&
                                        <AsignarHotTrigger onFinish={() => setShowAction2(false)} />
                                    }
                                </>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <MessagesUsers bot={botid} />
                </div>
                <div className="col-md-5">
                    {Object.keys(messengerUser).length > 0 &&
                        <Chat />
                    }
                </div>
                <div className="col-md-4">
                    {Object.keys(messengerUser).length > 0 &&
                        <ManagementAttributes />
                    }
                </div>
            </div>
        </>
    )
}

export default LiveChat;
