import { useFormik } from 'formik';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from "react-router-dom"

import { getUserGroupById, createUserGroupCode, getGroupCode, editUserGroupCode } from '../../data/user-groups';
import { UserGroupCode, UserGroup } from 'interfaces/UserGroup';
import Loading from '../../components/Loading';


const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
    group: Yup.number().required(),
});

interface IProps {
    groupId: string
    id?: string
}

const GroupCodeForm = ({match}: RouteComponentProps<IProps>) => {
    const id = match.params.id || '';
    const groupId = match.params.groupId;
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [group, setGroup] = useState<UserGroup>({ id: '', name: '', programs:[]})
    const [initialValues, setInitialValues] = useState<UserGroupCode>({group: groupId, code: '', available: true})

    useEffect(()=> {
        let mounted = true;

        if(mounted && loading){

            if(id){
                getData(groupId, id);
            }else{
                getUserGroupById(groupId)
                .then((resp:any) => {
                    setGroup(resp.data);
                    setLoading(false);
                })
                .catch(err=> console.log(err));
            }
        }

        return function cleanUp(){
            mounted = false;
        }
    },[id, groupId, loading, initialValues])

    const getData = async (groupId:any, id:any):Promise<any> =>{
        const [resp, respCode] = await Promise.all([
            getUserGroupById(groupId),
            getGroupCode(id, '', 1, false, false)
        ]);

        setInitialValues(respCode.data.results[0]);
        setGroup(resp.data);
        setLoading(false);
    }

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            if(id){
                await editUserGroupCode(id, payload);
            }else{
                await createUserGroupCode(payload);
            }
            await swal('Afinidata','Código creado correctamente','success');
            history.push(`/admin/user-groups/${groupId}`);
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Código de grupo</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Group: {group.name}</label>
                                    <input type="text" name="group"
                                     className={`form-control ${
                                        formik.errors.group && formik.touched.group ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.group}
                                    disabled={true}
                                    hidden={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.group && formik.touched.group ? (
                                        <div className="invalid-feedback">{formik.errors.group}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code"
                                     className={`form-control ${
                                        formik.errors.code && formik.touched.code ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.code && formik.touched.code ? (
                                        <div className="invalid-feedback">{formik.errors.code}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">
                                        <input type="checkbox"
                                            name="available"
                                            value={initialValues.available}
                                            defaultChecked={initialValues.available}
                                            onChange={formik.handleChange}
                                        />
                                        <span className="ml-2">Available</span>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href={`/admin/user-groups/${groupId}`} className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupCodeForm
