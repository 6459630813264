import { useState, useEffect } from 'react';
import SearchForm from '../../components/SearchForm';
import Loading from '../../components/Loading';
import Pagination from 'react-js-pagination';
import { deleteQuickReply, getQuickReplies } from 'data/quick-replies';
import { QuickReply } from 'interfaces/QuickReply';
import { Link } from 'react-router-dom';
import swal from "sweetalert";


const QuickRepliesPage = () => {

    const [search, setSearch] = useState("");
    const [quickReplies, setQuickReplies] = useState<QuickReply[]>([]);
    const [loading, setLoading] = useState(true);

    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                setActivePage(1);
                const { data } = await getQuickReplies(search, 1);
                const { results, count } = data;
                setQuickReplies(results);
                setCount(count);
                setLoading(false);
            }
            fetchData();
        }

        return () => { mounted = false; }
    }, [search])

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page);
        const { data } = await getQuickReplies(search, page);
        const { results, count } = data;
        setQuickReplies(results);
        setCount(count);
        setLoading(false);
    }

    const handleDelete = async (id: any) => {
        try {
            await deleteQuickReply(id);
            swal("Afinidata", "Borrado correctamente", "success").then(() => {
                const newData = quickReplies.filter(item => item.id !== id);
                setQuickReplies(newData);
            })
        } catch(err:any) {
            swal("Afinidata", err, "error");
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-sm-8">
                            <h3>Catálogo de quick replies</h3>
                        </div>
                        <div className="col-sm-4 text-right">
                            <Link to="/admin/quick-replies/add"
                                className="btn btn-sm btn-primary">
                                <i className="fa fa-plus"></i> New
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                        search={search}
                        setSearch={setSearch}
                    />
                    {(quickReplies && quickReplies.length > 0) ? (
                        <>
                            <table className="table table-sm" width="100%">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Label</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quickReplies.map((item: QuickReply) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.label}</td>
                                            <td className="text-right">
                                                <Link to={`/admin/quick-replies/show?label=${item.label}`}
                                                    className="btn btn-warning btn-sm">
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                                <button className="btn btn-danger btn-sm ml-2"
                                                    onClick={() => handleDelete(item?.id)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={50}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay data.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default QuickRepliesPage
