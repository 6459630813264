import MessageDetail from './MessageDetail';
import Loading from '../../../components/Loading';
import { useContext, useEffect, useState } from 'react';
import { MessengerUserContext } from '../MessengerUserContextProvider';
import { getConversation } from '../../../data/live-chat';

// @ts-ignore
import uuid from 'react-uuid';
import SendMessage from './SendMessage';
import { Message } from 'interfaces/Message';
import socketIOClient from 'socket.io-client';
import moment from 'moment';
import 'moment/locale/es';
import IntentModalSelect from './IntentModalSelect';
import SendPushModal from './SendPushModal';
moment.locale('es');
interface IntentData {
    isOpen: boolean,
    title: string,
    trainingText: string,
}

const Chat = () => {
    const [data, setData] = useState<Message[]>([]);
    const [loading, setLoading] = useState(true);
    const [showSendPushModal, setShowSendPushModal] = useState(false)
    const { messengerUser } = useContext(MessengerUserContext);
    const [ intentData, setIntentData] = useState<IntentData>({isOpen: false, title:"", trainingText:""});

    useEffect(() => {
        const endpoint = process.env.REACT_APP_NOTIFICATIONS_API || "";
        let socket = socketIOClient(endpoint, { rejectUnauthorized: false, transports: ["websocket"] });

        function fetchMessages() {
            setLoading(true);
            if (Object.keys(messengerUser).length > 0) {
                const { bot_id, bot_channel_id, user_channel_id } = messengerUser
                getConversation(bot_id, bot_channel_id, user_channel_id).then((response: any) => {
                    if (response.data) {
                        setData(response.data.data);
                    } else {
                        setData([]);
                    }
                    setLoading(false);
                    let el = document.getElementById("messages");
                    if (el) {
                        el.scrollTop = el.scrollHeight
                    }
                }).catch(err => {
                    console.log(err)
                    setLoading(false);
                });
            }
        }

        let mounted = true;

        if (mounted) {

            setLoading(true);
            setData([]);
            fetchMessages();

            socket.on(messengerUser.user_channel_id, (data: any) => {
                fetchMessages();
            });

        }

        return () => {
            mounted = false;
            socket.disconnect();
        }
    }, [messengerUser]);

    useEffect(() => {
        let el = document.getElementById("messages");
        if (el) {
            el.scrollTop = el.scrollHeight
        }
    }, [data]);

    const onMessageSend = (message: Message) => {
        setData([...data, message]);
    }

    const handleReloadMessages = () => {
        if (Object.keys(messengerUser).length > 0) {
            const {
                bot_id,
                bot_channel_id,
                user_channel_id
            } = messengerUser
            getConversation(bot_id, bot_channel_id, user_channel_id).then((response: any) => {
                setData(response.data.data || []);
                setLoading(false);
                let el = document.getElementById("messages");
                if (el) {
                    el.scrollTop = el.scrollHeight
                }
            }).catch(err => console.log(err));
        }
    }

    const intentModalTitles: {[name:string]:string} = {
        'addIntent': 'Agregar Intent'
    }

    const handleIntentModalSelect = (type: string, trainingText:string) => {
        
        setIntentData({isOpen:true, title: intentModalTitles[type], trainingText} as IntentData) 
    }

    return (
        <>
            <SendPushModal showFormModal={showSendPushModal} onCloseFormModal={function (): void {
               setShowSendPushModal(false)
            } } ></SendPushModal>
            <IntentModalSelect show={intentData.isOpen} title={intentData.title}
            trainingText={intentData.trainingText}
                handleUpdateOpen={function (isOpen: boolean): void {setIntentData({...intentData, isOpen:isOpen})} }/>
            <div className="row">
                <div className="col-sm-6">
                    <p className="m-0"><span className="font-weight-bold">Last Message: </span>{messengerUser?.last_user_message && (moment(messengerUser?.last_user_message).fromNow())}</p>
                    <p className="m-0"><span className="font-weight-bold">Last Interaction: </span>{messengerUser?.last_channel_interaction && (moment(messengerUser?.last_channel_interaction).fromNow())}</p>
                    <p className="m-0"><span className="font-weight-bold">24hr window: </span>{messengerUser?.window}</p>
                </div>
                <div className="col-sm-6 text-right" >
                    <button className="btn btn-warning btn-sm mb-2 mx-2" title="EnviarPush"
                        onClick={() => setShowSendPushModal(true)}>
                        <i className="fa fa-bell"></i> Enviar Push Notification
                    </button>
                    <button className="btn btn-secondary btn-sm mb-2 mx-2" title="Actualizar"
                        onClick={handleReloadMessages}>
                        <i className="fa fa-sync"></i> Actualizar
                    </button>
                </div>
            </div>
            <div className="messages" id="messages" style={{ minHeight: '400px' }}>
                {loading && <Loading text="Cargando mensajes" size="1rem" />}

                {!loading && data.length > 0 && (

                    data.map((item: any) => {
                        return item.message_sender === 'bot' ? (
                            <MessageDetail type="guest" item={item} key={uuid()} />
                        ) : (
                            <MessageDetail type="user" item={item} key={uuid()} handleIntentAction={handleIntentModalSelect} />
                        )
                    })
                )}

                {!loading && data.length === 0 && (
                    <div className="alert alert-info">No hay mensajes</div>
                )}
            </div>

            <SendMessage onFinish={onMessageSend} />
        </>
    )
}

export default Chat;
