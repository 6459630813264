import Loading from 'components/Loading';
import { setUserGroupType } from 'data/user-groups';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const BotUserRows = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const GROUP_DASHBOARD_KEY = process.env.REACT_APP_GROUP_DASHBOARD_KEY
    const URL = process.env.REACT_APP_CORE_URL; 

    const formatDate = (str_date:string) =>{
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        // @ts-ignore
        return new Date(str_date).toLocaleDateString([], options);
    }

    const set_user_type = (user_id:number, type: boolean) => {
        setLoading(true)
        setUserGroupType(user_id, type) // send type as normal if it come as true and test user other wise
            .then(({data}) => {
                swal('Afinidata', `usuario ${data.user_name} cambiado a tipo ${data.user_type? "test": "normal"}`, 'success')
                props.onChangeUser(data)
            })
            .catch(() => swal('Afinidata', "Error al comunicarse con la app", 'error'))
            .finally(() => setLoading(false))
    }

    return (
         loading?  <Loading /> :
        <>
            { props.tableData.map( (item:any) => (
                <tr className="p1" key={item.id}>
                    <td>
                        (<a rel="noreferrer" target="_blank" href={`${URL}/users/${item.user.id}/?attrk=${GROUP_DASHBOARD_KEY}`}>{item.user.id}</a>) {item.user.first_name} {item.user.last_name}
                    </td>
                    <td className="row">{item.user.instances.map( (i:any) => (
                        <span key={i.id}>(<a rel="noreferrer" target="_blank" href={`${URL}/instances/${i.id}/?attrk=${GROUP_DASHBOARD_KEY}`}>{i.id}</a>)
                             {i.name}</span>
                    ))}</td>
                    <td>{formatDate(item.user.created_at)}</td>
                    <td>{formatDate(item.created_at)}</td>
                    <td>{item.code}</td>
                    <td>
                        <div className="custom-control custom-checkbox">
                            <input checked={item.user.test_user} type="checkbox" className="custom-control-input" id={`test_user_${item.id}`}
                                onChange={() => set_user_type(item.user.id, !item.user.test_user)}/>
                            <label className="custom-control-label" htmlFor={`test_user_${item.id}`}>Usuario de Prueba</label>
                        </div>
                    </td>
                    <td>
                        <Link to={`/admin/user-groups/${props.groupId}/bot-user/${item.id}/delete`} className="btn btn-danger btn-sm" title="eliminar del grupo">
                            <i className="fa fa-times"></i>
                        </Link>
                    </td>
                </tr>
            ))}
        </>
    )
}

export default BotUserRows
