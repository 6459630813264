
type Props ={
    data: any,
    onClickModalChild: any,
    oncClickModalEncargado: any,
    onClickModalDelete: any,
}

var styles = {
    openModal :{
        color: '#4e73df',
        textDecoration: 'none',
        cursor: 'pointer'
    }
}

const PartnerInfoRow = ({data, onClickModalChild, oncClickModalEncargado, onClickModalDelete}:Props) => {
    //console.log(data)
    return (
        <tr>
            <td style={{width: "25%"}}>
                <span style={styles.openModal} onClick={(e: any) => onClickModalChild(e, data)}>
                    <i className="fas fa-eye"></i>
                    <span className="ml-1">{data.name}</span>
                </span>
            </td>
            <td style={{width: "25%"}}>
                <span style={styles.openModal} onClick={( e: any ) => oncClickModalEncargado(e, data)}>
                    <i className="fas fa-eye"></i>
                    <span className="ml-1">{data.username}</span>
                </span>
            </td>
            <td style={{ width: '10%'}}>{data.month}</td>
            <td style={{ width: '15%'}}>{data.birthday}</td>
            <td style={{ width: '5%'}}>{data.tel}</td>
            <td style={{ width: '5%'}}>{data.address}</td>
            <td style={{ width: '15%', textAlign: "center"}}>
                { String(data.risk) === String(0) && (
                    <span className="badge badge-info" style={{width: "80%"}}>BAJO</span>
                )}

                { String(data.risk) === String(1) && (
                    <span className="badge badge-warning" style={{width: "80%"}}>MEDIO</span>
                )}

                { String(data.risk) === String(2) && (
                    <span className="badge badge-danger" style={{width: "80%"}}>ALTO</span>
                )}
            </td>
            <td style={{ width: '5%'}}>
            <button type="button" className="btn btn-danger btn-sm ml-2"
                    onClick={(e)=>onClickModalDelete(e, data)}><i className="fa fa-eraser"></i></button>
            </td>
        </tr>
    )
}

export default PartnerInfoRow
