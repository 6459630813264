import { Link } from 'react-router-dom';

const PlatformRows = (props: any) => {

    const formatDate = (str_date:string) =>{
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        // @ts-ignore
        return new Date(str_date).toLocaleDateString([], options);
    }

    return (
        <>
            { props.tableData.map( (item:any) => (
                <tr className="p1" key={item.id}>
                    <td>{item.user.first_name ? item.user.first_name + item.user.last_name: item.user.username}</td>
                    <td>{item.role}</td>
                    <td>{formatDate(item.created_at)}</td>
                    <td><Link to={`/admin/user-groups/${props.groupId}/platform-user/${item.id}/edit`} className="btn btn-warning btn-sm" title="Editar usuario"><i className="fa fa-edit"></i></Link></td>
                </tr>
            ))}
        </>
    )
}

export default PlatformRows

