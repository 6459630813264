import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { assignEngagementSession } from 'data/live-chat';
import swal from 'sweetalert';


interface IProps {
    bot: any
}


const LiveChatOptions = ({bot}: IProps) => {
     
    const runAssignEngagementSession = async () => {
        try {
            await assignEngagementSession(bot.id);
            swal('afinidata', 'El bot se ha activado', 'success');
        } catch(err:any) {
            swal('afinidata', 'Ocurrio un error, intente de nuevo', 'danger');
        }
    }



    return (
        <div className="col-md-4">
          
            <div className="d-inline-block mr-2 btn-group float-left">
                <button type="button" className="btn btn-dark btn-sm rounded" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">{/* 
                // @ts-ignore */}
                <FontAwesomeIcon icon={faEllipsisV}/>
                </button>
                <div className="dropdown-menu mb-2">
                    <button className="dropdown-item" type="button" 
                            onClick={runAssignEngagementSession}>
                        Asignar Sesi&oacute;n de recuperaci&oacute;n
                    </button>
                </div>
            </div>
            <h5 className="m-1"><b>Bot</b>: # {bot.id}</h5>
        </div>
    )
}

export default LiveChatOptions