import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination'
import { Instance } from 'interfaces/Instance';
// import { advanceSearchForInstances, getInstances } from 'data/instances';
import { advanceSearchForInstances } from 'data/instances';
import Loading from '../../../components/Loading';
import TableInstanceItem from './TableInstanceItem';
import PeopleModalInstance from '../PeopleModalInstance';
import SearchForm from '../../../components/SearchForm';
import { Filtro } from 'interfaces/Filtro';
import AdvanceSearch from './AdvanceSearch';
// @ts-ignore
import uuid from 'react-uuid';
import PeopleModalUser from '../PeopleModalUser';
declare var $:any;

const ListInstances = () => {
    const [instance, setInstance] = useState<string>();
    const [instances, setInstances] = useState<Instance[]>([]);
    const [ user, setUser ] = useState("");
    const [count, setCount] = useState(1);
    const [activePage, setActivePage] = useState(Number(localStorage.getItem('peopleCurrentPage')) || 1);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState("");
    const [filtros, setFiltros] = useState<Filtro[]>([]);
    const [loading2, setLoading2] = useState(true);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            async function fetchData(){
                try{
                    setLoading2(true)
                    let currentPage = 1;
                    let resp:any;

                    // if(filtros.length > 0){
                    //     resp = await advanceSearchForInstances(filtros, search,currentPage)
                    // } else {
                    //     resp = await getInstances(search, currentPage)
                    // }

                    resp = await advanceSearchForInstances(filtros, search,currentPage);
                    console.log(resp);
                    localStorage.setItem('peopleCurrentPage',"1");
                    const {results, count} = resp.data;
                    setInstances(results);
                    setActivePage(currentPage);
                    setCount(count);
                    setLoading(false);
                    setLoading2(false)
                }catch(err:any){
                    console.log(err)
                }
            }
            fetchData();
        }

        return () => {mounted = false;}
    },[search, filtros])

    const handlePageChange = async (page: number) => {
        setLoading2(true);
        setActivePage(page)

        localStorage.setItem('peopleCurrentPage',String(page));

        let resp: any;

        // if(filtros.length > 0){
        //     resp = await advanceSearchForInstances(filtros, search, page)
        // } else {
        //     resp = await getInstances(search, page)
        // }
        resp = await advanceSearchForInstances(filtros, search, page)
        const {results} = resp.data;
        setInstances(results);
        setLoading2(false);
    }

    const handleSubmit = (items: Filtro[]) => {
        setFiltros(items);
    }

    const handleSelectUser = (id:string) => {
        setUser(id);
        $('#userModal').modal("toggle");
    }

    if(loading){
        return <Loading />
    }

    return (
        <>
            {instance && <PeopleModalInstance instanceId={instance}/>}
            {user && <PeopleModalUser userId={user}/>}

            <AdvanceSearch
            type="instances"
            onSubmit={(items:Filtro[]) => handleSubmit(items)}
            />

            <hr/>
            <SearchForm
            search={search}
            setSearch={setSearch}
            />

            { !loading2 && instances.length === 0 &&
                <div className="alert alert-info">No hay data</div>
            }

            { loading2 &&
                <Loading size="2rem" />
            }

            { !loading2 && instances.length > 0 &&
                <>
                    <p className="small font-weight-bold text-right">Total registros: {count} Instances</p>
                    <table className="table table-hover table-sm"
                    style={{width:"100%",fontSize:'0.8rem'}}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <td>Created at</td>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {instances.map( (item:any) =>  (
                                <TableInstanceItem key={uuid()} id={item.id}
                                name={item.name} createdAt={item.created_at}
                                user={item.user}
                                handleClick={setInstance}
                                onSelectUser={handleSelectUser}/>
                            ))}
                        </tbody>
                    </table>
                    <p className="small font-weight-bold text-right">Total registros: {count} Instances</p>

                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={50}
                        totalItemsCount={count}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                    />
                </>
            }
        </>
    )
}

export default ListInstances
