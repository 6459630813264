import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Bot } from '../../interfaces/Bot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash, faRobot, faCopy } from '@fortawesome/free-solid-svg-icons';
import { getBotsList } from '../../data/bots';
import Loading from 'components/Loading';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';

const ListBot = () => {
    const [ bots, setBots ] = useState<Bot[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);

    useEffect( () =>{
        let mounted = true;
        getBotsList(search, 1)
        .then( (resp: any) => {
            if(mounted){
                const { count, results} = resp.data
                setBots(results);
                setLoading(false);
                setCount(count)
            }
        })
        .catch( (err: any) => console.log(err));

        return function cleanup(){
            mounted = false;
        }
    },[history, search]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        const resp:any = await getBotsList(search, page)
        const {results, count} = resp.data;
        setCount(count);
        setBots(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">Listado de bots</h4>
                        </div>
                        <div className="col-xs-12 col-md-6 ">
                            <Link to="/admin/bots/add" className="btn btn-primary">{/* 
                        // @ts-ignore */}
                                <FontAwesomeIcon icon={faRobot} /> Crear bot
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    { bots && bots.length > 0 ? (
                        <>
                        <div className="table-responsive">
                            <table className="table"
                            id="data">
                                <thead>
                                    <tr className="text-center">
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Url</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { bots.map( item => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{item.url}</td>
                                            <td className="text-right">
                                                <div className="row">
                                                    <div className="col-xs-4 col-md-12 ">
                                                        <Link
                                                        to="/"
                                                        title="Clonar bot"
                                                        className="btn btn-outline-primary btn-sm mb-2"
                                                        style={{ width: '120px'}}>{/* 
                                                    // @ts-ignore */}
                                                            <FontAwesomeIcon icon={faCopy} /> <span>Clonar bot</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-xs-4 col-md-12">
                                                        <Link
                                                        to={`/admin/bots/configure/${item.id}`}
                                                        title="Configurar bot"
                                                        className="btn btn-outline-primary btn-sm mb-1"
                                                        style={{ width: '120px'}}>{/* 
                                                    // @ts-ignore */}
                                                            <FontAwesomeIcon icon={faCog} /> <span>Configurar</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-xs-4 col-md-12">
                                                        <Link
                                                        to={`/admin/bots/${item.id}/delete`}
                                                        title="Borrar bot"
                                                        className="btn btn-outline-danger btn-sm mb-1"
                                                        style={{ width: '120px'}}>{/* 
                                                    // @ts-ignore */}
                                                            <FontAwesomeIcon icon={faTrash} /> <span>Borrar</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={50}
                            totalItemsCount={count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay bots.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListBot
