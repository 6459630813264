import * as Yup from "yup";
import swal from 'sweetalert';
import { useFormik } from 'formik';
import Logo from "../../components/Logo"
import {useEffect, useState} from 'react'
import Loading from '../../components/Loading';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { passwordChange, verifyPasswordResetToken } from 'data/users';

type Props = {
    token: string
};

const validationSchema = Yup.object().shape({
    password: Yup.string().min(6,'Minimo 6 caracteres').required('Clave es requerida'),
    password_confirm: Yup.string()
       .oneOf([Yup.ref('password'), ''], 'Las claves no coinciden')
});

const PasswordReset = ({match}:RouteComponentProps<Props>) => {
    const token = match.params.token;
    const [username, setUsername] = useState<string>('')
    const history = useHistory();
    const initialValues = {
        password: '',
        password_confirm: ''
    };
    const [loading, setLoading ] = useState<boolean>(true);

    const storeNewPassword = async (values: any) => {
       try{
            await passwordChange(token, values);
            swal('Afinidata','La clave fue cambiada correctamente','success')
            .then(() => {
                history.push("/");
            })
       } catch( err ){
           swal('Afinidata','Ocurrio un error, intente de nuevo','error');
       }
    }



    useEffect(() => {
        let mounted = true;

        if(mounted){
            const fetchData = async() => {
                try{
                    const resp:any = await verifyPasswordResetToken(token);
                    setUsername(resp.data.user)
                    setLoading(false);
                } catch(err:any){
                    console.log(err)
                    swal('Afinidata',err.response.data.message, 'error')
                    .then(() => {
                        history.push('/password-forget');
                    })
                }
            }
            fetchData()
        }

        return () => {mounted = false};
    },[history, token]) ;

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            storeNewPassword(values);
        }
    });

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card">
                            <div className="card-header">
                                <Logo/>
                            </div>
                            <div className="card-body">
                                {loading ?
                                    <Loading />
                                    :
                                    <>
                                        <h4>Cambiar clave</h4>
                                        <hr/>
                                        <p>Hola, <strong>{username}</strong></p>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="">Clave</label>
                                                <input type="password"
                                                placeholder="Ingrese clave"
                                                name="password"
                                                value={formik.values.password}
                                                className={`form-control ${
                                                    formik.errors.password && formik.touched.password ? "is-invalid" : ""
                                                }`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                                {formik.errors.password && formik.touched.password ? (
                                                    <div className="invalid-feedback">{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Confirmar clave</label>
                                                <input type="password"
                                                name="password_confirm"
                                                placeholder="confirme su clave"
                                                value={formik.values.password_confirm}
                                                className={`form-control ${
                                                    formik.errors.password_confirm && formik.touched.password_confirm ? "is-invalid" : ""
                                                }`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                                {formik.errors.password_confirm && formik.touched.password_confirm ? (
                                                    <div className="invalid-feedback">{formik.errors.password_confirm}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block"
                                                type="submit">Guardar</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PasswordReset;
