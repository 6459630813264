import { useState, useEffect } from 'react';
import { RouteComponentProps } from "react-router-dom";
import EntityAddEdit from '../../components/EntityAddEdit';
import Loading from 'components/Loading';
import { Entity } from 'interfaces/Entity';
import { getEntityById } from "data/entities";


type Props = {
    id: string
}


const EntityDetail = ({match}:RouteComponentProps<Props>) => {

    const id = match.params.id;

    const [ entity, setEntity ] = useState<Entity>({name:'',description:''});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;
        
        getEntityById(id)
        .then( resp => {
            if(mounted){
                setEntity(resp.data)
                setLoading(false);
            }
        })
    },[id])

    
    if(loading){
        return <Loading />
    }

    return (
        <>
            <EntityAddEdit id={Number(id)} entidad={entity} type="edit" nameBtn="Editar"/>
        </>
    )

}

export default EntityDetail;