// import React from 'react';
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SinglePushModal from "./Push-modal";
import { PushDeleteModal } from "./PushDeleteModal";
import { getFutureUserPremium } from "services/mobile-app.service";

const example = [
  {
    id: 1,
    email: "example@1com",
    time: 1,
    used: true,
    account_type: 0,
  },
  {
    id: 2,
    email: "example2@2com",
    time: 10,
    used: true,
    account_type: 0,
  },
  {
    id: 3,
    email: "example2@2com",
    time: 20,
    used: true,
    account_type: 1,
  },
  {
    id: 4,
    email: "example3@3com",
    time: 30,
    used: true,
    account_type: 0,
  },
];

function PushEvents() {
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [users, setUsers] = useState<any[]>([]);
  const getAllPrem = async () => {
    let userPremium = await getFutureUserPremium();
    let temp = userPremium.data.subscriptions.map((item: any, inx: number) => {
      return { ...item, id: inx };
    });
    if (userPremium.data.ok) setUsers(temp);
  };
  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "time",
      headerName: "Tiempo",
      flex: 1,
      editable: false,
    },
    {
      field: "account_type",
      headerName: "Profecionales",
      flex: 2,
      editable: false,
      valueGetter:(val:number) => val==1?'Si':'No'
    },
    {
      field: "used",
      headerName: "Usado",
      flex: 2,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Borrar",
      width: 140,
      editable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModalDelete(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPrem();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => {
            setToEdit({ reset: true });
            setModal(true);
          }}
        >
          Agregarle premium a un usuario futuro.
        </Button>
      </div>
      <DataGrid
        rows={users.length != 0 ? users : example}
        //@ts-ignore
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "date_to_send", sort: "asc" }],
          },
        }}
      />
      <SinglePushModal
        visible={modal}
        close={() => {
          setModal(false);
          getAllPrem();
        }}
      />
      <PushDeleteModal
        visible={modalDelete}
        close={()=>setModalDelete(false)}
        toEdit={toEdit}
        refresh={getAllPrem}
      />
    </div>
  );
}

export default PushEvents;
