import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Grid,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { LanguageMap } from "./Push-modal";

type Props = {
  setAgeReq: (ageReq: boolean) => void;
  ageReq: boolean;
  languageMap: LanguageMap;
  modifyLanguageMap: (key: keyof LanguageMap, attr: string, val: any) => void;
  output: number;
  channel: number;
  spanishTemplete: any[];
  englishTemplete: any[];
  portTemplete: any[];
  setAgeMin: (programs: number) => void;
  ageMin: number | undefined;
  setAgeMax: (programs: number) => void;
  ageMax: number | undefined;
  imageMedia: any[];
  videoMedia: any[];
};

const output_map = ["Activity", "Article", "Session", "Milestones"];

function PushModalWA({
  ageReq,
  setAgeReq,
  languageMap,
  modifyLanguageMap,
  output,
  channel,
  spanishTemplete,
  englishTemplete,
  portTemplete,
  ageMax,
  setAgeMax,
  ageMin,
  setAgeMin,
  imageMedia,
  videoMedia,
}: Props) {
  // console.log('>>>>>>>>>.', tem)
  return (
    <Grid container p={1}>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row" }}
        p={1}
        spacing={1 / 2}
      >
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox checked={ageReq} onClick={() => setAgeReq(!ageReq)} />
            }
            label="Restrict by age"
          />
        </Grid>
        <Grid item xs={4} visibility={!ageReq ? "hidden" : "visible"}>
          <TextField
            label="Age Min"
            fullWidth
            disabled={!ageReq}
            value={ageMin}
            onChange={(el) => setAgeMin(el.target.value as unknown as number)}
          />
        </Grid>
        <Grid item xs={4} visibility={!ageReq ? "hidden" : "visible"}>
          <TextField
            label="Age Max"
            fullWidth
            disabled={!ageReq}
            value={ageMax}
            onChange={(el) => setAgeMax(el.target.value as unknown as number)}
          />
        </Grid>
      </Grid>
      {Object.keys(languageMap).map((el) => {
        const typedLanguage = el as keyof LanguageMap;
        const templetes =
          typedLanguage == "spanish"
            ? spanishTemplete
            : typedLanguage == "english"
            ? englishTemplete
            : portTemplete;
        return (
          <Grid
            container
            style={{ display: "flex", flexDirection: "row" }}
            p={1}
            spacing={1 / 2}
            key={typedLanguage}
          >
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={languageMap[typedLanguage].on}
                    onClick={() =>
                      modifyLanguageMap(
                        typedLanguage,
                        "on",
                        !languageMap[typedLanguage].on
                      )
                    }
                  />
                }
                label={`Send in ${typedLanguage}`}
              />
            </Grid>
            {languageMap[typedLanguage].on && (
              <>
                <Grid item xs={2}>
                  <TextField
                    label={`${output_map[output]} id`}
                    fullWidth
                    value={languageMap[typedLanguage].id}
                    onChange={(el) =>
                      modifyLanguageMap(typedLanguage, "id", el.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    options={templetes}
                    defaultValue={null}
                    // sx={{ width: 300 }}
                    value={null || languageMap[typedLanguage].templete}
                    onChange={(el, value) => {
                      if (value) {
                        modifyLanguageMap(typedLanguage, "templete", value);
                        modifyLanguageMap(typedLanguage, "format", value.type);
                        modifyLanguageMap(typedLanguage, "media", null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Templete" />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="format">Format</InputLabel>
                    <Select
                      labelId="format"
                      id="format"
                      label="Format"
                      value={languageMap[typedLanguage].format}
                      onChange={(el,val) =>
                        modifyLanguageMap(
                          typedLanguage,
                          "format",
                          val
                        )
                      }
                      disabled
                    >
                      <MenuItem key={1} value={0}>
                        TEXT
                      </MenuItem>
                      <MenuItem key={2} value={1}>
                        IMAGE
                      </MenuItem>
                      <MenuItem key={3} value={2}>
                        VIDEO
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    defaultValue={null}
                    disablePortal
                    options={
                      languageMap[typedLanguage].format == 1
                        ? imageMedia
                        : videoMedia
                    }
                    disabled={languageMap[typedLanguage].format == 0}
                    // sx={{ width: 300 }}
                    value={languageMap[typedLanguage].media}
                    onChange={(el, value) => {
                      if (value)
                        modifyLanguageMap(typedLanguage, "media", value);
                      // modifyLanguageMap(typedLanguage,'templete',value.id)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Templete" />
                    )}
                  />
                  {/* <TextField label='Media' disabled={languageMap[typedLanguage].format==0} fullWidth onChange={(el)=>modifyLanguageMap(typedLanguage,'media',el.target.value )}/> */}
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PushModalWA;
