import Loading from "components/Loading";
import { HitoI } from "interfaces/MilestoneAdmin";
import { useEffect, useState, useRef, useCallback} from "react";
import { Link } from "react-router-dom"
import { getHitos } from "services/milestones-services";
import Pagination from 'react-js-pagination'
import SearchForm from "components/SearchForm";
import HitosUploadModal  from './HitosUploadModal';


const ListadoDeHitos = () => {

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [hitos, setHitos] = useState<HitoI[]>([]);
    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const mountedRef = useRef(false);
    const [searchHito, setSearchHito] = useState('')

    const fetchData = useCallback(() => {
        getHitos(activePage, searchHito)
        .then(resp => {
            const { results, count } = resp.data;
            setHitos(results);
            setCount(count);
        })
        .catch(err => console.error(err))
        .finally(() => {
            setLoading(false)
        })
    },[activePage, searchHito])

    useEffect(() => {

        if(!mountedRef.current){
            fetchData();
            mountedRef.current = true;
        }

        return function cleanUp() {
            mountedRef.current = false;
        };

    },[fetchData]);

    useEffect(() => {
        setLoading1(true);
        async function search() {
            try{
                const resp = await getHitos(1, searchHito)
                const { results, count } = resp.data;
                setHitos(results);
                setCount(count);
            } catch(err:any){
                console.error(err)
            } finally{
                setLoading1(false)
            }
        }
        search();
    },[searchHito])

    const handlePageChange = (page: number) => {
        setActivePage(page);
    }

    if(loading){
        return <Loading />
    }

    return (
        <div className="card">
            <HitosUploadModal/>
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>Listado de hitos</h4>
                    </div>
                    <div className="col-sm-6 text-right">
                        <Link to="/admin/milestones/crear-hito"
                        className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Crear hito</Link>
                        <button className="btn btn-success btn-sm ml-2" data-toggle="modal" data-target="#modalData">
                            <i className="fa fa-file-excel"></i> Crear hitos
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">

                <SearchForm search={searchHito} setSearch={setSearchHito}/>

                {hitos.length === 0 &&
                    <div className="alert alert-danger">
                        No hay datos.
                    </div>
                }
                {hitos.length > 0 &&
                    <div className="table-responsive">
                        {loading1 ? (
                            <Loading />
                        ) : (
                            <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Source</th>
                                        <th>Areas</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {hitos.map((item:any, index: number) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.source.name}</td>
                                            <td>{item.areas.map((item:any) => item.name).join(", ")}</td>
                                            <td className="text-right">
                                                <Link to={`/admin/milestones/hito/${item.id}`}
                                                className="btn btn-primary btn-sm"><i className="fa fa-eye"></i></Link>
                                                <Link to={`/admin/milestones/editar-hito/${item.id}`}
                                                className="btn btn-warning btn-sm ml-2"><i className="fa fa-edit"></i></Link>
                                            </td>
                                        </tr>
                                ))}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                            </>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default ListadoDeHitos
