import { useFormik } from "formik";
import usePrograms from "hooks/usePrograms";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

type Props = {
    onFinish: (values:any) => void;
}

const initialState = {
    id: "",
    program_id: "",
    value: "",
    percentage: ""
}

const validationSchema = Yup.object({
    program_id: Yup.string().required(),
    value: Yup.number().required(),
    percentage: Yup.number().required(),
})

const AgregarAlerta = ({onFinish}:Props) => {

    const [programs] = usePrograms();

    const handleClick = (values: typeof initialState) => {
        onFinish({
            ...values,
            id: uuidv4()
        });
    }

    const formik = useFormik({
        initialValues: initialState,
        validationSchema,
        onSubmit: (values,{resetForm}) => {
            handleClick(values)
            resetForm();
        }
    })

    return (
        <div>
            <hr />
            <h4>Agregar nueva alerta</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col">
                        <label htmlFor="">Program</label>
                        <select name="program_id"
                        className="form-control"
                        value={formik.values.program_id}
                        onChange={formik.handleChange}>
                            <option value="">-- Program --</option>
                            {programs.map((item:any) => (
                                <option value={item.id} key={item.id}>{item.name}</option>
                            ))}
                        </select>

                        {formik.errors.program_id &&
                            <span className="help-block text-danger">El programa es requerido</span>
                        }
                    </div>
                    <div className="col">
                        <label htmlFor="">Value</label>
                        <input type="number"
                        name="value"
                        className="form-control"
                        value={formik.values.value}
                        onChange={formik.handleChange}/>
                        {formik.errors.value && formik.touched.value &&
                            <span className="help-block text-danger">El value es requerido</span>
                        }
                    </div>
                    <div className="col">
                        <label htmlFor="">Alert %</label>
                        <input type="number"  className="form-control"
                        name="percentage"
                        value={formik.values.percentage}
                        onChange={formik.handleChange}/>
                        {formik.errors.percentage && formik.touched.percentage &&
                            <span className="help-block text-danger">El percentage es requerido</span>
                        }
                    </div>
                    <div className="col">
                        <div className="d-flex justify-content-center align-items-center"
                        style={{ height: '100%'}}>
                            <button
                            type="submit"
                            className="btn btn-outline-primary btn-sm btn-block mt-4"
                            >Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
            <hr />
        </div>
    )
}

export default AgregarAlerta
