import { ContentManagerRequest } from "api/ContentManagerRequest"
import { s3Config } from "api/S3Config";
import ReactS3Client from 'react-aws-s3-typescript';
// @ts-ignore
const getErrorMessage = (status: number) => {
    switch (status) {
        case 400:
            return 'Solicitud incorrecta';
        case 401:
            return 'No autorizado';
        case 404:
            return 'Recurso no encontrado';
        case 500:
            return 'Error interno del servidor';
        default:
            return 'Error desconocido';
    }
};

const SIZE = 30;

type PayloadT = {
    search?: string,
    featured?: boolean,
    pagination?: boolean
}

export const getArticles = async (pagenumber = 1, payload?: PayloadT) => {
    return await ContentManagerRequest.post(`/api/0.1/articles/get_featured_articles/?pagenumber=${pagenumber}&size=${SIZE}`, {
        ...payload,
        pagination: true
    });
}

export const setArticleAsFeatured = async (id: number, featured: boolean) => {
    return await ContentManagerRequest.post(`api/0.1/articles/${id}/update_featured_article/`, {
       featured
    });
}

export const getImages = async (page: number = 1) => {
    return await ContentManagerRequest.get(`/api/0.1/media/list_images/?page=${page}&page_size=${SIZE}`);
}

export const saveImage = async (payload: FormData) => {
    return await ContentManagerRequest.post(`/api/0.1/media/save_image/`, payload,  {headers: {'Content-Type': 'multipart/form-data'}});
}

export const getVideos = async (page: number = 1) => {
    return await ContentManagerRequest.get(`/api/0.1/media/list_videos/?page=${page}&page_size=${SIZE}`);
}

export const saveVideos = async (payload: FormData) => {
    try {
        const s3 = new ReactS3Client(s3Config);
        const file = payload.get('video') as File
        const s3_response = await s3.uploadFile(file, payload.get('name') as string);
        return {data: {link: s3_response.location }, status: s3_response.status}
    } catch (error) {
        console.log(error)
        debugger
    }
    return null
}

export const getActivitiesByProgram = async (program:number, search: string, pagenumber:number = 1) => {
    return await ContentManagerRequest.get(`/api/0.1/activities_by_programs/${program}/list_by_program/?pagenumber=${pagenumber}&size=${SIZE}`);
}

export const setActivitiesByProgram = async (program:number, activity_id: number) => {

    return await ContentManagerRequest.post(`/api/0.1/activities_by_programs/`, {activity_id, program});
}

export const getActivitiesForSelect = async (search: string, pagenumber:number = 1) => {
    return await ContentManagerRequest.get(`/api/0.1/posts/?pagenumber=${pagenumber}&size=${SIZE}&for_select=true&search=${search}`);
}

export const getGroupIdByNameCM = async (name: string) => {
    try {
        const response = await ContentManagerRequest.get(`/api/0.1/groups/get_id_by_name?name=${name}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const createNewGroupCM = async (data: any) => {
    try {
        const response = await ContentManagerRequest.post(`/api/0.1/groups/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const editGroupCM = async (groupId: number, data: any) => {
    try {
        const response = await ContentManagerRequest.put(`/api/0.1/groups/${groupId}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const bulkCreateNewGroups = async (data: any) => {
    try {
        const response = await ContentManagerRequest.post(`/api/0.1/groups/bulk_create/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};