import { TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { LanguageMap } from "./Push-modal";

type Props = {
  setAgeReq: (ageReq: boolean) => void;
  ageReq: boolean;
  languageMap: LanguageMap;
  modifyLanguageMap: (key: keyof LanguageMap, attr: string, val: any) => void;
  output: number;
  channel: number;
  setAgeMin: (programs: number) => void;
  ageMin: number | undefined;
  setAgeMax: (programs: number) => void;
  ageMax: number | undefined;
};

const output_map = ["Activity", "Article", "Session", "Milestones"];

function PushModalAPP({
  ageReq,
  setAgeReq,
  languageMap,
  modifyLanguageMap,
  output,
  channel,
  ageMax,
  setAgeMax,
  ageMin,
  setAgeMin,
}: Props) {
  return (
    <Grid container p={1}>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row" }}
        p={1}
        spacing={1 / 2}
      >
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox checked={ageReq} onClick={() => setAgeReq(!ageReq)} />
            }
            label="Restrict by age"
          />
        </Grid>
        <Grid item xs={4} visibility={!ageReq ? "hidden" : "visible"}>
          <TextField
            label="Age Min"
            fullWidth
            value={ageMin}
            onChange={(el) => setAgeMin(el.target.value as unknown as number)}
          />
        </Grid>
        <Grid item xs={4} visibility={!ageReq ? "hidden" : "visible"}>
          <TextField
            label="Age Max"
            fullWidth
            value={ageMax}
            onChange={(el) => setAgeMax(el.target.value as unknown as number)}
          />
        </Grid>
      </Grid>
      {Object.keys(languageMap).map((el) => {
        const typedLanguage = el as keyof LanguageMap;
        return (
          <Grid
            container
            style={{ display: "flex", flexDirection: "row" }}
            p={1}
            spacing={1 / 2}
            key={typedLanguage}
          >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={languageMap[typedLanguage].on}
                    onClick={() =>
                      modifyLanguageMap(
                        typedLanguage,
                        "on",
                        !languageMap[typedLanguage].on
                      )
                    }
                  />
                }
                label={`Send in ${typedLanguage}`}
              />
            </Grid>
            {languageMap[typedLanguage].on && (
              <>
                <Grid item xs={2}>
                  <TextField
                    label={`${output_map[output]} id`}
                    value={languageMap[typedLanguage].id}
                    fullWidth
                    onChange={(el) =>
                      modifyLanguageMap(typedLanguage, "id", el.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    label={`message`}
                    value={languageMap[typedLanguage].templete}
                    fullWidth
                    onChange={(el) =>
                      modifyLanguageMap(
                        typedLanguage,
                        "templete",
                        el.target.value
                      )
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
export default PushModalAPP;
