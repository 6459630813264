import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_WEBHOOK;

export const getChannelOptions = (): Promise<any> => {

    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/channels/?options=True`);
            resolve(resp);
        }
        catch(err:any) {
            reject(err);
        }
    });
}


export const getChannels = (): Promise<any> => {

    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/channels/`);
            resolve(resp);
        }
        catch(err:any) {
            reject(err);
        }
    });
}
