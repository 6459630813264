import { Bot } from 'interfaces/Bot';
import { getBotsList } from 'data/bots';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import Pagination from 'react-js-pagination'
import SearchForm from '../../components/SearchForm'

const BotList = () => {
    const [ bots, setBots ] = useState<Bot[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ search, setSearch ] = useState<string>("");
    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);


    useEffect(() => {
        let mounted = true;

        if(mounted){
            getBotsList(search,1)
            .then( (resp:any) => {
                const { results, count} = resp.data;
                setCount(count)
                setBots(results);
                setLoading(false);
            })
            .catch( err => console.log(err))
        }
        return () => {mounted = false;}
    },[search]);

    const handlePageChange = async(page: number) => {
        try{
            setLoading(true);
            setActivePage(page);
            const resp:any = await getBotsList(search,page);
            const {results} = resp.data;
            setBots(results);
            setLoading(false);
        } catch(err:any){
            console.log(err)
        }
    }

    if(loading){
        return <Loading />
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4>Listado de bots</h4>
                </div>
                <div className="card-body">
                    {/** form search */}
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />

                    <table className="table table-hover table-sm"
                    width="100%"
                    id="data">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {bots.map( (item:any) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td className="text-right">
                                        <Link to={`/admin/live-chat/messages/${item.id}`}
                                        className="btn btn-outline-primary btn btn-sm">
                                            <i className="fa fa-comments"></i> Ver mensajes
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/** pagination component */}
                    <div className="mx-auto">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={50}
                            totalItemsCount={count}
                            pageRangeDisplayed={15}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BotList
