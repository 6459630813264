import { createContext, useState, useEffect } from "react"
import { getGroupSummary } from "services/programs.service";

export const GroupContext = createContext({} as any);

interface Resume {
    activities: number;
    children: number;
    users: number;
}

interface Milestone {
    y: number;
    y_label: string;
    label: string;
    milestone_id: number;
    instances: number[];
}

interface RiskList {
    id: number;
    label: string;
    users_in_risk: number;
}

interface Risk {
    id: number;
    name: string;
    total_users_in_risk: number;
    risks: RiskList[];
}

export interface CodeI {
    id: number,
    code: string
}
interface GroupContextI {
    resume: Resume;
    milestones: Milestone[];
    milestonesCount: number;
    risks: Risk[];
    ref: string;
    codes: CodeI[]
    setGroupId?: (value:any) => void
}


const initialState:GroupContextI = {
    resume: {
        activities: 0,
        children: 0,
        users: 0
    },
    milestones: [],
    milestonesCount: 0,
    risks: [],
    ref: "",
    codes: []
}

const GroupContextProvider = ({children}:any) => {
    const [data, setData] = useState<GroupContextI>(initialState)
    const [groupId, setGroupId] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
       (async() => {
            try{
                setIsLoading(true);
                setData((current: any) => ({
                    ...current,
                    resume: {
                        activities: 0,
                        children: 0,
                        users: 0
                    }
                }));

                if(groupId !== ""){
                    const resp = await getGroupSummary(groupId);
                    const { data } = resp;
                    setData((current:any) => ({
                        ...current,
                        resume: data.summary,
                        milestones: data.milestones,
                        ref: data.ref,
                        codes: data.codes,
                        risks: data.risks,
                        milestonesCount: data.milestones_count || 0
                    }))
                    setIsLoading(false);
                }
            } catch(e){
                console.log(e);
                setIsLoading(false);
            }
       })()
    },[groupId]);

    return (
        <GroupContext.Provider value={{
            data,
            setGroupId,
            isLoading
        }}>
            {children}
        </GroupContext.Provider>
    )
}

export default GroupContextProvider
