import Loading from 'components/Loading';
import { decodeToken, logout } from 'data/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Iprops {
  toggleSideBar: () => void
}

const Header = ({toggleSideBar}: Iprops) => {
  const history = useHistory();
  const identity = decodeToken();
  const { t, ready, i18n} = useTranslation();
  const [languageCode, setLanguageCode] = useState<string>('');

  const handleLogout = (): void => {
    logout();
    history.push('/');
  };

  const handleChangeLanguage = (lang: string) => {
    setLanguageCode(lang)
    i18n.changeLanguage(lang);
  }

  if(!ready){
    return <Loading />
  }
  if(languageCode === '') setLanguageCode(i18n.language)

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          onClick={toggleSideBar}
          className="btn btn-link d-md-none rounded-circle mr-3"
        > 
          <i className="fa fa-bars"/>
        </button>

        <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <div className='row align-items-center'>
                {t('seleccionar_idioma')}:
                <div className="dropdown ml-3">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                    {languageCode}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button
                      className="dropdown-item"
                      style={{marginLeft: 10}}
                      onClick={() => handleChangeLanguage('es')}>{t('spanish')}</button>
                    <button
                      className="dropdown-item"
                      style={{marginLeft: 10}}
                      onClick={() => handleChangeLanguage('pr')}>{t('portugues')}</button>
                  </div>
                </div>
              </div>
            </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                Hola, {identity?.username || ''}
              </span>
              <img
                className="img-profile rounded-circle"
                src="img/undraw_profile_2.svg"
                alt=""
              />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="/#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"/>
                Profile
              </a>
              <a className="dropdown-item" href="/#">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"/>
                Settings
              </a>
              <a className="dropdown-item" href="/#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"/>
                Activity Log
              </a>
              <div className="dropdown-divider"/>
              <button
                className="dropdown-item"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                Logout
              </button>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
