import { useEffect, useState, useRef } from 'react';
import { bulkCreateHitos  } from "services/milestones-services";
import '../../people/people.css';
import Loading from '../../../components/Loading';
import * as XLSX from "xlsx";
import { getLanguages, getProgramsMinimal } from "services/milestones-services"


const UpdateArticleModal = () => {
    const fileInputRef = useRef() as any;
    const [messageSuccess, setMessageSuccess] = useState<string>('');
    const [messageErr, setMessageErr] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [file, setFile] = useState({});
    const [errorList, setErrorList] = useState([]);
    const [successList, setSuccessList] = useState([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [language, setLanguage] = useState('');
    const [programs, setPrograms] = useState<any[]>([]);
    const [programsList, setProgramsList] =  useState<any[]>([]);



    useEffect(() => {
       
        async function fetchData() {
            try{
                const respLang = await getLanguages();
                const respPrograms = await getProgramsMinimal();
                setLanguages(respLang.data);
                setPrograms(respPrograms.data);
                setLoading(false)
            } catch(err:any){
                console.error(err);
            } finally{
                setLoading(false)
            }
        }

        fetchData();

    }, []);

    const handleInputFileChange = (e: any) => {
        setName(e.target.files[0].name || '');
        readExcel(e.target.files[0]);
    }

    const clickButton = () => {
        clearData();
        fileInputRef.current.click();
        
    }

    const clearData = () => {
        fileInputRef.current.value = '';
        setFile({});
        setMessageSuccess('');
        setMessageErr('');
        setName('');
        setErrorList([]);
    }

    const validateData = (data: any) => {

        if(data.length ===0){
            setMessageErr('Archivo vacío');
            return ;
        }
        
        const errorList: any = data.filter( (row: any) => {
            // Se valida que existan las columnas id y url, ya además que la columna no esté vacía
            return !(row.hasOwnProperty('Stage') &&
                    row.hasOwnProperty('Area') &&
                    row.hasOwnProperty('Code') &&
                    row.hasOwnProperty('Average') &&
                    row.hasOwnProperty('Minimum') &&
                    row.hasOwnProperty('Maximum') &&
                    row.hasOwnProperty('Question') &&
                    row.hasOwnProperty('Source') &&
                    row.Stage !== '' &&
                    row.Area !== '' &&
                    row.Code !== '' &&
                    row.Average !== '' &&
                    row.Minimum !== '' &&
                    row.Maximum !== '' &&
                    row.Question !== '' &&
                    row.Source !== ''
                    && row.Minimum <= row.Maximum
                    )
                    
        });

        errorList.length !== 0 ? setMessageErr(`Errores en las filas: ${createFileErrorList(errorList)}`) : setFile(data);
    }

    const createFileErrorList  = (errorList: any) => {
        // Se aumenta en 1 para indicar la fila exacta en el archivo excel
        return errorList.map((row: any) => row.__rowNum__+1 ).toString();
    }

    const readExcel = (errorList: any) => {
    
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(errorList);

        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            validateData(data);
        }
    }

    const sendData = async ( ) => {
        setLoading(true);
        let data = {
                data: file,
                programs: programsList,
                language : language
            }
        const resp: any = await bulkCreateHitos(data)
        setErrorList([]);
        setSuccessList([]);
        if (resp.data.errors.length > 0) {
            let textError =  resp.data.succeed.length > 0 ? 'Se crearon los hitos, pero fue imposible crear las siguientes filas:' : 'Se generaron los siguientes errores:';
            setMessageErr(textError);
            setErrorList(resp.data.errors);
            setLoading(false);

        }
        else {
            setMessageErr('')
            setMessageSuccess('Se crearon todos los hitos exitosamente');
            setSuccessList(resp.data.succeed);
            setLoading(false);
        }
    }

    const handleEvent = (event: any) => {
        if(event.target.name === 'language' ){
            setLanguage(event.target.value)
        }
        else{
            let values = Array.from(event.target.selectedOptions, (option: any) => option.value);
            setProgramsList(values)
        }        
    }


    return (
        <div className="modal fade in" id="modalData" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Crear hitos</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    { loading ? 
                            <Loading size="2rem" /> 
                        : 
                        <>
                            <div className="modal-body text-center">
                                 {messageErr && 
                                    <div className="alert alert-danger small animate__animated animate__shakeX" style={{
                                        overflowY: "auto",
                                        height: "500px"
                                    }}>
                                        <h5><i className="fa fa-exclamation-circle"></i> {messageErr}</h5>
                                        {errorList.length > 0 ?
                                            <table className="table table-sm table-bordered table-dark">
                                                <thead>
                                                    <tr>
                                                        {/*<th scope="col">Id</th>*/}
                                                        <th scope="col">Error</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorList.map( (item: any) => (
                                                        <tr>
                                                            {/*<th scope="row">{item.id}</th>*/}
                                                            <td>{item.error}</td>
                                                        </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>
                                            :
                                            <></>
                                        }

                                    </div>
                                }

                                {messageSuccess && 
                                    <div className="alert alert-success small animate__animated animate__fadeInDown"> 
                                        <h5><i className="fa fa-check-circle"></i> {messageSuccess}</h5>
                                        {successList.length >0 ?
                                                <table className="table table-sm table-bordered table-dark d-none">
                                                    <thead>
                                                        <tr>
                                                           {/**<th scope="col">Id</th> */}
                                                            <th scope="col">Titulo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {successList.map( (item: any) => (
                                                            <tr>
                                                                {/*<th scope="row">{item.id}</th>*/}
                                                                <td>{item.success}</td>
                                                            </tr>
                                                        ))}
                                                        
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                            }
                                    </div>
                                }
                                
                                <a className="btn btn-primary btn-sm mb-4" href="/Milestones format.xlsx" download>
                                    <i className="fa fa-download"></i> Descargar formato
                                </a>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="">Language</label>
                                            <select name="language" id="language-select" className="form-control" 
                                                    value={language} onChange={handleEvent}>
                                                        <option value=""></option>
                                                {languages && languages.map((item: any) => 
                                                    (<option value={item.id} key={item.id}>{item.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <label htmlFor="">Programs</label>
                                            <select name="programs" id="programs-select" className="form-control"
                                                onChange={handleEvent}
                                                value={programsList}
                                             multiple>
                                                {programs && programs.map((item: any) => 
                                                    (<option value={item.id} key={item.id}>{item.name} - {item.config.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {name && <h4>{name}</h4>}
                        
                                <form>
                                    <input type="file"
                                    onChange={(e) => handleInputFileChange(e)}
                                    ref={fileInputRef}
                                    accept=".xlsx"
                                    style={{ display: 'none' }}/>
                                </form>

                                <div className="mt-4">
                                    <button className="btn btn-warning btn-md ml-2"
                                    onClick={() =>  clickButton() }>
                                        Seleccionar archivo
                                    </button>
                                </div>
                            </div>

                            <div className="modal-footer">

                                <button type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => clearData() }>Cerrar</button>
                                
                                <button type="button"
                                    className={'btn btn-primary'}
                                    disabled={Object.keys(file).length === 0 ||  language === '' || Object.keys(programsList).length === 0  }
                                    onClick={ () => sendData()}
                                    >Actualizar</button>
                                
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default UpdateArticleModal
