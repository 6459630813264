import ListUserGroup from 'pages/user-groups/ListUserGroups';
import AddUserGroup from 'pages/user-groups/AddUserGroup';
import EditUserGroup from 'pages/user-groups/EditUserGroup';
import DetailUserGroup from 'pages/user-groups/DetailUserGroup';
import DeleteGroupBotUser from 'pages/user-groups/DeleteGroupBotUser';
import GroupCodeForm from 'pages/user-groups/GroupCodeForm';
import GroupPlatformForm from 'pages/user-groups/GroupPlatformForm';
import { IRoute } from 'interfaces/Route';

export const USER_GROUPS_ROUTES:IRoute[] = [
    {
        path:'/admin/user-groups',
        component: ListUserGroup,
        exact: true,
    },
    {
        path:'/admin/user-groups/add',
        component: AddUserGroup,
        exact: true,
    },
    {
        path:'/admin/user-groups/:id/edit',
        component: EditUserGroup,
        exact: true,
    },
    {
        path:'/admin/user-groups/:id',
        component: DetailUserGroup,
        exact: true,
    },
    {
        path:'/admin/user-groups/:groupId/code/:id/edit',
        component: GroupCodeForm,
        exact: true,
    },
    {
        path:'/admin/user-groups/:groupId/code/add',
        component: GroupCodeForm,
        exact: true,
    },
    {
        path:'/admin/user-groups/:groupId/platform-user/:id/edit',
        component: GroupPlatformForm,
        exact: true,
    },
    {
        path:'/admin/user-groups/:groupId/platform-user/add',
        component: GroupPlatformForm,
        exact: true,
    },
    {
        path:'/admin/user-groups/:groupId/bot-user/:id/delete',
        component: DeleteGroupBotUser,
        exact: true,
    },
];
