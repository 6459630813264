import AddContent from 'pages/nlu/components/AddContent';
import NluScreen from 'pages/nlu/NluScreen';
import { IRoute } from '../interfaces/Route';

export const NLU_ROUTES: IRoute[] = [
    {
        path: '/admin/nlu',
        component: NluScreen,
        exact: true,
        requiredRoles: []
    },

    {
        path: '/admin/nlu/add-content/:id',
        component: AddContent,
        exact: true,
        requiredRoles: []
    },
]
