import { FunctionComponent } from "react"

interface IProps {
    item: any,
    handleInputChange: (e:any, item:any) => void,
    onBlur: () => void
}
const ItemAttribute:FunctionComponent<IProps>= ({item, handleInputChange, onBlur}) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="">{item.name}</label>
                <input type="text" name={item.name} value={item.value} className="form-control form-control-sm"
                onChange={(e) => handleInputChange(e, item)}
                onBlur={onBlur}/>
            </div>
        </>
    )
}


export default ItemAttribute
