import Dashboard from 'pages/dashboard/Dashboard';
import PageNotFound from 'pages/page-not-found/PageNotFound';
import { IRoute } from 'interfaces/Route';
import { HOTTRIGGERS_ROUTES } from 'routes/sequences';
import { BOT_ROUTES } from 'routes/bots';
import { GROUPS_ROUTES } from 'routes/auth-groups';
import { USERS_ROUTES } from 'routes/users';
import { LIVECHAT_ROUTES } from 'routes/live-chat';
import { PROGRAMS_ROUTES } from 'routes/programs';
import { PEOPLE_ROUTES } from 'routes/people';
import { NLU_ROUTES } from 'routes/nlu';
import { ENTITY_ROUTES } from 'routes/entities';
import { ATTRIBUTES_ROUTES } from 'routes/attributes';
import { QUICKREPLIES_ROUTE } from 'routes/quick-replies';
import { RETRAIN_ROUTE } from 'routes/retrain';
import { USER_GROUPS_ROUTES } from 'routes/user-groups';
import { MILESTONE_ADMIN_ROUTES } from 'routes/milestones-admin';
import { POSTS_ROUTES } from 'routes/posts';
import { ARTICLES_ROUTES } from 'routes/articles';
import { PARTNERS_ROUTES } from 'routes/partners';
import { SESSIONS_ROUTES } from 'routes/sessions';
import { MEDIA_ROUTES } from 'routes/media';
import { QUERIES_ROUTES } from 'routes/queries';
import { PUSH_ROUTES } from 'routes/push-notifications';

const partnersRoutes: IRoute[] = [
    ...PARTNERS_ROUTES,
];

const privateRoutes: IRoute[] = [
    {
        path: '/admin/dashboard',
        component: Dashboard,
        exact: true,
        requiredRoles: []
    },

    ...USERS_ROUTES,
    ...MEDIA_ROUTES,
    ...GROUPS_ROUTES,
    ...BOT_ROUTES,
    ...HOTTRIGGERS_ROUTES,
    ...LIVECHAT_ROUTES,
    ...PROGRAMS_ROUTES,
    ...PEOPLE_ROUTES,
    ...NLU_ROUTES,
    ...ENTITY_ROUTES,
    ...ATTRIBUTES_ROUTES,
    ...QUICKREPLIES_ROUTE,
    ...RETRAIN_ROUTE,
    ...USER_GROUPS_ROUTES,
    ...MILESTONE_ADMIN_ROUTES,
    ...POSTS_ROUTES,
    ...ARTICLES_ROUTES,
    ...PARTNERS_ROUTES,
    ...SESSIONS_ROUTES,
    ...QUERIES_ROUTES,
    ...PUSH_ROUTES,
    {
        path: '*',
        component: PageNotFound,
        exact: true
    },
];


export { privateRoutes, partnersRoutes };
