import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';
import Loading from 'components/Loading';
import ExcelBulkModal from 'components/ExcelBulkModal';
import { bulkCreateSession, getSessionsList } from 'data/sessions';
import { InteractionShortDetail } from 'interfaces/Session';

const CM_URL = process.env.REACT_APP_CM
const API_URL_CM = process.env.REACT_APP_API_URL_CM

const ListSession = (props: any) => {
    const [sessions, setSessions] = useState<InteractionShortDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState<string>('');
    const [activePage, setActivePage] = useState(Number(localStorage.getItem('programPostsCurrentPage')) || 1);

    useEffect(() => {
        let mounted = true;
        getSessionsList(search, 1)
            .then((resp: any) => {
                if(mounted){
                    const results = resp.data;
                    setSessions(results);
                    setLoading(false);
                    setCount(count);
                }
            })
            .catch( (err: any) => console.log(err));

            return function cleanup(){
                mounted = false;
            }
        },[count, search]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        const resp: any = await getSessionsList(search, page);
        const { results, count } = resp.data;
        setCount(count);
        setSessions(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <>
            <ExcelBulkModal modalId="sessionCreateModal" title="Crear Sesiones"
            urlFormat={`${CM_URL}/static/excels/SessionsTemplate.xlsx`}
            requiredFields={["Name", "Type", "Topic", "ÁREAS", "Min", "Max"]}
            // the key value is necesary on backend to find the model
            globalFields={[{
                    label: "Licenses",
                    url: `${API_URL_CM}/licenses/`,
                    isMulti: true,
                    key: "LICENCES"
                },
                {
                    label: "Programs",
                    url: `${API_URL_CM}/programs/`,
                    isMulti: true,
                    key: "PROGRAMS"
                },
                {
                    label: "Channel",
                    url: `${API_URL_CM}/channels_select/`,
                    isMulti: true,
                    key: "CHANNEL"
                }
            ]}
            sendCallback={function (sessions: Object[]): Promise<any> {
                return bulkCreateSession(sessions)
            }}/>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">Listado de sesiones</h4>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <button className="btn btn-outline-primary float-right mx-3" title="Crear Usuarios"  
                                data-toggle="modal" data-target="#sessionCreateModal">
                                    <em className="fas fa-globe-americas"></em> Crear Sesiones
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    { sessions && sessions.length > 0 ? (
                        <>
                        <div className="table-responsive">
                            <table className="table"
                            id="data">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">Id</th>
                                        <th scope="col">Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { sessions.map( item => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={50}
                            totalItemsCount={count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay sesiones.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListSession

