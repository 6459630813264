import * as Yup from "yup";
import { useFormik } from "formik";
import { storeUserAttribute } from "data/user-attributes";
import { getAttributesList } from "data/attributes";
import { FunctionComponent, useState, useEffect } from "react"
// @ts-ignore
import  Select  from 'react-select';
import swal from "sweetalert";
declare var $:any;

interface Iprops {
    userId: string,
    onFinish: (item:any) => void
}

const validationSchema = Yup.object().shape({
    attribute_value: Yup.string().required("El attribute value es requerido"),
    attribute: Yup.object().shape({
        valor: Yup.string().required('El attribute name es requerido')
    })
});

const NewUserAttrModal:FunctionComponent<Iprops> = ({userId, onFinish}) => {

    const [options, setOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        attribute: {
            valor: ''
        },
        attribute_value: '',
    })

    useEffect(() => {
        let mounted = true;

        if(mounted){
            async function fetchData(){
                try{
                    let data = []
                    const resp:any = await getAttributesList("user",userId)
                    if(resp.data){
                        data = resp.data.map( (item:any) => ({value: item.name, label: item.name}));
                    }
                    setOptions(data);
                } catch(err:any){
                    setOptions([]);
                    console.log(err);
                }
            }
            fetchData();
        }

        return () => { mounted = false; }
    },[userId]);

    const handleSave = async (values: any) => {
        try{
            let { label } = values.attribute.valor;

            const payload = {
                data_key: label,
                data_value: values.attribute_value,
                user: userId,
                attribute: null
            }
            const resp:any = await storeUserAttribute(payload);
            const { data } = resp;
            onFinish({
                id: data.id,
                name: data.data_key,
                value: data.data_value
            });
            await swal('Afinidata',"Attribute de usuario agregado", "success");
            handleClose();
        } catch(err:any){
            console.log(err);
            swal('Afinidata',"Ocurrio un error", "error");
        }
    }

    const handleClose = () => {
        setInitialValues({
            attribute:{
                valor: ''
            },
            attribute_value:''
        });
        formik.resetForm();
        $("#userAttrModal").modal('hide');
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSave(values);
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="modal" role="dialog" id="userAttrModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Agregar nuevo attribute user</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Attribute</label>
                                    <Select options={options}
                                    isSearchable={true}
                                    value={formik.values.attribute.valor}
                                    placeholder="Selecciona attribute"
                                    className={`${
                                    formik.errors.attribute && formik.touched.attribute ? "is-invalid" : ""
                                    }`}
                                    onChange={ (value:any) => {
                                        formik.setFieldValue("attribute.valor",value,true)
                                    }}/>
                                    {formik.errors.attribute && formik.touched.attribute ? (
                                        <div className="invalid-feedback">{formik.errors.attribute.valor}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Value</label>
                                    <input type="text"
                                    name="attribute_value"
                                    placeholder="Attribute value"
                                    className={`form-control ${
                                        formik.errors.attribute_value && formik.touched.attribute_value ? "is-invalid" : ""
                                        }`}
                                    value={formik.values.attribute_value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.attribute_value && formik.touched.attribute_value ? (
                                        <div className="invalid-feedback">{formik.errors.attribute_value}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">
                                    <i className="fa fa-save"></i> Guardar
                                </button>
                                <button type="button"
                                className="btn btn-secondary"
                                onClick={ handleClose }>
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default NewUserAttrModal
