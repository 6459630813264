import {Link} from "react-router-dom"
import Logo from '../../components/Logo';

const PageNotFound = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <Logo />
                        <h1>404 | Opps! Page not found</h1>
                        <Link to="/" className="btn btn-primary">Volver al inicio</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
