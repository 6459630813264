import axios from "axios";

const NLU_URL = process.env.REACT_APP_API_URL_NLU

/**
 * store new response with text
 * @params payload
 * @returns void
 */
export const storeResponseText = (payload:any):Promise<any> => {
    return new Promise( async(resolve, reject) => {
        try{
            const resp = await axios.post(`${NLU_URL}/responsetext/`, payload);
            resolve(resp)
        }catch(err:any){
            reject(err)
        }
    });
}

/**
 * store new response with text
 * @params payload
 * @returns void
 */
 export const destroyResponseText = (id:string):Promise<any> => {
    return new Promise( async(resolve, reject) => {
        try{
            const resp = await axios.delete(`${NLU_URL}/responsetext/${id}/`,);
            resolve(resp)
        }catch(err:any){
            reject(err)
        }
    });
}
