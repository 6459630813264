import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { removeFromPushGroup } from "services/nodejs.service";

// import { createPushService } from "services/nodejs.service.ts";

interface LanguageData {
  on: boolean;
  id?: number | null;
  media: { id: number } | null;
  templete?: { id: number } | null;
  format?: number | null;
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}

export interface LanguageMap {
  spanish: LanguageData;
  english: LanguageData;
  portuges: LanguageData;
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type Props = {
  close: () => void;
  visible: boolean;
  refeshPush: () => void;
  currentPrograms: any;
  pushFragments: any;
  channel: any;
  id: number;
  type: any;
};

function RemovePushModal({
  id,
  close,
  visible,
  refeshPush,
  currentPrograms,
  pushFragments,
  channel,
  type
}: Props) {
  const [minorModal, setMinorModal] = useState(false);
  const [templete, setTemplete] = useState<any>([]);
  
  function returnShownFragments() {
    let pushToShow;
    if (channel == 100) pushToShow = pushFragments.wa;
    else pushToShow = pushFragments.app;
    if (pushToShow == undefined) return {};
    if (type == 1) pushToShow = pushToShow.filter((el: any) => el.age);
    else pushToShow = pushToShow.filter((el: any) => !el.age);
    return pushToShow.filter((el: any) => currentPrograms?.includes(el.value));
  }
  
  const usablePushFragments = useMemo(
    () => returnShownFragments(),
    [pushFragments, channel, type, currentPrograms]
  );

  const refreshModal = () => {
    setTemplete([]);
  };
  async function createPush() {
    let pushToSend: any = {
      id:id,
      pushToRemove: templete.filter((el:any)=>el.type=='push').map((el: any) => el.value),
      groupToRemove: templete.filter((el:any)=>el.type=='group').map((el: any) => el.value),
    };
    let push = await removeFromPushGroup(pushToSend);
    if (push.status == 201) {
      sleep(1000);
      refeshPush();
      refreshModal();
      close();
    }
  }

  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              style={{ display: "flex", flexDirection: "row" }}
              p={1}
            ></Grid>
            <Grid item xs={12} p={1}>
              {/* /#1 */}
              <Autocomplete
                multiple
                id=""
                value={templete}
                //@ts-ignore
                options={usablePushFragments}
                getOptionLabel={(option: any) => option.label}
                onChange={(v, nv) => setTemplete(nv)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pushes a remover"
                    placeholder=""
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={createPush}>
                {"Remover"}
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refreshModal();
                  close();
                }}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal open={minorModal}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error al remover push.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => setMinorModal(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default RemovePushModal;
