import axios from 'axios';
import swal from 'sweetalert';
import { getToken, logout } from 'data/users';

export const ProgramsRequest = axios.create({
    baseURL: process.env.REACT_APP_PROGRAMS_API || 'http://localhost:8213/api/0.1'
});

ProgramsRequest.interceptors.request.use(
    config => {
        const token = getToken();
        config.headers.Authorization = token ? 'Bearer ' + token : '';
        return config;
    },
    err => {
        Promise.reject(err);
    }
);

ProgramsRequest.interceptors.response.use(
    response => response,
    error => {

        const { response } = error;

        if(typeof response !== 'undefined' && 'status' in response){
            const {status} = response;

            /** usuario no autorizado */
            if (status === 401) {
                swal('Afinidata', 'Credenciales inválidas', 'error')
                .then( () => {
                    logout();
                    window.location.href = '/';
                });
            }

            /** usuario sin permisos */
            if (status === 403) {
                swal('Afinidata', 'Usuario no tiene permisos', 'error')
                .then( () => {
                    window.location.href = '/admin/dashboard';
                });
            }
        }

        return Promise.reject(error);
    }
);
