import { useFormik } from "formik";
import swal from 'sweetalert';
import * as Yup from "yup";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import { editAuthGroup, getAuthGroupById } from '../../data/auth-groups';
import { useEffect, useState } from "react";
import { AuthGroup } from "interfaces/AuthGroup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required()
});

type Props = {
    id: string
}

const EditAuthGroup = ({match}:RouteComponentProps<Props>) => {
    const history = useHistory();
    const id = match.params.id

    const [initialValues, setInitialValues] = useState<AuthGroup>({name: ''})

    useEffect(()=> {
        let mounted = true;

        if(mounted){
            getAuthGroupById(id)
            .then(resp => {
                console.log(resp)
                setInitialValues(resp.data)
            })
            .catch(err=> console.log(err))
        }

        return function cleanUp(){
            mounted = false;
        }
    },[id])

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            await editAuthGroup(id, payload);
            await swal('Afinidata','Grupo actualizado correctamente','success');
            history.push('/admin/groups');
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Editar nombre grupo</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name"
                                     className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href="/admin/groups/" className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAuthGroup;
