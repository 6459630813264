import { MilestoneServiceRequest } from "api/MilestoneServiceRequest"

export const getAreas = async () => {
    try{
        const resp = await MilestoneServiceRequest.get('/api/0.1/areas/?options=true');
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getSources = async() => {
    try{
        const resp = await MilestoneServiceRequest.get('/api/0.1/sources/?options=true');
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const crearHito = async (payload:any) => {
    try{
        const resp = await MilestoneServiceRequest.post('/api/0.1/milestones/',payload);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const updateHito = async (payload:any, id:string) => {
    try{
        const resp = await MilestoneServiceRequest.patch(`/api/0.1/milestones/${id}/`,payload);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getHitoById = async (id: string) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/milestones/?detail=true&id=${id}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getHitos = async (page: number = 1, search='') => {
    try{
        // TODO: add search in url
        const resp = await MilestoneServiceRequest.get(`/api/0.1/milestones/?detail=true&page=${page}&search=${search}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const bulkCreateHitos = async (payload = {}) => {
        try {
            // TODO: add search in url
            const resp = await MilestoneServiceRequest.post(`/api/0.1/milestones/bulk_create/`, payload);
            return Promise.resolve(resp);
        } catch (err: any) {
            return Promise.reject(err);
        }
}

export const asociatePrograms = async (payload = {}) => {
    try {
        // TODO: add search in url
        const resp = await MilestoneServiceRequest.post(`/api/0.1/milestones/test/`, payload);
        return Promise.resolve(resp);
    } catch (err: any) {
        return Promise.reject(err);
    }
}

export const getTraducciones = async (hitoId:string, page: number = 1) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/translations/?detail=true&milestone_id=${hitoId}&page=${page}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getMilestoneConfigById = async(id:string, page:number = 1) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/milestone_config/?detail=True&language=en&milestone_id=${id}&page=${page}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getLanguages = async () => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/languages/?options=true`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const getTraduccionById = async(id:string) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/translations/?detail=true&id=${id}`)
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const crearTraduccion = async(payload: any) => {
    try{
        const resp = await MilestoneServiceRequest.post("/api/0.1/translations/", payload)
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const updateTraduccion = async(payload:any, id:string) => {
    try{
        const resp = await MilestoneServiceRequest.patch(`/api/0.1/translations/${id}/`, payload)
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const deleteTraduccion = async(id:any) => {
    try{
        const resp = await MilestoneServiceRequest.delete(`/api/0.1/translations/${id}/`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

/** programs */
export const getPrograms = async (page: number = 1, search: string = "") => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/programs/?search=${search}&page=${page}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


/** programs */
export const getProgramsMinimal = async ( ) => {
    try {
        const resp = await MilestoneServiceRequest.get(`/api/0.1/programs/minimal/`);
        return Promise.resolve(resp);
    } catch (err: any) {
        return Promise.reject(err);
    }
}
