import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { deleteUserPremium } from "services/mobile-app.service";


function PushDeleteModal({ toEdit, close, visible, refresh }: any) {
  const deleteTemplete = async (email: any) => {
    await deleteUserPremium({ email: email });
    await refresh();
    close();
  };
  
  return (
    <Modal open={visible}>
      <Box sx={minorStyle}>
        <Grid container flexDirection={"row"}>
          <Grid container p={1}>
            <Grid item>
              <Typography variant="h6" color={"black"}>
                Seguro que quieres borrar este premium futuro:
              </Typography>
              <Typography variant="h6" color={"black"}>
                Email: {toEdit?.email}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Tiempo: {toEdit?.time}
              </Typography>
              <Typography variant="h6" color={"black"}>
                NOTA: Si el usuario ya uso este premium borrarlo no se lo quitara.
              </Typography>

            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={()=>deleteTemplete(toEdit.email)}
              >
                Si
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={close}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { PushDeleteModal };
