import { useFormik } from 'formik';
import Loading from 'components/Loading';
import { getUsers } from 'data/users';
import { Usuario } from 'interfaces/Usuario';
import {useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';
import ExcelBulkModal from 'components/ExcelBulkModal';
import { bulkUserCreate, bulkUserStartMessageSend } from 'data/auth-groups';

import { Button, Form, Modal, Toast } from 'react-bootstrap';
import { sendWelcomeMail } from 'data/programs';

const CM_URL = process.env.REACT_APP_CM

const UsersList = () => {
  const toastTypes = {
    success: "bg-success text-white m-3",
    error: "bg-danger text-white m-3"
  }
  const [ users, setUsers ] = useState<Usuario[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [emailModalUser, setEmailModalUser] = useState({username:""});
  const [emailModalLoading, setEmailModalLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  useEffect(() => {
    let mounted = true;

    if(mounted){
      getUsers(search)
      .then( (resp:any) => {
        if(resp){
          const { count, results } = resp.data;
          setUsers(results);
          setCount(count);
          setLoading(false);
        }
      }).catch( err => console.log(err));
    }

    return function cleanUp(){
      mounted = false;
    }
  },[search]);

  const handlePageChange = async (page: number) => {
    setLoading(true);
    setActivePage(page)
    const resp = await getUsers(search, page)
    const {results, count} = resp.data;
    setCount(count);
    setUsers(results);
    setLoading(false);
  }

  const handleEmailUserModalClose = () => {
    setEmailModalShow(false)
    formik.resetForm()
  }

  const handleEmailUserModalOpen = (user: any) => {
    setEmailModalUser(user)
    setEmailModalShow(true)
  }

  const formik = useFormik({
    initialValues: {
        password: "",
        username: "",
        personal_code: ""
    },
    enableReinitialize: false,
    onSubmit: (values) => {
      values.username=emailModalUser.username
      setEmailModalLoading(true)
      sendWelcomeMail(values).then(() => {
        setToastMessage("Correo enviado correctamente")
        setToastType(toastTypes.success)
      }).catch((error) => {
        setToastMessage(error.response.data)
        setToastType(toastTypes.error)
      }).then(() => {
        handleEmailUserModalClose()
        setShowToast(true)
        setEmailModalLoading(false)
      })
    }
});

  if(loading){
    return <Loading />
  }
  return (
        <>
          <Toast
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                minWidth: "200px"
              }}
            className={`${toastType} text-white m-3`}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>

          <ExcelBulkModal modalId="userCreateModal" title="Crear Usuarios"
            urlFormat={`${CM_URL}/static/excels/userCreateFormat.xlsx`}
            requiredFields={["Usuario", "Nombre",	"Apellido",	"Rol",	"Grupos",	"Clave"]}
            sendCallback={function (users: Object[]): Promise<any> {
              return bulkUserCreate(users);
            }}/>

          <ExcelBulkModal modalId="bulkEmailModal" title="Enviar Correos"
            urlFormat={`${CM_URL}/static/excels/EmailUserFormat.xlsx`}
            successMessage="Correos enviados correctamente"
            requiredFields={["username", "password", "personal_code", "group_code"]}
            sendCallback={function (users: Object[]): Promise<any> {
              return bulkUserStartMessageSend(users);
            }}/>
         <Modal show={emailModalShow} onHide={handleEmailUserModalClose}>
          <Modal.Header>
            <Modal.Title>Enviar Correo a usuario {emailModalUser.username}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            {emailModalLoading? <Loading/>:
              <>
                <Modal.Body>
                  <Form.Control size="lg" type="text" placeholder="Contraseña"  name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className="mb-3"
                    onBlur={formik.handleBlur} />
                  <Form.Control size="lg" type="text" placeholder="Codigo Personal"  name="personal_code"
                    value={formik.values.personal_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary"  onClick={handleEmailUserModalClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Enviar correo
                  </Button>
                </Modal.Footer>
              </>
            }
            </Form>
        </Modal>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h4>Listado de usuarios</h4>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="dropdown float-right">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                      Opciones
                    </button>
                    <div className="dropdown-menu text-primary" aria-labelledby="dropdownMenuButton">
                      <Link to="/admin/users/create" className="dropdown-item">
                        <i className="fa fa-user mr-2"></i> Crear usuario
                      </Link>
                      <button className="dropdown-item" title="Crear Usuarios"
                        data-toggle="modal" data-target="#userCreateModal">
                          <em className="fas fa-globe-americas mr-2"></em> Crear Usuarios
                      </button>
                      <button className="dropdown-item" title="Enviar Correo Masivo"
                        data-toggle="modal" data-target="#bulkEmailModal">
                          <em className="fas fa-envelope mr-2"></em> Enviar Correo Masivo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <SearchForm
              search={search}
              setSearch={setSearch}
              />

              <div className="table-responsive">
                {users.length > 0 &&
                <>
                  <table className="table"
                  width="100%" id="data">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Groups</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map( item => (
                        <tr key={item.id}>
                          <td><Link to={`/admin/users/${item.id}/show`}>{item.username}</Link></td>
                          <td>{item.email}</td>
                          <td>{item.first_name}</td>
                          <td>{item.last_name}</td>
                          <td>
                            {item.groups_name?.join(", ")}
                          </td>
                          <td>
                            {item.is_active &&
                              <span className="text-success small"><i className="fa fa-check"></i> Activo</span>
                            }

                            {!item.is_active &&
                              <span className="text-danger small"><i className="fa fa-times"></i> Inactivo</span>
                            }
                          </td>
                          <td className="text-right">
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <a href={`/admin/users/${item.id}/show`} className="btn btn-warning"><i className="fa fa-edit"></i></a>
                              <button className='btn btn-primary' onClick={() => handleEmailUserModalOpen(item)}><em className='fas fa-envelope'></em></button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                </>
                }

                {users.length === 0 &&
                  <div className="alert alert-info">No hay usuarios</div>
                }
              </div>
            </div>
          </div>
        </>
    )
}

export default UsersList;
