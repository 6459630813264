import { useState } from 'react'
import ListInstances from './components/ListInstances'
import ListUsers from './components/ListUsers'

const PeopleList = () => {

    const [type, setType] = useState(localStorage.getItem('peopleType') || 'users');

    const handleTypeChange = (e: any) => {
        const { value } = e.target;
        localStorage.setItem('peopleType', value);
        localStorage.setItem('peopleCurrentPage', "1");
        setType(value)
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h4>
                                <i className="fa fa-users"></i> People
                            </h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="">
                                        Filter by
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <select name="type"
                                        value={type}
                                        onChange={handleTypeChange}
                                        className="form-control form-control-sm">
                                        <option value="users">User</option>
                                        <option value="instances">Instance</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {type === 'instances' &&
                        <ListInstances />
                    }

                    {type === 'users' &&
                        <ListUsers />
                    }

                </div>
            </div>
        </>
    )
}
export default PeopleList
