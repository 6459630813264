import { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  FormControl,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { createPushCalendar, updatePushCalendar } from "services/nodejs.service";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
// import { createPushService } from "services/nodejs.service.ts";

interface LanguageData {
  on: boolean;
  id?: number | null;
  media: { id: number } | null;
  templete?: { id: number } | null;
  format?: number | null;
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}

export interface LanguageMap {
  spanish: LanguageData;
  english: LanguageData;
  portuges: LanguageData;
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type Props = {
  close: () => void;
  visible: boolean;
  englishTemplete: any[];
  spanishTemplete: any[];
  portTemplete: any[];
  imageMedia: any[];
  videoMedia: any[];
  pushFragments: any;
  refeshPush: () => void;
  toEdit: any;
};

function isEmpty(obj: any) {
  if (obj.reset) return true;
  return Object.keys(obj).length === 0;
}

function SinglePushModal({
  close,
  visible,
  pushFragments,
  refeshPush,
  toEdit,
}: Props) {
  const [sendDate, setSendDate] = useState<Dayjs | null>(dayjs());
  const [minorModal, setMinorModal] = useState(false);
  const [channel, setChannel] = useState<undefined | number>(0);
  const [templete, setTemplete] = useState<any>(null);
  const [programs, setPrograms] = useState<null | string >("");
  const refreshModal = () => {
    setChannel(0);
    setPrograms("");
  };
  useEffect(() => {
    if (!Object.keys(toEdit).length) {
      return;
    }
    if (toEdit.reset) {
      refreshModal();
      return;
    }
    console.log("did not exit");
    setSendDate(
      dayjs(
        new Date(
          toEdit.date_to_send.getTime() +
            toEdit.date_to_send.getTimezoneOffset() * 60000
        )
      )
    );
    setChannel(toEdit.channel);
    let pushFragmentsTemp = toEdit.channel as number>99?pushFragments.wa:pushFragments.app
    setTemplete(pushFragmentsTemp.find((el:any)=>el.value==toEdit.push_id));
    setPrograms(toEdit.program_ids);
  }, [toEdit]);

  async function createPush() {
    let pushToSend: any = {
      channel: channel,
      date_to_send: sendDate?.format("DD MMM YYYY")
    };
    if (programs && programs != "") pushToSend.program_ids = programs;
    if(templete.type=='push') pushToSend.push_to_send= templete.value
    else pushToSend.group_to_send= templete.value
    let push;
    if (isEmpty(toEdit)) {
      push = await createPushCalendar(pushToSend);
    } else {
      pushToSend.id = toEdit.id;
      push = await updatePushCalendar(pushToSend);
    }
    if (push.status == 201) {
      sleep(1000);
      refeshPush();
      refreshModal();
      close();
    }
  }

  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              style={{ display: "flex", flexDirection: "row" }}
              p={1}
            >
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Fecha"
                    defaultValue={dayjs()}
                    slotProps={{
                      textField: { fullWidth: true },
                    }}
                    value={sendDate}
                    onChange={(val) => {
                      // console.log('sys',dayjs().format())
                      // console.log('trans',val?.format())
                      if (val) setSendDate(val);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="channel">Channel</InputLabel>
                  <Select
                    labelId="channel"
                    id="channel"
                    label="Channel"
                    value={channel}
                    onChange={(v) => {
                      setChannel(v.target.value as number);
                      setTemplete(undefined)
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      APP POR PROGRAMA
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      APP BRAZIL
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      APP BOTNAR
                    </MenuItem>
                    <MenuItem key={3} value={50}>
                      AFINIAPP
                    </MenuItem>
                    <MenuItem key={4} value={100}>
                      WHATSAPP PROGRAMA
                    </MenuItem>
                    <MenuItem key={5} value={101}>
                      WHATSAPP BRAZIL
                    </MenuItem>
                    <MenuItem key={6} value={102}>
                      WHATSAPP BOTNAR
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {channel == 100 || channel == 0 ? (
              <Grid item xs={12} p={1}>
                <TextField
                  label="Program ids"
                  value={programs}
                  onChange={(el) => setPrograms(el.target.value)}
                  fullWidth
                />
              </Grid>
            ) : null}
            {/* <></> */}
            <Grid item xs={12} p={1}>
                {/* /#1 */}
            <Autocomplete
                    // multiple
                    id=""
                    value={templete||null}
                    options={channel==100?pushFragments.wa:pushFragments.app}
                    getOptionLabel={(option:any) => option.label}
                    onChange={ (v,nv) => setTemplete(nv) }
                    // filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Pushes a enviar"
                        placeholder=""
                    />
                    )}
                />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={createPush}>
                {isEmpty(toEdit) ? "Agregar" : "Editar"}
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refreshModal();
                  close();
                }}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal open={minorModal}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error al guardar push.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => setMinorModal(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default SinglePushModal;
