import { IRoute } from '../interfaces/Route';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import ListSession from 'pages/sessions/List';

export const SESSIONS_ROUTES:IRoute[] = [
    {
        path: '/admin/sessions/',
        component: ListSession,
        codename:'list_session',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    }
]
