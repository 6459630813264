import { IRoute } from "interfaces/Route";
import VideoList from "pages/media/VideoList";
import MediaList from '../pages/media/MediaList';

export const MEDIA_ROUTES:IRoute[] = [
    {
        path:'/admin/media/images',
        component: MediaList,
        exact: true,
    },
    {
        path:'/admin/media/videos',
        component: VideoList,
        exact: true,
    }
];
