import { useState } from 'react';

const UserSelect = (props: any) => {
    const [programAttr, setProgramAttr] = useState<any>(props.programAttr);
    const initialValue: any = props.programAttr.value;
    
    let changePrograms = (option: any) => {

        programAttr['value'] = option.label
        setProgramAttr(programAttr);
        
        initialValue === option.label ? props.removeNewValue(programAttr.attribute_id) : props.addNewValue(option.value, programAttr.attribute_id);

    }

    return (
            <li className="col-12 col-lg-6 py-2" key={`prograttr-${programAttr.program_attribute_id}`}>
                <div className="d-flex flex-wrap p-2"><span className="pr-2">{programAttr.name}</span>
                    <div className="dropdown">
                        <button className={`btn dropdown-toggle ${programAttr.risk === 0 ? 'btn-success' : programAttr.risk === 1 ? 'btn-danger' : 'btn-primary'}`}
                                type="button" 
                                id={`dropdown-attribute-${programAttr.attribute_id}`}
                                data-toggle="dropdown" 
                                aria-haspopup="true"
                                aria-expanded="false">{programAttr.value}
                        </button>
                        <div className="dropdown-menu" aria-labelledby={`dropdown-attribute-${programAttr.attribute_id}`}>
                            {
                                programAttr.options.map((option: any) => (
                                    <button className="dropdown-item"
                                            key={`option-${option.label}`}
                                            onClick={() => changePrograms(option) }>
                                        {option.label}</button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </li>
    )
};

export default UserSelect