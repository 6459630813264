import axios from 'axios';
import { Attribute } from 'interfaces/Attribute';
const URL = process.env.REACT_APP_API_URL_CM

/** get attributes list */
export const getAttributesList = (type: string = "user", typeId = "") => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/attributes/?pagination=off`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/** get attributes type date list */
export const getDateAttributesList = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/attributes/?attribute_type=date`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


export const getAttributes = () => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/attributes/?pagination=off`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/** obtener Attribute por id */
export const getAttributeById = (id: string): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/attributes/${id}`;
        try {
            const resp = await axios.get(endpoint);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}


/** agregar nuevo Attribute */
export const addAttribute = (payload: Attribute): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/attributes/`;
        try {
            const resp = await axios.post(endpoint,payload);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}

/** actualizar atributo */
export const updateAttribute = (id: string, payload: Attribute): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/attributes/${id}/`;
        try {
            const resp = await axios.put(endpoint, payload);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}
