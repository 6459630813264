import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

interface IProps{
    setSearch: (value: any) => void,
    search: string,
    className?: any,
    placeholder?:string | null
}


const SearchField = ({search, setSearch,
                      className="form-control form-control-sm", 
                      placeholder=null}: IProps) => {
    const [text, setText] = useState(search);
    const {t} = useTranslation();

    useEffect(()=> {
        const pressControl = setTimeout(() => {
            setSearch(text);
        }, 400)
        return () => clearTimeout(pressControl)
    }, [setSearch, text])

    const handleInputChange = (e:any) => {
        setText(e.target.value)
    }

    return (
        <input type="text" name="search"
        value={text}
        className={className}
        placeholder={placeholder || t("buscar")}
        onChange={handleInputChange}/>
          
    )
    
}

export default SearchField
