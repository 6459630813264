import { useContext, useState, useEffect } from 'react';
import FormIntent from './components/FormIntent';
import {IntentsContext} from './IntentsContextProvider';
import TrainingText from './components/TrainingText';
import {Intent} from '../../interfaces/Intent';
import Loading from '../../components/Loading';
import { destroyIntent } from 'data/intents';
import swal from 'sweetalert';
import BotReplyWithText from './components/BotReplyWithText';
import BotReplyWithSession from './components/BotReplyWithSession';
import SetTrainingModal from './components/SetTrainingModal';
import { startTrain, statusTrain } from '../../data/trainer';



const NluMain = () => {
    const {
        intents,
        setIntents,
        showIntentForm,
        setShowIntentForm,
        next,
        previous,
        setPage,
        page,
        loading
    } = useContext(IntentsContext);

    const [ training, setTraining ] = useState<string>("Cargando..");

    const handlePageChange = (boton:string) => {
        if(boton === 'prev'){
            setPage((page:number) => page - 1);
        }

        if(boton === 'next'){
            setPage((page:number) => page + 1);
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const newData = intents.filter((intent:Intent) => intent.id !== id);
            await destroyIntent(id);
            swal("Afinidata","Intent borrado correctemante","success");
            setIntents(newData);
        } catch(err:any){
            swal("Afinidata","Ocurrio un error, intente de nuevo","error");
            console.log(err);
        }
    }

    const trainrasa = () => {
        setTraining("Ocupado")
        startTrain().then((resp: any)=>{
            if(resp.result.message.includes("ocupado"))
                swal("Afinidata","El sistema está ocupado","error");
            else
                swal("Afinidata","Se ha iniciado el entrenamiento","success");
        })
    }

    const handleRadioChange = (e:any, id: string) => {
        const newList = intents.map( intent => intent.id === id ?
            {...intent, response: e.target.value} : intent);
        setIntents(newList);
    }

    const reloadTrainer = () => {

        setTraining("Cargando..")

        statusTrain().then((resp: any)=>{
            console.log("statusTrain");
            console.log(resp);
            setTraining(resp.data.result.status)
        }).catch(error => {
            console.error(error);
            setTraining("Disponible");
        })
    }

    useEffect(() => {
        statusTrain().then((resp: any)=>{
            console.log("statusTrain");
            console.log(resp);
            setTraining(resp.data.result.status)
        }).catch(error => {
            console.error(error);
            setTraining("Disponible");
        })
    }, []);

    if(loading){
        return <Loading />
    } else {
    return (
        <>
            <SetTrainingModal/>
            <div className="row mb-3">
                <div className="col-12">
                    <h4 className="d-inline-block"><i className="far fa-smile"/> NLU</h4>
                    <p>
                        Estado servicio:&nbsp;
                        <span className={`${training === "Disponible" ? "training-on" : "training-off"}`}>
                            {training}
                        </span>
                        <span onClick={()=> reloadTrainer()}>&nbsp;<i className="fas fa-redo"/></span>
                    </p>
                    <div className="btn-group float-right" role="group" aria-label="Basic example">
                        <button className="btn btn-secondary" data-toggle="modal" data-target="#trainingModal">
                            <i className="fas fa-upload"/>Subir Intents</button>
                        <button className="btn btn-success" onClick={() => trainrasa()}>
                            <i className="fas fa-caret-circle-right"/>Entrenar rasa
                        </button>
                        <button className="btn btn-primary px-5"
                            onClick={() => setShowIntentForm(!showIntentForm)}>
                            { showIntentForm ? (
                                <span><i className="fa fa-minus"/> Close</span>
                            ) : (
                                <span><i className="fa fa-plus"/> Add</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                { showIntentForm &&
                    <FormIntent />
                }
            </div>

            {intents.length === 0 &&
                <div className="alert alert-info my-3">No hay data</div>
            }

            {intents.length > 0  &&
                <div>
                    {intents.map((item:any) => (
                        <div className="bg-white p-4 mb-3" key={item.id}>
                            <div className="row">
                                <div className="col-sm-12 text-right">
                                    <button className="btn btn-danger btn-sm"
                                    type="button"
                                    onClick={() => handleDelete(item.id)}>
                                        <i className="fa fa-trash"></i> Eliminar
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Intents</p>
                                    <hr/>
                                    <FormIntent intent={item}/>
                                </div>
                                <div className="col-md-4">
                                    { item &&  <TrainingText intent={item} />}
                                </div>
                                <div className="col-md-5">
                                    <p>Bot replies with</p>
                                    <hr/>
                                    <div className="form-group">
                                        <label htmlFor="" className="radio-inline">
                                            <input type="radio"
                                            checked={item.response==="text"}
                                            value="text"
                                            onChange={(e) => handleRadioChange(e, item.id)}/>
                                            <span className="ml-2">Text (If multiple options answer will be send randomly)</span>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="radio-inline">
                                            <input type="radio"
                                            checked={item.response==="session"}
                                            value="session"
                                            onChange={(e) => handleRadioChange(e, item.id)}/>
                                            <span className="ml-2">Session</span>
                                        </label>
                                    </div>
                                    <div className="row">
                                        { item.response === 'text' && (
                                            <div className="col-sm-12">
                                                <BotReplyWithText intent={item}
                                                intentId={item.id}/>
                                            </div>
                                        )}

                                        { item.response === 'session' && (
                                            <div className="col-sm-12">
                                                <BotReplyWithSession
                                                intent={item}
                                                intentId={item.id}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="mt-3 text-center mb-3">
                        <div>
                            <h5>Page: {page}</h5>
                        </div>
                        { previous &&
                            <button className="btn btn-primary ml-2"
                            type="button"
                            onClick={() => handlePageChange('prev') }>Anterior</button>
                        }

                        { next &&
                            <button className="btn btn-primary ml-2"
                            type="button"
                            onClick={() => handlePageChange('next') }>Siguiente</button>
                        }
                    </div>
                </div>
            }
        </>
    )}
}

export default NluMain
