import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "components/Loading";
import swal from "sweetalert";
import { getDetailProgramAttributes, updateProgramAttributes } from "data/programs-attributes";
import { ProgramAttributes } from "interfaces/Program";

type Props = {
    programAttribute: string,
    programAttributeTypeId: string
}

const validationSchema = Yup.object().shape({
    label: Yup.string().required().max(50),
    weight: Yup.number().required(),
    threshold: Yup.number().required()
});


const EditProgramAttribute = ({match}:RouteComponentProps<Props>) => {

    const [initialValues, setInitialValues] = useState<ProgramAttributes>({ id: "", attribute: "", attribute_type: "", weight: 0, threshold: 0, label:""});
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();
    const programAttribute = match.params.programAttribute;
    const attributeTypeId = match.params.programAttributeTypeId;
    const returnUrl = `/admin/program_attributes/configure/${attributeTypeId}`;

    useEffect(() => {

        getDetailProgramAttributes(programAttribute)
        .then( resp => {
            setInitialValues(resp.data.results[0]);
            setLoading(false);
        })
        .catch( err => {console.log(err); history.push("/admin/programs"); });

    }, [programAttribute, attributeTypeId, history]);

    const handleSubmit = async (payload: any): Promise<void> => {
        try{
            await updateProgramAttributes(programAttribute, payload);
            await swal("Afinidata","Atributo de Programa actualizado.","success")
            history.push(returnUrl);
        }catch(err:any){
            swal("Afinidata",'Ocurrió un error intente de nuevo','error');
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={returnUrl} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Editar Atributo de Programa</h6>
                            <hr/>
                            { initialValues && !loading ? (
                                    <div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="label">Label</label>
                                                <input type="text" name="label" placeholder="Enter label"
                                                className={`form-control ${
                                                    formik.errors.label && formik.touched.label ? "is-invalid" : ""
                                                }`}
                                                value={formik.values.label}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                                {formik.errors.label && formik.touched.label ? (
                                                    <div className="invalid-feedback">{formik.errors.label}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="weight">Weight</label>
                                                <input type="text" name="weight" placeholder="Ingrese weight"
                                                value={formik.values.weight}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="threshold">Threshold</label>
                                                <input type="text" name="threshold" placeholder="Enter threshold"
                                                value={formik.values.threshold}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"/>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <button type="submit"
                                                    className="btn btn-primary">{/* 
                                                // @ts-ignore */}
                                                        <FontAwesomeIcon icon={faSave} /> Actualizar
                                                    </button>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <Loading />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EditProgramAttribute
