import { storeIntent, updateIntent } from 'data/intents';
import { useContext, useState, useEffect } from 'react';
import { IntentsContext } from '../IntentsContextProvider';
import swal from 'sweetalert';
import validator from 'validator';
import { Intent } from 'interfaces/Intent';

interface Props {
    intent?: Intent;
}

const FormIntent = ({ intent }: Props) => {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { intents, setIntents, setShowIntentForm } = useContext(IntentsContext);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (intent) {
                setId(intent?.id || "");
                setName(intent.name);
                setDescription(intent.description || "");
            }
        }

        return () => { mounted = false; }
    }, [intent])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setErrorMessage("")

        if (validator.isEmpty(name)) {
            setErrorMessage("Name is required");
            return;
        }

        try {
            let resp: any;

            if (intent) {
                resp = await updateIntent(intent.id, { name, description });
                await swal('Afinidata', 'Intent actualizado correctamente', 'success');
            } else {
                let rasa_name: string = name.replace(" ", "_").toLowerCase();
                resp = await storeIntent({ name, description, rasa_name });
                console.log(resp);
                const payload = {
                    ...resp.data,
                    trainingtext: resp.data.trainingtext_set,
                    responsesession: resp.data.responsesession_set,
                    responsetext: resp.data.responsetext_set
                }
                setIntents([payload, ...intents]);
                setShowIntentForm(false);
                await swal('Afinidata', 'Intent creado correctamente', 'success');
            }
        } catch(err:any) {
            if (err.response.data?.name) {
                swal('Afinidata', err.response.data?.name[0], 'error');
                return;
            }
            swal('Afinidata', 'Ocurrio un error, intente de nuevo', 'error');
        }
    }

    return (
        <div className="card mb-2 animate__animated animate__fadeIn">
            {!intent &&
                <div className="card-header">
                    <h4>Add new intent</h4>
                </div>
            }
            <div className="card-body">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="">Id</label>
                        <p className="static-control">{id}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input type="text" name="intent_name" className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Description</label>
                        <textarea name="intent_description" className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-outline-primary btn-block">
                            <span className="fa fa-save"></span> Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormIntent
