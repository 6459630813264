import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_CM;

export const getCmGroupsOptions = (): Promise<any> => {

    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/groups/?options=True`);
            resolve(resp);
        }
        catch(err:any) {
            reject(err);
        }
    });
}


export const getCmGroups = (): Promise<any> => {

    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/groups/`);
            resolve(resp);
        }
        catch(err:any) {
            reject(err);
        }
    });
}
