import axios from 'axios';

// const URL = process.env.TRAINER_API

const URL = process.env.NLU_URL;

/**
 * description: obtener listado de bots
 * @param: search string
 * @param: page number
 * */
export const startTrain = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/training/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const statusTrain = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/status_training/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

