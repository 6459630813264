import Spinner from "components/Spinner";
import { GroupContext } from "context/GroupContext"
import { useContext } from "react"
import { useTranslation } from "react-i18next";

const DashboardResume = () => {
    const {data:{resume}, isLoading} = useContext(GroupContext);
    const {t} = useTranslation();

    return (
        <div className="row mb-5">
            <div className="col">
                <div className="resume-card card-1">
                    <h3>{t('total_usuarios')}</h3>
                    {isLoading &&  <Spinner />}
                    {!isLoading && <h4>{resume.users}</h4> }

                </div>
            </div>
            <div className="col">
                <div className="resume-card card-2">
                    <h3>{t('total_ninos')}</h3>
                    {isLoading &&  <Spinner />}
                    {!isLoading && <h4>{resume.children}</h4> }
                </div>
            </div>
            <div className="col">
                <div className="resume-card card-3">
                    <h3>{t('actividades_entregadas')}</h3>
                    {isLoading &&  <Spinner />}
                    {!isLoading && <h4>{resume.entregados}</h4> }
                </div>
            </div>
            <div className="col">
                <div className="resume-card card-3">
                    <h3>{t('actividades_consumidas')}</h3>
                    {isLoading &&  <Spinner />}
                    {!isLoading && <h4>{resume.cosumidos}</h4> }
                </div>
            </div>
        </div>
    )
}

export default DashboardResume
