import { asignarSecuenciaEnHottrigger } from 'data/live-chat';
import { useContext, useEffect, useState } from 'react';
import { MessengerUserContext } from '../MessengerUserContextProvider';
// @ts-ignore
import Select from 'react-select';
import swal from 'sweetalert';
import { getSequences } from 'data/sequences';

interface SelectSession {
    label: string;
    value: string;
}

interface Props {
    onFinish: () => void
}

const AsignarHotTrigger = ({onFinish}:Props) => {
    const { messengerUser } = useContext(MessengerUserContext);
    const [sequences, setSequences] = useState<SelectSession[]>([]);
    const [sequenceId, setSequenceId] = useState("");
    const [ position, setPosition ] = useState(1);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        let mounted  = true;
        if(mounted){
            getSequences()
            .then( resp => {
                setSequences(resp.data.results || [])
            })
            .catch( err => console.log(err))
        }

        return () => {  mounted = false; }
    },[]);

    const handleSelectChange = (item:SelectSession) => {
        setSequenceId(item.value);
        console.log(sequenceId)
    }

    const handleSave = async (e:any) =>{
        setLoading(true)
        e.preventDefault();

        const payload = {
            "sequence_id": sequenceId,
            "start_position": position,
            "data": [
                {
                    "user_id":messengerUser.id,
                    "bot_id":messengerUser.bot_id,
                    "user_channel_id":messengerUser.user_channel_id,
                    "bot_channel_id":messengerUser.bot_channel_id
                }
            ]
        };

        try{
            const resp: any = await asignarSecuenciaEnHottrigger(payload);
            const {data}:any = resp;
            if(data.request_status === 'done'){
                await swal('Afinidata','Ejecutado correctamente','success');
            } else {
                await swal('Afinidata','Ocurrio un error al guardar, intente de nuevo','error');
            }
            onFinish();
        } catch(err:any){
            console.log(err);
        }
        setLoading(false);
    }
    return (
        <div className="row mt-2">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSave}>
                            <div className="form-group">
                                <label htmlFor="">Sequences</label>
                                <Select
                                onChange={handleSelectChange}
                                options={sequences.map( (item:any) => ({label: item.name, value: item.id}))}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Start position</label>
                                <input type="number"
                                value={position}
                                name="position"
                                className="form-control"
                                onChange={(e:any) => setPosition(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block btn-sm"
                                disabled={loading}>{ loading ? 'Guardando...' : 'Guardar'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AsignarHotTrigger
