import { Message } from 'interfaces/Message';
import { useState, useContext, useRef } from 'react';
import { enviarMensaje, stopBot } from '../../../data/live-chat';
import { MessengerUserContext } from '../MessengerUserContextProvider';
import swal from 'sweetalert';

// @ts-ignore
import InputEmoji from "react-input-emoji";

interface Props {
    onFinish: (message:any) => void;
}

const SendMessage = ({onFinish}:Props) => {
    const [message, setMessage] = useState("");
    const { messengerUser } = useContext(MessengerUserContext);
    const inputFile = useRef<HTMLInputElement>(null);

    const handleFileInputChange = (e:any) => {
        console.log(e.target.files[0]);
    }

    const handleMessageSend = async(e:any) => {
        if(message.trim().length < 2){
            swal("Afinidata","Hola, escribe algo. Por favor","warning");
            setMessage("");
            return;
        }

        try{
            const { bot_id, bot_channel_id, user_channel_id } = messengerUser
            const resp:any = await enviarMensaje(bot_id, bot_channel_id, {
                userChannelId: user_channel_id,
                message
            });

            if(resp.data.status === 'done'){

                let newMessage:Message  = {
                    message_type: 'text',
                    message_sender:'bot',
                    content: message,
                    created_at: new Date()
                }
                // detener el bot
                await stopBot(messengerUser.id, bot_id);
                onFinish(newMessage);
            } else{
                swal('Afinidata','Ocurrio un error al enviar el mensaje','intenta de nuevo');
            }

            setMessage("");
        } catch(err:any){
            console.log(err)
        }
    }

    return (
        <div className="row mt-2">
            <div className="col-md-12">
                <div className="form-group">
                        <InputEmoji
                        value={message}
                        onChange={setMessage}
                        cleanOnEnter
                        onEnter={handleMessageSend}
                        placeholder="Escribe tu mensaje..."
                        />
                        <button
                        className="btn btn-success btn-sm btn-block"
                        onClick={handleMessageSend}>
                            <i className="fa fa-paper-plane"></i> Enviar mensaje
                        </button>
                </div>
                <div>
                    <input type="file" name="image" style={{display:"none"}}
                    ref={inputFile} accept="image/*"
                    onChange={(e:any) => handleFileInputChange(e) }/>
                    <button className="btn btn-primary btn-sm"
                    type="button"
                    title="Adjuntar imagen"
                    onClick={() => inputFile.current?.click()}>
                        <i className="fa fa-paperclip"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SendMessage
