import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { deleteATemplete } from "services/nodejs.service";

const language_map = ["", "español", "ingles", "", "portugués"];

function DeleteModal({ templeteToDelete, close, visible, refresh }: any) {
  const [minorModal, setMinorModal] = useState(false);
  const deleteTemplete = async (id: any) => {
    let responce = await deleteATemplete({ id: id });
    if (responce.status == 205) {
        setMinorModal(true)
    }
    await refresh();
    close();
  };
  return (
    <>
      <Modal open={visible}>
        <Box sx={minorStyle}>
          <Grid container flexDirection={"row"}>
            <Grid container p={1}>
              <Grid item>
                <Typography variant="h6" color={"black"}>
                  Seguro que quieres borrar el templete: {templeteToDelete.name}{" "}
                  en {language_map[templeteToDelete.language_id]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} p={1}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => deleteTemplete(templeteToDelete.id)}
                >
                  Si
                </Button>
              </Grid>
              <Grid item xs={6} p={1}>
                <Button variant="contained" fullWidth onClick={close}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={minorModal}>
        <Box sx={minorStyle}>
          <Grid container flexDirection={"row"}>
            <Grid container p={1}>
              <Grid item>
                <Typography variant="h6" color={"black"}>
                    Este templete esta siendo usado por alguna push pasada o futura.
                  {/* {templeteError} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={() => setMinorModal(false)}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { DeleteModal };
