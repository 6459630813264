import '../style.css';
import swal from 'sweetalert';
import ItemAttribute from "./ItemAttribute";
import NewUserAttrModal from './NewUserAttrModal';
import NewInstanceAttrModal from './NewInstanceAttrModal';
import { getInstancesByUser } from "data/instances";
import { useCallback, useContext, useEffect, useState } from "react"
import { getInstanceAttributesById, updateInstanceAttribute } from 'data/instance-attributes';
import { getUserAttributes, updateUserAttribute } from "data/user-attributes";
// @ts-ignore
import Select from 'react-select';
import { setDataFormAttribute } from 'helpers/attributes-user';
import { MessengerUserContext } from '../MessengerUserContextProvider';

// interface IProps {
//     id: string;
// }

const ManagementAttributes = () => {
    const [dataUser, setDataUser] = useState<any[]>([]);
    const [dataInstance, setDataInstance] = useState<any[]>([]);
    const [showUserAttr, setShowUserAttr] = useState<boolean>(false);
    const [showInstanceAttr, setShowInstanceAttr] = useState<boolean>(false);
    const [instances, setInstances] = useState<any[]>([]);
    const [selectRef, setSelectRef] = useState<any>(null);
    const [selectInstance, setSelectInstance] = useState<any>('');
    const [searchUserAttr, setSearchUserAttr] = useState<string>("");
    const [searchInstanceAttr, setSearchInstancerAttr] = useState<string>("");
    const { messengerUser } = useContext(MessengerUserContext);
    const { id } = messengerUser;

    const clearValue = useCallback(() => {
        if(selectRef){
            selectRef.select.clearValue()
        }
    },[selectRef]);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            async function fetchData() {

                try{
                    setDataInstance([]);
                    setSelectInstance("");
                    const respU = await getUserAttributes(messengerUser.id,"","off");
                    const dataU = respU.data;
                    if(dataU){
                        setDataUser( dataU.map((d:any) => ({
                            id: d.id,
                            name: d.data_key,
                            value: d.data_value
                        })))
                    }
                    const respI = await getInstancesByUser(messengerUser.id);
                    const {results} = respI.data;
                    clearValue();
                    if(results.length > 0){
                        let data = results.map((item:any) => ({label: `${item.id} ${item.name}`, value: item.id}));
                        setInstances( data );
                    }
                } catch(err:any){
                    console.log(err)
                }

            }
            fetchData();
        }

        return () => { mounted = false;}
    },[messengerUser, clearValue]);

    const handleOnChange = (e:any, item:any, type: string = "user") => {
        if(type === "user"){
            setDataUser(dataUser.map(el => el.id === item.id ? {...item, value: e.target.value} : el));
        }

        if(type === "instance"){
            setDataInstance(dataInstance.map(el => el.id === item.id ? {...item, value: e.target.value} : el));
        }
    }

    const updateAttribute = async (item: any, type:string) => {
        try{
            if(type==='user'){
                await updateUserAttribute(item.id, {user: id, data_value: item.value});
            }

            if(type === 'instance'){
                await updateInstanceAttribute(item.id, item.value);
            }

            swal("Afinidata",`Atributo de ${type} editado correctamente.`,'success');
        } catch(err:any){
            swal("Afinidata",`Ocurrio un error`,'error');
        }
    }

    const getInstanceAttributes = async (id: string) => {
        try{
            if(id){
                setSearchInstancerAttr("");
                setSelectInstance(id);
                const resp = await getInstanceAttributesById(id);
                const { results } = resp.data;
                if(results.length > 0){
                    let data = results.map((item:any) => ({id: item.id, name: item.attribute.name,value: item.value}))
                    setDataInstance(data)
                } else {
                    setDataInstance([]);
                }
            }
        } catch(err:any){
            console.log(err);
        }
    }

    const searchInAttributes = async (e: any, type: string = 'user') => {
        try{
            e.preventDefault();
            if(type === 'user'){
                const resp = await getUserAttributes(id, searchUserAttr, 'off');
                setDataUser(setDataFormAttribute(resp.data));
            }

            if(type === 'instance'){
                const resp = await getInstanceAttributesById(selectInstance,searchInstanceAttr);
                const {results} = resp.data;
                if(results.length > 0){
                    const data = results.map((item:any) => ({id: item.id, name: item.attribute.name,value: item.value}))
                    setDataInstance(data);
                }else {
                    setDataInstance([])
                }
            }
        }catch(err:any){
            console.log(err)
        }
    }

    const clearSearchAttr = async (e: any, type: string = 'user') => {
        try{
            e.preventDefault();
            if(type === 'user'){
                const resp = await getUserAttributes(id, "","off");
                setDataUser(setDataFormAttribute(resp.data));
                setSearchUserAttr("")
            }

            if(type === 'instance'){
                const resp = await getInstanceAttributesById(selectInstance,"");
                const {results} = resp.data;
                if(results.length > 0){
                    const data = results.map((item:any) => ({id: item.id, name: item.attribute.name,value: item.value}))
                    setDataInstance(data);
                }else {
                    setDataInstance([])
                }
                setSearchInstancerAttr("")
            }
        }catch(err:any){
            console.log(err)
        }
    }


    return (
        <>
            <NewUserAttrModal userId={id} onFinish={(item) => setDataUser([...dataUser, item])}/>
            <NewInstanceAttrModal instanceId={selectInstance}
            onFinish={ () => getInstanceAttributes(selectInstance) }/>
            <ul className="list-group mb-2">
                <li className="list-group-item">
                    User attributes
                    <div className="float-right">
                        <button className="btn btn-outline-primary btn-sm ml-3"
                        onClick={() => setShowUserAttr(!showUserAttr)}>
                            { !showUserAttr && <i className="fa fa-angle-down"></i>}
                            { showUserAttr && <i className="fa fa-angle-up"></i>}
                        </button>
                    </div>

                </li>
            </ul>
            <div className={`mt-3 mb-3 attributes-div ${!showUserAttr ? 'd-none' : ''}`}>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                                User attributes
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-primary btn-sm"
                                data-toggle="modal" data-target="#userAttrModal">
                                    <i className="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <form action="" onSubmit={(e) => searchInAttributes(e, 'user')}>
                                    <div className="form-group row">
                                        <div className="col-md-9">
                                            <input type="text" name="search_in_user" placeholder="Search by name or value"
                                            value={searchUserAttr}
                                            onChange={(e) => setSearchUserAttr(e.target.value)}
                                            className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <button
                                            className="btn btn-sm btn-primary"
                                            type="submit">
                                                <i className="fa fa-search"></i>
                                            </button>
                                            <button
                                            type="button"
                                            className="btn btn-sm btn-danger ml-2"
                                            onClick={(e) => clearSearchAttr(e, 'user')}>
                                                <i className="fa fa-eraser"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        { dataUser.length === 0 && (
                            <div className="alert alert-info">No hay data</div>
                        )}

                        {dataUser.length > 0 && (
                            dataUser.map( (item:any, i:number) => (
                                <ItemAttribute item={item}
                                key={item.id}
                                handleInputChange={(e) => handleOnChange(e, item, "user")}
                                onBlur={() => updateAttribute(item, "user")}/>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <ul className="list-group">
                <li className="list-group-item">
                    Instance attributes
                    <div className="float-right">
                        <button className="btn btn-outline-primary btn-sm ml-3"
                        onClick={() => setShowInstanceAttr(!showInstanceAttr)}>
                            { !showInstanceAttr && <i className="fa fa-angle-down"></i>}
                            { showInstanceAttr && <i className="fa fa-angle-up"></i>}
                        </button>
                    </div>
                </li>
            </ul>
            <div className={`mt-3 mb-3 attributes-div ${!showInstanceAttr ? 'd-none' : ''}`}>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                                <h5>
                                    Instance attributes
                                </h5>
                            </div>
                            <div className="col-md-6">
                                {selectInstance &&
                                    <button className="btn btn-primary btn-sm"
                                    data-toggle="modal" data-target="#instanceAttrModal"><i className="fa fa-plus"></i> Add</button>
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <form onSubmit={e => searchInAttributes(e, 'instance')}>
                                    <div className="form-group">
                                        <Select options={instances}
                                        placeholder="Select instance"
                                        ref={(ref:any) => setSelectRef(ref)}
                                        defaultValue={instances[0]}
                                        onChange={ (values:any) => getInstanceAttributes(values?.value) }/>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-9">
                                            <input type="text"
                                            name="search_in_instance"
                                            value={searchInstanceAttr}
                                            onChange={ e => setSearchInstancerAttr(e.target.value)}
                                            placeholder="Search by name or value"
                                            className="form-control form-control-sm"
                                            disabled={!selectInstance}/>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-primary btn-sm"
                                            type="submit">
                                                <i className="fa fa-search"></i>
                                            </button>
                                            <button className="btn btn-danger btn-sm ml-2"
                                            type="button"
                                            onClick={(e) => clearSearchAttr(e, 'instance')}>
                                                <i className="fa fa-eraser"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        { dataInstance.length === 0 && (
                            <div className="alert alert-info">No hay data</div>
                        )}

                        { dataInstance.length > 0 && (
                            dataInstance.map( (item:any) => (
                                <ItemAttribute item={item}
                                key={item.id}
                                handleInputChange={(e) => handleOnChange(e, item, "instance")}
                                onBlur={() => updateAttribute(item, "instance") }/>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManagementAttributes
