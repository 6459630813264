import UserSelect from './UserSelect'
import { useState } from 'react';
import { updateUserResponses } from 'services/core.service';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

const UserOption = (props: any) => {
    const { t } = useTranslation();
    const [newUserData, setNewUserData] = useState<any>({});

    const addNewValue = (value : any, attribute: number ) => {
        setNewUserData({ ...newUserData, [attribute] : value});
    }

    const removeNewValue = (attribute: number) => {
        delete newUserData[attribute]
        setNewUserData(newUserData)
    }

    const changeFormat = (newUserData:any, user: number) => {
        let listUserData: any = []
        let attributeIDs: any = []
        for(const attribute in newUserData){
            listUserData.push({ 'attribute_id': attribute, 
                                'data_value': newUserData[attribute],
                                'user_id': user
                            })
            attributeIDs.push(attribute)
        }
        return { 
                data : listUserData,
                attributes : attributeIDs
                }
    }

    const saveData = async () => {
        console.log(newUserData)
        props.setLoading(true)
        try {
            await updateUserResponses(changeFormat(newUserData, props.user))
            await props.fetchData();
            swal('Guardado correctamente', 'Actualizado correctamente', 'success')
        } catch (err: any) {
            console.error(err);
        } finally {
            props.setLoading(false)
        }
    }


    return (
        <>
            <div className="modal-body">
                    <div className="container" id="ficha_instancia">
                        { props.attributes.map((attr: any) => (
                            <div key={`attr-${attr.attributes_type_id}`}>
                            <h4>{attr.name}</h4>
                                <ul className="row">
                                    {
                                        attr.program_attributes.map((programAttr: any) => (
                                            <UserSelect 
                                                key={`user-select-${programAttr.program_attribute_id}`}
                                                programAttr={programAttr}
                                                addNewValue={addNewValue}
                                                removeNewValue={removeNewValue} />
                                        ))
                                    }
                                </ul>
                            </div>
                            )
                        )}
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-info" onClick={saveData}>
                    {t("guardar_cambios")} <i className="far fa-save"></i>
                </button>
                <button type="button" 
                        className="btn btn-secondary" 
                        data-dismiss="modal" 
                        aria-label="Close" 
                        onClick={() => props.setShowUserModal(false)}>
                    {t("regresar")} <i className="fas fa-reply"></i>
                </button>
            </div>
        </>
       
    )
};

export default UserOption
