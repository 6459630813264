import TimeInWA from 'pages/queries/TimeInWA';
import { IRoute } from '../interfaces/Route';

export const QUERIES_ROUTES:IRoute[] = [
    {
        path: '/admin/queries/',
        component: TimeInWA,
        codename:'queries',
        exact: true,
        requiredRoles: []
    }
]
