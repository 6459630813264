import { useEffect, useState } from 'react'
import { getPrograms } from 'services/milestones-services';

const usePrograms = () => {
    const [programs, setPrograms] = useState<any[]>([]);

    useEffect(() => {
        getPrograms()
        .then( resp => setPrograms(resp.data.results) )
        .catch( err => console.log(err));

    },[]);

    return [programs];
}

export default usePrograms
