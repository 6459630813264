import { useState, useEffect } from 'react';
import { detailBot } from '../../data/bots';
import { Bot } from '../../interfaces/Bot';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

type Props = {
    id: string
}

const ConfigureBot = ({ match }: RouteComponentProps<Props>) => {
    const [bot, setBot] = useState<Bot>({ id: '', name: '', description: '', url: '', botchannel_set: [] });
    const botId = match.params.id

    useEffect(() => {
        detailBot(botId)
            .then((resp: any) => {
                setBot(resp.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [botId])

    return (
        <>
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right mb-2">
                                <a href="/admin/bots" className="btn btn-outline-primary btn-sm"><i className="fa fa-arrow-left"></i> Volver al listado de bots</a>
                            </div>
                            <h5 className="text-primary m-0 font-weight-bold">Configurar bot</h5>
                        </div>
                        <div className="card-body">
                            <p><b>Name:</b> {bot.name}</p>
                            <p><b>Description:</b> {bot.description}</p>
                            <p><b>Url:</b> {bot.url}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={`/admin/channels/add/${botId}`} className="btn btn-primary"><i className="fa fa-plug"></i> Agregar canal</a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {bot.botchannel_set && bot.botchannel_set.length > 0 ? (
                                    bot.botchannel_set.map((channel) => (
                                        <div className="col-xs-12 col-md-6 my-1" key={channel.id}>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    <h6>
                                                        {channel.channel === 1 &&
                                                            //@ts-ignore
                                                            <FontAwesomeIcon icon={faFacebook} size="3x" color="#4267B2 " />
                                                        }
                                                        {channel.channel === 2 &&
                                                            //@ts-ignore
                                                            <FontAwesomeIcon icon={faWhatsapp} size="3x" color="#4267B2 " />
                                                        }
                                                    </h6>
                                                    <p className="ml-2 small"><b>Channel id: </b>{channel.id}</p>
                                                </div>
                                                <div className="info-channel" style={{ padding: "10px" }}>
                                                    <p><b>token: </b>{channel.token}</p>
                                                    <p><b>Challenge: </b>{channel.challenge}</p>
                                                    <p><b>Account sid: </b> {channel.account_sid}</p>
                                                    <p><b>Phone number: </b> {channel.phone_number}</p>
                                                    <p><b>Url de devolución: </b></p>
                                                    <p>
                                                        {`${process.env.REACT_APP_WEBHOOK}/bots/${botId}/channel/${channel.id}/webhook/`}
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-6 col-xs-12">
                                                            <a href={`/admin/channels/${channel.id}/bot/${botId}`} className="btn btn-outline-primary btn-sm btn-block">
                                                                <i className="fa fa-edit"></i> Editar
                                                            </a>
                                                        </div>
                                                        <div className="col-md-6 col-xs-12">
                                                            <a href={`/admin/channels/${channel.id}/delete`} className="btn btn-outline-danger btn-sm btn-block">
                                                                <i className="fa fa-edit"></i> Borrar
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-xs-12 col-md-12">
                                        <div className="alert alert-info">No hay canales configurados.</div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfigureBot
