import { useRef, useState } from "react";
import _uniqueId from 'lodash/uniqueId';


interface IProps {
    label: string,
    formik: any,
    formName: string,
    type?: string,
    params?: Object
}

const FormikField = ({label, formik, formName, type = 'string', params = {} }:IProps) => {
    const fileInputRef = useRef() as any;
    const defaultFieldInputShow = "Escoge un archivo"
    const [fieldInputShow, setFieldInputShow] = useState(defaultFieldInputShow)
    const [componentId] = useState(_uniqueId('formik-field-'));
    const setField = () => {
        switch (type) {
            case 'file':
                return (
                    <div className={`custom-file form-control ${formik.errors[formName] && formik.touched[formName] ? "is-invalid" : ""}`}>
                        <input type="file" ref={fileInputRef} aria-describedby="inputGroupFileAddon01" 
                        className={`custom-file-input ${formik.errors[formName] && formik.touched[formName] ? "is-invalid" : ""}`}
                        onChange={(event) => {
                            if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                                const file = event.currentTarget.files[0]
                                formik.setFieldValue(formName, file)
                                setFieldInputShow(file.name)
                            }
                          }
                        }
                        onClick={() => formik.setFieldTouched(formName)}/>
                        <label className="custom-file-label" htmlFor={fileInputRef.id}>{fieldInputShow}</label>
                    </div>)
            case 'checkbox':
                return (<div className="form-check">
                        <input className={`form-check-input ${formik.errors[formName] && formik.touched[formName] ? "is-invalid" : ""}`}
                            type="checkbox" id={`${componentId}-${formName}`}
                            value={formik.values[formName]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name={formName}
                         />
                        <label className="form-check-label" htmlFor={`${componentId}-${formName}`}>
                            {label}
                        </label>
                    </div>)
            default:
                return (<input
                    className={`form-control ${formik.errors[formName] && formik.touched[formName] ? "is-invalid" : ""}`}
                    value={formik.values[formName]}
                    name={formName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={100}
                    {...params}
                />)
        }
        
    }

    return (
        <div className="form-group">
            {type !== "checkbox" && <label >{label}</label>}
            {setField()}
            {formik.errors[formName] && formik.touched[formName] ? (
                                    <div className="invalid-feedback">{formik.errors[formName]}</div>
                                ) : null}
        </div>
    )
}

export default FormikField
