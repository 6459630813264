import Loading from "components/Loading";
import { useFormik } from "formik";
import { LanguageI } from "interfaces/MilestoneAdmin";
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { crearTraduccion, getLanguages } from "services/milestones-services";
import swal from "sweetalert";
import * as Yup from 'yup';

type Params = {
    id: string
}

const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
});


const CrearTraduccion = () => {
    const {id} = useParams<Params>();
    const [loading,setLoading] = useState(true);
    const [languages, setLanguages] = useState<LanguageI[]>([])
    const [initialValues, setInitialValues] = useState({
        question: "",
        language: ""
    });

    useEffect(() => {
        getLanguages()
        .then(resp => {
            setLanguages(resp.data.results);
            setInitialValues( (prevData: any)=> ({...prevData, language: String(resp.data.results[0].id) }) );
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    },[]);

    const handleSubmit = async (values:any) => {
       const payload = {
           ...values,
           milestone: Number(id)
       }

       try{
            await crearTraduccion(payload);
            swal("Traducción creada",{icon:'success'});
        } catch(err:any){
            console.error(err)
            swal("Ocurrió un error",{icon:'error'});
       }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values:any, {resetForm}) => {
            handleSubmit(values);
            resetForm();
        }
    })

    if(loading){
        return <Loading />
    }

    return (
        <div className="row">
            <div className="col-sm-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3>Crear traducción</h3>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to={`/admin/milestones/hito/${id}`}
                                    className="btn btn-secondary btn-sm">Volver</Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="">Question</label>
                                <input type="text" name="question"
                                value={formik.values.question}
                                onChange={formik.handleChange}
                                className={`form-control ${formik.errors.question && formik.touched.question ? "is-invalid" : ""}`}
                                />
                                {formik.errors.question && formik.touched.question ? (
                                    <div className="invalid-feedback">{formik.errors.question}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Language</label>
                                <select name="language" className="form-control"
                                value={formik.values.language}
                                onChange={formik.handleChange}>
                                    {languages.map((item:LanguageI) => (<option value={item.id} key={item.id}>{item.name}</option>)) }
                                </select>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-sm"
                                type="submit">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearTraduccion
