import Loading from "components/Loading";
import { useEffect, useState, useRef, useCallback} from "react";
import { Link } from "react-router-dom"
import { getPrograms } from "services/milestones-services";
import Pagination from 'react-js-pagination'
import SearchForm from "components/SearchForm";
import AsociateHitosUploadModal  from './AsociateHitosUploadModal';


const ListadoProgramas = () => {

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [programs, setPrograms] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const mountedRef = useRef(false);
    const [value, setValue] = useState("");

    const fetchData = useCallback(() => {
        getPrograms(activePage)
        .then((resp:any) => {
            const { results, count } = resp.data;
            setPrograms(results);
            setCount(count);
        })
        .catch(err => console.error(err))
        .finally(() => {
            setLoading(false)
        })
    },[activePage])

    useEffect(() => {

        if(!mountedRef.current){
            fetchData();
            mountedRef.current = true;
        }

        return function cleanUp() {
            mountedRef.current = false;
        };

    },[fetchData]);

    useEffect(() => {
        setLoading1(true);
        async function search() {
            try{
                const resp = await getPrograms(1, value)
                const { results, count } = resp.data;
                setPrograms(results);
                setCount(count);
            } catch(err:any){
                console.error(err)
            } finally{
                setLoading1(false)
            }
        }
        search();
    },[value])

    const handlePageChange = (page: number) => {
        setActivePage(page);
    }

    if(loading){
        return <Loading />
    }

    return (
        <div className="card">
            <AsociateHitosUploadModal/>
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>Listado de hitos programas</h4>
                    </div>
                    <div className="col-sm-6 text-right">
                        <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalData"> 
                            <i className="fa fa-file-excel"></i> Asociación de hitos
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">

                <SearchForm search={value} setSearch={setValue}/>

                {programs.length === 0 &&
                    <div className="alert alert-danger">
                        No hay datos.
                    </div>
                }
                {programs.length > 0 &&
                    <div className="table-responsive">
                        {loading1 ? (
                            <Loading />
                        ) : (
                            <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {programs.map((item:any) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td className="text-center">
                                                <Link to={`/admin/milestones/detalle-programa/${item.id}`} className="btn btn-success btn-sm">
                                                    <i className="fa fa-eye"></i> Ver detalle
                                                </Link>
                                            </td>
                                        </tr>
                                ))}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={20}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                            </>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default ListadoProgramas
