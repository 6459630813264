import { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { getPostTraductionList } from '../../data/posts';
import { PostTraductionItem, LocaleTraductionItem } from '../../interfaces/Post';
import Loading from 'components/Loading';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';
import UpdatePostModal  from './UpdatePostModal';

const PostTraductionsListPage = () => {
    const [ postTraduction, setPostTraduction ] = useState<PostTraductionItem[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    // const history = useHistory();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    // const [searchParams, setSearchParams] = useState({});
    const [activePage, setActivePage] = useState(1);

    const langs: string[] = ['es', 'en', 'ar', 'pt'];

    useEffect(()=> {
        let mounted = true;
        getPostTraductionList(search)
        .then( (resp: any) => {
            if(mounted) {
                setTable(resp);
            }
        })
        .catch( (err: any) => console.log(err));

        return function cleanup(){
            mounted = false;
        }
    }, [search]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page);
        const resp: any = await getPostTraductionList(search,page);
        setTable(resp);
    }

    const setTable = (resp: any) => {
        resp.data.results = resp.data.results.map((item: PostTraductionItem) => {
            item.localObject = {};
            (item.postlocale_set as LocaleTraductionItem[])
            .forEach((locale) => {
                item.localObject[locale.language] = {
                    url: locale.link_post,
                    id: locale.id
                }
            });
            return item;
        })
        const { count, results} = resp.data;
        setPostTraduction(results);
        setLoading(false);
        setCount(count)
    }

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <div className="card">
            <UpdatePostModal/>
            <div className="card-header">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-8">
                                <div className="h4 card-title">Listado de Traducciones</div>
                            </div>
                            <div className="col-4 text-right">
                                <button className="btn btn-outline-primary btn-sm pull-right" title="Actualizar Post Traducidos"  data-toggle="modal" data-target="#userModal">
                                    <em className="fas fa-globe-americas"></em> Actualizar traducciones
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <SearchForm
                search={search}
                setSearch={setSearch}
                />
                { postTraduction && postTraduction.length > 0 ? (
                    <>
                    <div className="table-responsive">
                        <table className="table" id="data">
                            <thead>
                                <tr className="text-center">
                                    <th>Id</th>
                                    <th>Name</th>
                                    {langs.map( item => (
                                        <th key={`lang-${item}`}>{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                { postTraduction.map( item => (
                                    <tr key={`post-${item.id}`}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        {langs.map((langItem, index )=> (
                                            <td key={`post-lang-${item.id}${index}`} className="text-center">
                                                {
                                                item.localObject[langItem] &&
                                                <>
                                                    <button type="button" className="btn btn-md btn-warning" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-angle-down"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" href={item.localObject[langItem]["url"]} target="blank">
                                                            <em className="fa fa-link"/> Open post
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href={`/admin/posts/translations/${item.localObject[langItem]["id"]}`}>
                                                            <em className="fa fa-pen"/> Edit post
                                                            </a>
                                                    </div>
                                                </>
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={30}
                        totalItemsCount={count}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                    />
                    </>
                ) : (
                    <div className="alert alert-info">Sin datos.</div>
                )}
            </div>
        </div>
    )
};

export default PostTraductionsListPage
