import axios from "axios";

const NLU_URL = process.env.REACT_APP_API_URL_NLU
const CM_URL = process.env.REACT_APP_API_URL_CM;

/**
 * Obtener el listado de intents
 * @returns void
 */
export const getIntents = (page = 1): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/intents/?pagenumber=${page}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Obtener el listado de intents sin paginacion
 * @returns void
 */
export const getIntentOptions = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/intents/?options=True`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Guardar un nuevo intent
 * @param payload
 * @returns void
 */
export const storeIntent = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${NLU_URL}/intents/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Actualizar un intent
 * @param payload
 * @returns void
 */
export const updateIntent = (id: string | any, payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${NLU_URL}/intents/${id}/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Obtener un intent by id
 * @param id
 * @returns void
 */
export const getIntentById = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/intents/${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Borrar un intent
 * @param id
 * @returns void
 */
export const destroyIntent = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${NLU_URL}/intents/${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * get actividades (posts) by intent
 * @param id
 * @returns void
 */
export const getPostsIntent = (id: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_URL}/post_intent/?intent_id=${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * get articles by intent
 * @param id
 * @returns void
 */
export const getArticlesIntent = (id: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_URL}/article_intent/?intent_id=${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * delete post from intent
 * @param id
 * @returns void
 */
export const postIntentDelete = (id: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${CM_URL}/post_intent/${id}/`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * delete article from intent
 * @param id
 * @returns void
 */
export const articleIntentDelete = (id: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${CM_URL}/article_intent/${id}/`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * list of articles
 * @returns void
 */
export const getListArticles = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_URL}/articles/?options=True`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * list of post
 * @returns void
 */
export const getListPosts = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_URL}/posts/?options=True`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Post intent create
 * @returns void
 */
export const postIntentCreate = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${CM_URL}/post_intent/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Article intent create
 * @returns void
 */
export const articleIntentCreate = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${CM_URL}/article_intent/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


export const createTrainingIntents = (payload: any = {}): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${NLU_URL}/intents/bulk_create/`;
        try {
            const resp = await axios.post(endpoint, payload);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}



export const getSelectIntents = (search: string, page: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${NLU_URL}/intents_select/?search=${search}&pagenumber=${page}`;
        try {
            const resp = await axios.get(endpoint);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}


export const getSelectIntent = (id: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${NLU_URL}/intents_select/${id}/`;
        try {
            const resp = await axios.get(endpoint);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}


export const setTextIntent = (id: number, trainingText: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${NLU_URL}/intents/${id}/asign_training_text/`;
        try {
            const resp = await axios.post(endpoint, {text:trainingText});
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}
