import { IRoute } from '../interfaces/Route';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import ArticlesTraductionsList from 'pages/articles/ArticlesTraductionsList';
import EditTranslationPage from 'pages/articles/EditTranslation';
import ArticleLists from 'pages/articles/ArticleLists';

export const ARTICLES_ROUTES:IRoute[] = [
    {
        path: '/admin/articles/traductions',
        component: ArticlesTraductionsList,
        codename:'articles_traduction_list',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/articles/translations/:id',
        component: EditTranslationPage,
        codename: 'articles_traduction_list',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/articles/list',
        component: ArticleLists,
        codename: 'article_list',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    }
]
