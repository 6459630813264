import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_HOTTRIGGERS;

const getHotTriggersList = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/hot_triggers?sequence=${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

const storeHotTrigger = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/hot_triggers/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

const updateHotTrigger = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log("payload")
            console.log(payload)
            const resp = await axios.put(`${URL}/hot_triggers/${payload.id}/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

const deleteHotTrigger = (item: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/hot_triggers/${item.id}/`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

export {
    getHotTriggersList,
    storeHotTrigger,
    updateHotTrigger,
    deleteHotTrigger
};
