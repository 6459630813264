import AddNewAttributeInstance from 'pages/people/AddNewAttributeInstance';
import AddNewAttributeUser from 'pages/people/AddNewAttributeUser';
import PeopleList from 'pages/people/PeopleList';
import { IRoute } from '../interfaces/Route';

export const PEOPLE_ROUTES:IRoute[] = [
    {
        path: '/admin/people',
        component: PeopleList,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/people/:id/instance-attribute',
        component: AddNewAttributeInstance,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/people/:id/user-attribute',
        component: AddNewAttributeUser,
        exact: true,
        requiredRoles: []
    },
]
