import { Form, Input, Button, Select, InputNumber } from 'antd';
import { editGroupPROGRAMS } from 'services/programs.service';
import { editGroupCM, getGroupIdByNameCM } from 'services/content-manager.service';
import { Spin } from 'antd';
import { useState } from 'react';
import Swal from 'sweetalert';

const { Option } = Select;

const countries = [
    { id: 1, name: "Argentina" },
    { id: 2, name: "Brazil" },
    { id: 3, name: "Chile" },
    { id: 4, name: "Guatemala" },
    { id: 5, name: "México" },
    { id: 6, name: "Perú" },
    { id: 6, name: "USA" },
];

const transformValuesForCreateCM = (values: any) => {
    const transformedValuesForCreateEndpointCM = {
        name: values.name,
        parent: values.parent,
        country: values.country,
        region: values.region,
        license: values.license,
        program: values.program,
        group_type: values.group_type,
    };
    return transformedValuesForCreateEndpointCM;
};


const transformValuesForCreatePROGRAMS = (values: any) => {
    const transformedValuesForCreateWithEndpointPROGRAMS = {
        name: values.name,
        parent: values.parent,
        country: values.country,
        region: values.region,
        license: values.license,
        program_name: values.program,
        group_type: values.group_type,
    };
    return transformedValuesForCreateWithEndpointPROGRAMS;
};

const EditGroupForm = ({ selectedGroup }: { selectedGroup: any }) => {
    const [loading, setLoading] = useState(false);
    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            // CREATE SINGLE EN PROGRAMS - ALL FIELD WORKS OK, BUT ONLY PROGRAMS AND ASSIGNATION OK
            const transformedValuesForCreateWithEndpointPROGRAMS = transformValuesForCreatePROGRAMS(values);
            const response = await editGroupPROGRAMS(selectedGroup.id, transformedValuesForCreateWithEndpointPROGRAMS);

            if (response && (response.status === 200 || response.status === 201)) {
                Swal('Grupo editado exitosamente en programs', 'Grupo editado con éxito', 'success');
            } else {
                const errorMessage = response ? `${response.status}: ${response.data}` : 'No se pudo editar el grupo';
                console.error(`Error al editar el grupo: ${errorMessage}`);
                Swal(`Error al editar el grupo`, errorMessage, 'error');
            }

            // CREATE SINGLE EN CM - ALL FIELDS WORKS OK, BUT ONLY CM AND NOT ASSIGNATION
            const transformedValuesForCreateEndpointCM = transformValuesForCreateCM(values)
            const response3 = await getGroupIdByNameCM(selectedGroup.name)
            const groupIdFromCM = response3.data.group_id
            const response2 = await editGroupCM(groupIdFromCM, transformedValuesForCreateEndpointCM);

            if (response2 && (response2.status === 200 || response2.status === 201)) {
                Swal('Grupo editado exitosamente en content manager', 'Grupo editado con éxito', 'success');
            } else {
                const errorMessage = response2 ? `${response2.status}: ${response2.data}` : 'No se pudo editar el grupo';
                console.error(`Error al editar el grupo: ${errorMessage}`);
                Swal(`Error al editar el grupo`, errorMessage, 'error');
            }
        } catch (error) {
            console.error('Error al editar el grupo:', error);
            Swal('Error al editar el grupo', '', 'error');
        } finally {
            setLoading(false);

        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Error al enviar el formulario:', errorInfo);
    };

    return (
        <>
            <Spin spinning={loading}>
                <Form
                    name="editGroupForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    initialValues={selectedGroup}
                >
                    <Form.Item label="Group Name" name="name" rules={[{ required: true, message: 'Please input group name' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Parent Group ID" name="parent">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item label="País" name="country" rules={[{ required: true, message: 'Please select a country' }]}>
                        <Select>
                            {countries.map((country) => (
                                <Option key={country.id} value={country.name}>
                                    {country.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Región/Departamento" name="region" rules={[{ required: true, message: 'Please input region' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="License" name="license" rules={[{ required: true, message: 'Please input license' }]}>
                        <Select>
                            <Option value="1">Free</Option>
                            <Option value="2">Premium</Option>
                            <Option value="3">Patrocinado</Option>
                            <Option value="4">Trial Premium</Option>
                        </Select>
                    </Form.Item>
                    {/* 
                   TODO: TRAER LISTA DE NOMBRES DE PROGRAMAS DESDE ALGUN SERVICIO GET ALL PROGRAMS O QUERY + DEFAULT VALUE CURRENT PROGRAM
                   <Form.Item label="Programa" name="program_name" rules={[{ required: true, message: 'Please input program name' }]}>
                        <Select>
                            <Option value="Afini Estimulación Temprana">Afini Estimulación Temprana</Option>
                            <Option value="Afini MINSA">Afini MINSA</Option>
                            <Option value="Afini Popoyan">Afini Popoyan</Option>
                            <Option value="Unicef Peru">Unicef Peru</Option>
                            <Option value="DEMO BOLIVIA -">DEMO BOLIVIA -</Option>
                            <Option value="Prueba 22 Octubre">Prueba 22 Octubre</Option>
                            <Option value="Prueba 2">Prueba 2</Option>
                            <Option value="Jose David">Jose David</Option>
                            <Option value="Demo en Portugués">Demo en Portugués</Option>
                            <Option value="Hola mundo">Hola mundo</Option>
                            <Option value="Demo Popoyan">Demo Popoyan</Option>
                            <Option value="Popoyan">Popoyan</Option>
                            <Option value="Unicef Bolivia panmanitos">Unicef Bolivia panmanitos</Option>
                            <Option value="Afini Botnar">Afini Botnar</Option>
                            <Option value="Popoyan">Popoyan</Option>
                            <Option value="Pruebas Ingrid Weebhook">Pruebas Ingrid Weebhook</Option>
                            <Option value="Ejemplo">Ejemplo</Option>
                            <Option value="Gaby">Gaby</Option>
                            <Option value="Instrucciones">Instrucciones</Option>
                            <Option value="Afini Botnar Big Trial">Afini Botnar Big Trial</Option>
                            <Option value="Afinidata Bolivia Nuevo">Afinidata Bolivia Nuevo</Option>
                            <Option value="Demo en Ingles">Demo en Ingles</Option>
                            <Option value="DEMO AFINIDATA">DEMO AFINIDATA</Option>
                            <Option value="Demo Español/Ingles">Demo Español/Ingles</Option>
                            <Option value="Demo ES/EN">Demo ES/EN</Option>
                            <Option value="Prueba Programa">Prueba Programa</Option>
                            <Option value="prueba 2">prueba 2</Option>
                            <Option value="Demo en Español">Demo en Español</Option>
                            <Option value="DEMO PORTUGUESA">DEMO PORTUGUESA</Option>
                            <Option value="Demonstração de português">Demonstração de português</Option>
                            <Option value="Demonstração de português1">Demonstração de português1</Option>
                            <Option value="Demo Portugues1">Demo Portugues1</Option>
                            <Option value="Demo Ingles">Demo Ingles</Option>
                            <Option value="Demo Ingles1">Demo Ingles1</Option>
                            <Option value="Demo ingles2">Demo ingles2</Option>
                            <Option value="Demo Ingles2">Demo Ingles2</Option>
                            <Option value="Fundación Niños Primero">Fundación Niños Primero</Option>
                            <Option value="Niños Primero">Niños Primero</Option>
                            <Option value="Fundación Niños Primero1">Fundación Niños Primero1</Option>
                            <Option value="Fundación Niños Primero2">Fundación Niños Primero2</Option>
                            <Option value="Prueba">Prueba</Option>
                            <Option value="Fundación Niños Primero Oficia">Fundación Niños Primero Oficia</Option>
                            <Option value="Prueba Luis">Prueba Luis</Option>
                            <Option value="Afini Crianza Feliz">Afini Crianza Feliz</Option>
                            <Option value="Pruebas de Pantallas">Pruebas de Pantallas</Option>
                            <Option value="Kusi Warma">Kusi Warma</Option>
                            <Option value="Dashboard de App">Dashboard de App</Option>
                            <Option value="PruebaMaga">PruebaMaga</Option>
                            <Option value="Programa Empresas">Programa Empresas</Option>
                            <Option value="Isabel Gutiérrez">Isabel Gutiérrez</Option>
                            <Option value="Tutora Profesionales">Tutora Profesionales</Option>
                            <Option value="Afini MINSA Bolivia">Afini MINSA Bolivia</Option>
                            <Option value="MINSA PERU CRED">MINSA PERU CRED</Option>
                            <Option value="Demo Afinidata">Demo Afinidata</Option>
                            <Option value="Afinidata Demo">Afinidata Demo</Option>
                            <Option value="Demos Afinidata">Demos Afinidata</Option>
                            <Option value="Demo">Demo</Option>
                            <Option value="Afini Estimulación Temprana PT">Afini Estimulación Temprana PT</Option>
                            <Option value="Afini Estimulacion Temprana EN">Afini Estimulacion Temprana EN</Option>
                            <Option value="Home Visitors Crianza Feliz">Home Visitors Crianza Feliz</Option>
                            <Option value="Fundación Familias Power">Fundación Familias Power</Option>
                            <Option value="demo">demo</Option>
                            <Option value="Prueba maga 1">Prueba maga 1</Option>
                            <Option value="PRUEBA 2">PRUEBA 2</Option>
                            <Option value="United Way Guatemala">United Way Guatemala</Option>
                            <Option value="Afinidata Premium Español">Afinidata Premium Español</Option>
                            <Option value="The Family Center">The Family Center</Option>
                            <Option value="Afini Velez Perez">Afini Velez Perez</Option>
                            <Option value="Botnar tutora virtual">Botnar tutora virtual</Option>
                            <Option value="Popoyan 2023">Popoyan 2023</Option>
                            <Option value="Enseña por Mexico">Enseña por Mexico</Option>
                        </Select>
                    </Form.Item>*/}
                    <Form.Item label="Group Type" name="group_type" rules={[{ required: true, message: 'Please input group type' }]}>
                        <Select>
                            <Option value="1">Families</Option>
                            <Option value="2">Tutors</Option>
                            <Option value="3">Duplicates</Option>
                            <Option value="4">Expired</Option>
                            <Option value="5">Others users</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" htmlType="button" style={{ paddingInline: '32px', marginRight: '32px' }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" className='create_group_button'>
                            Editar Grupo
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    );
};

export default EditGroupForm;
