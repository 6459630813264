import button1 from  '../assets/button1.png';
import button2 from  '../assets/button2.png';

type PropsT = {
    item: any,
    type: "milestones" | "risks",
    onClick: (item:any) => void
}

const PartnerButtonCard = ({item, type, onClick}:PropsT) => {
    return (
        <div className="col-sm-3 col-xs-3" onClick={() => onClick(item)}>
            <div className="card bg-dark text-white border-0 zoom">
                <img className="card-img shadow-none" src={type === 'milestones' ? button1 : button2} alt="Lorem" />
                <div className="card-img-overlay shadow-none d-flex flex-column justify-content-end">
                    <p className="card-text">
                        <span id="milestones_count">{item.qty}</span>
                        <br />{item.name}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PartnerButtonCard
