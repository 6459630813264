import {  Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ObjectModal from "./ObjectCreateModal";
import { getAllObjects } from "services/nodejs.service";
import { DeleteObjectModal } from "./ObjectDeleteModal";

const type_map = ['texto','imagen','video']



const example = [
    {
        id:1,
        name:'imagen',
        url:'https://afinimedia.s3.us-east-2.amazonaws.com/notificaciones/Imagenes+caracteri%CC%81sticas+de+etapa+portugues+28+a+36+(2).png',
        type:0
    },
    {
        id:2,
        name:'bienvenida_comunicadoresii',
        url:'https://afinimedia.s3.us-east-2.amazonaws.com/notificaciones/Curiosos+4+a+5+an%CC%83os+para+push-espan%CC%83ol+(1).mp4',
        type:1
    },
]

function Objects() {
    const [modal, setModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [objects, setObjects] = useState([])
    const [objectToDelete, setObjectToDelete] = useState(example[0])
    
    const columns = [
        {
            field: "name",
            headerName: "Name",
            // width: 110,
            flex:1,
            editable: false
        },
        {
            field: "type",
            headerName: "Tipo",
            valueGetter:(val:number) => type_map[val],
            flex:1,
            editable: false
        },
        {
            field: "url",
            headerName: "Url",
            flex:3,
            editable: false
        },
        {
        field: 'action',
        headerName: 'borrar',
        // type: 'actions',
        sortable: false,
        renderCell:(tmpt:any)=> {
            return <button type="button" className="btn btn-danger btn-sm ml-2"
                    onClick={(el)=>{setObjectToDelete(tmpt.row);setDeleteModal(true)}} ><i className="fa fa-eraser"></i></button>
            }
        },
    ]

    const getObjects = async ()=> {
        let objs = await getAllObjects()
        setObjects(objs.data.result)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getObjects()
    }, []);

    function copyUrl(el:any) {
        if(el.field=='url') navigator.clipboard.writeText(el.value)
    }

    return(
        <div>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Button variant="contained" onClick={()=> setModal(true)}>Crear nuevo</Button>
            </div>
            <DataGrid
                rows={objects}
                columns={columns}
                onCellClick={copyUrl}
            /> 
            <ObjectModal visible={modal} close={()=> setModal(false)} refresh={getObjects} />
            <DeleteObjectModal visible={deleteModal} close={()=>setDeleteModal(false)} objectToDelete={objectToDelete} refresh={getObjects} />

        </div>
    )
}

export default Objects