import { OptionAttribute, ProgramAttribute } from "interfaces/Instance"
import { useEffect, useState } from "react";

const ProgramAttributeDropdown = (props: { program_attribute: ProgramAttribute, colClass:string, onChange: (value: OptionAttribute) => void}) => {
    const [value, setValue] = useState("");


    const riskClassesDict: {
        [id:number]: string
    } = {
        0: 'btn-success',
        1: 'btn-danger',
    }

    const milestonesResponseDict: {
        [id:string]: string
    } = {
        'completado': 'Logrado',
        'no completado': 'No Logrado',
    }

    useEffect(() => {
        setValue(props.program_attribute.name || "")
    }, [props.program_attribute.name])

    const handleChange =(value:OptionAttribute)=>{    
        props.onChange(value)
      }

    return (
        <li className={`col-12 ${ props.colClass } py-2`} >
            <div className="d-flex flex-wrap p-2">
                <span className="pr-2"> {value} </span>
                {
                    props.program_attribute.options.length > 0 ? (
                        <div className="dropdown ml-auto mr-3">
                            <button className={`btn ${riskClassesDict[props.program_attribute.risk as number] || "btn-primary"} dropdown-toggle`} 
                                type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            >
                                {props.program_attribute.value}
                                <span className="caret"></span>
                            </button>
                            <div className="dropdown-menu" >
                                {props.program_attribute.options.map((option, index) => (
                                    <button className="dropdown-item" type="button" key={`pa_option_${index}`}
                                        onClick={() => handleChange(option)}
                                    >
                                        {option.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) :
                    (   <button className={`btn ${riskClassesDict[props.program_attribute.risk as number] || "btn-primary"} ml-auto mr-3`} type="button">
                            {milestonesResponseDict[(props.program_attribute.value as string).toLowerCase()] || props.program_attribute.value}
                        </button>)
                }
            </div>
        </li> 
    )};

export default ProgramAttributeDropdown