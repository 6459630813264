import { editAuthGroup, getAuthGroupById } from "data/auth-groups";
import { AuthGroup } from "interfaces/AuthGroup";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom"
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import { Permission } from "interfaces/Permission";
import { getPermissions } from "data/permissions";
import swal from "sweetalert";

type Props = {
    id: string
}

const SetGroupPermissions = ({match}:RouteComponentProps<Props>) => {

    const [group, setGroup] = useState<AuthGroup>({id:'',name:'',permissions:[]});
    const [loading, setLoading] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const history = useHistory();
    const id = match.params.id;

    useEffect(() => {
        let mounted = true;
        if(mounted){
            getAuthGroupById(id)
            .then( resp => {
                setGroup(resp.data);
                setLoading(false);
            }).catch(err => console.log(err))

            getPermissions()
            .then( resp => setPermissions(resp.data.results))
            .catch(err => console.log(err));
        }

        return function cleanup(){
            mounted = false;
        }
    }, [id]);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        let permissionsSelected:any = [];
        const checked = document.querySelectorAll('input[name=permissions]:checked') as NodeListOf<HTMLInputElement>;

        if(checked.length > 0){
            checked.forEach( item => {
                permissionsSelected.push(item.value);
            });
        }

        try{
            await editAuthGroup(id, {
                ...group,
                permissions: permissionsSelected
            });
            swal("Afinidata","Permisos actualizados!","success")
            .then( () => {
                history.push('/admin/groups');
            })
        } catch( err ){
            swal("Afinidata","Error al guardar, intente de nuevo","error");
        }
    }

    const handleSelectAll = () => {
        const el = document.querySelectorAll('input[name=permissions]') as NodeListOf<HTMLInputElement>;
        el.forEach( item => item.checked = true);
    }

    const handleUnselectAll = () => {
        const el = document.querySelectorAll('input[name=permissions]') as NodeListOf<HTMLInputElement>;
        el.forEach( item => item.checked = false);
    }

    if(loading){
        return <Loading />
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Asignar permisos al grupo</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={ e => handleSubmit(e)}>
                                <div className="form-group row">
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="">Id</label>
                                        <h5 className="static-control">{group.id}</h5>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="">Nombre</label>
                                        <h5 className="static-control">{group.name}</h5>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Permisos</label>
                                    <hr/>
                                    <div className="row my-4">
                                        <div className="col-md-12 text-right">
                                            <button className="btn btn-primary btn-sm"
                                            type="button"
                                            onClick={handleSelectAll}>Seleccionar todos</button>
                                            <button className="btn btn-danger btn-sm ml-2"
                                            type="button"
                                            onClick={handleUnselectAll}>Quitar todos</button>
                                        </div>
                                    </div>
                                    {permissions.length > 0 &&
                                        <div className="permisos">
                                            <div className="row">
                                                {permissions.map( (item) => (
                                                    <div className="col-md-4" key={item.id}>
                                                        <label htmlFor="">
                                                            <input type="checkbox"
                                                            name="permissions"
                                                            value={item.id}
                                                            defaultChecked={group.permissions?.includes(item.id) ? true : false}
                                                            onChange={() => {}}
                                                            />
                                                            <span className="ml-2">{item.id} - {item.codename}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="form-group text-right">
                                    <button className="btn btn-primary">
                                        <i className="fa fa-save"></i> Guardar
                                    </button>
                                    <Link to="/admin/groups" className="btn btn-secondary ml-2">
                                        <i className="fa fa-arrow-left"></i> Volver
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetGroupPermissions
