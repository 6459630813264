import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import swal from "sweetalert";
import * as Yup from "yup";
import { getAttributesList } from "data/attributes";
import { saveProgramAttributes } from "data/programs-attributes";
import { ProgramAttributeTypes } from "interfaces/Program";
import { getDetailProgramAttributeType } from '../../data/programs-attribute-types';
import { Attribute } from "interfaces/Attribute";


type Props = {
    programAttributeTypeId: string
}

const validationSchema = Yup.object().shape({
    attribute: Yup.string().required(),
    label: Yup.string().required().max(50),
    weight: Yup.number().required(),
    threshold: Yup.number().required()
});

const AddProgramAttribute = ({ match }: RouteComponentProps<Props>) => {

    const [attributes, setAttribute] = useState<Attribute[]>([]);
    const [attributeType, setAttributeType] = useState<ProgramAttributeTypes>({ id: "", program: "", entity: "", name: "", description: "", weight: 0 });
    const attributeTypeId = match.params.programAttributeTypeId;
    const history = useHistory();

    const initialValues = { id: "", attribute: "", attribute_type: attributeTypeId, weight: 0, threshold: 0, label: "" };

    useEffect(() => {
        getDetailProgramAttributeType(attributeTypeId)
            .then((resp: any) => {
                let { data } = resp;
                setAttributeType(data.results[0]);
            })
            .catch((err: any) => console.log(err));

        getAttributesList()
            .then((resp: any) => {
                let { data } = resp;
                if(data.hasOwnProperty('results')){
                    setAttribute(data.results);
                }
                else{
                    setAttribute(data);
                }
            })
            .catch((err: any) => console.log(err));

    }, [attributeTypeId]);


    const handleSubmit = async (payload: any): Promise<void> => {
        try {
            await saveProgramAttributes(payload);

            swal("Afinidata", `El nuevo atributo para: "${attributeType.name}" ha sido creado.`, "success")
                .then(() => {
                    history.push(`/admin/program_attributes/configure/${attributeTypeId}`);
                })
        } catch(err:any) {
            swal("Afinidata", "Ocurrio un error. intente de nuevo", "error");
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values);
            resetForm({});
        },
    });


    return (
        <>
            <div className="row mb-4">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={`/admin/program_attributes/configure/${attributeTypeId}`} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Añadir atributo</h6>
                            <hr />
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group d-flex flex-row">
                                    <label className="mr-2" htmlFor="">program attribute type: </label>
                                    <input type="hidden" name="attribute_type" value={attributeTypeId} />
                                    <p className="static-control">{attributeType.name}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Attribute</label>
                                    <select name="attribute"
                                        className={`form-control ${formik.errors.attribute && formik.touched.attribute ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.attribute}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value="">-- select attribute --</option>
                                        {attributes.map((item, i) => (
                                            <option value={item.id} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                    {formik.errors.attribute && formik.touched.attribute ? (
                                        <div className="invalid-feedback">{formik.errors.attribute}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Label</label>
                                    <input type="text" name="label" placeholder="Enter label"
                                        className={`form-control ${formik.errors.label && formik.touched.label ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.label}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Weight</label>
                                    <input type="text" name="weight" placeholder="Enter weight"
                                        className={`form-control ${formik.errors.weight && formik.touched.weight ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.weight}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Threshold</label>
                                    <input type="text" name="threshold" placeholder="Enter threshold"
                                        className={`form-control ${formik.errors.threshold && formik.touched.threshold ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.threshold}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <button type="submit"
                                            className="btn btn-primary">{/* 
                                        // @ts-ignore */}
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddProgramAttribute
