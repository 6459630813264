import axios from 'axios';
const URL = process.env.REACT_APP_API_URL_CM

export const saveProgramAttributeTypes = (payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/programs_attribute_types/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const updateProgramAttributeTypes = (id: string, payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/programs_attribute_types/${id}/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteProgramAttributeTypes = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/programs_attribute_types/${id}/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getProgramAttributeTypes = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs_attribute_types/?program=${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getDetailProgramAttributeType = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs_attribute_types/?id=${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
