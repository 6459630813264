import { FunctionComponent } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
moment.locale('es');

interface IMessage {
    type: string,
    item: any,
    handleIntentAction?: (action: string, trainingText:string) => void 
}

const MessageDetail: FunctionComponent<IMessage> = ({ type, item, handleIntentAction = () => {} }) => {

    return (
        <>
            <div className={type === 'guest' ? 'message-guest' : 'message-user'}>
                <div className="message-content" >
                    {item.message_type === 'image' ? (
                        <div>
                            <img src={item.content} width="320" height="320" alt="" />
                        </div>
                    ) : (
                        <div>
                            <p>{item.content}</p>
                            <hr />
                            <p className="small">Hace {moment(item.created_at).fromNow()}</p>
                        </div>
                    )}
                </div>
                {type!== 'guest' &&
                    <div className="d-inline-block ml-2 btn-group float-right dropup">
                        <button type="button" className="btn btn-secondary btn-sm rounded" data-toggle="dropdown" 
                            aria-haspopup="true" aria-expanded="false">{/* 
                        // @ts-ignore */}
                        <FontAwesomeIcon icon={faEllipsisV}/>
                        </button>
                        <div className="dropdown-menu dropdown-menu-left mb-2">
                            <button className="dropdown-item" type="button" 
                                    onClick={() => handleIntentAction('addIntent', item.content)}>
                                Agregar a Intent
                            </button>
                            <button className="dropdown-item" type="button">Solicitar Intent</button>
                            <button className="dropdown-item" type="button">Intent Erroneo</button>
                        </div>
                    </div>
                }
                
            </div>
            <div className="clearfix"></div>
        </>
    )
}

export default MessageDetail;
