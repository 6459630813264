import { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom"
import { deleteSequence, getSequenceById } from "data/sequences";
import Loading from '../../components/Loading';
import {useHistory } from 'react-router-dom';
import swal from "sweetalert";

interface IProps {
    id: string;
}

const DeleteSequencePage = ({match}:RouteComponentProps<IProps>) => {
    const id = match.params.id;
    const [sequence, setSequence] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        let mounted = true;

        if(mounted){
            getSequenceById(id)
            .then( resp => {
                setSequence(resp.data)
                setLoading(false);
            })
            .catch(err => console.log(err))
        }
        return () => { mounted = false; }
    },[id]);

    const handleDelete = async() => {
        try{
            await deleteSequence(sequence.id)
            await swal("Afinidata","Secuencia eliminada","success");
            history.push('/admin/sequences');
        } catch( err ){
            swal("Afinidata","Ocurrio un error, intente de nuevo","error");
            console.log(err);
        }
    }

    if(loading){
        return <Loading />;
    }

    return (
        <div className="row">
            <div className="col-md-6 mx-auto">
                <div className="card">
                    <div className="card-header">
                        <h4>Borrar secuencia</h4>
                    </div>
                    <div className="card-body">
                        <h4>¿Realmente desea borrar la secuencia #{sequence.id} ?</h4>
                        <p>{sequence.name}</p>
                        <p>{sequence.description}</p>
                        <button type="button"
                        onClick={handleDelete}
                        className="btn btn-danger">Confirmar</button>
                        <a href="/admin/sequences" className="btn btn-secondary ml-2">Volver</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteSequencePage
