import AddAttribute from "pages/attributes/AddAttribute";
import ListAttribute from "pages/attributes/ListAttribute";
import AttributeDetail from "pages/attributes/AttributeDetail";
import { IRoute } from '../interfaces/Route';

export const ATTRIBUTES_ROUTES:IRoute[] = [
    {
        path: '/admin/attributes/add',
        component: AddAttribute,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/attributes/:id/show',
        component: AttributeDetail,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/attributes/list',
        component: ListAttribute,
        exact: true,
        requiredRoles: []
    }
];