import { deleteProgramAttributes, getDetailProgramAttributes } from 'data/programs-attributes';
import { ProgramAttributes } from 'interfaces/Program';
import { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from "react-router-dom"
import PageNotFound from '../page-not-found/PageNotFound';
import Loading from '../../components/Loading';
import swal from 'sweetalert';

interface IProps {
    id: string
}

const DeleteProgramAttribute = ({match}: RouteComponentProps<IProps>) => {
    
    let init = { id: "", attribute: "", attribute_type: "", weight: 0, threshold: 0, label:""};
    const [programAttribute, setProgramAttribute ] = useState<ProgramAttributes>(init);
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();
    const id = match.params.id;

    useEffect(() => {
        getDetailProgramAttributes(id)
        .then( resp => {
            setProgramAttribute(resp.data.results[0])
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[id])

    const handleClick = async () => {
        try {
            await deleteProgramAttributes(id);
            await swal('Afinidata','El program fue borrado correctamente','success');
            history.push('/admin/program_attributes/configure/' + programAttribute.attribute);
        } catch(err:any){
            swal('Afinidata','Ocurrio un error al borrar el program','error');
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    if(!programAttribute){
        return <PageNotFound/>
    }


    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas borrar el atributo del programa?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>Id:</b>{programAttribute.id}</p>
                            <p><b>Label:</b> {programAttribute.label}</p>
                            <p><b>Weight:</b> {programAttribute.weight}</p>
                            <p><b>Threshold:</b> {programAttribute.threshold}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                href="/admin/programs/">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteProgramAttribute
