import { createContext, useState } from "react";

interface ToastContextI {
    toasts: Array<ToastI>
    createToast: (message: string, type: string) =>  void,
    deleteToast: () => void
}
interface ToastI {
    typeClass: string,
    message: string
}
export const ToastContext = createContext({} as ToastContextI);

const toastTypes: any = {
    success: "bg-success text-white m-3",
    error: "bg-danger text-white m-3"
}


const ToastContextProvider = ({children}:any) => {
    const [toasts, setToasts] = useState<Array<ToastI>>([]);


    const createToast = (message: string, type: string) => {
        const toast: ToastI = {
            typeClass: toastTypes[type],
            message: message
        }
        const newToasts = [...toasts]
        newToasts.push(toast)
        setToasts(newToasts)
    }

    const deleteToast = () => {
        const newToasts = [...toasts]
        newToasts.shift()
        setToasts(newToasts)
    }
    
    return (
        <ToastContext.Provider value={{
            toasts, 
            createToast,
            deleteToast
            }}>
            {children}
        </ToastContext.Provider>
    )
}

export default ToastContextProvider
