import { deleteProgramAttributeTypes, getDetailProgramAttributeType } from 'data/programs-attribute-types';
import { ProgramAttributeTypes } from 'interfaces/Program';
import { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from "react-router-dom"
import Loading from '../../components/Loading';
import swal from 'sweetalert';

import PageNotFound from '../page-not-found/PageNotFound';

interface IProps {
    id: string
}

const DeleteProgramAttributeType = ({match}: RouteComponentProps<IProps>) => {
    
    let init = { id: "", program: "", entity: "", name: "", description: "", weight: 0, attributes_set:[]};
    const [ programAttributeType, setProgramAttributeType ] = useState<ProgramAttributeTypes>(init);
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();
    const id = match.params.id;

    useEffect(() => {
        getDetailProgramAttributeType(id)
        .then( resp => {
            setProgramAttributeType(resp.data.results[0]);
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[id])

    const handleClick = async () => {
        try {
            await deleteProgramAttributeTypes(id);
            await swal('Afinidata','El program fue borrado correctamente','success');
            history.push('/admin/programs/configure/' + programAttributeType.program);
        } catch(err:any){
            swal('Afinidata','Ocurrio un error al borrar el program','error');
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    if(!programAttributeType){
        return <PageNotFound/>
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas borrar el tipo de atributo del programa?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>Id:</b>{programAttributeType.id}</p>
                            <p><b>Entity:</b>{programAttributeType.entity}</p>
                            <p><b>Program:</b>{programAttributeType.program}</p>
                            <p><b>Name:</b> {programAttributeType.name}</p>
                            <p><b>Description:</b> {programAttributeType.description}</p>
                            <p><b>Weight:</b> {programAttributeType.weight}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                href="/admin/programs/">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteProgramAttributeType
