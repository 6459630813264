import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { saveProgramAttributeTypes } from 'data/programs-attribute-types';
import { Program } from 'interfaces/Program';
import { detailProgram } from '../../data/programs';
import { Entity } from 'interfaces/Entity';
import { getEntitiesList } from '../../data/entities';


type Props = {
    programId: string
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(50),
    entity: Yup.string().required(),
    description: Yup.string().required().max(150),
    weight: Yup.number().required()
});

const AddProgramAttributeType = ({match}:RouteComponentProps<Props>) => {

    const programId = match.params.programId;
    const [ entities, setEntities ] = useState<Entity[]>([]);
    const [ program, setProgram ] = useState<Program>({id: '', name:'', description:''});
    const history = useHistory();

    const initialValues = { 
        id: "", 
        program: programId, 
        entity: "", 
        name: "", 
        description: "", 
        weight: 0
    };

    useEffect(() => {
        detailProgram(programId)
        .then( (resp:any) => {
            const { data } = resp;
            setProgram(data);
        })
        .catch( (err:any) => console.log(err));

        getEntitiesList()
        .then( (resp:any) => {
            const { data } = resp;
            setEntities(data.results);
        })
        .catch( (err:any) => console.log(err));

    }, [programId]);


    const handleSubmit = async (payload: any): Promise<void> => {
        try{
            await saveProgramAttributeTypes(payload);

            swal("Afinidata",`El nuevo tipo de atributo para: "${program.name}" ha sido creado.`,"success")
            .then( () => {
                history.push(`/admin/programs/configure/${programId}`)
            })
        }catch(err:any){
            swal("Afinidata","Ocurrio un error. intente de nuevo","error");
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values,{resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });


    return (
        <>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={`/admin/programs/configure/${programId}`} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Añadir tipo de atributo</h6>
                            <hr/>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group d-flex flex-row">
                                    <label className="mr-2" htmlFor="">program: </label>
                                    <input type="hidden" name="program" value={formik.values.program}/>
                                    <p className="static-control">{program.name}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Entity</label>
                                    <select name="entity"
                                    className={`form-control ${
                                        formik.errors.entity && formik.touched.entity ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.entity}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}>
                                        <option value="">-- select entity --</option>
                                        {entities.map( (item,i) => (
                                            <option value={item.id} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                    {formik.errors.entity && formik.touched.entity ? (
                                        <div className="invalid-feedback">{formik.errors.entity}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name" placeholder="Enter name"
                                    className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" placeholder="Enter description"
                                    className={`form-control ${
                                        formik.errors.description && formik.touched.description ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Weight</label>
                                    <input type="text" name="weight" placeholder="Enter weight"
                                    className={`form-control ${
                                        formik.errors.weight && formik.touched.weight ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.weight}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <button type="submit"
                                        className="btn btn-primary">{/* 
                                    // @ts-ignore */}
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddProgramAttributeType
