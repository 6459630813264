import { CodeI } from "context/GroupContext";
import { useTranslation } from "react-i18next";

type PropsT = {
    link: string,
    codes: CodeI[]
    onClose: () =>  void
}

const SharedLink = ({link, codes, onClose}:PropsT) => {

    const {t} = useTranslation();

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <h2>{t("invitar_familias")}</h2>
            <hr />
            <p>{t("copiar_link")}</p>
            <div className="input-group mb-3">
                <input type="text"
                className="form-control"
                readOnly
                value={link}
                />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"
                    onClick={() => handleCopyToClipboard(link)}>
                        <i className="fa fa-copy"></i> {t("copiar")}
                    </button>
                </div>
            </div>
            <hr />
            <p>{t("codigos_app_title")}</p>
            {codes.map(({code}) => (
                <div className="input-group mb-3">
                <input type="text"
                className="form-control"
                readOnly
                value={code}
                />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"
                    onClick={() => handleCopyToClipboard(code)}>
                        <i className="fa fa-copy"></i> {t("copiar")}
                    </button>
                </div>
            </div>
            ))}
            <div className="text-right">
                <button onClick={onClose}
                className="btn btn-dark btn-sm">{t("cerrar")}</button>
            </div>
        </>
    )
}

export default SharedLink
