import { RouteComponentProps } from "react-router-dom";

import { Attribute } from "interfaces/Attribute";
import { useFormik } from 'formik';
import swal from "sweetalert";
import Loading from '../../components/Loading';
import * as Yup from "yup";
import { updateAttribute, getAttributeById } from "data/attributes";
import { useState, useEffect } from 'react';

type Props = {
    id: string
}


const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
});

const EntityDetail = ({match}:RouteComponentProps<Props>) => {

    const id = match.params.id;
    const [ attribute, setAttribute ] = useState<Attribute>({
        "name":"",
        "type":"numeric",
        "attribute_view":'0'
    });
    const [loading, setLoading] = useState<boolean>(true);

    const attribute_view:any = {
        0: "service",
        1: "data"
    }

    const types = [
        {
            'value':'numeric',
            'label':'Numeric'
        },
        
        {
            'value':'string',
            'label':'String'
        },
        {
            'value':'date',
            'label':'Date'
        },
        {
            'value':'boolean',
            'label':'Boolean'
        }
    ];


    const handleFormSubmit = async (values:any) => {
        try{
            console.log(values);
            await updateAttribute(id, values);
            swal('Afinidata','Atributo editado!','success')
        } catch(err:any){
            console.log(err);
            swal('Afinidata','Ocurrio un error. Intente de nuevo','danger')
        }
    }

    const formik = useFormik({
        initialValues: {
            ...attribute
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    useEffect(() => {

        let mounted = true;

        getAttributeById(id)
        .then( resp => {
            if(mounted){
                setAttribute(resp.data)
                setLoading(false);
            }
        })

    },[id])

    if(loading){
        return <Loading />
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4>Editar atributo</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" name="name"
                                    value={formik.values.name}
                                    className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                {formik.errors.name && formik.touched.name ? (
                                    <div className="invalid-feedback">{formik.errors.name}</div>
                                ) : null}
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <div className="form-group">
                                <label>Tipo</label>
                                
                                <select
                                    name="type"
                                    className={`form-control ${
                                        formik.errors.type && formik.touched.type ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {
                                        types.map( (t, index) =>(
                                            <option key={index} value={t.value} label={t.label} />
                                        ))
                                    }

                                </select>
                                {formik.errors.type && formik.touched.type ? (
                                    <div className="invalid-feedback">{formik.errors.type}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-group">
                                <label>Vista atributo</label>
                                
                                <select
                                    name="attribute_view"
                                    className={`form-control ${
                                        formik.errors.attribute_view && formik.touched.attribute_view ? "is-invalid" : ""
                                    }`}
                                    value={formik.values.attribute_view}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value={0} label={attribute_view[0]} />
                                    <option value={1} label={attribute_view[1]} />
                                </select>
                                {formik.errors.attribute_view && formik.touched.attribute_view ? (
                                    <div className="invalid-feedback">{formik.errors.attribute_view}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right">
                            <button className="btn btn-primary" type="submit"><i className="fas fa-plus-circle"></i> Editar </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default EntityDetail;