import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "components/Loading";
import swal from "sweetalert";
import { getSpecificUnrecognized, correctUnrecognized } from "data/session-interactions";
import { getIntentOptions } from "data/intents";
import { UnrecognizedInteraction, InteractionShortDetail } from "interfaces/Session";

type Props = {
    id: string
}

const validationSchema = Yup.object().shape({
    interaction: Yup.string().required().max(50),
    reply: Yup.number(),
    intent: Yup.number(),
    ignore: Yup.bool()
});


const Retrain = ({ match }: RouteComponentProps<Props>) => {

    const [interaction, setInteraction] = useState<UnrecognizedInteraction>({ id: "", question: "", text: "", user: { instances: [] }, replies: [] });
    const [intents, setIntents] = useState<InteractionShortDetail[]>([]);
    const [attribute, setAttribute] = useState<InteractionShortDetail>({ id: "", name: "" });
    const [initialValues] = useState<any>({ interaction: match.params.id, reply: "", intent: "", ignore: false });
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const returnUrl = `/admin/retrain`;
    const CORE_URL = process.env.REACT_APP_CORE

    useEffect(() => {
        getSpecificUnrecognized(match.params.id)
            .then(resp => {
                setInteraction(resp.data.result);
                setLoading(false);
            })
            .catch(err => { console.log(err); history.push(returnUrl); });

        getIntentOptions()
            .then(resp => {
                setIntents(resp.data.results);
            })
            .catch(err => { console.log(err); history.push(returnUrl); });

    }, [match, history, returnUrl]);

    const handleOptionChange = (e: any) => {
        let attributes = e.target.selectedOptions[0].attributes;
        let attr = { id: "", name: "" };
        if ("data-attr" in attributes && attributes["data-attr"].value) {
            attr = JSON.parse(attributes["data-attr"].value);
        }
        setAttribute(attr);
    }

    const handleSubmit = async (payload: any): Promise<void> => {
        try {
            if (!payload['intent'] && !payload['reply'] && !payload['ignore']) {
                throw new Error("Para corregir una respuesta selecciona intent, reply o ignorar");
            }
            await correctUnrecognized(payload);
            await swal("Afinidata", "Respuesta actualizada.", "success")
            history.push(returnUrl);
        } catch(err:any) {
            swal("Afinidata", 'Ocurrió un error intente de nuevo \n' + err, 'error');
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={returnUrl} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Correct Unrecognized Reply</h6>
                            <hr />
                            {initialValues && !loading ? (
                                <div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="id"><b>Interaction: </b>{interaction.id}</label>
                                            <input type="text" name="interaction"
                                                className={`form-control`}
                                                value={interaction.id}
                                                onChange={formik.handleChange}
                                                hidden />
                                        </div>
                                        <div className="form-group">
                                            <label >
                                                <b>Session: </b>
                                                <a rel='noreferrer' target='_blank' href={CORE_URL + '/sessions/' + interaction.session}>{interaction.session_name}</a>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label ><b>Question: </b>{interaction.question}</label>
                                        </div>
                                        <div className="form-group">
                                            <label ><b>User's answer: </b>{interaction.text}</label>
                                            <input type="text" name="text"
                                                className={`form-control`}
                                                value={interaction.text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                hidden />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="attribute">
                                                <b>Attribute: </b><span id="attr">{attribute.name}</span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="reply">Valid Replies</label>
                                            <select name="reply"
                                                className={`form-control ${formik.errors.reply && formik.touched.reply ? "is-invalid" : ""
                                                    }`}
                                                value={formik.values.reply}
                                                onChange={(e) => { formik.handleChange(e); handleOptionChange(e); }}
                                                onBlur={formik.handleBlur}>
                                                <option value="">-- select reply --</option>
                                                {interaction.replies.map((reply) => (
                                                    <option key={reply.id} value={reply.id} data-attr={JSON.stringify(reply.attribute)}>{reply.label}</option>
                                                ))}
                                            </select>
                                            {formik.errors.reply && formik.touched.reply ? (
                                                <div className="invalid-feedback">{formik.errors.reply}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="intent">Intents</label>
                                            <select name="intent"
                                                className={`form-control ${formik.errors.intent && formik.touched.intent ? "is-invalid" : ""
                                                    }`}
                                                value={formik.values.intent}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}>
                                                <option value="">-- select intent --</option>
                                                {intents.map((intent) => (
                                                    <option key={intent.id} value={intent.id} >{intent.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.intent && formik.touched.intent ? (
                                                <div className="invalid-feedback">{formik.errors.intent}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <input type="checkbox"
                                                    name="ignore"
                                                    value={initialValues.ignore}
                                                    defaultChecked={false}
                                                    onChange={formik.handleChange}
                                                />
                                                <span className="ml-2">Ignore Reply</span>
                                            </label>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <button type="submit"
                                                    className="btn btn-primary">
                                                    <FontAwesomeIcon icon={faSave} /> Update
                                                    </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <Loading />
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Retrain
