import Loading from 'components/Loading';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    dowloadFormatUploadMilestones, uploadBulkFile
} from 'services/milestones-programs.service';
import swal from 'sweetalert';
import '../milestones-admin.css';

const CargarHitosExcel = () => {
    const fileInputRef = useRef() as any;
    const [name, setName] = useState("");
    const [file, setFile] = useState("");
    const [data, setData] = useState("");
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleInputFileChange = (e:any) => {
        setName(e.target.files[0].name || "");
        setFile(e.target.files[0]);
    }

    const handleDownloadFormat = async () => {
        try{
            setLoading1(true);
            const resp = await dowloadFormatUploadMilestones();
            setData(resp.data);
        } catch(err:any){
            console.error(err);
        } finally {
            setLoading1(false);
        }
    }

    const handleFileUpload = async () => {
        try{
            setLoading2(true);
            await uploadBulkFile(file);
            await swal("Archivo procesado correctamente",{icon: "success"});
            setFile("");
            setName("");
        } catch(err:any){
            console.error(err);
            swal("Ocurrió un error",{icon: "error"})
        } finally {
            setLoading2(false);
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>Añadir hito de desarrollo</h4>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/admin/milestones/programas"
                        className="btn btn-secondary">
                            <i className="fa fa-arrow-left"></i> Volver
                        </Link>
                    </div>
                </div>

            </div>
            <div className="card-body">


                <div className="row">
                    <div className="col-sm-6">

                        <button className="btn btn-primary btn-sm"
                        onClick={handleDownloadFormat}>
                            <i className="fa fa-download"></i> Descargar formato
                        </button>

                        {loading1 &&
                            <>
                            <Loading size="2rem" />
                            <p>Preparando tu archivo, por favor espere</p>
                            </>
                        }

                        {data && (
                            <>
                                <p className="mt-4" style={{color: "black"}}>tu archivo se encuentra listo, presiona el siguiente boton para descargar</p>
                                <a
                                href={`data:text/csv;charset=utf-8,${escape(data)}`}
                                download={`${ Math.floor(Math.random() * (999999 - 1)) + 1}.csv`}
                                className="btn btn-outline-primary"
                                >
                                    Bajar archivo
                                </a>
                            </>
                        )}
                    </div>

                    <div className="col-sm-6">

                        <form>
                            <input type="file"
                            onChange={(e) => handleInputFileChange(e)}
                            ref={fileInputRef}
                            accept=".csv"
                            style={{ display: 'none' }}/>
                        </form>

                        <div className="mb-4">
                            {name && (
                                <div className="mb-3">
                                    <p>Archivo seleccionado: {name}</p>
                                    <div>
                                        <button className="btn btn-success btn-sm btn-block"
                                        onClick={handleFileUpload}>
                                            <i className="fa fa-upload"></i> Subir archivo
                                        </button>
                                    </div>

                                    {loading2 &&
                                        <Loading text="Subiendo archivo..." size="2rem" />
                                    }
                                </div>
                            )}

                            <h4>Subir hitos</h4>
                            <p>Selecciona el archivo que deseas importar</p>

                        </div>

                        <div className="mt-4">
                            <button className="btn btn-warning btn-sm ml-2"
                            onClick={() => fileInputRef.current.click() }>
                                Seleccionar archivo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CargarHitosExcel
