
// import { decodeToken, getToken, verifyRouteAccessPermissions } from 'data/users';
import { partnersRoutes, privateRoutes } from 'routes';
import Footer from '../../components/shared/Footer';
import TopBar from '../../components/shared/TopBar';
import Sidebar from '../../components/shared/Sidebar';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'context/UserContext';
import ToastContainer from 'components/ToastContainer';
import ToastContextProvider from 'context/ToastContext';

interface RoutesProps{
    component: any,
    path: string,
    exact?:boolean,
    requiredRoles?:number[],
    codename?: string
}

const PrivateRoute = ({component, path, requiredRoles, codename, ...rest}:RoutesProps) => {
    // const [hasPermissions, setHasPermissions] = useState<boolean>(false);
    // const [loading, setLoading] = useState<boolean>(true);
    // const identity = decodeToken();

    // useEffect(() => {
    //     let mounted = true;

    //     if(mounted){
    //         if(!identity.superuser){
    //             if(!!codename){
    //                 verifyRouteAccessPermissions(codename)
    //                 .then( resp => {
    //                     setHasPermissions(resp);
    //                     setLoading(false);
    //                 } )
    //                 .catch(err => {
    //                     setHasPermissions(false)
    //                     setLoading(false);
    //                 });
    //             } else {
    //                 setHasPermissions(true);
    //                 setLoading(false);
    //             }
    //         }
    //         setHasPermissions(true);
    //         setLoading(false);
    //     }

    //     return () => { mounted = false;}
    // },[codename, identity])

    // if(!loading){

    //     let userHasRequiredRoles;

    //     if(identity){
    //         if(identity.superuser){
    //             userHasRequiredRoles = !!true;
    //         } else {
    //             if(requiredRoles?.length === 0){
    //                 userHasRequiredRoles = !!true;
    //             } else {
    //                 userHasRequiredRoles = !!requiredRoles?.some(role => identity.groups.indexOf(role) > -1 );
    //             }
    //         }
    //     }

    //     return getToken() ? (
    //         userHasRequiredRoles && hasPermissions ? (
    //             <Route component={component} path={path} {...rest} />
    //         ) : (
    //             <Redirect to="/admin/dashboard" />
    //         )
    //     ):(
    //         <Redirect to="/" />
    //     )
    // }

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        if(user){
            setLoading(false);
        }
    },[user])

    if(!loading){
        return user.token ? (
            <Route component={component} path={path} {...rest} />
        ) : (
            <Redirect to="/" />
        )
    }

    return null;
}

const Admin = () => {
    const { user } = useContext(UserContext);
    const [isSideBarVisible, setSideBarVisible] = useState<boolean>(false);

    return (
        <>
            <ToastContextProvider>
                <ToastContainer/>
                <div id="wrapper">
                    <Sidebar isVisible={isSideBarVisible}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <TopBar toggleSideBar={() => setSideBarVisible(!isSideBarVisible)}/>
                            <div className="container-fluid">
                                <Switch>
                                    { user.isAdmin  ? (
                                        privateRoutes.map( (route,i) => (
                                            <PrivateRoute
                                            key={i}
                                            {...route}
                                            />
                                        ))
                                    ) : (
                                        partnersRoutes.map( (route,i) => (
                                            <PrivateRoute
                                            key={i}
                                            {...route}
                                            />
                                        ))
                                    )}
                                </Switch>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </ToastContextProvider>
        </>
    )
}

export default Admin
