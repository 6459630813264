import Index from 'pages/auth-groups/ListGroups';
import AddGroup from 'pages/auth-groups/AddGroup';
import EditGroup from 'pages/auth-groups/EditGroup';
import SetGroupPermissions from 'pages/auth-groups/SetGroupPermissions';
import { IRoute } from 'interfaces/Route';

export const GROUPS_ROUTES:IRoute[] = [
    {
        path:'/admin/groups',
        component: Index,
        exact: true,
    },
    {
        path:'/admin/groups/add',
        component: AddGroup,
        exact: true,
    },
    {
        path:'/admin/groups/:id/edit',
        component: EditGroup,
        exact: true,
    },
    {
        path:'/admin/groups/:id/set_permissions',
        component: SetGroupPermissions,
        exact: true,
    }
];
