import axios from 'axios';
import { Filtro } from 'interfaces/Filtro';
const URL = process.env.REACT_APP_API_URL_CM

/**
 * Description: Listado de messenger users
 * Author: Jose Quintero
 * @param search
 * @param page
 */
export const getMessengersUsers = (search = '', page: number = 1) => {
    return new Promise( async (resolve, reject) => {
        try {
            let endpoint = `${URL}/messenger_users/?search=${search}&page=${page}`;
            const resp = await axios.get(endpoint);
            resolve(resp);
        } catch(err:any){
            reject(err)
        }
    })
}


/**
 * Description: Busqueda avanzanda en messenger users
 * Author: Jose Quintero
 * @param fitros
 * @param search
 * @param page
 */
export const advanceSearchForUsers = (filtros: Filtro[], search = '', page: number = 1, dates:any = {}) => {
    return new Promise( async (resolve, reject) => {
        try {
            let endpoint = `${URL}/messenger_users/advance_search/?search=${search}&page=${page}`;
            let { dateFrom, dateTo, dateType } = dates;
            if(dateFrom && dateTo){
                endpoint = endpoint + `&date_type=${dateType}&date_from=${dateFrom}&date_to=${dateTo}`
            }
            const resp = await axios.post(endpoint, {filtros})
            resolve(resp);
        } catch(err:any){
            reject(err)
        }
    })
}


/**
 * Description: Exportar a excel data
 * Author: Jose Quintero
 * @param fitros
 * @param search
 * @param page
 */
 export const exportToExcelData = (payload: any) => {
    return new Promise( async (resolve, reject) => {
        try {
            let endpoint = `${URL}/messenger_users/advance_search/`;
            const resp = await axios.post(endpoint, payload,{responseType: 'blob'})
            resolve(resp);
        } catch(err:any){
            reject(err)
        }
    })
}
