import React, { useEffect, useState, useCallback } from 'react';
import Loading from '../../../components/Loading';
import Modal from 'react-modal';
import { getInstance, updateInstance } from 'services/programs.service';
import { AttributeType, InstanceData, OptionAttribute } from 'interfaces/Instance';
import ProgramAttributeDropdown from './ProgramAttributeDropdown';
import { useFormik } from 'formik';
import * as Yup from "yup";
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';


const validationSchema = Yup.object().shape({
    program_name: Yup.string(),
    language: Yup.string(),
    instance_id: Yup.number(),
    user_id: Yup.number(),
    name: Yup.string(),
    image: Yup.string(),
    months: Yup.string(),
    seguimiento: Yup.string(),
    observaciones: Yup.string(),
    attributes_types: Yup.array()
});


const ChildModal = (props: { show: boolean, id: number, user: number, group: string, handleUpdateOpen: (isOpen: boolean) => void, months: number }) => {
    const [loading, setLoading] = useState(true);
    const [isShowed, setIsShowed] = useState(true);
    const [instanceData, setInstanceData] = useState<InstanceData>({ name: '', attributes_types: [] });
    const [attributeTypes, setAttributeTypes] = useState<AttributeType[]>([]);

    const MILESTONE_URL = process.env.REACT_APP_APP_MILESTONES_URL
    const CORE_URL = process.env.REACT_APP_CORE_URL
    const GROUP_DASHBOARD_KEY = process.env.REACT_APP_GROUP_DASHBOARD_KEY

    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: instanceData,
        validationSchema,
        enableReinitialize: true,
        onSubmit: function (values, helper) {
            const changed = []
            if (instanceData.seguimiento !== values.seguimiento) {
                changed.push('seguimiento')
            }
            if (instanceData.observaciones !== values.observaciones) {
                changed.push('observaciones')
            }

            for (const attr_type_id in attributeTypes) {
                const attr_type = attributeTypes[attr_type_id]
                for (const attr_id in attr_type.program_attributes) {
                    if (attr_type.program_attributes[attr_id].value !== values.attributes_types[attr_type_id].program_attributes[attr_id].value) {
                        values.attributes_types[attr_type_id].program_attributes[attr_id].value = attr_type.program_attributes[attr_id].value;
                        changed.push(`attr_${attr_type.program_attributes[attr_id].attribute_id}`)
                    }
                }
            }
            values.changed = changed
            updateInstance(props.id, values).then(() =>
                swal('Guardado correctamente', 'Actualizado correctamente', 'success'))
        }
    });

    useEffect(() => {
        let mounted = true;
        Modal.setAppElement('#root');

        if (mounted) {
            setLoading(false);
        }
        return () => { mounted = false; };

    }, []);

    useEffect(() => {
        setAttributeTypes(JSON.parse(JSON.stringify(instanceData.attributes_types)))
    }, [instanceData.attributes_types])

    useEffect(() => {
        setIsShowed(props.show)
    }, [props.show])

    const fetchData = useCallback(async () => {
        if (props.id === -1) return
        setLoading(true)
        const resp = await getInstance(props.group, props.id)
        setInstanceData(resp.data as InstanceData)
        setLoading(false)
    }, [props.id, props.group])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleChangeOptionValue = (at_index: number, pa_index: number, option: OptionAttribute) => {
        let temTypes = [...attributeTypes];
        const programAttribute = temTypes[at_index].program_attributes[pa_index]
        programAttribute.value = option.label;
        programAttribute.risk = parseFloat(option.value) <= programAttribute.threshold ? 1 : 0
        setAttributeTypes(temTypes)
    }

    return (
        <Modal style={{
            content: { height: "fit-content", maxHeight: "90%", maxWidth: "1140px", margin: "auto", padding: "0" },
            overlay: { zIndex: 1050 }
        }} isOpen={isShowed}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => props.handleUpdateOpen(false)}>
            <div className="modal-header mt-1">
                <div className="w-100 row justify-content-start">
                    <div className="col-1">
                        <img className="img-circle rounded-circle" src={`${CORE_URL}/static/images/${instanceData.image}`}
                            width="50" height="50" style={{ content: "padding:0px; border:0px" }} alt='user'></img>
                    </div>
                    <div className="col-11">
                        <h3 className="modal-title" > {instanceData.name}
                            <a className="px-2 float-right" href={`${CORE_URL}/instances/${props.id}/?attrk=${GROUP_DASHBOARD_KEY}`} target="_blank"
                                rel="noreferrer">
                                <i className="fas fa-info-circle"></i> {t('ver_mas_informacion')}
                            </a>
                            <a className="px-2 float-right" href={`${MILESTONE_URL}/hitos?user_id=${props.user}&instance_id=${props.id}&instance_name=${instanceData.name}&instance_months=${props.months}&language=${instanceData.language}&program_name=${instanceData.program_name}`} target="_blank" rel="noreferrer"> <i className="fas fa-info-circle"></i> {t('actualizar_milestones')}</a>
                        </h3>
                        <span className="pr-2">{t('edad')}: {instanceData.months} </span>
                    </div>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.handleUpdateOpen(false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            {loading ?
                <Loading size="2rem" />
                :
                <>
                    <div className="modal-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="container">
                                {attributeTypes.map((attributes_type, at_index) => {
                                    const colClass = attributes_type.attributes_type_id === 0 ? "" : "col-lg-6"
                                    return (
                                        <React.Fragment key={`dropdown-${at_index}`}>
                                            <h4 className='p-2'>{attributes_type.name}</h4>
                                            <ul className="row">
                                                {attributes_type.program_attributes.map((program_attribute, pa_index) => (
                                                    <ProgramAttributeDropdown program_attribute={program_attribute} colClass={colClass}
                                                        onChange={(value) => handleChangeOptionValue(at_index, pa_index, value)}
                                                        key={`at_pa_${pa_index}_${at_index}`} />
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className="container" id="ficha_observaciones_grupo">
                                <h4 className="modal-title p-2" >{t('seguimiento')}:</h4>
                                <div className="row w-100 pl-4">
                                    <select className="form-control" name="seguimiento"
                                        value={formik.values.seguimiento }
                                        placeholder='Sin seguimiento'
                                        onChange={formik.handleChange}
                                    >
                                        <option value="" className='d-none'>{t("Sin seguimiento")}</option>
                                        <option value="1">{t("pendiente")}</option>
                                        <option value="2">{t("en progreso")}</option>
                                        <option value="3">{t("atendido")}</option>
                                    </select>
                                </div>
                                <h4 className="modal-title p-2">{t("observaciones")}:</h4>
                                <div className="row w-100 pl-4">
                                    <textarea className="form-control" id="ficha_observaciones" rows={2} name="observaciones"
                                        value={formik.values.observaciones}
                                        onChange={formik.handleChange}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-info">
                                    {t("guardar_cambios")} <i className="far fa-save"></i>
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={() => props.handleUpdateOpen(false)}>
                                    {t("regresar")} <i className="fas fa-reply"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            }
        </Modal>
    )
};

export default ChildModal
