import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import * as Yup from "yup";
// @ts-ignore
import  Select  from 'react-select';
import { useEffect, useState } from "react";
import { getAttributesList } from "data/attributes";
import { storeInstanceAttribute } from "data/instance-attributes";
import swal from 'sweetalert';
import { useFormik } from "formik";

type IProps = {
    id: string
}

const validationSchema = Yup.object().shape({
    attribute_value: Yup.string().required("El attribute value es requerido"),
    attribute: Yup.object().shape({
        valor: Yup.string().required('El attribute name es requerido')
    })
});

const AddNewAttributeInstance = ({match}:RouteComponentProps<IProps>) => {
    const instanceId = match.params.id;
    const [options, setOptions] = useState([]);
    const history = useHistory();
    const [initialValues] = useState({
        attribute: {
            valor: ''
        },
        attribute_value: '',
    })

    useEffect(() => {
        let mounted = true;

        if(mounted){
            async function fetchData(){
                try{
                    let data = []
                    const resp:any = await getAttributesList("instance",instanceId)

                    if(resp.data){
                        data = resp.data.map( (item:any) => ({value: item.id, label: item.name}));
                    }
                    setOptions(data);
                } catch(err:any){
                    setOptions([]);
                    console.log(err);
                }
            }
            fetchData();
        }

        return () => { mounted = false; }
    },[instanceId]);

    const handleSave = async (values: any) => {
        try{
            const { value } = values.attribute.valor;
            const payload = {
                instance: instanceId,
                attribute: value,
                value: values.attribute_value
            }
            await storeInstanceAttribute(payload);
            await swal('Afinidata',"Attribute de instance agregado correctamente!", "success");
            history.push('/admin/people')
        } catch(err:any){
            console.log(err);
            swal('Afinidata',"Ocurrio un error", "error");
        }
    }
  const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSave(values);
            formik.resetForm()
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>New attribute for instance # {instanceId}</h4>
                            <div className="text-right">
                                <Link to={`/admin/people`}
                                className="btn btn-secondary ml-2">Volver</Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <form  onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Attribute</label>
                                    <Select options={options}
                                    isSearchable={true}
                                    value={formik.values.attribute.valor}
                                    placeholder="Selecciona attribute"
                                    className={`${
                                    formik.errors.attribute && formik.touched.attribute ? "is-invalid" : ""
                                    }`}
                                    onChange={ (value:any) => {
                                        formik.setFieldValue("attribute.valor",value,true)
                                    }}/>
                                    {formik.errors.attribute && formik.touched.attribute ? (
                                        <div className="invalid-feedback">{formik.errors.attribute.valor}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Value</label>
                                    <input type="text"
                                    name="attribute_value"
                                    placeholder="Attribute value"
                                    className={`form-control ${
                                        formik.errors.attribute_value && formik.touched.attribute_value ? "is-invalid" : ""
                                        }`}
                                    value={formik.values.attribute_value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.attribute_value && formik.touched.attribute_value ? (
                                        <div className="invalid-feedback">{formik.errors.attribute_value}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit">
                                        <i className="fa fa-save mr-2"></i>Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewAttributeInstance
