import { useState, useEffect } from 'react';
import SearchForm from '../../components/SearchForm';
import Loading from '../../components/Loading';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

import { getUnrecognized } from 'data/session-interactions';
import { UnrecognizedInteraction, InteractionShortDetail } from 'interfaces/Session';


const RetrainList = () => {

    const [search, setSearch] = useState("");
    const [interactions, setInteractions] = useState<UnrecognizedInteraction[]>([]);
    const [loading, setLoading] = useState(true);

    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const CORE_URL = process.env.REACT_APP_CORE

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                setActivePage(1);
                const { data } = await getUnrecognized(search, 1);
                const { results, count } = data;
                setInteractions(results);
                setCount(count);
                setLoading(false);
            }
            fetchData();
        }

        return () => { mounted = false; }
    }, [search])

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page);
        const { data } = await getUnrecognized(search, page);
        const { results, count } = data;
        setInteractions(results);
        setCount(count);
        setLoading(false);
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-sm-8">
                            <h3>Catálogo de respuestas no reconocidas</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                        search={search}
                        setSearch={setSearch}
                    />
                    {(interactions && interactions.length > 0) ? (
                        <>
                            <table className="table table-sm" width="100%">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>User's answer</th>
                                        <th>User</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {interactions.map((item: UnrecognizedInteraction) => (
                                        <tr key={item.id}>
                                            <td>{item.question}</td>
                                            <td>{item.text}</td>
                                            <td>
                                                <div>
                                                    <a rel='noreferrer' target='_blank' href={CORE_URL + '/users/' + item.user.id}>
                                                        {item.user.first_name} {item.user.last_name === 'None' ? '' : item.user.last_name}
                                                    </a>
                                                    <div>{item.user.instances.map((i: InteractionShortDetail) =>
                                                    (<span key={i.id}>(
                                                        <a rel='noreferrer' target='_blank' href={CORE_URL + '/instances/' + i.id} key={i.id}>instance #{i.id}</a>
                                                        : {i.name})</span>))}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <Link to={`/admin/retrain/${item.id}`}
                                                    className="btn btn-warning btn-sm">
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={50}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay data.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default RetrainList
