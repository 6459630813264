// import React from 'react';
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SinglePushModal from "./Push-modal";
import { getAllPushGrouping, getCreatedPush, getCreatedPushGroup } from "services/nodejs.service";
import { PushDeleteModal } from "./PushDeleteModal";
import AddPushModal from "./PushModalAdd";
import RemovePushModal from "./PushModalRemove";

const example = [
  {
    id: 1,
    date_to_send: "19/12/2023",
    channel: "WHATSAPP NO BRAZIL",
    output: "ARTICULO",
    age_min: 1,
    age_max: 2,
    program_ids: 1,
    post_ids: "[]",
    messages: "[]",
  },
  {
    id: 2,
    fecha: "06/08/2024",
    canal: "WHATSAPP POR PROGRAMA",
    output: "Session",
  },
  {
    id: 3,
    fecha: "07/08/2024",
    canal: "APP POR PROGRAMA",
    output: "Activity",
  },
  {
    id: 4,
    fecha: "19/12/2023",
    canal: "WHATSAPP NO BRAZIL",
    output: "ARTICULO",
  },
  {
    id: 5,
    fecha: "06/08/2024",
    canal: "WHATSAPP POR PROGRAMA",
    output: "Session",
  },
  {
    id: 6,
    fecha: "07/08/2024",
    canal: "APP POR PROGRAMA",
    output: "Activity",
  },
];

function PushList() {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [toEdit, setToEdit] = useState<any>({});
  const [pushNotifications, setPushNotifications] = useState<any[]>([]);
  const [pushFragments, setPushFragments] = useState<any[]>([]);
  const columns = [
    {
      field: "id",
      headerName: "id",
      editable: false,
      width: 10,
    },
    {
      field: "group_name",
      headerName: "Nombre de grupo",
      // width: 110,
      flex: 1,

      editable: false,
    },
    {
      field: "type",
      headerName: "Typo de grupo",
      // width: 110,
      flex: 1,
      valueGetter: (el: any) => (el === 0 ? "Grupo" : "Edad"),
      editable: false,
    },
    {
      field: "channel",
      headerName: "Canal",
      // width: 110,
      flex: 1,
      valueGetter: (el: any) => (el === 100 ? "WA" : "APP"),
      editable: false,
    },
    {
      field: "size",
      headerName: "Tamaño de grupo",
      flex: 1,
      valueGetter: (el:any, row:any) => {
        if(row.push_ids?.length==0) {
            return 0
        }
        else {
            return el
        }
      },
      // width: 110,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Opciones",
      // flex:1,
      width: 200,
      editable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-success btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setAddModal(true);
              }}
            >
              <i className="fa fa-plus"></i>
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setRemoveModal(true);
              }}
            >
              <i className="fa fa-minus"></i>
            </button>
            <button
              type="button"
              className="btn btn-warning btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModal(true);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModalDelete(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPush = async () => {
    let allObjs = await getAllPushGrouping();
    let reducedObjs: any[] = [];
    allObjs.data.result.forEach((element: any) => {
      // let currIndex = reducedObjs.findIndex((el: any) => el.id == element.id);
      reducedObjs.push({
        id: element.id,
        group_name: element.group_name,
        type: element.type,
        push_ids: element.push_ids
          ? element.push_ids.split(",").map(Number)
          : [],
        group_ids: element.push_ids
            ? element.group_ids?.split(",").map(Number)
            : [],
        size: element.group_size,
        channel: element.channel,
      });
    });
    console.log(">", reducedObjs);
    setPushNotifications(reducedObjs);
  };

  async function getFractionalPushes() {
    let createdFractPush = await getCreatedPush();
    let createdGroupPush = await getCreatedPushGroup();

    let reducedPush: any = { wa: [], app: [] };
    createdFractPush.data.result.forEach((push: any) => {
      let pushToAdd = {
        label: push.name,
        value: push.id,
        age: false,
        min: 0,
        max: 0,
        type:'push'
      };
      if (push.age_min != null) {
          pushToAdd.age = true;
          pushToAdd.min = push.age_min
          pushToAdd.max = push.age_max
      } 
      if (push.channel == 100) {
        reducedPush.wa.push(pushToAdd);
      } else {
        reducedPush.app.push(pushToAdd);
      }
    });
    createdGroupPush.data.result.forEach((push: any) => {
        let pushToAdd = {
            label: push.name,
            value: push.id,
            age: false,
            min: 0,
            max: 0,
            type:'group'
          };
          console.log('push', push.channel )
          if (push.channel == 100) {
            reducedPush.wa.push(pushToAdd);
          } else {
            reducedPush.app.push(pushToAdd);
          }
    })
    setPushFragments(reducedPush);
  }

  useEffect(() => {
    getFractionalPushes();
  }, []);

  useEffect(() => {
    getPush();
  }, []);
  console.log("toEdit", toEdit);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => {
            setToEdit({ reset: true });
            setModal(true);
          }}
        >
          Crear nuevo grupo
        </Button>
      </div>
      <DataGrid
        rows={pushNotifications ? pushNotifications : example}
        //@ts-ignore
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: "date_to_send", sort: "asc" }],
        //   },
        // }}
        // autosizeOptions={{
        //   columns: ['delete'],
        //   includeOutliers: true,
        //   includeHeaders: false,
        // }}
      />
      <SinglePushModal
        visible={modal}
        close={() => {
          setModal(false);
          getPush();
          setToEdit({});
        }}
        pushFragments={pushFragments}
        refeshPush={getPush}
        toEdit={toEdit}
      />
      <AddPushModal
        id={toEdit.id}
        visible={addModal}
        close={() => {
          setAddModal(false);
          getPush();
        }}
        pushFragments={pushFragments}
        refeshPush={getPush}
        currentPrograms={toEdit.push_ids?.concat(toEdit.group_ids)}
        channel={toEdit?.channel}
        type={toEdit?.type}
      />
      <RemovePushModal
        id={toEdit.id}
        visible={removeModal}
        close={() => {
          setRemoveModal(false);
          getPush();
        }}
        refeshPush={getPush}
        currentPrograms={toEdit.push_ids?.concat(toEdit.group_ids)}
        pushFragments={pushFragments}
        channel={toEdit?.channel}
        type={toEdit?.type}
      />
      <PushDeleteModal
        visible={modalDelete}
        close={setModalDelete}
        refresh={getPush}
        pushToDelete={toEdit}
      />
    </div>
  );
}

// const horizontal = {
//     display: 'flex',
//     flexDirection: 'column',
//   }

export default PushList;
