import axios from 'axios';
const URL = process.env.REACT_APP_API_URL_CM

export const saveProgramAttributes = (payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/programs_attributes/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const updateProgramAttributes = (id: string, payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/programs_attributes/${id}/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteProgramAttributes = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/programs_attributes/${id}/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getProgramAttributes = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs_attributes/?attribute_type=${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getDetailProgramAttributes = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs_attributes/?id=${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
