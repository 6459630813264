import { createContext, useEffect, useState } from "react"

interface UserI {
    token: string;
    isAdmin: boolean
}

interface UserContextI {
    user: UserI,
    setUserInfo: (payload: UserI) =>  void
}

export const UserContext = createContext({} as UserContextI);

const initialState:UserI = {
    token: '',
    isAdmin: false
}

const UserContextProvider = ({children}:any) => {
    const [user, setUser] = useState<UserI>(initialState);

    useEffect(() => {
        if(localStorage.getItem('@appUser')){
            const storageUser = JSON.parse(localStorage.getItem('@appUser') || "")
            setUser(storageUser);
        }
    },[])

    const setUserInfo = (payload: UserI) => {
        localStorage.setItem('@appUser',JSON.stringify(payload));
        setUser(payload);
    }

    return (
        <UserContext.Provider value={{
            user,
            setUserInfo
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
