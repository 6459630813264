import NluMain from './NluMain'
import IntentsContextProvider from './IntentsContextProvider';

const NluScreen = () => {
    return (
        <IntentsContextProvider>
            <NluMain />
        </IntentsContextProvider>
    )
}

export default NluScreen
