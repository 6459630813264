import { IRoute } from '../interfaces/Route';
import GroupsLists from 'pages/partners/GroupsLists';
import DashboardPartners from 'pages/partners/DashboardPartners';

export const PARTNERS_ROUTES:IRoute[] = [
    {
        path: '/admin/partners/groups',
        component: GroupsLists,
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/partners/groups/:id/dashboard/:name',
        component: DashboardPartners,
        exact: true,
        requiredRoles: []
    }
]
