import Pagination from 'react-js-pagination';
import { useEffect, useState, useCallback } from 'react';
import { getGroupsListByUser, createNewGroupPROGRAMS, editGroupPROGRAMS } from 'services/programs.service';
import { createNewGroupCM, editGroupCM, getGroupIdByNameCM } from 'services/content-manager.service';
// import { bulkCreateNewGroups } from 'services/content-manager.service';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import Loading from 'components/Loading';
import SearchForm from 'components/SearchForm';
import CreateGroupForm from 'components/CreateGroupForm'
import Swal from 'sweetalert';
import EditGroupForm from 'components/EditGroupForm';
import { LeftOutlined } from '@ant-design/icons';

const GroupsLists = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [buttonSelected, setButtonSelected] = useState("");
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [fileName] = useState<string>('');
    const [jsonData, setJsonData] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(4);
    const [paginationGroups, setPaginationGroups] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const { t, ready } = useTranslation();
    const fetchData = useCallback(async () => {
        try {
            const { data: { count, results } } = await getGroupsListByUser();
            setCount(count);
            setPaginationGroups(results);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        async function handleSearch(searchValue: string) {
            try {
                setLoading(true);
                setActivePage(1);
                const { data: { count, results } } = await getGroupsListByUser(1, searchValue);
                setCount(count);
                setPaginationGroups(results);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        }
        handleSearch(search);
    }, [search]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handlePageChange = async (page: number) => {
        try {
            setLoading(true);
            setActivePage(page);
            const { data: { count, results } } = await getGroupsListByUser(page);
            setCount(count);
            setPaginationGroups(results);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const setFileName = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const files = event.target.files;
            if (!files || files.length === 0) return;

            setLoading2(true);

            const file = files[0];
            if (!file.name.endsWith('.xlsx')) {
                Swal('El archivo no es un excel válido, usa formato .xlsx', '', 'error');
                setLoading2(false);
                return;
            }

            const data = await readFileAsync(file);
            if (data instanceof ArrayBuffer) {
                const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const json = XLSX.utils.sheet_to_json(sheet);
                const formattedJson = json.map((item: any) => ({
                    ...item,
                    license: item.license.toString(),
                    group_type: item.group_type.toString(),
                }));
                setJsonData(formattedJson);
                Swal('Archivo cargado con éxito', '', 'success');
                setLoading2(false);
            }
        } 
        catch (error: any) {
            console.error('Error al leer el archivo:', error);
            const stringError = error.message;
            Swal('Error al leer el archivo', stringError, 'error');
            setLoading2(false);
        }
        
        
    };

    const readFileAsync = (file: File): Promise<ArrayBuffer | string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = () => reject(reader.error);
            reader.readAsArrayBuffer(file);
        });
    };

    /*const transformValuesForBulkCreateCM = (values: any) => {
        const transformedValuesForBulkCreateEndpointCM = {
            'Nombre de grupo': values.name,
            parent: values.parent,
            country: values.country,
            region: values.region,
            license: values.license,
            program: values.program,
            group_type: values.group_type,
        };
        return [transformedValuesForBulkCreateEndpointCM];
    };
    */

    const transformValuesForCreateCM = (values: any) => {
        const transformedValuesForCreateEndpointCM = {
            name: values.name,
            parent: values.parent,
            country: values.country,
            region: values.region,
            license: values.license,
            program: values.program_name,
            group_type: values.group_type,
        };
        return transformedValuesForCreateEndpointCM;
    };

    const transformValuesForCreatePROGRAMS = (values: any) => {
        const transformedValuesForCreateWithEndpointPROGRAMS = {
            name: values.name,
            parent: values.parent,
            country: values.country,
            region: values.region,
            license: values.license,
            program_name: values.program_name,
            group_type: values.group_type,
        };
        return transformedValuesForCreateWithEndpointPROGRAMS;
    };

    const performBulkCreate = async (data: any[]) => {
        setLoading(true);
    
        try {
            if (data.length === 0) {
                setLoading(false);
                return Swal('No hay grupos para crear', '', 'info');
            }
    
            const failedGroups: string[] = [];
            let successCount = 0;
    
            for (const group of data) {
                try {
                    const transformedValuesForCreateWithEndpointPROGRAMS = transformValuesForCreatePROGRAMS(group);
                    const responsePrograms = await createNewGroupPROGRAMS(transformedValuesForCreateWithEndpointPROGRAMS);
    
                    if (responsePrograms && (responsePrograms.status === 200 || responsePrograms.status === 201)) {
                        const transformedValuesForCreateEndpointCM = transformValuesForCreateCM(group);
                        const responseCM = await createNewGroupCM(transformedValuesForCreateEndpointCM);
    
                        if (responseCM && (responseCM.status === 200 || responseCM.status === 201)) {
                            successCount++;
                        } else {
                            failedGroups.push(group.name);
                        }
                    } else {
                        failedGroups.push(group.name);
                    }
                } catch (error) {
                    failedGroups.push(group.name);
                }
                await new Promise((resolve) => setTimeout(resolve, 100)); // Retraso entre llamadas para evitar sobrecargas
            }
    
            let message = '';
            if (failedGroups.length === 0) {
                message = successCount === data.length ? 'Todos los grupos fueron creados exitosamente en ambos endpoints' : `Se crearon ${successCount} grupo(s) exitosamente`;
                Swal(message, '', 'success');
            } else {
                const failedGroupsList = `Grupos que no se pudieron crear: \n${failedGroups.join('\n')}`;
                message = `${failedGroupsList}`;
                Swal(`Error al crear ${failedGroups.length} grupo(s)`, message, 'error');
            }
        } catch (error) {
            console.error('Error al crear grupos:', error);
            Swal('Error al crear grupos', '', 'error');
        } finally {
            setLoading(false);
        }
    };
    
    const performBulkEdit = async (data: any[]) => {
        setLoading(true);

        try {
            if (data.length === 0) {
                setLoading(false);
                return Swal('No hay grupos para editar', '', 'info');
            }

            const failedEdits: string[] = [];
            let successCount = 0;

            for (const group of data) {
                try {
                    const groupId = group.id;
                    const groupData = { ...group };
                    delete groupData.id;

                    const transformedValuesForCreateWithEndpointPROGRAMS = transformValuesForCreatePROGRAMS(group);
                    const responsePrograms = await editGroupPROGRAMS(groupId, transformedValuesForCreateWithEndpointPROGRAMS);
    
                    if (responsePrograms && (responsePrograms.status === 200 || responsePrograms.status === 201)) {
                        const transformedValuesForCreateEndpointCM = transformValuesForCreateCM(group);
                        const responseAux = await getGroupIdByNameCM(group.name)
                        const groupIdFromCM = responseAux.data.group_id
                        const responseCM = await editGroupCM(groupIdFromCM, transformedValuesForCreateEndpointCM);
    
                        if (responseCM && (responseCM.status === 200 || responseCM.status === 201)) {
                            successCount++;
                        } else {
                            failedEdits.push(group.name);
                        }
                    } else {
                        failedEdits.push(group.name);
                    }
                } catch (error) {
                    failedEdits.push(group.name);
                }
                await new Promise((resolve) => setTimeout(resolve, 10));
            }

            let message = '';
            if (failedEdits.length === 0) {
                message = successCount === data.length ? 'Todos los grupos fueron editados exitosamente' : `Se editaron ${successCount} grupo(s) exitosamente`;
                Swal(message, '', 'success');
            } else {
                message = `Ha ocurrido un error al editar ${failedEdits.length} grupo(s)`;
                const errorMessage = `No se pudieron editar los siguientes grupos: \n${failedEdits.join('\n')}`;
                Swal(message, errorMessage, 'error');
            }
        } catch (error) {
            console.error('Error al editar grupos:', error);
            Swal('Error al editar grupos', '', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleReload = () => {
        setButtonSelected("");
        fetchData();
    };

    if (!ready || loading) {
        return <Loading />;
    }
    else if (buttonSelected === "create_single_group") {
        return (
            <div className="card">
                <div className="card-header">
                    <div className='custom-display-flex'>
                        <Button
                            onClick={handleReload}
                            style={{ marginLeft: '10px' }}
                        >
                            <LeftOutlined />
                        </Button>
                        <h3>{t("grupos")}</h3>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-secundary shadow-sm"
                            onClick={() => { setShowModal(true); setButtonSelected("bulk_create_groups") }}
                        >
                            <i className="fas fa-file-upload fa-sm text-black-50 mx-2"></i>
                            Subir Grupos
                        </button>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => setButtonSelected("create_single_group")}
                        >
                            <i className="fas fa-plus fa-sm text-white-50 mx-2"></i>
                            Crear grupo
                        </button>
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Subir Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_NEW.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkCreate(jsonData)}>Crear Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Modificar Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_EDIT.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkEdit(jsonData)}>Modificar Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal2(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => { setShowModal2(true); setButtonSelected("bulk_modify_groups"); }}
                        >
                            <i className="fas fa-edit fa-sm text-white-50 mx-2"></i>
                            Modificar Grupos
                        </button>
                    </div >
                </div >
                <div className="card-body">
                    <CreateGroupForm />
                </div>
            </div >
        )
    }
    else if (buttonSelected === "edit_single_group") {
        return (
            <div className="card">
                <div className="card-header">
                    <div className='custom-display-flex'>
                        <Button
                            onClick={handleReload}
                            style={{ marginLeft: '10px' }}
                        >
                            <LeftOutlined />
                        </Button>
                        <h3>{t("grupos")}</h3>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-secundary shadow-sm"
                            onClick={() => { setShowModal(true); setButtonSelected("bulk_create_groups") }}
                        >
                            <i className="fas fa-file-upload fa-sm text-black-50 mx-2"></i>
                            Subir Grupos
                        </button>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => setButtonSelected("create_single_group")}
                        >
                            <i className="fas fa-plus fa-sm text-white-50 mx-2"></i>
                            Crear grupo
                        </button>
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Subir Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_NEW.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkCreate(jsonData)}>Crear Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Modificar Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_EDIT.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkEdit(jsonData)}>Modificar Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal2(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => { setShowModal2(true); setButtonSelected("bulk_modify_groups"); }}
                        >
                            <i className="fas fa-edit fa-sm text-white-50 mx-2"></i>
                            Modificar Grupos
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <EditGroupForm selectedGroup={selectedGroup} />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="card">
                <div className="card-header">
                    <div className='custom-display-flex'>
                        <Button
                            onClick={handleReload}
                            style={{ marginLeft: '10px' }}
                        >
                            <LeftOutlined />
                        </Button>
                        <h3>{t("grupos")}</h3>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-secundary shadow-sm"
                            onClick={() => { setShowModal(true); setButtonSelected("bulk_create_groups") }}
                        >
                            <i className="fas fa-file-upload fa-sm text-black-50 mx-2"></i>
                            Subir Grupos
                        </button>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => setButtonSelected("create_single_group")}
                        >
                            <i className="fas fa-plus fa-sm text-white-50 mx-2"></i>
                            Crear grupo
                        </button>
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Subir Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_NEW.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkCreate(jsonData)}>Crear Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Modificar Grupos por Lote</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading2 ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {'Procesando...'}
                                        <Spinner animation="border" role="status" />
                                    </div>) : (
                                    <div className="modal-body d-flex flex-column align-items-center">
                                        <a href="https://public-static-files-afinidata.s3.us-west-1.amazonaws.com/GRUPOS_EDIT.xlsx" className="btn btn-outline-secondary mb-3" target="_blank">Descargar Formato</a>
                                        <div className="custom-file w-75 mb-3">
                                            <label className="custom-file-label" htmlFor="fileInput">
                                                {fileName ? fileName : 'Seleccione un archivo'}
                                            </label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                aria-describedby="inputGroupFileAddon04"
                                                onChange={setFileName}
                                            />
                                        </div>

                                        <button className="btn btn-outline-secondary" type="button" onClick={() => performBulkEdit(jsonData)}>Modificar Grupos</button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal2(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <button
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                            onClick={() => { setButtonSelected("bulk_modify_groups"); setShowModal2(true); }}
                        >
                            <i className="fas fa-edit fa-sm text-white-50 mx-2"></i>
                            Modificar Grupos
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                        search={search}
                        setSearch={setSearch}
                    />
                    {paginationGroups.length === 0 ? (
                        <div className="alert alert-info">
                            <p>{t("no_hay_data")}</p>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <td>{t("Id")}</td>
                                        <td>{t("nombre")} </td>
                                        <td>{t("fecha_creacion")}</td>
                                        <td>{t("opciones")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginationGroups.map((item: any) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <Link
                                                    className="btn btn-sm btn-primary ml-2"
                                                    to={`/admin/partners/groups/${item.id}/dashboard/${item.name}`}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                                <button
                                                    className="btn btn-sm btn-success ml-2"
                                                    onClick={() => {
                                                        setButtonSelected("edit_single_group");
                                                        setSelectedGroup(item);
                                                    }}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={30}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default GroupsLists;