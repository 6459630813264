import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_CM;

/**
 * Description: Devolver los attributes de un messenger user por id
 * @param userId
 * @param text
 */
export const getUserAttributes = (userId: string, text = "", pagination = ""): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/messenger_users_data/?user_id=${userId}&search=${text}&pagination=${pagination}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const storeUserAttribute = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/messenger_users_data/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/**
 * Description: actualizar atributo de un usuario
 * @param id
 * @param payload
 */
export const updateUserAttribute = (id: string, payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/messenger_users_data/${id}/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


/**
 * Description: obtener posibles values por attribute
 * @param attributeId
 *
 */
export const getPossibleValuesByAttributeUser = (attributeId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/user/${attributeId}/get_possible_values/`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
