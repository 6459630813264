import ChildModal from './components/ModalChild';
import UserModal from './components/ModalEncargado';
import Loading from 'components/Loading';
import Spinner from 'components/Spinner';
import { GroupContext } from 'context/GroupContext';
import { useState, useEffect, useContext } from 'react';
import Pagination from 'react-js-pagination';
import { useParams } from 'react-router';
import { getUserGroupsChildren } from 'services/programs.service';
import DashboardResume from './components/DashboardResume';
import PartnerInfoRow from './components/PartnerInfoRow';
import PartnerProgressBar from './components/PartnerProgressBar';
import PartnerButtonCard from './components/PartnertButtonCard';
// @ts-ignore
import Select from 'react-select';
import './style.css';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import SharedLink from './components/SharedLink';
import SearchField from 'components/search_field';
import {deleteFromProgram, contentManagerDeleteProgram} from '../../data/programs'

type ParamsComponentT = {
    id: string,
    name: string
}

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

const DashboardPartners = () => {

    const {id,name}:ParamsComponentT = useParams();
    const { t } = useTranslation();
    const [showModalChild, setShowChildModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteFailModal, setShowDeleteFailModal] = useState(false);
    const [toDeleteId, setToDeleteId] = useState({username:''});
    const [userId, setUserId] = useState(0);
    const [childId, setChildId] = useState(-1);
    const [childMonths, setChildMonths] = useState(0);
    const [count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [childrens, setChildrens] = useState<any[]>([]);
    const [loading1, setLoading1] = useState(true);
    const [textString, setTextString] = useState("");
    const [progressBarData, setProgressBarData] = useState<any[]>([]);
    const [currentNameList, setCurrentNameList] = useState("");
    const [instanceUser, setInstanceUser] = useState<Number>(0);
    const [edades, setEdades] = useState<string[]>([])
    const [riskType, setRiskType] = useState<any>({})
    const [filteredBy, setFilteredBy] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModalShare, setShowModalShare] = useState(false);
    const textValue = t("riesgos_desarrollo");
    
    const {
        setGroupId,
        data:{
            risks,
            milestonesCount,
            milestones,
            ref,
            codes
        },
        isLoading
    } = useContext(GroupContext);

    useEffect(() => {
        let mounted = true;

        Modal.setAppElement('#root');

        if(mounted){
            console.log("mounted")
        }

        return () => { mounted = false; };
    }, []);

    useEffect(() => {
        (async() => {
            try{
                setGroupId(id);
                const resp = await getUserGroupsChildren(id)
                const { data: {count, results}} = resp;
                setCount(count);
                setChildrens(results);
                setLoading1(false);
            }catch(e){
                console.error(e);
                setLoading1(false);
            }
        })();

    },[id,setGroupId]);

    useEffect(() => {
        setProgressBarData(
            milestones.map((i:any) => ({...i, users_in_risk: i.y, total: milestonesCount}))
        );
        setCurrentNameList(textValue);
    // eslint-disable-next-line
    },[milestones, milestonesCount])

    const handlePageChange = async (page:number) => {
        try{
            setLoading1(true);
            setActivePage(page);
            const resp = await getUserGroupsChildren(id, page, textString, riskType.type, riskType.typeId)
            const { data: {count, results}} = resp;
            setCount(count);
            setChildrens(results);
            setLoading1(false);
        }catch(e){
            console.error(e);
            setLoading1(false);
        }
    }

    useEffect(() => {
        async function handleSearch(sString: string){
            try{
                setLoading1(true);
                setActivePage(1);
                let resp;
                if (edades.length > 0)
                    resp = await getUserGroupsChildren(id, 1, sString, riskType.type, riskType.typeId, edades.join(','))
                else
                    resp = await getUserGroupsChildren(id, 1, sString, riskType.type, riskType.typeId)
                const { data: {count, results}} = resp;
                setCount(count);
                setChildrens(results);
                setLoading1(false);
            }catch(e){
                console.error(e);
                setLoading1(false);
            }
        }
        handleSearch(textString);
    },[textString, id, edades, riskType])

    const handleSeeModalChild = (e: any, data:any) => {
        e.preventDefault();
        setChildId(data.instance_id);
        setUserId(data.user_id)
        setChildMonths(data.number_month)
        setShowChildModal(true);
    }

    const handleBarClick = (type: string | null, typeId: number, label?: string ) => {
        if(type !== "milestones"){
            setFilteredBy(label || "");
        } else {
            setFilteredBy("");
        }
        setRiskType({type, typeId})
    }

    const handleMonthChange = (value: any) => {
        setEdades( value.map((item: any) => item.value));
    }

    const triggerRemoveFromGroup = async (value: any) => {
        // console.log(value)
        // console.log(id)
        setShowDeleteModal(false)
        setLoading1(true)
        try {
            await deleteFromProgram(id,value.user_id)
            await contentManagerDeleteProgram(id,value.user_id)
            const resp = await getUserGroupsChildren(id)
            const { data: {count, results}} = resp;
            setCount(count);
            setChildrens(results);
        } catch (error) {
            setShowDeleteFailModal(true)
            setLoading1(false);            
        }
        setLoading1(false);
    }

    const handleMonthSearch = () => {
        async function handleSearch(){
            try{
                setLoading1(true);
                setActivePage(1);
                const resp = await getUserGroupsChildren(id, 1, '', null, null, edades.join(','))
                const { data: {count, results}} = resp;
                setCount(count);
                setChildrens(results);
                setLoading1(false);
            }catch(e){
                console.error(e);
                setLoading1(false);
            }
        }
        handleSearch()
    }

    const handleUpdateSeeChildModal = (isOpen: boolean) => {
        setShowChildModal(isOpen)
    }

    const handleSeeModalEncargado = (e:any, data:any) => {
        e.preventDefault();
        setInstanceUser(data.instance_id)
        setUserId(data.user_id)
        setShowUserModal(true)
    }


    const handleDeleteModal = (e:any, data:any) => {
        e.preventDefault();
        setToDeleteId(data)
        // setUserId(data.user_id)
        setShowDeleteModal(true)
    }

    const handleCardClick = (item: any) => {
        setProgressBarData(item.data);
        setCurrentNameList(item.name);
    }

    return (
        <div className="mt-5 mb-5 root-container">
            <ChildModal
                show={showModalChild}
                id={childId}
                months={childMonths}
                user={userId}
                group={id}
                handleUpdateOpen={handleUpdateSeeChildModal} />

            {showUserModal &&
                    <UserModal
                            group={id}
                            instance={instanceUser}
                            user={userId}
                            setShowUserModal={setShowUserModal} />
            }

            <div className="row mb-5">
                <div className="col-sm-6">
                    <h3 style={{color: 'grey', fontWeight: 500}}>{t('Grupo')}: {name}</h3>
                </div>
                <div className="col-sm-6">
                    <button className="btn btn-outline-primary custom-button"
                    onClick={() => setShowModalShare(true)}>{t('invitar_familias')}</button>
                </div>
            </div>

            <DashboardResume />

            <div className="row mb-5">
                {isLoading && <Spinner />}
                {!isLoading && (
                    <>
                        <PartnerButtonCard
                        type="milestones"
                        onClick={handleCardClick}
                        item={{
                            name: t("riesgos_desarrollo"),
                            qty: milestonesCount,
                            data: milestones.map((i:any) => ({...i, users_in_risk: i.y, total: milestonesCount}))
                        }}
                        />

                        {risks.map((item:any, i:number) => (
                            <PartnerButtonCard
                            type="risks"
                            onClick={handleCardClick}
                            key={`${item.key}-${i}`}
                            item={{
                                name: item.name,
                                qty: item.total_users_in_risk,
                                data: item.risks.map((i:any) =>({...i, total:  item.total_users_in_risk})),
                            }}
                            />
                        ))}
                    </>
                )}
            </div>

            <div className="row mb-2">
                <div className="col-sm-12">
                    {isLoading && <Spinner />}
                    {!isLoading && (
                        progressBarData.length === 0 ? (
                            <div className="alert alert-info text-center">{t('no_hay')} {currentNameList}</div>
                        ) : (
                            progressBarData.map((item: any, i:number) => (<PartnerProgressBar
                                item={item}
                                key={`${item.key}-${i}`}
                                onClick={(type:string, id: number, label: string ) => handleBarClick(type, id, label)}
                            />))
                        )
                    )}
                </div>
            </div>

            <div className="card">
                <div className="card-header">
                    <div className="mb-3 d-flex justify-content-end">
                        <button className="btn btn-outline-primary btn-sm custom-button"
                        onClick={() => {setTextString(""); handleBarClick(null, -1)}}>{t('ver_todos')}</button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className='row'>
                                <div className='col-10'>
                                    <Select isMulti
                                        placeholder={t('seleccione_una_edad')}
                                        closeMenuOnSelect={false}
                                        onChange={handleMonthChange}
                                        options={
                                            [
                                                { value: '0_3', label: t('0_3_meses') },
                                                { value: '4_6', label: t('4_6_meses') },
                                                { value: '7_9', label: t('7_9_meses') },
                                                { value: '10_12', label: t('10_12_meses') },
                                                { value: '13_15', label: t('13_15_meses') },
                                                { value: '16_18', label: t('16_18_meses') },
                                                { value: '19_21', label: t('19_21_meses') },
                                                { value: '22_24', label: t('22_24_meses')},
                                                { value: '25_30', label: t('25_30_meses') },
                                                { value: '31_36', label: t('31_36_meses') },
                                                { value: '37_42', label: t('37_42_meses') },
                                                { value: '43_48', label: t('43_48_meses') },
                                                { value: '49_60', label: t('49_60_meses') },
                                                { value: '61_72', label: t('61_72_meses') },
                                                { value: '73_84', label: t('73_84_meses') },
                                                { value: '85_96', label: t('85_96_meses') },
                                                { value: '-9_0', label: t('embarazo') }
                                            ]
                                        }>
                                    </Select>
                                </div>
                                <div className='col-2 pl-0'>
                                    <button className='btn btn-primary'
                                        onClick={() => handleMonthSearch()}
                                    >{t('buscar')}</button>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-center"
                            style={{flexDirection: 'column', height: "100%"}}>
                                <div className="mb-3">
                                    <SearchField search={textString} setSearch={(value) => setTextString(value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-body">
                    { loading1 ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="table-responsive">
                                { !!filteredBy && (
                                    <p>{t('filtrado_:por')}: {filteredBy}</p>
                                )}
                                <table className="table table-sm table-striped table-hover">
                                    <thead className="thead-light" style={{width: "100%"}}>
                                        <tr className="text-center">
                                            <th scope="col">{t('nombre')}</th>
                                            <th scope="col">{t('encargado')}</th>
                                            <th scope="col">{t('edad')}</th>
                                            <th scope="col">{t('fecha_de_nacimiento')}</th>
                                            <th scope="col">{t('telefono')}</th>
                                            <th scope="col">{t('ubicacion')}</th>
                                            <th scope="col" style={{ width: "15%"}}>
                                                {t('nivel_de_riesgo')} &nbsp; <i
                                                className="fa fa-question-circle"
                                                style={{color: "blue", cursor: 'pointer'}}
                                                onClick={() => {
                                                    setShowModal(true)
                                                }}></i>
                                            </th>
                                            <th scope="col">{t('Desasociar')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { childrens.map((item:any, i:any) => (
                                            <PartnerInfoRow
                                                data={item}
                                                key={`${item.id}-${i}`}
                                                onClickModalChild={handleSeeModalChild}
                                                oncClickModalEncargado={handleSeeModalEncargado}
                                                onClickModalDelete={handleDeleteModal}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={30}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center"
                            />
                        </>
                    )}
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={customStyles}
            >
                <h2>{t("niveles_de_riesgo")}</h2>
                <hr />
                <h5>{t("tres_niveles_de_riesgo")}</h5>
                <ul>
                    <li>
                        <span className="badge badge-info">{t("bajo")}</span>
                        <p>
                            {t("nivel_0_text")}
                        </p>
                    </li>
                    <li>
                        <span className="badge badge-warning">{t("medio")}</span>
                        <p>
                            {t("nivel_1_text")}
                        </p>
                    </li>
                    <li>
                        <span className="badge badge-danger">{t("Alto")}</span>
                        <p>
                            {t("nivel_2_text")}
                        </p>
                    </li>
                </ul>
                <hr />
                <div className="text-right">
                    <button onClick={() => setShowModal(false)}
                    className="btn btn-dark btn-sm">{t("cerrar")}</button>
                </div>
            </Modal>
            <Modal
                isOpen={showModalShare}
                onRequestClose={() => setShowModalShare(false)}
                style={customStyles}
            >
                <SharedLink link={ref} codes={codes} onClose={()=> setShowModalShare(false)}/>
            </Modal>
            <Modal
                isOpen={showDeleteModal}
                onRequestClose={() => setShowDeleteModal(false)}
                style={customStyles}
            >
                <h5>Estas seguro que quieres desasociar a {toDeleteId.username} de {name}? </h5>
                <div className="modal-footer">
                    <div className="text-right">
                        <button onClick={() => setShowDeleteModal(false)}
                        className='btn btn-primary'>{t("no")}</button>
                    </div>
                    <div className="text-right">
                        <button onClick={() => triggerRemoveFromGroup(toDeleteId)}
                        className='btn btn-danger'>{t("si")}</button>
                    </div>
                </div>
                {/* <SharedLink link={ref} codes={codes} onClose={()=> setShowModalShare(false)}/> */}
            </Modal>
            <Modal
                isOpen={showDeleteFailModal}
                onRequestClose={() => setShowDeleteFailModal(false)}
                style={customStyles}
            >
                <h5>Error al desasociar ususario</h5>
                <div className="modal-footer">
                    <div className="text-right">
                        <button onClick={() => setShowDeleteFailModal(false)}
                        className='btn btn-danger'>{t("Okey")}</button>
                    </div>
                </div>
            </Modal>
        </div>
     )
}

export default DashboardPartners
