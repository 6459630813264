import { IRoute } from '../interfaces/Route';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import PostTraductionListPage from 'pages/posts/PostTraductionsList';
import EditTranslationPage from 'pages/posts/EditTranslation';

export const POSTS_ROUTES:IRoute[] = [
    {
        path: '/admin/posts/translations',
        component: PostTraductionListPage,
        codename:'posts_traduction_list',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/posts/translations/:id',
        component: EditTranslationPage,
        codename:'posts_traduction_list',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    }
]
