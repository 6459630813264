import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_CM;

const getSessionsList = (search = '', page = 1): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/sessions/?search=${search}&page=${page}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

const bulkCreateSession = (data: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/sessions/bulk_create/`, data);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

export { 
    getSessionsList,
    bulkCreateSession
}
