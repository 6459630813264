


export const setDataFormAttribute = (data: any) => {
    return data.map((item:any) => ({
        id: item.id,
        name: item.data_key,
        value: item.data_value
    }))
}

