import EntityAddEdit from '../../components/EntityAddEdit';


const AddEntity = () => {

    return (
        <>
            <EntityAddEdit type="add" nameBtn="Crear"/>
        </>
    )
}

export default AddEntity;