import { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import { getUserData } from 'services/programs.service';
import UserOption from './UserOption'
import { useTranslation } from 'react-i18next';
declare var $: any;

const ModalEncargado = (props: any) => {
    const [loading, setLoading] = useState<Boolean>(true);
    const [data, setData] = useState<any>({});
    const [attributes, setAttributes] = useState<any>([]);
    const CORE_URL = process.env.REACT_APP_CORE_URL || "http://localhost:8208"
    const GROUP_DASHBOARD_KEY = process.env.REACT_APP_GROUP_DASHBOARD_KEY || "rrHYg8FnApmu5i45uCYr1eYk7s-7JbEHT5a6uAoQT7DazOr1-vnw_4VZxxmhHqEgRuUqnUpfANWFadmlS4_Dqg=="

    const fetchData = async () => {
        try {
            const dataResponse = await getUserData(props.group, props.instance, props.user)
            setData(dataResponse.data)
            setAttributes(dataResponse.data.attributes_types)
        } catch (err: any) {
            console.error(err);
        } finally {
            setLoading(false)
        }
    }

    const {t} = useTranslation();

    useEffect(() => {
        $('#modalUser').modal("show");
        setLoading(true)
        
        const getData = async () => {
            try {
                const dataResponse = await getUserData(props.group, props.instance, props.user)
                setData(dataResponse.data)
                setAttributes(dataResponse.data.attributes_types)
            } catch (err: any) {
                console.error(err);
            } finally {
                setLoading(false)
            }
        }

        getData()
       
    }, [props]);

    return (
        <div className="modal fade" id="modalUser" tabIndex={-1} role="dialog"
            aria-labelledby="modalUserTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="dialog">
                <div className="modal-content">
                    {loading ?
                        <Loading size="2rem" />
                        :
                        <>
                            <div className="modal-header">
                                <div className="container" id="ficha_header">
                                    <div className="row justify-content-start">
                                        <div className="col-1">
                                            <img className="img-circle rounded-circle"
                                            src={`${CORE_URL}/static/images/${data.image}`} width="50" height="50" alt={"Imagen encargado"} />

                                        </div>
                                        <div className="col-11">
                                            <h3 className="modal-title" id="exampleModalCenterTitle">{data.name}

                                            <a className="px-2"
                                                href={`${CORE_URL}/users/${props.user}/?attrk=${GROUP_DASHBOARD_KEY}`}
                                                target="_blank" rel="noopener noreferrer">
                                                    <i className="fas fa-info-circle"></i>
                                                    {t("ver_mas_informacion")}</a>
                                            </h3>
                                        <span className="pr-2">{data.phone && `Tel: ${data.phone}`} {data.address && `Dir: ${data.address}` }</span>
                                        </div>
                                    </div>
                                </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setShowUserModal(false)} >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <UserOption attributes={attributes} 
                                        setShowUserModal={props.setShowUserModal} 
                                        user={props.user} 
                                        setLoading={setLoading}
                                        fetchData={fetchData}
                                         />

                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default ModalEncargado
