import '../milestones-admin.css';
import swal from 'sweetalert';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback, useRef } from 'react';
import {
    getMilestonesConfigWithAdvanceSearch,
    getProgramById,
    getStages
} from 'services/milestones-programs.service';
import { ProgramI } from 'interfaces/ProgramResponse';
import Loading from 'components/Loading';
import { getAreas, getLanguages } from 'services/milestones-services';

/**
 * TODO:
 *
 * - detalle de configuracion
 * - borrar configuracion
 * - Asociar milestones
 */

type Params = {
    id: string
}

interface PayloadI {
    program_id: number;
    code?:  string;
    difficulty?: number;
    average?: number;
    minimum?: number;
    maximum?: number;
    areas?: string[];
    stage_id?: number;
    source?:number;
    question?:  string;
    alert?: number;
    language?: string;
}

const DetallePrograma = () => {
    const { id } = useParams<Params>();
    const [program, setProgram] = useState<ProgramI>();
    const [configuracion, setConfiguracion] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [payload, setPayload] = useState<PayloadI>({program_id: Number(id)})

    const [languages, setLanguages] = useState<any[]>([]);
    const [stages, setStages] = useState<any[]>([]);
    const [areas, setAreas] = useState<any[]>([]);

    const ref = useRef(false);

    const fetchData = useCallback(() => {
        if(!ref.current){
            getMilestonesConfigWithAdvanceSearch({program_id: id})
            .then( resp => {
                ref.current = true;
                setConfiguracion(resp.data)
            } )
            .catch(err => console.log(err)   )
            .finally(() => setLoading2(false))
        }
    },[id])

    useEffect(() => {

        async function getData(){
            try{
                const resp = await getProgramById(id);
                const respLanguages = await getLanguages();
                const respStages = await getStages(id);
                const respAreas = await getAreas();
                setLanguages(respLanguages.data.results);
                setStages(respStages.data.results);
                setAreas(respAreas.data.results);
                const {data} = resp;
                setProgram(data.results[0]);
            } catch(err:any){
                console.log(err)
            } finally {
                setLoading(false);
                ref.current = true;
            }
        }
        if(!ref.current){
            getData();
        }

        return function cleanUp(){
            ref.current = false;
        }

    }, [id]);

    useEffect(() => {
        if(!ref.current){
            fetchData();
        }

        return function cleanUp(){
            ref.current = false;
        }
    },[fetchData]);

    const handleSubmit = async (e:any) => {
        try{
            e.preventDefault();
            setLoading2(true);
            const resp = await getMilestonesConfigWithAdvanceSearch(payload);
            setConfiguracion(resp.data);
        } catch(err:any){
            console.log(err);
        } finally {
            setLoading2(false);
        }
    }

    const handleAsociarMilestones = () => {
        swal({
            title: "Se agregaran 108 hitos a tu programa",
            text: "",
            icon: "warning",
            buttons: ['Cancelar','Agregar'],
            dangerMode: true
        })
        .then((result) => {
            if(result){
                swal("Ready",{
                    icon: "success"
                });
            } else {
                swal("Sin cambios",{
                    icon: "success"
                });
            }
        });
    }

    const handleChangeFormInput = (evt:any) => {
        const newData = {
            ...payload,
            [evt.target.name]: evt.target.value
        }
        setPayload(newData);
    }

    const handleReset = () => {
        setLoading2(true);
        setPayload({program_id: Number(id)});
        fetchData()
    }


    if(loading){
        return <Loading />
    }


    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h3>{program?.name}</h3>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/admin/milestones/programas"
                        className="btn btn-secondary btn-sm">
                            <i className="fa fa-arrow-left"></i> Volver
                        </Link>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-8">
                        <h5><span className="mr-2">Configuración</span>
                            <Link to={`/admin/milestones/editar-programa/${id}/${program?.config.id}`} className="btn btn-warning btn-sm">
                                <i className="fa fa-edit"></i>
                            </Link>
                        </h5>
                        <p><b>Name: </b> {program?.config.name}</p>
                        <p><b>Algoritmo: </b> {program?.config.algorithm}</p>
                        {/*program &&  <p><b>Sources: </b> {program?.sources.map((item:any) => item.name).join(", ")}</p>*/}
                        
                    </div>
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <button
                                className="btn btn-outline-primary btn-sm btn-block"
                                onClick={handleAsociarMilestones}>Asociar milestones</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="separador my-3"></div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <div className="col-3">
                            <label htmlFor="">Code</label>
                            <input type="text" name="code" className="form-control"
                            value={payload.code || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Difficulty</label>
                            <input type="text" name="difficulty" className="form-control"
                            value={payload.difficulty || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Average</label>
                            <input type="text" name="average" className="form-control"
                            value={payload.average || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Minimum</label>
                            <input type="text" name="minimum" className="form-control"
                            value={payload.minimum || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Maximum</label>
                            <input type="text" name="maximum" className="form-control"
                            value={payload.maximum || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Areas</label>
                            <select name="areas" className="form-control"
                            value={payload.areas || ""}
                            onChange={handleChangeFormInput}>
                                <option value="">-- select value --</option>
                                {areas !== undefined && areas.map((item:any) => (
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Epatas (stage_id)</label>
                            <select name="stage_id" className="form-control"
                            value={payload.stage_id || ""}
                            onChange={handleChangeFormInput}>
                                <option value="">-- select value --</option>
                                {stages !== undefined && stages.map((item:any) => (
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Question</label>
                            <input type="text" name="question" className="form-control"
                            value={payload.question || ""}
                            onChange={handleChangeFormInput}/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Alert</label>
                            <select name="alert" className="form-control"
                            value={payload.alert || ""}
                            onChange={handleChangeFormInput}>
                                <option value="">-- select value --</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Lenguaje</label>
                            <select name="language" className="form-control"
                            value={payload.language || ""}
                            onChange={handleChangeFormInput}>
                                <option value="">-- select value --</option>
                                {languages !== undefined && languages.map((item:any) => (
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <div className="col" style={{ paddingTop: 35}}>
                            <button type="submit" className="btn btn-primary btn">Filtrar</button>
                            <button type="button" className="btn btn-secondary btn ml-2"
                            onClick={handleReset}>Limpiar</button>
                        </div>
                    </div>
                </form>
                {loading2 ? (
                    <Loading />
                ) : (
                    <div className="table-responsive">
                        <div className="text-right mb-4">{configuracion.length} Hitos</div>
                        <table className="table table-sm">
                            <thead>
                                <tr className="text-center">
                                    <th>Alerta</th>
                                    <th>Code</th>
                                    <th>Min</th>
                                    <th>Max</th>
                                    <th>Areas</th>
                                    <th>Average</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {configuracion.length === 0 ? (
                                    <tr className="text-center py-4">
                                        <td colSpan={7}>No hay datos.</td>
                                    </tr>
                                ) : (
                                    configuracion.map((item:any, index: number) => (
                                        <tr key={index}>
                                            <td className="text-center">{item.program_alert && (
                                                <i className="fa fa-exclamation-circle" style={{color: 'red'}}></i>
                                            )}</td>
                                            <td>{item.code}</td>
                                            <td>{item.minimum}</td>
                                            <td>{item.maximum}</td>
                                            <td>{item.areas.map((item:any) => item.name).join(", ")}</td>
                                            <td>{item.average}</td>
                                            <td className="text-right">
                                                <Link to={`/admin/milestones/editar-configuracion-milestones/${item.id}/program/${id}`}  className="btn btn-success btn-sm">
                                                    <i className="fa fa-eye"></i>
                                                </Link>

                                                <Link to={`/admin/milestones/delete-config/${item.id}/program/${id}`}  className="btn btn-danger btn-sm ml-2">
                                                    <i className="fa fa-trash"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DetallePrograma
