import {ToastContext} from "context/ToastContext";
import { useContext } from "react";
import { Toast } from "react-bootstrap"


const ToastContainer = () => {



    const { toasts, deleteToast } = useContext(ToastContext);

    return (
        <div
            style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex:100
            }}
        >
            {toasts.map((toast) => (
                <Toast
                    onClose={() => deleteToast()} 
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        minWidth: "200px"
                    }} 
                    className={`${toast.typeClass} text-white m-3`}
                    delay={3000}
                    autohide>
                    <Toast.Body>{toast.message}</Toast.Body>
                </Toast>
            ))}
            
        </div>)
}

export default ToastContainer
