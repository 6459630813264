import { useState, useEffect } from 'react';
import { getProgramAttributeTypes } from '../../data/programs-attribute-types';
import { ProgramAttributeTypes }  from '../../interfaces/Program';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';

const ConfigureProgramAttributeTypes = (props:any) => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ programAttributeTypes, setProgramAttributeTypes ] = useState<ProgramAttributeTypes[]>([]);
    const programId = props.programId;

    useEffect(() =>{
        getProgramAttributeTypes(programId)
        .then( (resp: any) => {
            setProgramAttributeTypes(resp.data.results);
            setLoading(false);
        })
        .catch( (err: any) => console.log(err));

    },[programId])
    
    if(loading){
        return <Loading />;
    }

    return (
        <>
            <div className="row">
                   <div className="card card flex-grow-1">
                       <div className="card-header bg-secondary text-white d-flex">
                            <h6 className="mr-auto d-inline-flex m-0 font-weight-bold align-self-center">Tipos de attributos</h6>
                            <Link to={`/admin/program_attribute_types/add/${programId}`} className="btn btn-outline-light btn-sm">
                            {/* 
                        // @ts-ignore */}    
                        <FontAwesomeIcon icon={faPlus} /> Nuevo
                            </Link>
                       </div>
                       <div className="card-body d-flex flex-wrap">
                        { programAttributeTypes && programAttributeTypes.length > 0 ? (
                            programAttributeTypes.map( (attributeType) => (
                                <div className="col-xs-12 col-md-4 my-1" key={attributeType.id}>
                                    <div className="card p-2" >
                                        <div className="card-header text-center">
                                            <h6 className="m-0">{ attributeType.name } </h6>
                                        </div>
                                        <div className="info-attributeType" style={{ padding: "10px"}}>
                                            <p className="mb-1"><b>id: </b>{attributeType.id}</p>
                                            <p className="mb-1"><b>entity: </b> { attributeType.entity}</p>
                                            <p className="mb-1"><b>description: </b>{attributeType.description}</p>
                                            <p className="mb-1"><b>weight: </b> { attributeType.weight}</p>
                                            { attributeType.attributes_set && attributeType.attributes_set.length > 0 && ( 
                                                <div className="row flex-wrap">
                                                    {
                                                        attributeType.attributes_set.map((attribute) => (
                                                            <p key={attribute.id} className="badge badge-secondary m-1 d-flex" style={{ overflow: 'hidden'}}> 
                                                                <span className="text-truncate d-block">{attribute.label}</span>
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                            )}
                                            <div className="row mt-2">
                                                <div className="col mt-2">
                                                    <Link
                                                    to={`/admin/program_attribute_types/edit/${attributeType.id}/program/${programId}`}
                                                    title="Editar tipo de attributo"
                                                    className="btn btn-outline-primary btn-block">{/* 
                                                // @ts-ignore */}
                                                        <FontAwesomeIcon icon={faEdit} /> <span>Editar</span>
                                                    </Link>
                                                </div>
                                                <div className="col mt-2">
                                                    <Link
                                                    to={`/admin/program_attribute_types/${attributeType.id}/delete`}
                                                    title="Borrar tipo de attributo"
                                                    className="btn btn-outline-danger btn-block">{/* 
                                                // @ts-ignore */}
                                                        <FontAwesomeIcon icon={faTrash} /> <span>Borrar</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mt-2">
                                                    <Link
                                                    to={`/admin/program_attributes/configure/${attributeType.id}`}
                                                    title="Configurar atributos"
                                                    className="btn btn-outline-info btn-block btn-block">{/* 
                                                // @ts-ignore */}
                                                        <FontAwesomeIcon icon={faCog} /> <span>Configurar attributos</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-xs-12 col-md-12">
                                <div className="alert alert-info">Aún no tiene tipos de atributos.</div>
                            </div>
                        )}
                       </div>
                   </div>
               </div>
        </>
    )
}

export default ConfigureProgramAttributeTypes
