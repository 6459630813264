import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_CM;

export const getLicenseOptions = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/licenses/?options=true`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


