import * as Yup from "yup";
import swal from "sweetalert";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Sequence } from "interfaces/Sequence";
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import { getSequenceById, storeSequence, updateSequence } from "data/sequences";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
});

interface Props {
    id?: string
}

const AddSequencePage = ({ match }: RouteComponentProps<Props>) => {

    const history = useHistory();
    const id = match.params.id || '';
    const [edit, setEdit] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<Sequence>({ name: '', description: '' })

    const handleSubmit = async (values: any) => {
        try {
            if (!edit) {
                await storeSequence(values);
                await swal('Afinidata', "Secuencia creada", "success")
            } else {
                await updateSequence(id, values);
                await swal('Afinidata', "Secuencia editada", "success")
            }
            history.push('/admin/sequences');
        } catch (err:any) {
            if(err.response.data.name){
                if(err.response.data.name[0] === 'Ensure this field has no more than 100 characters.')
                swal('Afinidata', 'Max. 100 Caracteres', "error");
                return;
            }
            swal('Afinidata', 'Ocurrio un error al guardar, intente de nuevo', "error")
        }
    }

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (id) {
                getSequenceById(id)
                    .then((resp) => {
                        setInitialValues(resp.data);
                        setEdit(true);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

        return () => { mounted = false; }
    }, [id]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values,) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>{edit ? 'Editar' : 'Añadir'} secuencia</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name"
                                        className={`form-control ${formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        maxLength={100}
                                    />
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <textarea name="description" className="form-control"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary"
                                        type="submit">
                                        <i className="fa fa-save"></i>
                                        {!edit ? (
                                            <span className="ml-2">Guardar</span>
                                        ) : (
                                                <span className="ml-2">Actualizar</span>
                                            )}
                                    </button>
                                    <Link to="/admin/sequences" className="btn btn-secondary ml-2">
                                        <i className="fa fa-arrow-left"></i>
                                        <span className="ml-2">Volver</span>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSequencePage;
