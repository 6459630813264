import { useFormik } from 'formik';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from "react-router-dom"

import { getUserGroupById, getGroupPlatformUsers, getUserGroupsUserOptions, 
        createGroupPlatformUsers, editGroupPlatformUsers, getUserGroupsRoleOptions } from '../../data/user-groups';
import { GroupPlatformUsers, UserGroup } from 'interfaces/UserGroup';
import { Option } from 'interfaces/shared';
import Loading from '../../components/Loading';

const validationSchema = Yup.object().shape({
    group: Yup.number().required(),
    role: Yup.string().required(),
    user: Yup.number(),
});

interface IProps {
    groupId: string
    id?: string
}

const GroupPlatformForm = ({match}: RouteComponentProps<IProps>) => {
    const id = match.params.id || '';
    const groupId = match.params.groupId;
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [roleOptions, setRoleOptions] = useState<Option[]>([])
    const [userOptions, setUserOptions] = useState<Option[]>([])
    const [group, setGroup] = useState<UserGroup>({ id: '', name: '', programs:[]})
    const [initialValues, setInitialValues] = useState<GroupPlatformUsers>({group: groupId, role: '', user:''})

    useEffect(()=> {
        let mounted = true;

        if(mounted && loading){

            getUserGroupById(groupId)
            .then((resp:any) => {
                setGroup(resp.data);
            })
            .catch(err=> console.log(err));

            getUserGroupsUserOptions()
            .then((resp:any) => {
                setUserOptions(resp.data);
            })
            .catch(err=> console.log(err));

            getUserGroupsRoleOptions()
            .then((resp:any) => {
                setRoleOptions(resp.data);
            })
            .catch(err=> console.log(err));

            if(id){
                getGroupPlatformUsers(id, '', 1, false, false)
                .then((resp:any) => {
                    let data = resp.data.results[0];
                    data['user'] = data['user']['id']
                    setInitialValues(data);
                    setLoading(false);
                })
                .catch(err=> console.log(err));
            }else{
                setLoading(false);
            }
        }

        return function cleanUp(){
            mounted = false;
        }
    },[id, groupId, loading, initialValues])

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            if(id){
                await editGroupPlatformUsers(id, payload);
            }else{
                await createGroupPlatformUsers(payload);
            }
            await swal('Afinidata','Usuario del grupo fue creado correctamente','success');
            history.push(`/admin/user-groups/${groupId}`);
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Usuario de la plataforma</h4>
                            <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                    <label htmlFor="">Group: {group.name}</label>
                                    <input type="text" name="group"
                                     className={`form-control ${
                                        formik.errors.group && formik.touched.group ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.group}
                                    disabled={true}
                                    hidden={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.group && formik.touched.group ? (
                                        <div className="invalid-feedback">{formik.errors.group}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">User</label>
                                    <select name="user" className="form-control"
                                        defaultValue={formik.values.user}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {userOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Role</label>
                                    <select name="role" className="form-control"
                                        defaultValue={formik.values.role}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {roleOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href={`/admin/user-groups/${groupId}`} className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupPlatformForm
