import axios from 'axios';
const URL = process.env.REACT_APP_API_URL_CM

/** get programs list */
export const getProgramsList = (search = '', page = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs/?search=${search}&page=${page}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getProgramOptions = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs/?options=True`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getProgramPostList = (program_id: string, page: number = 1, params: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let extra_params = '';
            Object.keys(params).forEach(function (key) {
                if (params[key]) {
                    extra_params += `&${key}=${params[key]}`;
                }
            });

            const resp = await axios.get(`${URL}/programs_posts/?page=${page}&program=${program_id}${extra_params}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const detailProgram = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/programs/${id}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteProgram = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/programs/${id}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const bulkCreateRisks = (data: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/programs_attribute_types/bulk_risk_create/`, data)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const sendWelcomeMail = (data: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/group-platform-users/send_welcome_mail/`, data)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteFromProgram = (group: string, user: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            /*
                url = f'{os.getenv("PROGRAMS_API")}/user_group/unassigned_user/{assignation.user_id}/child/{assignation.messenger_user_id}?group={assignation.group.name}'
            */
            //user_data/<int:group_id>/<int:instance_id>/<int:user_id>
            const resp = await axios.get(`${process.env.REACT_APP_PROGRAMS_API}/user_group/delete_user/${group}/${user}/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const coreDeleteFromProgram = (group: string, user: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${process.env.REACT_APP_CORE_API}/${group}/remove_assignation/${user}/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const contentManagerDeleteProgram = (group: string, user: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/groups/delete_user?group_id=${group}&user_id=${user}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const report = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log('>>', process.env.REACT_APP_PROGRAMS_API)
            const resp = await axios.get(`${process.env.REACT_APP_PROGRAMS_API}/user_groups/list/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}