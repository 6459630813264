import axios from 'axios';
import { Sequence } from 'interfaces/Sequence';

const URL = process.env.REACT_APP_API_URL_HOTTRIGGERS

/**
 * description: obtener listado de secuencias para hot triggers
 * @param: search string
 * @param: page number
 */
export const getSequences = (search = '', page = 1): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/sequences/?search=${search}&page=1`);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const getSequenceOptions = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/sequences/?options=True`);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const storeSequence = (payload: Sequence): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/sequences/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const getSequenceById = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/sequences/${id}/`);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const updateSequence = (id: string, payload: Sequence): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/sequences/${id}/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const deleteSequence = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/sequences/${id}/`);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const updateHotTriggerPositions = (id: string, payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/sequences/${id}/update-positions/`, { hot_triggers: payload });
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}

export const getTagsList = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/hot_triggers_tags/`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}