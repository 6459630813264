import { deleteBot, detailBot } from 'data/bots';
import { Bot } from 'interfaces/Bot';
import { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from "react-router-dom"
import Loading from '../../components/Loading';
import swal from 'sweetalert';

interface IProps {
    id: string
}

const DeleteBot = ({match}: RouteComponentProps<IProps>) => {
    const id = match.params.id;
    const [bot, setBot ] = useState<Bot>({name: '',description:'', url:''});
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        detailBot(id)
        .then( resp => {
            setBot(resp.data)
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[id])

    const handleClick = async () => {
        try {
            await deleteBot(id);
            await swal('Afinidata','El bot fue borrado correctamente','success');
            history.push('/admin/bots');
        } catch(err:any){
            swal('Afinidata','Ocurrio un error al borrar el bot','error');
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas borrar el bot?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>Id:</b>{bot.id}</p>
                            <p><b>Name:</b> {bot.name}</p>
                            <p><b>Description:</b> {bot.description}</p>
                            <p><b>Url:</b> {bot.url}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                href="/admin/bots/">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteBot
