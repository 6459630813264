import '../style.css'
import moment from 'moment';
import Loading from '../../../components/Loading';
import { MessengerUserContext } from '../MessengerUserContextProvider';
import { getUltimasConversaciones } from 'data/live-chat';
import { FunctionComponent, useEffect, useState, useContext } from 'react';
import swal from 'sweetalert';
import socketIOClient from 'socket.io-client';

interface Props {
    bot: string
}

const UserList: FunctionComponent<Props> = ({ bot }) => {
    const [lists, setLists] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [option, setOption] = useState("");
    const [next, setNext] = useState<string | null>(null);
    const [prev, setPrev] = useState<string | null>(null);
    const { setMessengerUser } = useContext(MessengerUserContext);

    useEffect(() => {
        let mounted = true;
        const endpoint = process.env.REACT_APP_NOTIFICATIONS_API || "";
        let socket = socketIOClient(endpoint, { rejectUnauthorized: false, transports: ["websocket"] });

        if (mounted) {
            async function reloadData(response: any) {
                const { bot: botUpdated } = response;
                if (String(botUpdated) === bot) {
                    setLoading(true);
                    const liveChat = localStorage.getItem('liveChat') || '';
                    const searchText = localStorage.getItem('searchText') || '';
                    const resp: any = await getUltimasConversaciones(bot, searchText, currentPage, liveChat);
                    const data = resp.data
                    setNext(data.next);
                    setPrev(data.previous);
                    if (data.results) {
                        let currentUser = JSON.parse(localStorage.getItem('currentUserSelected') || data.results[0])
                        setLists(data.results.map((item: any) => item.id === currentUser.id ? { ...item, active: true } : { ...item, active: false }));
                    }
                    setLoading(false);
                }
            }

            socket.on(bot, reloadData);
            socket.on("logs", (data:any) => {
                console.log("ejecuto un evento log");
                console.log(data);
            });
        }

        return () => {
            mounted = false
            localStorage.removeItem('liveChat');
            localStorage.removeItem('searchText');
            localStorage.removeItem('currentUserSelected')
            socket.disconnect();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                try {
                    setLoading(true);
                    setLists([]);
                    setMessengerUser({});
                    const resp: any = await getUltimasConversaciones(bot);
                    const data = resp.data
                    setNext(data.next);
                    setPrev(data.previous);
                    if (data.results) {
                        setLists(data.results.map((item: any, idx: number) => idx === 0 ? { ...item, active: true } : { ...item, active: false }));
                        let currentUser = { ...data.results[0], active: true };
                        localStorage.setItem('currentUserSelected', JSON.stringify(currentUser))
                        setMessengerUser(currentUser || {});
                    }
                    setLoading(false);
                } catch(err:any) {
                    console.log(err)
                }
            }

            fetchData();
        }

        return () => {
            mounted = false
        }
    }, [bot, setMessengerUser]);

    const handlePageChange = (page: number) => {
        clearResults();
        setLoading(true);
        setCurrentPage(page);
        let liveChat: string = "";

        if (option === 'inbox') {
            liveChat = "True";
        } else if (option === 'done') {
            liveChat = "False";
        } else {
            liveChat = "";
        }

        getUltimasConversaciones(bot, search, page, liveChat)
            .then((resp: any) => {
                const data = resp.data
                setNext(data.next);
                setPrev(data.previous);
                const results = data.results;
                if (results.length > 0) {
                    setLists(results.map((item: any, idx: number) => idx === 0 && item.bot_channel_id ? { ...item, active: true } : { ...item, active: false }));
                    let currentUser = { ...data.results[0], active: true };
                    localStorage.setItem('currentUserSelected', JSON.stringify(currentUser))
                    setMessengerUser(currentUser || {});
                }
                setLoading(false)
            })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setCurrentPage(1);
        clearResults();

        let liveChat: string = "";

        if (option === 'done') {
            liveChat = "False";
        } else if (option === 'inbox') {
            liveChat = "True";
        } else {
            liveChat = "";
        }

        localStorage.setItem('liveChat', liveChat);
        localStorage.setItem('searchText', search);

        getUltimasConversaciones(bot, search, 1, liveChat)
            .then((resp: any) => {
                const data = resp.data
                setNext(data.next);
                setPrev(data.previous);
                const results = data.results;
                if (results.length > 0) {
                    setLists(results.map((item: any, idx: number) => idx === 0 && item.bot_channel_id ? { ...item, active: true } : { ...item, active: false }));
                    let currentUser = { ...data.results[0], active: true };
                    localStorage.setItem('currentUserSelected', JSON.stringify(currentUser))
                    setMessengerUser(currentUser || {});
                }
                setLoading(false)
            })
    }

    const handleItemClick = (item: any) => {
        if (!item.bot_channel_id) {
            swal("Afinidata", 'Bot channel id no existe', 'error');
            return;
        }
        let currentUser = { ...item, active: true };
        localStorage.setItem('currentUserSelected', JSON.stringify(currentUser))
        setMessengerUser(currentUser);
        const newData = lists.map(d => d.id === item.id && item.bot_channel_id ? { ...d, active: true } : { ...d, active: false });
        setLists(newData);
    }

    const clearResults = () => {
        setMessengerUser({});
        setLists([]);
    }

    return (
        <div>
            <form action="" onSubmit={handleSearch}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-search"></i></span>
                            </div>
                            <input type="text" className="form-control"
                                placeholder="Search by user name"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <select name="option" id="option"
                            className="form-control"
                            value={option}
                            onChange={(e: any) => setOption(e.target.value)}>
                            <option value="">Todos</option>
                            <option value="inbox">Pausados</option>
                            <option value="done">Pausados últimos 30 días</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-primary btn-block" type="submit">
                            <i className="fa fa-search"></i> Buscar
                        </button>
                    </div>
                </div>
            </form>

            <div className="row">
                <div className="col-md-12">
                    {loading ? (
                        <Loading size="2rem" text="Cargando conversaciones..." />
                    ) : (
                        <>
                            <div
                                className="conversations">
                                {lists.length === 0 &&
                                    <div className="alert alert-danger">No hay conversaciones</div>
                                }

                                {lists.length > 0 &&
                                    <ul className="list-group">
                                        {lists.map((item: any, index: number) => (
                                            <li className={`list-group-item single-message ${item.active ? 'active-message' : ''}`}
                                                key={index}
                                                onClick={() => handleItemClick(item)}>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <img src={item.profile_pic === '' ? '/img/no-pic.jpg' : item.profile_pic} alt=""
                                                            className="img-users" />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="font-weight-bold">{item.first_name} {item.last_name}</p>
                                                        <p className="text-muted small mb-2">{item.last_message.slice(0, 50)}</p>
                                                        <p className="text-muted small text-right text-uppercase">{moment(item.last_user_message).fromNow()}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </div>
                            <div className="mt-2 text-center">
                                {prev &&
                                    <button className="btn btn-outline-primary"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >Anterior</button>
                                }

                                {next &&
                                    <button className="btn btn-outline-primary ml-2"
                                        onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                                }
                            </div>
                        </>
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export default UserList
