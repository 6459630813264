import { useEffect, useState, useRef } from 'react';
import { createTrainingIntents } from '../../../data/intents';
import Loading from '../../../components/Loading';
import * as XLSX from "xlsx";

const SetTrainingModal = () => {
    const fileInputRef = useRef() as any;
    const [messageSuccess, setMessageSuccess] = useState<string>('');
    const [messageErr, setMessageErr] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [file, setFile] = useState({});

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(false);
        }
        return () => { mounted = false; };

    }, []);

    const handleInputFileChange = (e: any) => {
        setName(e.target.files[0].name || '');
        readExcel(e.target.files[0]);
    }

    const clickButton = () => {
        clearData();
        fileInputRef.current.click();
        
    }

    const clearData = () => {
        fileInputRef.current.value = '';
        setFile({});
        setMessageSuccess('');
        setMessageErr('');
        setName('');
    }

    const validateData = (data: any) => {

        if(data.length ===0){
            setMessageErr('Archivo vacío');
            return ;
        }

        const errorList: any = data.filter( (row: any) => {
            // Se valida que existan las columnas id y url, ya además que la columna no esté vacía
            return !('intent' in row && 'text' in row && row.intent !== '' && row.text !== '');
        });

        errorList.length !== 0 ? setMessageErr(`Errores en las filas: ${createFileErrorList(errorList)}`) : setFile(data);
    }

    const createFileErrorList  = (errorList: any) => {
        // Se aumenta en 1 para indicar la fila exacta en el archivo excel
        return errorList.map((row: any) => row.__rowNum__+1 ).toString();
    }

    const readExcel = (errorList: any) => {
    
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(errorList);

        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            validateData(data);
        }
    }

    const sendData = async ( ) => {
        setLoading(true);
        const resp: any = await createTrainingIntents({ trains: file })

        if (resp.status !== 201) {
            let textError =  resp.data.succeed.lenght > 0 ? 'Se actualizaron los posts, pero fue imposible actualizar los siguientes:' : 'Se generaron los siguientes errores:';
            setMessageErr(textError);
            setLoading(false);

        }
        else {
            setMessageSuccess('Se actualizaron todos los posts exitosamente');
            setLoading(false);
        }
    }

    return (
        <div className="modal fade in" id="trainingModal" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Actualización de posts</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    { loading ? 
                            <Loading size="2rem" /> 
                        : 
                        <>
                            <div className="modal-body text-center">
                                {messageSuccess ? (
                                <div className="alert alert-success" role="alert">{messageSuccess}</div>
                                ): (null)}
                                {messageErr ? (<div className="alert alert-danger" role="alert">{messageErr}</div>) : (null)}
                                <a className="btn btn-primary btn-sm mb-4" href="/intentsCreateFormat.xlsx" download>
                                    <i className="fa fa-download"></i> Descargar formato
                                </a>
                                
                                {name && <h4>{name}</h4>}
                        
                                <form>
                                    <input type="file"
                                    onChange={(e) => handleInputFileChange(e)}
                                    ref={fileInputRef}
                                    accept=".xlsx"
                                    style={{ display: 'none' }}/>
                                </form>

                                <div className="mt-4">
                                    <button className="btn btn-warning btn-md ml-2"
                                    onClick={() =>  clickButton() }>
                                        Seleccionar archivo
                                    </button>
                                </div>
                                        
                                
                                
                            </div>

                            <div className="modal-footer">

                                <button type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => clearData() }>Cerrar</button>

                                <button type="button"
                                    className={'btn btn-primary'}
                                    disabled={Object.keys(file).length === 0}
                                    onClick={ () => sendData()}
                                    >Actualizar</button>
                                
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default SetTrainingModal
