import axios from 'axios';
import { AuthGroup } from 'interfaces/AuthGroup';

const URL = process.env.REACT_APP_API_URL_AUTH;

// ROLES
export const ROLE_PARTNER_PROGRAM_MANAGER = Number(process.env.REACT_APP_PARTNER_PROGRAM_MANAGER);
export const ROLE_PARTNER_FIELD_PROFESSIONAL = Number(process.env.REACT_APP_PARTNER_FIELD_PROFESSIONAL);

/**
 * description: obtener los grupos
 * @param: search string
 * @param: page number
 */
export const getAuthGroups = (search = '', page = 1): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/groups/?search=${search}&page=${page}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const createAuthGroup = (payload: AuthGroup): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/groups/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const getAuthGroupById = (id: string):Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.get(`${URL}/groups/${id}`)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const editAuthGroup = (id:string, payload: AuthGroup): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.put(`${URL}/groups/${id}/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const bulkUserCreate = (payload: Array<Object>): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/users/bulk_create/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}

export const bulkUserStartMessageSend = (payload: Array<Object>): Promise<any> => {
    return new Promise(( resolve , reject) => {
        axios.post(`${URL}/users/send_user_start_message/`,payload)
        .then( resp =>{
            resolve(resp)
        }).catch( err => {
            reject(err);
        })
    });
}
