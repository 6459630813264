import { CoreRequest } from "api/CoreRequest";
import { ProgramsRequest } from "api/ProgramsRequest"
import { InstanceData } from "interfaces/Instance";

export const getGroupsListByUser = (page = 1, search="") => {
    return ProgramsRequest.get(`/user_group/?page=${page}&search=${search}`);
}

export const getUserGroupsChildren = (id:any, page = 1, search = "", type?: string | null, type_id?:number | null, months?:string) => {
    let extraParams = ""
    if (type) extraParams = `&${type}=${type_id}`
    if (months) extraParams += `&months=${months}`
    return ProgramsRequest.get(`/user_group/${id}/children/?search=${search}&page=${page}${extraParams}`)
}

export const getGroupSummary = (id: string) => {
    return ProgramsRequest.get(`/user_group/${id}/summary`);
}

export const getInstance = (group: string, id:number) => {
    return ProgramsRequest.get(`/user_group/instance_data/${group}/${id}`);
}

export const updateInstance = (id:number, instance: InstanceData) => {
    return CoreRequest.put(`/instance_only/${id}/`, instance);
}

export const getUserData = (group: string, instance: number, user: number) => {
    return ProgramsRequest.get(`/user_groups/user_data/${group}/${instance}/${user}`);
}

export const getAllGroupsPROGRAMS = async (data: any) => {
    try {
        const response = await ProgramsRequest.get('/user_group/');
        return response;
    } catch (error) {
        throw error;
    }
};

export const createNewGroupPROGRAMS = async (data: any) => {
    try {
        const response = await ProgramsRequest.post('/user_group/', data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const editGroupPROGRAMS = async (groupId: number, data: any) => {
    try {
        const response = await ProgramsRequest.put(`/user_groups/${groupId}/update/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProgramNameByGroupId = async (groupId: number) => {
    try {
        const response = await ProgramsRequest.put(`/user_groups/get_program_name_by_group_id/${groupId}`);
        return response;
    } catch (error) {
        throw error;
    }
};