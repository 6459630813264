import { deleteGroupBotUser, getGroupBotUsers } from 'data/user-groups';
import { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from "react-router-dom"
import Loading from '../../components/Loading';
import swal from 'sweetalert';

interface IProps {
    groupId: string
    id?: string
}

const DeleteGroupBotUser = ({match}: RouteComponentProps<IProps>) => {
    const id = match.params.id || '';
    const groupId = match.params.groupId;
    const [data, setData ] = useState<any>({user:{'id':'', 'first_name':'', 'last_name':''}});
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        getGroupBotUsers(id, '', 1, true, false)
        .then( resp => {
            setData(resp.data.results[0])
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[id])

    const handleClick = async () => {
        try {
            await deleteGroupBotUser(id);
            await swal('Afinidata','El ususario fue eliminado del grupo correctamente','success');
            history.push(`/admin/user-groups/${groupId}`);
        } catch(err:any){
            swal('Afinidata','Ocurrio un error al eliminar el usuario del grupo','error');
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas eliminar al usuario de este grupo?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>User Id:</b>{data.user.id}</p>
                            <p><b>User:</b> {data.user.first_name} {data.user.last_name}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                href={`/admin/user-groups/${groupId}`}>Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteGroupBotUser
