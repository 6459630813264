import axios from 'axios';
import { Filtro } from 'interfaces/Filtro';

const URL = process.env.REACT_APP_API_URL_CM;

/**
 * Description: Listar instances
 * author: Jose Quintero
 * @param search
 * @param page
 */
export const getInstances = (search = "", page: number = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/instances/?search=${search}&page=${page}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/**
 * Description: Busqueda avanzada en instances
 * Autho: Jose Quintero
 * @param filtros
 * @param search
 * @param page
 */
export const advanceSearchForInstances = (filtros: Filtro[], search = "", page = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/instances/advance_search/?search=${search}&page=${page}`, { filtros });
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getInstancesByUser = (userId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/instances?user_id=${userId}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
