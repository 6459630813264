import { useFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { useState, useEffect } from 'react';
import { Entity } from 'interfaces/Entity';
import { addEntity, updateEntity } from 'data/entities';
import { Attribute } from 'interfaces/Attribute';
import { ChangeEvent } from 'react';
import { getAttributes } from 'data/attributes';
import Loading from 'components/Loading';


type AddEntityProps = {
    id?: Number,
    nameBtn?: string,
    entidad?: Entity,
    type: string
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required()
});

const attributeview:Record<number, string> = {
    0: "service",
    1: "data"
}

const EntityAddEdit = ({ type, nameBtn, entidad }: AddEntityProps) => {

    const [ entity, setEntity ] = useState<Entity>({name:'',description:''});
    const [ attributes, setAttributes ] = useState<Attribute[]>([]);
    const [ attributeSelectTmp, setAttributeSelectTmp ] = useState<Number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const nameInit = entidad !== undefined ? entidad.name : "";
    const descriptionInit = entidad !== undefined ? entidad.description : "";

    const formik = useFormik({
        initialValues: {
            ...entity,
            name:nameInit,
            description:descriptionInit
        },
        validationSchema,
        enableReinitialize: false,
        onSubmit: (values,{resetForm}) => {
            handleFormSubmit(values);
            if(type === "add"){
                resetForm()
                setEntity({name:'',description:'',attributes:[]})
                setAttributeSelectTmp(Number(attributes[0].id))
            }
        }
    });


    useEffect(() => {
        let mounted = true;
        setAttributeSelectTmp(0)

        entidad !== undefined ? setEntity(entidad) : setEntity({name:'',description:''})

        getAttributes()
            .then( resp => {
                if(mounted){
                    setAttributes(resp.data)
                    const attrTmp = resp.data
                    setAttributeSelectTmp(attrTmp !== undefined && attrTmp.length !== 0 ? Number(attrTmp[0].id) : 0)
                    setLoading(false);
                }
            })
    },[entidad])

    const handleFormSubmit = async (values:any) => {
        
        try{
            const params = {...entity,name:values.name,description:values.description}
            if(type==="add"){
                await addEntity(params);
                swal('Afinidata','Entidad Creada!','success')
            }else if(entity.id !== undefined && type==="edit") {
                await updateEntity(entity.id,params);
                swal('Afinidata','Entidad actualizada!','success')
            }else 
                throw new Error('No existe el id requerido');
            
        } catch(err:any){
            console.log(err);
            swal('Afinidata','Ocurrio un error, revise que el nombre no exista. Intente de nuevo','danger')
        }

    }

    const handleChangeAttr = (event:ChangeEvent<HTMLSelectElement>) => {
        setAttributeSelectTmp(Number(event.target.value))
    }

    const addAttr = () => {
        const attrGetter = attributes.find(attr => Number(attr.id) === attributeSelectTmp)
        const attrRepetionCount = (entity.attributes !== undefined ? entity.attributes : []).filter(attr => Number(attr.id) === attributeSelectTmp).length
        const attrTmp = entity.attributes !== undefined ? entity.attributes : []
        if(attrGetter !== undefined && attrRepetionCount === 0){
            attrTmp.push(attrGetter);
            setEntity({...entity,attributes: attrTmp })
        }
    }

    const deleteAttr = (id:Number = 0) => {
        const attrTmp = entity.attributes !== undefined ? entity.attributes : []
        const attrNew = attrTmp.filter(attr => Number(attr.id) !== id)
        setEntity({...entity,attributes: attrNew })
    }

    if(loading){
        return <Loading />
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4>Crear nuevo Entity</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" name="name"
                                    value={formik.values.name}
                                    className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                {formik.errors.name && formik.touched.name ? (
                                    <div className="invalid-feedback">{formik.errors.name}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-group">
                                <label>Descripción</label>
                                <input type="text" name="description"
                                    value={formik.values.description}
                                    className={`form-control ${
                                        formik.errors.description && formik.touched.description ? "is-invalid" : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                {formik.errors.description && formik.touched.description ? (
                                    <div className="invalid-feedback">{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </div>
                        
                        <div className="form-inline">
                            <div className="form-group">
                                <label>Propiedades</label>
                                
                                <select
                                    name="color"
                                    className={"form-control select-add-attr"}
                                    value={String(attributeSelectTmp)}
                                    onChange={handleChangeAttr}
                                    style={{ display: 'block' }}
                                >
                                    {
                                        attributes.map( attr =>(
                                            <option key={attr.id} value={attr.id} label={attr.name} />
                                        ))
                                    }

                                </select>
                                <button type="button" className={"btn btn-secondary btn-add-attr"} onClick={()=>{addAttr()}}>Agregar</button>
                                {formik.errors.description && formik.touched.description ? (
                                    <div className="invalid-feedback">{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="row mt-4">
                            <table className="table" width="100%" id="data">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Tipo</th>
                                        <th>Vista</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entity.attributes !== undefined && entity.attributes.map( item => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.type}</td>
                                            <td>{attributeview[Number(item.attribute_view)]}</td>
                                            <td className="text-right">
                                                <a href={`/admin/attributes/${item.id}/show`} className="btn btn-warning btn-sm ml-1"><i className="fa fa-edit"></i></a>
                                                <button type="button" onClick={ ()=>{ deleteAttr(Number(item.id)) }} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right">
                            <button className="btn btn-primary" type="submit"><i className="fas fa-plus-circle"></i> {nameBtn} </button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    )

}

export default EntityAddEdit;