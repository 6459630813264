import Loading from "components/Loading";
import { useTranslation } from "react-i18next"

const Dashboard = () => {

    const {ready, t} = useTranslation();

    if(!ready){
        return <Loading />
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3><i className="fa fa-home"></i> Home</h3>
                </div>
                <div className="card-body"><p>{t("welcome")}</p></div>
            </div>
        </>
    )
}

export default Dashboard
