import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_WEBHOOK

/**
 * description: obtener listado de bots
 * @param: search string
 * @param: page number
 * */
export const getBotsList = (search = '', page = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/bots/?search=${search}&page=${page}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getBotOptions = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/bots/?options=True`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const addBot = (payload: object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/bots/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const detailBot = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/bots/${id}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteBot = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/bots/${id}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
