import ListComponent, {IColumn} from "components/ListComponent"
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getActivitiesByProgram } from "services/content-manager.service"
import AddActivityComponent from "./AddActivityComponent";

interface RouteParams {
    id: string
  }

const ListActivitiesContainer = () => {
    const [data, setData] = useState<any>(null)
    const [search, setSearch] = useState<string>("")
    const [page, setPage] = useState<number>(1)

    const params = useParams<RouteParams>();

    const handleGetList = useCallback((search: string, page: number) => {
        const activityEndPoint = getActivitiesByProgram(Number(params.id) , search, page)
        setSearch(search)
        setPage(page)
        activityEndPoint.then(({data}) => {
            const { results } = data;
            setData(results)
        })
        return activityEndPoint
    }, [params.id])

    const handleAddActivity = () => {
        handleGetList(search, page)
    }
    
    const columns: IColumn[] = [
        {
            label: 'id',
            value: 'id'
        },
        {
            label: 'Nombre',
            value: "name"
        },
        {
            label: 'Min',
            value: "min_range"
        },
        {
            label: 'Max',
            value: "max_range"
        } 
    ]
    return (
        <ListComponent getList={handleGetList} data={data}
            columns={columns} title={"Actividades de Programa"}
            headerRight={(<AddActivityComponent program={Number(params.id)} onAddedActivity={handleAddActivity}/>)}
         ></ListComponent>
    )
}

export default ListActivitiesContainer