import { MobileAppRequest } from "api/MobileAppRequest";

export const sendPushNotification = async (payload:any) => {
    return await MobileAppRequest.post(`api/v1/user/send_push/`, payload);
}

export const makeFutureUserPremium = async (payload:any) => {
    return await MobileAppRequest.post(`api/v1/user/check_email_for_sub/`, payload);
}

export const getFutureUserPremium = async () => {
    return await MobileAppRequest.get(`api/v1/user/get_check_email_for_sub/`);
}

export const deleteUserPremium = async (payload:any) => {
    return await MobileAppRequest.post(`api/v1/user/delete_email_for_sub/`, payload);
}


