import { RouteComponentProps, useHistory, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Usuario } from "interfaces/Usuario";
import { deleteUser, getUserById, updateUser } from "data/users";
import { getAuthGroups } from "data/auth-groups";
import { AuthGroup } from "interfaces/AuthGroup";
import { Permission } from "interfaces/Permission";
import { getPermissions } from "data/permissions";
import * as Yup from "yup";
import { useFormik } from 'formik';
import swal from "sweetalert";
import Loading from '../../components/Loading';

type Props = {
    id: string
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    username: Yup.string().required(),
    groups: Yup.array().required()
});

const UserDetail = ({match}:RouteComponentProps<Props>) => {
    const id = match.params.id;
    const [user, setUser ] = useState<Usuario>({first_name: '', last_name:'', email:'',username:'',groups:[], user_permissions:[], is_active: false})
    const [groups, setGroups] = useState<AuthGroup[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        let mounted = true;

        getUserById(id)
        .then( resp => {
            if(mounted){
                setUser(resp.data);
                setLoading(false);
            }
        })
        .catch( err => console.log(err));

        getAuthGroups()
        .then( resp => setGroups(resp.data.results))
        .catch( err => console.log(err));

        getPermissions()
        .then( resp => setPermissions(resp.data.results))
        .catch(err => console.log(err));

        return function cleanUp(){
            mounted = false;
        }

    },[id]);

    const handleFormSubmit = async (values:any) => {
        try{
            await updateUser(id, values);
            swal('Afinidata','Usuario actualizado!','success')
            .then( () => {
                history.push('/admin/users');
            })
        } catch(err:any){
            console.log(err);
            swal('Afinidata','Ocurrio un error. Intente de nuevo','danger')
        }
    }

    const formik = useFormik({
        initialValues: {
            ...user,
            user_permissions: user.user_permissions?.map(String)
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleSelectAll = () => {
        const el = document.querySelectorAll('input[name=user_permissions]') as NodeListOf<HTMLInputElement>;
        el.forEach( item => item.checked = true);
        setUser({
            ...user,
            user_permissions: permissions.map( item => item.id )
        })
    }

    const handleUnselectAll = () => {
        const el = document.querySelectorAll('input[name=user_permissions]') as NodeListOf<HTMLInputElement>;
        el.forEach( item => item.checked = false);
        setUser({
            ...user,
            user_permissions: []
        })
    }

    const handleDeleteUser = async() => {
        try{
            await deleteUser(id);
            swal("Afinidata","Usuario desactivado","success");
            history.push("/admin/users");
        }catch(err:any){
            console.log(err)
        }
    }

    if(loading){
        return <Loading />
    }

    return (
        <div>
           <div className="card">
               <div className="card-header">
                   <h4>Editar usuario</h4>
               </div>
               <div className="card-body">
                   <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-2">Username</div>
                        <div className="col-md-4">
                            <input type="text" name="username"
                            value={formik.values.username}
                            className={`form-control ${
                                formik.errors.username && formik.touched.username ? "is-invalid" : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}/>
                            {formik.errors.username && formik.touched.username ? (
                                <div className="invalid-feedback">{formik.errors.username}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row bg-gradient-primary text-white mt-4 p-2">
                            Info Personal
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-2">Email</div>
                        <div className="col-md-4">
                            <input type="email" name="email"
                            value={formik.values.email}
                            className={`form-control ${
                                formik.errors.email && formik.touched.email ? "is-invalid" : ""
                            }`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}/>
                            {formik.errors.email && formik.touched.email ? (
                                <div className="invalid-feedback">{formik.errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-2">First name</div>
                        <div className="col-md-4">
                            <input type="text" name="first_name"
                            value={formik.values.first_name}
                            className="form-control"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-2">Last name</div>
                        <div className="col-md-4">
                            <input type="text" name="first_name"
                            value={formik.values.last_name}
                            className="form-control"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <label htmlFor="">
                                <input type="checkbox"
                                name='is_active'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultChecked={user.is_active ? true : false}/>
                                <span className="ml-2">Activo</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-2">Groups</div>
                        <div className="col-md-4">
                            <select name="groups" id="groups"
                            className={`form-control ${
                                formik.errors.groups && formik.touched.groups ? "is-invalid" : ""
                                }`}
                            value={formik.values.groups}
                            multiple={true}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}>
                                {groups.map((item, index) => (
                                    <option value={item.id} key={index}>{item.name}</option>
                                ))}
                            </select>
                            {formik.errors.groups && formik.touched.groups ? (
                                <div className="invalid-feedback">{formik.errors.groups}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row bg-gradient-primary text-white mt-4 p-2">
                            Permisos
                    </div>
                    <div className="row my-3">
                        <div className="col-md-12 text-right">
                            <button className="btn btn-primary btn-sm"
                            type="button"
                            onClick={handleSelectAll}>Seleccionar todos</button>
                            <button className="btn btn-danger btn-sm ml-2"
                            type="button"
                            onClick={handleUnselectAll}>Quitar todos</button>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            {permissions.length > 0 &&
                                <div className="permisos"
                                style={{ maxHeight: "350px", overflowY: 'scroll', overflowX:'hidden'}}>
                                    <div className="row">
                                        {permissions.map( (item) => (
                                            <div className="col-md-4" key={item.id}>
                                                <label htmlFor="">
                                                    <input type="checkbox"
                                                    name="user_permissions"
                                                    value={item.id}
                                                    defaultChecked={user.user_permissions?.includes(item.id) ? true : false}
                                                    onChange={formik.handleChange}
                                                    />
                                                    <span className="ml-2">{item.id} - {item.codename}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mt-5 row">
                            <div className="col-md-6 col-sm-12">
                                <button
                                onClick={handleDeleteUser}
                                type="button"
                                className="btn btn-danger"><i className="fa fa-trash"></i> delete</button>
                            </div>
                            <div className="col-md-6 col-sm-12 text-right">
                                <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                <Link to="/admin/users" className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</Link>
                            </div>
                    </div>
                   </form>
               </div>
           </div>
        </div>
    )
}

export default UserDetail;
