import { getInstanceAttributesById, updateInstanceAttribute } from "data/instance-attributes";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import './people.css';
import Loading from '../../components/Loading';

interface Iprops {
    instanceId: string
}

interface attribute {
    id: number,
    value: string,
    name: string
}

const PeopleModalInstance:FunctionComponent<Iprops> = ({instanceId}) => {
    const [values, setValues] = useState<attribute[]>([]);
    const [search, setSearch] = useState('');
    const [messageSuccess, setMessageSuccess] = useState<string>('');
    const [messageErr, setMessageErr] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback( async() => {
        try{
            setLoading(true);
            const resp:any = await getInstanceAttributesById(instanceId);
            setValues(resp.data.results);
            setLoading(false);
        } catch(e){
            console.log(e);
        }
    },[instanceId])

    useEffect(() => {
        let mounted = true;
        if(mounted){
            fetchData();
        }

        return () => { mounted = false; }

    },[fetchData])

    const handleInputChange = (e:any, item:any) => {
        const data = values.map( v => v.id === item.id ? {...v, value: e.target.value} : v);
        setValues(data);
    }

    const handleInputBlur = async (e:any, item: any) => {
        try{
            await updateInstanceAttribute(item.id,e.target.value);
            setMessageSuccess('Atributo editado correctamente');
            setTimeout(() => {
                setMessageSuccess("");
            },2000)
        } catch(e){
            console.log(e);
            setMessageErr('Ocurrio un error al actualizar, intente de nuevo.');
            setTimeout(() => {
                setMessageErr("");
            },2000)
        }
    }

    const handleSearch = async (e:any) => {
        try{
            setLoading(true);
            e.preventDefault();
            const resp:any = await getInstanceAttributesById(instanceId, search);
            setValues(resp.data.results);
            setLoading(false);
        } catch(err:any){
            console.log(err);
        }
    }

    const handleResetSearch = () => {
        setSearch("");
        fetchData();
    }

    return (
        <>
            <div className="modal fade" id="instanceModal" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Editar attributes de instance ID: {instanceId}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSearch} autoComplete="off">
                                <div className="form-group">
                                    <input
                                    type="text"
                                    name="search"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e) =>setSearch(e.target.value)}
                                    className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-sm mr-2"
                                    type="submit"><i className="fa fa-search"></i></button>
                                    <button className="btn btn-danger btn-sm"
                                    onClick={handleResetSearch}><i className="fa fa-eraser" aria-hidden="true"></i></button>
                                </div>
                            </form>

                            {messageSuccess && <div className="alert alert-success small">{messageSuccess}</div>}
                            {messageErr && <div className="alert alert-danger small">{messageErr}</div>}

                            <div className="card-form-body">
                                { loading && <Loading size="2rem" />}
                                { !loading &&
                                    <form>
                                        {values.length === 0 && (
                                            <div className="alert alert-info">No hay data.</div>
                                        )}

                                        {values.map((item:any) => (
                                            <div className="form-group" key={item.id}>
                                                <label htmlFor="">{item.attribute.name}</label>
                                                <input type="text"
                                                name={item.attribute.name}
                                                className="form-control"
                                                value={item.value}
                                                onChange={(e) => handleInputChange(e, item)}
                                                onBlur={(e) => handleInputBlur(e, item)}/>
                                            </div>
                                        ))}
                                    </form>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PeopleModalInstance
