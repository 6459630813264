import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_AUTH;

export const getPermissions = (): Promise<any> =>{
    return new Promise( async (resolve, reject) => {
        try{
            const resp = await axios.get(`${URL}/permissions`);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}
