import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { deleteAPushGroup } from "services/nodejs.service";

const channel_map: any = {
  0: "APP",
  100: "WHATSAPP",
};
const type_map = ["Grupo", "Edad"];

function PushDeleteModal({ pushToDelete, close, visible, refresh }: any) {
  const deleteTemplete = async (id: any) => {
    await deleteAPushGroup({ id: id });
    await refresh();
    close();
  };

  return (
    <Modal open={visible}>
      <Box sx={minorStyle}>
        <Grid container flexDirection={"row"}>
          <Grid container p={1}>
            <Grid item>
              <Typography variant="h6" color={"black"}>
                Seguro que quieres borrar el grupo:
              </Typography>
              <Typography variant="h6" color={"black"}>
                Nombre: {pushToDelete.group_name}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Canal: {channel_map[pushToDelete.channel]}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Tipo de grupo: {type_map[pushToDelete.type]}
              </Typography>
              <Typography variant="h6" color={"black"}>
                si este grupo sige estando en algun push evento o push calendar esto fallara
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => deleteTemplete(pushToDelete.id)}
              >
                Si
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={() => close()}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { PushDeleteModal };
