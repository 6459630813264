import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from "react-router-dom";
import { detailBot } from '../../data/bots';
import { useEffect, useState } from "react";
import { Bot } from "interfaces/Bot";
import { getChannels } from "data/channels";
import { Channel } from "interfaces/Channel";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { saveBotChannel } from "data/bots-channels";
import { useHistory } from 'react-router-dom';

type Props = {
    botid: string
}

const validationSchema = Yup.object().shape({
    channel: Yup.string().required(),
    token: Yup.string().required()
});

const AddChannel = ({ match }: RouteComponentProps<Props>) => {

    const botId = match.params.botid;
    const [bot, setBot] = useState<Bot>({ id: '', name: '', description: '', url: '' })
    const [channels, setChannels] = useState<Channel[]>([]);

    const history = useHistory();

    const initialValues = {
        bot: botId,
        channel: "",
        token: "",
        challenge: "",
        phone_number: "",
        account_sid: ""
    };

    useEffect(() => {
        detailBot(botId)
            .then((resp: any) => {
                const { data } = resp;
                setBot(data);
            })
            .catch((err: any) => console.log(err));

        getChannels()
            .then((resp: any) => {
                setChannels(resp.data.results)
            })
            .catch((err: any) => console.log(err));

    }, [botId]);


    const handleSubmit = async (payload: any): Promise<void> => {
        try {
            const resp = await saveBotChannel(payload);
            const { data } = resp;

            const url = `${process.env.REACT_APP_WEBHOOK}/bots/${data.bot}/channel/${data.id}/webhook/`;

            swal("Afinidata", "El canal ha sido creado \n\n Url de devolución: \n\n" + url, "success")
                .then(() => {
                    console.log('success');
                    history.push(`/admin/bots/configure/${botId}`)
                })
        } catch(err:any) {
            swal("Afinidata", "Ocurrio un error. intente de nuevo", "error");
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values);
            resetForm({});
        },
    });


    return (
        <>
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={`/admin/bots/configure/${botId}`} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Añadir canal</h6>
                            <hr />
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Bot</label>
                                    <input type="hidden" name="bot_id" value={formik.values.bot} />
                                    <p className="static-control">{bot.name}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Channel</label>
                                    <select name="channel"
                                        className={`form-control ${formik.errors.channel && formik.touched.channel ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.channel}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value="">-- seleccione channel --</option>
                                        {channels.map((item, i) => (
                                            <option value={item.id} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                    {formik.errors.channel && formik.touched.channel ? (
                                        <div className="invalid-feedback">{formik.errors.channel}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Token</label>
                                    <input type="text" name="token" placeholder="Enter token value"
                                        className={`form-control ${formik.errors.token && formik.touched.token ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.token}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                    {formik.errors.token && formik.touched.token ? (
                                        <div className="invalid-feedback">{formik.errors.token}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Challenge</label>
                                    <input type="text" name="challenge" placeholder="Enter challenge value"
                                        className={`form-control ${formik.errors.challenge && formik.touched.challenge ? "is-invalid" : ""
                                            }`}
                                        value={formik.values.challenge}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Account ssid</label>
                                    <input type="text" name="account_sid" placeholder="Enter account id"
                                        value={formik.values.account_sid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Phone number</label>
                                    <input type="text" name="phone_number" placeholder="Enter phone number"
                                        value={formik.values.phone_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="form-control" />
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <button type="submit"
                                            className="btn btn-primary">{/* 
                                        // @ts-ignore */}
                                            <FontAwesomeIcon icon={faSave} /> Guardar
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12">
                    <div className="card">
                        <div className="card-body">
                            <p><strong>Instrucciones: </strong></p>
                            <ul>
                                <li>Dirigirse a <a href="https://developers.facebook.com/" target="_blank" rel="noreferrer">https://developers.facebook.com/</a></li>
                                <li>De no tener una cuenta, crearse una cuenta de developers en facebook con su cuenta de facebook principal</li>
                                <li>Al tener ya una cuenta activa iniciar sesión</li>
                                <li>Click en la sección "Mis apps"
                                <ul>
                                        <li>De no tener un app creada para el bot, dar click en crear app</li>
                                        <li>En las opciones para crear app, elegir "Administrar integraciones comerciales"</li>
                                        <li>Elegir un nombre para la app.</li>
                                        <li>Elegir como propósito de la app: "Tú y tu negocio"</li>
                                        <li>Darle click en Crear app</li>
                                    </ul>
                                </li>
                                <li>Elegir la opción "messenger"</li>
                                <li>Al abrir por primera vez la opción messenger por defecto abrirá la parte de configuración, de lo contrario, en la sección messenger en la barra lateral izquierda elegir "configuración"
                                <ul>
                                        <li>De no haber generado nunca un token en esta aplicación, ir a la sección "tokens de acceso".</li>
                                        <li>Agregar una página</li>
                                        <li>Para esto iniciará sesión en facebook en un modal, después de eso debe dar permisos a la fanpag une que se usará con esta aplicación.</li>
                                        <li>Hecho esto se mostrará una modal con un token oculto, al cual debe darle click en el checkbox “Acepto” para mostrarlo</li>
                                        <li>Ese token debe pegarlo en la casilla token</li>
                                        <li>Si la página ya estaba adjunta, solo es de darle click al botón "Generar Token".</li>
                                        <li>Deberá darle click en el checkbox "Acepto"</li>
                                        <li>Ese token debe pegarlo en la casilla token</li>
                                    </ul>
                                </li>
                                <li>En la sección "Webhook" deberá agregar una url de devolución de llamada</li>
                                <li>Para esto le pedirá que agregue un token de verificación, donde lo ideal es poner un distintivo de la página, por ejemplo si tu página se llama "cachorrito", un buen ejemplo del token sería: "cachorrito_token"</li>
                                <li>Este token lo agregas en la casilla "Challenge".</li>
                                <li>Guardas el canal para el bot.</li>
                                <li>Al guardar el canal, se te mostrará una URL para colocar en la casilla "URL de devolución de llamada".</li>
                                <li>(Estructura: DOMAIN/bots/BOT_ID/channel/BOT_CHANEL_ID/webhook/)</li>
                                <li>Colocas esta URL en la casilla y le das "Verificar y Guardar".</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AddChannel
