import Loading from "components/Loading";
import { useFormik } from "formik";
import { AreaI, SourceI } from "interfaces/MilestoneAdmin";
import { useEffect, useState} from "react"
import { Link, useLocation, useParams } from "react-router-dom";
import {
    getAreas,
    getHitoById,
    getSources,
    updateHito
} from "services/milestones-services"
import swal from "sweetalert";


type Params = {
    id: string;
}

const EditarHito = () => {
    const {id} = useParams<Params>()
    const [areas, setAreas] = useState<AreaI[]>([]);
    const [sources, setSources] = useState<SourceI[]>([]);
    const [loading, setLoading] = useState(true);
    const [initialValues,setInitialValues] = useState({
        areas: [],
        source: "",
        draft: false
    });

    const location = useLocation() as any;

    useEffect(() => {

        async function fetchData() {
            try{
                const respHito = await getHitoById(id);
                const respAreas = await getAreas();
                const respSources = await getSources();
                setAreas(respAreas.data);
                setSources(respSources.data);
                const {areas, source, draft} = respHito.data.results[0]
                setInitialValues({
                    areas: areas.map((item:any) => item.id),
                    source: source.id,
                    draft
                })
            } catch(err:any){
                console.error(err);
            } finally{
                setLoading(false)
            }
        }

        fetchData();
    },[id]);

    const handleSubmit = async(values: any) => {
        try{
            await updateHito(values, id);
            swal("Hito actualizado",{icon: 'success'});
        } catch(err:any){
            console.error(err);
            swal("Ocurrió un error",{icon: 'error'});
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    })

    if(loading){
        return <Loading />;
    }

    return (
       <div className="card">
           <div className="card-header">
               <h3>Editar hito de desarrollo</h3>
           </div>
           <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="">Área</label>
                                    <select
                                    name="areas"
                                    id="areas"
                                    className="form-control"
                                    value={formik.values.areas}
                                    onChange={formik.handleChange}
                                    multiple>
                                        {areas && areas.map((item:AreaI) => (<option value={item.id} key={item.id}>{item.name}</option>))}
                                    </select>
                                </div>
                        </div>
                        <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="">Source</label>
                                    <select name="source" id="source" className="form-control"
                                    value={formik.values.source}
                                    onChange={formik.handleChange}>
                                        {sources && sources.map((item:SourceI) => (<option value={item.id} key={item.id}>{item.name}</option>))}
                                    </select>
                                </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <div className="form-group row">
                                    <div className="col-sm-6">
                                        <label htmlFor="">
                                            <input type="radio" name="draft" value="draft" className="mr-2"
                                            checked={!formik.values.draft}
                                            disabled={true}
                                            onChange={() => formik.setFieldValue("draft",false) }/>
                                            Draft
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="">
                                            <input type="radio" name="draft" value="published" className="mr-2"
                                            checked={formik.values.draft}
                                            disabled={true}
                                            onChange={() => formik.setFieldValue("draft",true)}/>
                                            Published
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <Link to={location.state?.from === 'detail' ? `/admin/milestones/hito/${id}`: `/admin/milestones/listado-hitos`} className="btn btn-secondary btn-sm mr-2"><i className="fa fa-arrow-left"></i> Cancelar</Link>
                            <button className="btn btn-primary btn-sm" type="submit"><i className="fa fa-save"></i> Actualizar milestone</button>
                        </div>
                    </div>
                </form>
           </div>
       </div>
    )
}

export default EditarHito
