import { AuthGroup } from 'interfaces/AuthGroup';
import { Link } from 'react-router-dom';

type Props = {
    formik: any,
    groups: AuthGroup[],
    selectedGroups?: any[]
}

const UserForm = ({formik, groups, selectedGroups}:Props) => {
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input type="text" name="email"
                            className={`form-control ${
                            formik.errors.email && formik.touched.email ? "is-invalid" : ""
                            }`}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}/>
                        {formik.errors.email && formik.touched.email ? (
                            <div className="invalid-feedback">{formik.errors.email}</div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="">Username</label>
                        <input type="text" name="username"
                            className={`form-control ${
                            formik.errors.username && formik.touched.username ? "is-invalid" : ""
                            }`}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}/>
                        {formik.errors.username && formik.touched.username ? (
                            <div className="invalid-feedback">{formik.errors.username}</div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="">First name</label>
                        <input type="text" name="first_name"
                            className={`form-control ${
                            formik.errors.first_name && formik.touched.first_name ? "is-invalid" : ""
                            }`}
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}/>
                        {formik.errors.first_name && formik.touched.first_name ? (
                            <div className="invalid-feedback">{formik.errors.first_name}</div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="">Last name</label>
                        <input type="text" name="last_name"
                            className={`form-control ${
                            formik.errors.last_name && formik.touched.last_name ? "is-invalid" : ""
                            }`}
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}/>
                        {formik.errors.last_name && formik.touched.last_name ? (
                            <div className="invalid-feedback">{formik.errors.last_name}</div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="">Groups</label>
                        <select name="groups"
                        className={`form-control ${
                            formik.errors.groups && formik.touched.groups ? "is-invalid" : ""
                            }`}
                        multiple={true}
                        defaultValue={selectedGroups || []}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                            {groups.map( item => (
                                <option value={item.id} key={item.id}>{item.name}</option>
                            ))}
                        </select>
                        {formik.errors.groups && formik.touched.groups ? (
                            <div className="invalid-feedback">{formik.errors.groups}</div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" type="submit">
                        <i className="fa fa-save"></i> Guardar
                    </button>
                    <Link to="/admin/users"
                    className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Volver</Link>
                </div>
            </form>
        </>
    )
}

export default UserForm;
