import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory} from "react-router-dom"
import Loading from '../../components/Loading';
import { deleteBotChannel, getBotChannel } from 'data/bots-channels';
import swal from 'sweetalert';

interface IProps {
    id: string
}

const DeleteChannel = ({match}: RouteComponentProps<IProps>) => {
    const id = match.params.id;
    const [channel, setChannel] = useState<any>(null)
    const [ loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        getBotChannel(id)
        .then( resp => {
            setChannel(resp.data)
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[id])

    const handleClick = async () => {
        try {
            await deleteBotChannel(id);
            await swal('Afinidata','El Bot channel fue borrado correctamente','success');
            history.push(`/admin/bots/configure/${channel.bot}`);
        } catch(err:any){
            swal('Afinidata','Ocurrio un error al borrar el Bot channel','error');
        }
        console.log('success');
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas borrar el Bot channel?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>Id:</b> {channel.id}</p>
                            <p><b>Token:</b> {channel.token}</p>
                            <p><b>Challenge:</b> {channel.challenge}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                href={`/admin/bots/configure/${channel.bot}`}>Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteChannel
