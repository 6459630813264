import { destroyResponseText, storeResponseText } from "data/response-text";
import { Intent } from "interfaces/Intent";
import { useState, useEffect } from 'react';
import swal from "sweetalert";
import validator from 'validator';

interface IProps {
    intent: Intent;
    intentId: string;
    isReadOnly?:boolean;
}

interface IResponse {
    id: string;
    text: string;
}

const BotReplyWithText = ({intent, intentId, isReadOnly=false}:IProps) => {
    const { responsetext } = intent;
    const [responses, setResponses] = useState<IResponse[]>([]);
    const [text, setText] = useState("");
    const [err, setErr] = useState("");

    useEffect(() => {
        if (responsetext && responsetext.length > 0){
            setResponses( responsetext.map( (item:any) => ({id: item.id, text: item.text })));
        }
        else{
            setResponses([]);
        }
    },[responsetext]);

    const handleRemove = async (id: string) => {
        setResponses( responses.filter( (item:IResponse) => item.id !== id) ) ;
        try{
            await destroyResponseText(id);
            swal("Afinidata",'Eliminado correctamento','success');
        } catch(err:any){
            console.log(err);
            swal("Afinidata",'Ha ocurrido un error, intente de nuevo.','error');
        }
    }

    const handleSubmit = async (e:any) => {
        try{
            e.preventDefault();
            setErr("");
            if( validator.isEmpty(text) ){
                setErr("Text is required");
                setTimeout(() => {
                    setErr("");
                },2000);
                return;
            }

            const resp:any = await storeResponseText({
                intent: intentId,
                text,
            });
            const { data } = resp;
            setResponses([{id: data.id, text}, ...responses]);
            swal("Afinidata",'Agregado correctamento','success');
            setText("");
        } catch(err:any){
            console.log(err);
            swal("Afinidata",'Ha ocurrido un error, intente de nuevo.','error');
        }
    }

    return (
        <div>
            { err &&
                <div className="alert alert-danger">
                    { err }
                </div>
            }
            {!isReadOnly &&
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="">Text</label>
                    <input type="text" className="form-control"
                    name="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <button className="btn btn-outline-primary btn-sm btn-block"
                    type="submit">
                        <i className="fa fa-save"></i> Guardar
                    </button>
                </div>
            </form>
            }
            { responses.length > 0 &&
                <ul className="list-group">
                    {responses.map( (item:IResponse) => (
                        <li className="list-group-item"
                        key={item.id}>
                            <span>{item.text}</span>
                            {!isReadOnly &&
                            <div className="float-right">
                                <i
                                style={{cursor:'pointer'}}
                                className="fa fa-times"
                                onClick={() =>  handleRemove(item.id)}></i>
                            </div>

}
                        </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default BotReplyWithText
