import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
    id: string;
    name: string;
    createdAt: string;
    user: any;
    handleClick: (id:string) => void,
    onSelectUser: (id:string) => void,
}

const TableItem:FunctionComponent<IProps> = ({
        id, name, createdAt, user,  handleClick, onSelectUser
    }) => {

    return (
        <>
            <tr key={id}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{createdAt}</td>
                <td className="text-right"
                style={{padding:"40px 10px"}}>
                    <Link to={`/admin/people/${id}/instance-attribute`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Add</Link>
                    <button className="btn btn-primary btn-sm ml-2"
                    data-toggle="modal" data-target="#instanceModal"
                    onClick={() => handleClick(id)}><i className="fa fa-search"></i></button>
                </td>
                <td style={{width:"400px"}}>
                    <p>Associate user</p>
                    <ul className="list-group">
                        {user.map((item:any) => (
                            <li key={item.id} className="list-group-item">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <p>{item.id} | {item.first_name} {item.last_name}</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <button className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={() => onSelectUser(item.id)}><i className="fa fa-user"></i> View user</button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </td>
            </tr>
        </>
    )
}

export default TableItem
