import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import * as XLSX from "xlsx";
import Loading from './Loading';

export interface GlobalFields {
    label: string,
    isMulti?: boolean,
    url: string,
    key: string,
    value?: any
}
interface props {
    modalId: string,
    title: string,
    sendCallback: (value: any) => Promise<any>,
    urlFormat: string,
    requiredFields?: Array<string>,
    globalFields?: Array<GlobalFields>,
    successMessage?: string
}

const ExcelBulkModal = ({modalId, sendCallback, title, urlFormat, requiredFields = [], globalFields = [], successMessage="Usuarios creados y asignados correctamente"}: props) => {
    const fileInputRef = useRef() as any;
    const [messageSuccess, setMessageSuccess] = useState<string>('');
    const [messageErr, setMessageErr] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [file, setFile] = useState({});
    const [errorList, setErrorList] = useState<Array<any>>([]);
    const [successList, ] = useState<Array<any>>([]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(false);
        }
        return () => { mounted = false; };

    }, []);

    const handleInputFileChange = (e: any) => {
        setName(e.target.files[0].name || '');
        readExcel(e.target.files[0]);
    }

    const clickButton = () => {
        clearData();
        fileInputRef.current.click();
        
    }

    const clearData = () => {
        fileInputRef.current.value = '';
        setFile({});
        setMessageSuccess('');
        setMessageErr('');
        setName('');
        setErrorList([]);
    }

    const validateData = (data: Array<any>) => {
        if(data.length ===0){
            setMessageErr('Archivo vacío');
            return ;
        }
        const errorList: Array<string> = []

        data.forEach((row, index) => {
            requiredFields.forEach(field => {
                if (!(field in row)) {
                    errorList.push(`Error en la fila ${index} no existe la columna ${field}`)
                }
            });
        });
        errorList.length !== 0 ? setMessageErr(`Errores en las filas: ${errorList.join(",")}`) : setFile(data);
    }

    const setGlobalFields = (value: any, key: string) => {
        const modifiedField = globalFields.find((field => field.label === key)) as GlobalFields
        modifiedField.value = value
    }


    const readExcel = (errorList: any) => {
    
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(errorList);

        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            validateData(data);
            setFile(data)
        }
    }

    const sendData = async ( ) => {
        setLoading(true);
        try {
            await sendCallback({ data: file, global_fields: globalFields });
            setMessageSuccess(successMessage);
        } catch (error) {
            setMessageErr("Ocurrio un error en el servidor");
        }
        setLoading(false)
        // if (resp.data.errors.length > 0) {
        //     let textError =  resp.data.succeed.length > 0 ? 'Se actualizaron los posts, pero fue imposible actualizar los siguientes:' : 'Se generaron los siguientes errores:';
        //     setMessageErr(textError);
        //     setErrorList(resp.data.errors);
        //     setLoading(false);

        // }
        // if (resp.data.succeed.length > 0) {
        //     setMessageSuccess('Se actualizaron los siguientes posts exitosamente');
        //     setSuccessList(resp.data.succeed);
        //     setLoading(false);
        // }
    }

    return (
        <div className="modal fade in" id={modalId} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    { loading ? 
                            <Loading size="2rem" /> 
                        : 
                        <>
                            <div className="modal-body text-center">
                                {messageErr && 
                                    <div className="alert alert-danger small animate__animated animate__shakeX">
                                        <h5><i className="fa fa-exclamation-circle"></i> {messageErr}</h5>
                                        {errorList.length > 0 ?
                                            <table className="table table-sm table-bordered table-dark">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Id</th>
                                                        <th scope="col">Error</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorList.map( (item: any) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">{item.id}</th>
                                                            <td>{item.err_message}</td>
                                                        </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>
                                            :
                                            <></>
                                        }

                                    </div>
                                }

                                {messageSuccess && 
                                  <div className="alert alert-success small animate__animated animate__fadeInDown"> 
                                            <h5><i className="fa fa-check-circle"></i> {messageSuccess}</h5>
                                        {successList.length >0 ?
                                                <table className="table table-sm table-bordered table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Id</th>
                                                            <th scope="col">Titulo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {successList.map( (item: any) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{item.id}</th>
                                                                <td>{item.title}</td>
                                                            </tr>
                                                        ))}
                                                        
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                            }
                                    </div>
                                }

                                <a className="btn btn-primary btn-sm mb-4" href={urlFormat} download>
                                    <i className="fa fa-download"></i> Descargar formato
                                </a>
                                
                               
                        
                                <form>
                                    <input type="file"
                                    onChange={(e) => handleInputFileChange(e)}
                                    ref={fileInputRef}
                                    accept=".xlsx"
                                    style={{ display: 'none' }}/>
                                    {globalFields.map((field, index) => 
                                        (<div className="form-group" key={`glob_field_${index}`}>
                                            <label htmlFor="exampleInputEmail1">{field.label}</label>
                                            <AsyncPaginate 
                                                isMulti={field.isMulti}
                                                debounceTimeout='400'
                                                onChange={(value: any) => setGlobalFields(value, field.label)}
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                loadOptions={async (search: string, loadedOptions: any, extras: any) => {
                                                    const response = await axios.get(`${field.url}?search=${search}&pagenumber=${extras? extras.page : 1}`);
                                                    const responseJSON = response.data;
                                                    return {
                                                    options: responseJSON.results.map((item: any) => {return {value: item.id, label: item.name}}),
                                                    hasMore: !!responseJSON.next,
                                                    additional: {
                                                        page: responseJSON.next ? responseJSON.next.match(/pagenumber=(?<page>\d+)/).groups.page : 1,
                                                      },
                                                    };
                                                }}/>
                                        </div>))}
                                </form>
                                {name && <h4>{name}</h4>}
                                <div className="mt-4">
                                    <button className="btn btn-warning btn-md ml-2"
                                    onClick={() =>  clickButton() }>
                                        Seleccionar archivo
                                    </button>
                                </div>
                                        
                                
                                
                            </div>

                            <div className="modal-footer">

                                <button type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => clearData() }>Cerrar</button>

                                <button type="button"
                                    className={'btn btn-primary'}
                                    disabled={Object.keys(file).length === 0}
                                    onClick={ () => sendData()}
                                    >Actualizar</button>
                                
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default ExcelBulkModal
