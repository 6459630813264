import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import SearchField from "./search_field";

interface IProps{
    setSearch: (value: any) => void,
    search: string
}

const SearchForm:FunctionComponent<IProps> = ({search, setSearch}) => {
    const [state, setState] = useState(search);
    const {t, ready} = useTranslation();

    const handleInputChange = (value: string) => {
        setSearch(value)
    }

    const handleInputReset = () => {
        setState("");
        setSearch("")
    }

    if(!ready) {
        return <Loading />
    }

    return (
        <form onSubmit={(e) => e.preventDefault() }>
            <div className="form-group row">
                <div className="col-md-4">
                    <SearchField search={state} setSearch={handleInputChange}
                        className="form-control form-control-sm"
                        placeholder={t("buscar")}
                    />
                </div>
                <div className="col-md-4">
                    <button type="button" className="btn btn-danger btn-sm ml-2"
                    onClick={handleInputReset}><i className="fa fa-eraser"></i></button>
                </div>
            </div>
      </form>
    )
}

export default SearchForm
