import axios from 'axios';
import { LocaleTraductionItem } from 'interfaces/Post';

const CM_API = process.env.REACT_APP_API_URL_CM

export const getMaterials = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/posts_materiales/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/**
 * description: obtener listado de Traducciones de post
 * @param: search string
 * @param: page number
 * @param: parameters object
 * */
 export const getPostTraductionList = (search = '', page = 1, parameters={}) => {
    return new Promise(async (resolve, reject) => {
        try {
            let params = '';
            for (const [key, value] of Object.entries(parameters)) {
                params += `&${key}=${value}`
              }

            const resp = await axios.get(`${CM_API}/posts/get_traduction_list/?size=30&search=${search}&pagenumber=${page}${params}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


 export const updatePosts = (payload={}) => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${CM_API}/posts/update_translated/`;
        try {
            const resp = await axios.post(endpoint, payload);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}

export const getAreas = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/posts_areas/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getPostTranslationById = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/posts_translations/${id}/`);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}

export const updatePostTranslation = (id: string, payload: LocaleTraductionItem): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${CM_API}/posts_translations/${id}/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err);
        }
    });
}