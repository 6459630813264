import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addBot } from '../../data/bots';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import swal from 'sweetalert';
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
});

const AddBot = () => {

    const initialValues = {
        name: "",
        description: "",
        url: ""
    };

    const history = useHistory();

    const handleSubmit = (payload: any): void => {
        addBot(payload)
        .then( (resp: any) => {
            const { id } = resp.data;
            swal("Afinidata","Bot creado.","success")
            .then( () => {
                history.push(`/admin/bots/configure/${id}`);
            });
        }).catch((err: any) => {
            swal("Afinidata",`Ocurrio un error. Intente de nuevo \n ${err.response.data.name[0]}`,"error");
        })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    return (
        <>
            <div className="card mx-auto" style={{maxWidth:'650px'}}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-8 col-xs-12">
                            <h4>Crear bot</h4>
                        </div>
                        <div className="col-md-4 col-xs-12 text-right">
                            <Link
                            className="btn btn-outline-primary btn-sm"
                            to="/admin/bots">
                                {/* 
                                // @ts-ignore */}
                                 <FontAwesomeIcon icon={faArrowLeft} /> Volver
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="nombre">Name <span className="text-danger">*</span></label>
                            <input type="text" name="name"
                            className={`form-control ${
                                formik.errors.name && formik.touched.name ? "is-invalid" : ""
                              }`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                            {formik.errors.name && formik.touched.name ? (
                                <div className="invalid-feedback">{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description <span className="text-danger">*</span></label>
                            <textarea name="description"
                            className={`form-control ${
                                formik.errors.description && formik.touched.description ? "is-invalid" : ""
                              }`}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            ></textarea>
                            {formik.errors.description && formik.touched.description ? (
                                <div className="invalid-feedback">{formik.errors.description}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="url">Url</label>
                            <p className="small text-muted">URL es opcional en caso de Facebook messenger colocar url fanpage, web colocar url web y whatsapp el numero Telefono al que es conectado</p>
                            <input type="text" name="url"
                            value={formik.values.url}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit"
                            className="btn btn-primary btn-sm">{/* 
                        // @ts-ignore */}
                                <FontAwesomeIcon icon={faSave} /> Guardar
                            </button>
                            <p className="mt-2">Los campos marcados con <span className="text-danger">*</span> son requeridos</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddBot
