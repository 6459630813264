import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getChannels } from "data/channels";
import { Channel } from "interfaces/Channel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getBotChannel, updateBotChannel } from "data/bots-channels";
import Loading from "components/Loading";
import { detailBot } from "data/bots";
import { Bot } from "interfaces/Bot";
import swal from "sweetalert";

type Props = {
    botid: string,
    channelid: string
}

const validationSchema = Yup.object().shape({
    channel: Yup.string().required(),
    token: Yup.string().required()
});


const EditChannel = ({match}:RouteComponentProps<Props>) => {

    const [channels, setChannels] = useState<Channel[]>([]);
    const [initialValues, setInitialValues] = useState<any>({challenge: '',token:'', bot: '', channel: '',account_sid: '',phone_number: ''});
    const [bot, setBot] = useState<Bot>({name:'',description: '',url:''});
    const history = useHistory();
    const channelId = match.params.channelid;
    const botId = match.params.botid;
    const [urlDevolucion, setUrlDevolucion] = useState<string>("")

    useEffect(() => {
        setUrlDevolucion(`${process.env.REACT_APP_DOMAIN}/bots/${botId}/channel/${channelId}/webhooks`);

        getChannels()
       .then( resp => setChannels(resp.data.results) )
       .catch( err => console.log(err));

        getBotChannel(channelId)
        .then( resp => setInitialValues(resp.data))
        .catch(err => console.log(err));

        detailBot(botId)
        .then(resp => setBot(resp.data))
        .catch(err => console.log(err));

    }, [botId, channelId]);


    const handleSubmit = async (payload: any): Promise<void> => {
        try{
            await updateBotChannel(channelId, payload);
            await swal("Afinidata","Botc channel actualizado.","success")
            history.push(`/admin/bots/configure/${botId}`)
        }catch(err:any){
            swal("Afinidata",'Ocurrió un error intente de nuevo','error');
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={`/admin/bots/configure/${formik.values.bot}`} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Editar canal</h6>
                            <hr/>
                            { initialValues ? (
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="">Url de devolución</label>
                                        <p>{urlDevolucion}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Bot</label>
                                        <input type="hidden" name="bot" value={formik.values.bot}/>
                                        <p className="static-control">{bot.name}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Channel</label>
                                        <select name="channel"
                                        className={`form-control ${
                                            formik.errors.channel && formik.touched.channel ? "is-invalid" : ""
                                        }`}
                                        value={formik.values.channel}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                            <option value="">-- seleccione channel --</option>
                                            {channels.map( (item,i) => (
                                                <option value={item.id} key={i}>{item.name}</option>
                                            ))}
                                        </select>
                                        {formik.errors.channel && formik.touched.channel ? (
                                            <div className="invalid-feedback">{formik.errors.channel}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Token</label>
                                        <input type="text" name="token" placeholder="Enter token value"
                                        className={`form-control ${
                                            formik.errors.token && formik.touched.token ? "is-invalid" : ""
                                        }`}
                                        value={formik.values.token}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}/>
                                        {formik.errors.token && formik.touched.token ? (
                                            <div className="invalid-feedback">{formik.errors.token}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Challenge</label>
                                        <input type="text" name="challenge" placeholder="Enter challenge value"
                                        className={`form-control ${
                                            formik.errors.challenge && formik.touched.challenge ? "is-invalid" : ""
                                        }`}
                                        value={formik.values.challenge}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Account ssid</label>
                                        <input type="text" name="account_sid" placeholder="Enter account id"
                                        value={formik.values.account_sid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Phone number</label>
                                        <input type="text" name="phone_number" placeholder="Enter phone number"
                                        value={formik.values.phone_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="form-control"/>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <button type="submit"
                                            className="btn btn-primary">{/* 
                                        // @ts-ignore */}
                                                <FontAwesomeIcon icon={faSave} /> Actualizar
                                            </button>

                                        </div>
                                    </div>
                                </form>
                                ) : (
                                    <Loading />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EditChannel
