import axios from 'axios';
import swal from 'sweetalert';
import Login from 'pages/users/Login';
import Admin from './pages/admin/Admin';
import { getToken, logout } from 'data/users';
import { Route, Switch } from 'react-router-dom';
import ActivateUser from 'pages/users/ActivateUser';
import PasswordReset from 'pages/users/PasswordReset';
import PasswordForget from 'pages/users/PasswordForget';
import PageNotFound from 'pages/page-not-found/PageNotFound';
import UserContextProvider from 'context/UserContext';
import GroupContextProvider from 'context/GroupContext';

axios.interceptors.request.use(
  config => {
    const token = getToken();
    config.headers.Authorization = token ? 'Bearer ' + token : '';
    return config;
  },
  err => {
    Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    console.log('error' , error)
    const { response: {status}} = error;

    /** usuario no autorizado */
    if (status === 401) {
      swal('Afinidata', 'Credenciales inválidas', 'error')
      .then( () => {
        logout();
        window.location.href = '/';
      });
    }

    /** usuario sin permisos */
    if (status === 403) {
      swal('Afinidata', 'Usuario no tiene permisos', 'error')
      .then( () => {
        window.location.href = '/admin/dashboard';
      });
    }

    return Promise.reject(error);
  }
);

const AppState = ({children}:any) => {
  return (
    <UserContextProvider>
      <GroupContextProvider>
        {children}
      </GroupContextProvider>
    </UserContextProvider>
  )
}

const App = () => {
    return (
      <>
        <AppState>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/activate-user/:token" component={ActivateUser} exact />
            <Route path="/password-forget/" component={PasswordForget} exact />
            <Route path="/password-reset/:token" component={PasswordReset} exact />
            <Route path="/admin" component={Admin}/>
            <Route path="*" component={PageNotFound} />
          </Switch>
        </AppState>
      </>
    );
};

export default App;
