import { /*useEffect,*/ useEffect, useState } from 'react';
// import { utils, writeFile } from 'xlsx';
import Loading from 'components/Loading';
import axios from 'axios';
import { report } from 'data/programs';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const SQ_API = "https://seperate-queries-green.afinidata.com/"

const ProgramReports = (props: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [finishedMakingFile, setFinishedMakingFile] = useState<boolean>(false);
    const [programList, setProgramList] = useState<any>([]);
    const [selectedProgram, setSelectedProgram] = useState<any>({id:0,name:""});

    useEffect(()=> {
        const getPrograms = async () => {setProgramList((await report()).data.programs);setLoading(false)}
        getPrograms()
    },[])
    async function getTimeInWhats() {
        setLoading(true)
        try {
            await axios.get(`${SQ_API}v1/queries/createFile?programId=${selectedProgram.id}`);
            // console.log('opened window', openWindow)
            // setProgramData(resp.data.data)
            setFinishedMakingFile(true)
        } catch {
            setLoading(false)
            // setProgramData(false)
        } finally {
            setLoading(false)

        }          
    }
    // useEffect(() => {
    //     return
    //     },
    //     [ search]);

    if( loading ){
        return (
            <Loading />
        )
    }
    if(finishedMakingFile) {
        return(
            <Grid item xs={6} p={1}>
                <Button onClick={()=>{window.open(`${SQ_API}v1/queries/getFile?programId=${selectedProgram.id}`, "_blank", "noreferrer");setFinishedMakingFile(false)}} fullWidth variant="contained"> descargar reporte</Button>
            </Grid>
        )
    }
    console.log('>>>>>>>>>>>>>>', selectedProgram.name)
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">Reporte de programas</h4>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                    <InputLabel id="channel">Program to select</InputLabel>
                                    <Select labelId="channel" id="channel" label="Reporte de programas" value={selectedProgram} onChange={(v) => setSelectedProgram(v.target.value)}>
                                        {
                                            programList?.map((el:any)=>{
                                                return(
                                                    <MenuItem key={el.key} value={el}>{el.name}</MenuItem>

                                                )
                                            })
                                        }
                                    </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} p={1}>
                            <Button onClick={getTimeInWhats} fullWidth variant="contained"> descargar reporte</Button>
                        </Grid>
                    </Grid>
                    
                </div>
            </div>
        </>
    )
}

export default ProgramReports

