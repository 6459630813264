import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import TempleteModal from "./TempleteModal";
import { getAllTempletes } from "services/nodejs.service";
import { DeleteModal } from "./TempleteDeleteModal";

const language_map = ["", "español", "ingles", "", "portugués"];
const type_map = ["Texto", "Imagen", "Video"];

const example = [
  {
    id: 1,
    name: "beneficios_afini2",
    language_id: "1",
    type: 0,
  },
  {
    id: 2,
    name: "bienvenida_comunicadoresii",
    language_id: "2",
    type: 1,
  },
  {
    id: 3,
    name: "comunicadores2_caracteristica1",
    language_id: "4",
    type: 2,
  },
  {
    id: 4,
    name: "narradores_caracteristica1",
    language_id: "1",
    type: 0,
  },
];

function Templete() {
  const [modal, setModal] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [templetes, setTempletes] = useState(example);
  const [templeteToDelete, setTempleteToDelete] = useState(example[0]);

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 10,
    //   flex: 1,
      editable: false,
    },
    {
      field: "name",
      headerName: "Name",
      // width: 110,
      flex: 1,
      editable: false,
    },
    {
      field: "language_id",
      headerName: "Lenguaje",
      valueGetter: (val: number) => language_map[val],
      // width: 110,
      // minWidth:250,
      flex: 1,
      editable: false,
    },
    {
      field: "type",
      headerName: "Tipo",
      valueGetter: (val: number) => type_map[val],
      // width: 110,
      // minWidth:250,
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "Opciones",
      // type: 'actions',
      sortable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-warning btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModal(true);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setTempleteToDelete(tmpt.row);
                setDeleteModal(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];

  const getTemple = async () => {
    let temps = await getAllTempletes();
    setTempletes(temps.data.result);
    console.log(temps.data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(modal);
    getTemple();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={() => setModal(true)}>
          Crear nuevo
        </Button>
      </div>
      <DataGrid
        rows={templetes}
        columns={columns}

        // disableSelectionOnClick={true}
        // getRowId={(row) => row._id}
        // getRowHeight={() => "auto"}
        // filterModel={{
        //   items: items,
        // }}
        // getRowClassName={(params) => `theme--${params.row.status}`}
      />
      <TempleteModal
        visible={modal}
        close={() => {setToEdit({});setModal(false)}}
        refresh={getTemple}
        toEdit={toEdit}
      />
      <DeleteModal
        visible={deleteModal}
        close={() => setDeleteModal(false)}
        templeteToDelete={templeteToDelete}
        refresh={getTemple}
      />
    </div>
  );
}

export default Templete;
