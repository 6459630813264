import axios from 'axios';

const CM = process.env.REACT_APP_CM;
const CM_API = process.env.REACT_APP_API_URL_CM;
const WH = process.env.REACT_APP_WEBHOOK;
const HT = process.env.REACT_APP_HOTTRIGGERS;

/**
 * Enviar chatbot a una session
 * @param userId
 * @param instanceId
 * @param sessionId
 */
export const sendChatBotToSession = (userId: string, sessionId: string, instanceId = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const fd = new FormData();
      fd.append('user_id', userId);
      if (instanceId !== "") {
        fd.append('instance_id', instanceId);
      }
      fd.append('session_id', sessionId);
      const resp = await axios.post(`${CM}/chatfuel/get_session_field/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  });
}

/**
 * Enviar chatbot a una posicion especifica de la session
 * @param userId
 * @param position
 * @param sessionId
 */
export const sendChatBotToPositionInSession = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fd = new FormData();
      fd.append('user_id', payload.userId);
      fd.append('position', String(payload.position));
      fd.append('session', payload.sessionId);
      fd.append('bot_id', payload.botId);
      fd.append('bot_channel_id', payload.botChannelId);
      fd.append('user_channel_id', payload.userChannelId);

      const resp = await axios.post(`${CM}/chatfuel/send_session/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  });
}

/**
 * Asignar secuencia en hottriggers
 * @param payload
 *
 */
export const asignarSecuenciaEnHottrigger = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = axios.post(`${HT}/assign_to_sequence/`, payload);
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  });
}

/**
 * Obtener listado de ultimas conversaciones
 * @param botId
 * @param search
 * @param page
 */
export const getUltimasConversaciones = (botId: string, search = "", page = 1, liveChat: string = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = axios.post(`${CM_API}/messenger_users/user_conversations/?bot_id=${botId}&search=${search}&page=${page}&live_chat=${liveChat}`);
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  });
}

/**
 * Obtener conversacion
 * @param botId
 * @param botChannelId
 * @param userChannelId
 */

export const getConversation = (botId: string, botChannelId: string, userChannelId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const UCI = encodeURIComponent(userChannelId);
      const resp: any = await axios.get(`${WH}/bots/${botId}/channel/${botChannelId}/get_conversation/?user_channel_id=${UCI}`);
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  });
}

/**
 * enviar mensaje al chat
 * @param botId
 * @param botChannelId
 * @param payload
 * @returns Promise
 */
export const enviarMensaje = (botId: string, botChannelId: string, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fd = new FormData();
      fd.append('user_channel_id', payload.userChannelId);
      fd.append('message', payload.message);
      const resp = await axios.post(`${WH}/bots/${botId}/channel/${botChannelId}/send_message_to_user/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      resolve(resp);
    } catch(err:any) {
      reject(err)
    }
  });
}

/**
 * Detener el bot despues
 * @param userId
 * @param botId
 * @returns Promise
 */
export const stopBot = (userId: string, botId: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      let fd = new FormData();
      fd.append('user_id', userId);
      fd.append("bot_id", botId);
      fd.append("stop", "True");

      const resp = axios.post(`${CM}/chatfuel/stop_bot/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  })
}

/**
 * Detener el bot despues
 * @param userId
 * @param botId
 * @returns Promise
 */
export const startBot = (userId: string, botId: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      let fd = new FormData();
      fd.append('user_id', userId);
      fd.append("bot_id", botId);
      fd.append("stop", "False");

      const resp = axios.post(`${CM}/chatfuel/stop_bot/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  })
}


/**
 * assign engagement session
 * @param botId
 * @returns Promise
 */
 export const assignEngagementSession = (botId: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      const resp = axios.post(`${CM}/api/0.1/bots/${botId}/set_engagement_session/`, {});
      resolve(resp);
    } catch(err:any) {
      reject(err);
    }
  })
}

