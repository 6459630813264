import { Link, useHistory } from 'react-router-dom';
import {useState} from 'react';
import validate from 'validator';
import { storeQuickReply } from 'data/quick-replies';
import swal from 'sweetalert';

const AddQuickReply = () => {
    const [label, setLabel] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();

    const handleSubmit = async (e:any) => {
        e.preventDefault()
        setError("");

        if(validate.isEmpty(label)){
            setError("El label es requerido");
            return;
        }

        try{
            await storeQuickReply({label});
            swal('Afinidata','Agregado correctamente','success')
            .then(() => {
                history.push("/admin/quick-replies");
            });
        } catch( err ){
            swal('Afinidata','Hubo un error, al guardar','error');
        }

    }

    return (
        <div className="row">
            <div className="col-sm-6 mx-auto">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-6">
                                Add quick reply
                            </div>
                            <div className="col-sm-6 text-right">
                                <Link to="/admin/quick-replies"
                                className="btn btn-secondary">Volver</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="">Label</label>
                                <input type="text" placeholder="label"
                                className={`form-control ${error && 'is-invalid'}`}
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}/>
                                {error &&
                                    <div className="text-danger small mt-2">{error}</div>
                                }
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary"
                                type="submit"><i className="fa fa-save"></i> Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddQuickReply
