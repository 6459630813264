import { IRoute } from 'interfaces/Route';
import CrearHito from 'pages/milestones-admin/hitos/CrearHito';
import EditarHito from 'pages/milestones-admin/hitos/EditarHito';
import Hito from 'pages/milestones-admin/Hito';
import CrearTraduccion from 'pages/milestones-admin/traducciones/CrearTraduccion';
import EditarTraduccion from 'pages/milestones-admin/traducciones/EditarTraduccion';
import ListadoDeHitos from 'pages/milestones-admin/hitos/ListadoDeHitos';
import ListadoProgramas from 'pages/milestones-admin/programas/ListadoProgramas';
import CargarHitosExcel from 'pages/milestones-admin/programas/CargarHitosExcel';
import DetallePrograma from 'pages/milestones-admin/programas/DetallePrograma';
import { EditarPrograma } from 'pages/milestones-admin/programas/EditarPrograma';
import EditarConfiguracionMilestones from 'pages/milestones-admin/programas/EditarConfiguracionMilestones';
import DeleteConfiguracionMilestone from 'pages/milestones-admin/programas/DeleteConfiguracionMilestone';

export const MILESTONE_ADMIN_ROUTES:IRoute[] = [
    {
        path:'/admin/milestones/listado-hitos',
        component: ListadoDeHitos,
        exact: true,
    },
    {
        path:'/admin/milestones/crear-hito',
        component: CrearHito,
        exact: true,
    },
    {
        path:'/admin/milestones/editar-hito/:id',
        component: EditarHito,
        exact: true,
    },
    {
        path:'/admin/milestones/hito/:id',
        component: Hito,
        exact: true,
    },
    {
        path:'/admin/milestones/hito/:id/crear-traduccion',
        component: CrearTraduccion,
        exact: true,
    },
    {
        path:'/admin/milestones/hito/:hitoId/editar-traduccion/:translateId',
        component: EditarTraduccion,
        exact: true,
    },
    {
        path:'/admin/milestones/programas',
        component: ListadoProgramas,
        exact: true,
    },
    {
        path:'/admin/milestones/cargar-data-desde-excel',
        component: CargarHitosExcel,
        exact: true,
    },
    {
        path:'/admin/milestones/detalle-programa/:id',
        component: DetallePrograma,
        exact: true,
    },
    {
        path:'/admin/milestones/editar-programa/:id/:currentConfig',
        component: EditarPrograma,
        exact: true,
    },
    {
        path:'/admin/milestones/editar-configuracion-milestones/:id/program/:programId',
        component: EditarConfiguracionMilestones,
        exact: true,
    },
    {
        path:'/admin/milestones/delete-config/:id/program/:programId',
        component: DeleteConfiguracionMilestone,
        exact: true,
    },
];
