import { useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { checkName, createObjInDB, getS3Link } from "services/nodejs.service";

const baseURL =
  "https://app-afini-frontend-uploads.s3.us-east-2.amazonaws.com/";

type Props = {
  close: () => void;
  visible: boolean;
  refresh: () => void;
};

function ObjectModal({ close, visible, refresh }: Props) {
  const [objName, setObjName] = useState("");
  const [media, setMedia] = useState("1");
  const [minorModal, setMinorModal] = useState(false);
  const [nameAvailable, setNameAvailable] = useState(false);
  const [objectError, setObjectError] = useState("");
  const [objBlob, setObjBlob] = useState<File>();

  async function updateName(v: any) {
    setObjName(v.target.value);
    let check = await checkName(v.target.value);
    setNameAvailable(check.data.status == "available");
  }

  async function saveImage(e: any) {
    setObjBlob(e.target.files[0]);
  }

  async function uploadImage() {
    let tempObjTypeImg = objBlob?.type == "image/png";
    let tempObjTypeVideo = objBlob?.type == "video/mp4";
    if (
      (media == "1" && tempObjTypeImg) ||
      (media == "2" && tempObjTypeVideo)
    ) {
      let finalURL = baseURL + objName + (media == "1" ? ".jpeg" : ".mp4");
      if (!nameAvailable) {
        setObjectError("Nombre para objecto no esta disponible");
        setMinorModal(true);
        return true;
      }
      try {
        let link = await getS3Link(objName, media);
        await fetch(link.data.link, {
          method: "PUT",
          body: objBlob,
        }).then(async (responce) => {
          if (responce.status == 200) {
            createObjInDB({ name: objName, url: finalURL, type: +media });
            refresh();
            close();
            return;
          } else {
            return false;
          }
        });
        return;
      } catch {
        setObjectError("Error al subir Objecto");
        setMinorModal(true);
        return;
      }
    }
    setObjectError("Archivo subido no es del tipo indicado");
    setMinorModal(true);
    return true;
  }

  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              display={"flex"}
              flexDirection={"row"}
              p={1}
            >
              <Grid container spacing={1 / 2} p={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre del objeto"
                    onChange={updateName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="media">Media</InputLabel>
                    <Select
                      labelId="media"
                      id="mediaL"
                      label="Media"
                      value={media}
                      onChange={(v) => setMedia(v.target.value)}
                    >
                      <MenuItem key={1} value={"1"}>
                        Imagen (.png)
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        Video
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1 / 2} p={1}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    label="Nombre del archivo"
                    value={objBlob?.name || ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} alignSelf={"center"}>
                  <Button fullWidth variant="contained" component="label">
                    Elegir archivo
                    <input type="file" hidden onChange={saveImage} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={uploadImage}>
                Add
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refresh();
                  close();
                }}
              >
                close
              </Button>
            </Grid>
          </Grid>
          <Modal open={minorModal}>
            <Box sx={minorStyle}>
              <Grid container flexDirection={"row"}>
                <Grid container p={1}>
                  <Grid item>
                    {!objectError ? (
                      <Typography variant="h6" color={"black"}>
                        Objecto guardado correctamente
                      </Typography>
                    ) : (
                      <Typography variant="h6" color={"black"}>
                        {objectError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => setMinorModal(false)}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default ObjectModal;
