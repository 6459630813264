import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { getUserGroupById, getGroupPlatformUsers, getGroupCode, getGroupBotUsers } from 'data/user-groups';
import { UserGroupDetail } from 'interfaces/UserGroup';
import Loading from '../../components/Loading';

import GroupGeneralTable  from 'pages/user-groups/components/general-group-tbl';

interface IProps {
    id: string
}

const DetailUserGroup = ({ match }: RouteComponentProps<IProps>) => {
    const URL = process.env.REACT_APP_CORE; 
    const id = match.params.id;
    const [userGroup, setUserGroup] = useState<UserGroupDetail>({ id: '', name: '', programs:[]});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getUserGroupById(id, true)
            .then(resp => {
                setUserGroup(resp.data)
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, [id])


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="col">
                <h2>Group {userGroup.id}: {userGroup.name}</h2>
                <div className="row d-flex flex-wrap m-2">
                    <div className="row d-flex flex-grow-1 flex-wrap m-1 p-1 bg-white rounded border-left-primary shadow py-2" 
                        style={{minWidth:300}}>
                        <div className="col" style={{minWidth:150}}>
                            <b>Total de usuarios:</b>
                            <h4>{userGroup.total_users || 0 }</h4>
                        </div>
                        <i className="mr-2 fas fa-users fa-2x text-primary"></i>
                    </div>
                    <div className="row d-flex flex-grow-1 flex-wrap m-1 p-1 bg-white rounded border-left-success shadow py-2" 
                        style={{minWidth:300}}>
                        <div className="col" style={{minWidth:150}}>
                            <b>Total de niños:</b>
                            <h4>{userGroup.total_instances || 0 }</h4>
                        </div>
                        <i className="mr-2 fas fa-users fa-2x text-success"></i>
                    </div>
                    <div className="row d-flex flex-grow-1 flex-wrap m-1 p-1 bg-white rounded border-left-warning shadow py-2" 
                        style={{minWidth:300}}>
                        <div className="col" style={{minWidth:150}}>
                            <b>Actividades enviadas111:</b>
                            <h4>{userGroup.activities_sent || 0 }</h4>
                        </div>
                        <i className="mr-2 fas fa-users fa-2x text-warning"></i>
                    </div>
                    <div className="row d-flex flex-grow-1 flex-wrap m-1 p-1 bg-white rounded border-left-warning shadow py-2" 
                        style={{minWidth:300}}>
                        <div className="col" style={{minWidth:150}}>
                            <b>Actividades enviadas:</b>
                            <h4>{userGroup.activities_sent || 0 }</h4>
                        </div>
                        <i className="mr-2 fas fa-users fa-2x text-warning"></i>
                    </div>
                </div>
                <div className="row d-flex flex-wrap m-2">
                    <div className="card d-flex flex-grow-1" style={{minWidth:300}}>
                        <div className="card-header">
                            <h6 className="m-0 font-weight-bold text-primary">Información general</h6>
                        </div>
                        <div className="card-body flex-column d-flex">
                            {userGroup.created_at && (<span><b>Created:</b> {userGroup.created_at}</span> )}
                            {userGroup.parent && (<span><b>Parent Group:</b> {userGroup.parent}</span> )}
                            {userGroup.country && (<span><b>Country:</b> {userGroup.country}</span> )}
                            {userGroup.region && (<span><b>Region:</b> {userGroup.region}</span> )}
                        </div>
                    </div>
                    <div className="card d-flex flex-grow-1" style={{minWidth:300}}>
                        <div className="card-header">
                            <h6 className="m-0 font-weight-bold text-primary">Asignaciones</h6>
                        </div>
                        <div className="card-body flex-column d-flex">
                            <h6>Programs</h6>
                            <div>
                                {// @ts-ignore
                                 userGroup.programs.map((program:any) => (
                                    <a key={program.id} rel="noreferrer" target="_blank" href={`${URL}/programs/${program.id}/content/`}>{program.name}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col mt-5">
                    <GroupGeneralTable 
                        tableTitle="Codigos" 
                        groupId={userGroup.id}
                        add={true}
                        dataType="code"
                        dataMethod={getGroupCode}
                        headers={["Código", "Intercambio", "Disponible", "Creado", "Opciones"]} />
                </div>
                <div className="col mt-5">
                    <GroupGeneralTable 
                        tableTitle="Usuarios de plataforma asignaciones" 
                        groupId={userGroup.id}
                        add={true}
                        dataType="platform-user"
                        dataMethod={getGroupPlatformUsers}
                        headers={[ "Nombre",  "Rol", "Fecha de asignación", "Opciones"]} />
                </div>
                <div className="col mt-5">
                    <GroupGeneralTable 
                        tableTitle="Usuarios de bot" 
                        groupId={userGroup.id}
                        add={false}
                        dataType="bot-user"
                        dataMethod={getGroupBotUsers}
                        headers={[ "Usuarios",  "Niños", "Se unió en", "Asignado en", "Código", "tipo", "Opciones"]} />
                </div>
            </div>
        </>
    )
}

export default DetailUserGroup
