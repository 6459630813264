import { useState, useEffect } from 'react';
import { detailProgram } from '../../data/programs';
import { Program } from '../../interfaces/Program';
import { RouteComponentProps, useHistory } from "react-router-dom"
import ConfigureProgramAttributeTypes from '../program-attribute-types/ConfigureProgramAttributeTypes';
import Loading from 'components/Loading';

type Props = {
    id: string
}

const ConfigureProgram = ({ match }: RouteComponentProps<Props>) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [program, setProgram] = useState<Program>({ id: '', name: '', description: '' });
    const programId = match.params.id;

    useEffect(() => {

        detailProgram(programId)
            .then((resp: any) => {
                setProgram(resp.data);
                setLoading(false);
            })
            .catch((err: any) => { history.push("/admin/programs"); });

    }, [programId, history])

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className="col-xl">
                <div className="row ">
                    <div className="card flex-grow-1">
                        <div className="card-header bg-primary text-white d-flex">
                            <h5 className="mr-auto d-inline-flex m-0 font-weight-bold">Configurar programa</h5>
                            <a href="/admin/programs" className="btn btn-outline-light btn-sm"><i className="fa fa-arrow-left"></i> Regresar</a>
                        </div>
                        <div className="card-body">
                            <p><b>Name:</b> {program.name}</p>
                            <p><b>Description:</b> {program.description}</p>
                        </div>
                    </div>
                </div>
                <ConfigureProgramAttributeTypes programId={programId} />
            </div>
        </>
    )
}

export default ConfigureProgram
