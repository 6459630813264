import { useState } from 'react';
import Pagination from 'react-js-pagination';
import Loading from 'components/Loading';
import SearchForm from './SearchForm';
import { useEffect } from 'react';
import { useCallback } from 'react';

interface IlistData {
    results: any,
    count:number
}

export interface IListResponse {
    data:IlistData
}
export interface IColumn {
    label:string,
    value: string
}

interface IProps {
    // check on getList function is wrapped on useCallback
    getList: (search:string, page: number) => Promise<IListResponse>,
    headerRight?: JSX.Element,
    columns: IColumn[],
    title: string,
    data: any
}

const ListComponent = ({getList, headerRight, title, columns, data}:IProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);


    const handlePageChange = useCallback( async (page: number) => {
        setLoading(true);
        setActivePage(page)
        try {
            const resp: any = await getList(search, page);
            const { count } = resp.data;
            setCount(count);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }, [ search, getList])

    useEffect(() => {
        handlePageChange(activePage)
    }, [activePage, handlePageChange])

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">{title}</h4>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            {headerRight}
                        </div>
                    </div>
                </div>
                {loading? <Loading/> : 
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />                     
                    { count > 0 ? (
                        <>
                        <div className="table-responsive">
                            <table className="table"
                            id="data">
                                <thead>
                                    <tr className="text-center">
                                        {columns.map((item) => {
                                            return (
                                                <th scope="col">{item.label}</th> 
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.map( (item: any) => {
                                        return (
                                        <tr className="p1" key={item.id}>
                                            {columns.map((column) => {
                                               return (<td>{item[column.value]}</td>)
                                            })}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={35}
                            totalItemsCount={count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay datos.</div>
                    )}
                </div>
                }
            </div>
        </>
    )
}

export default ListComponent

