import { useState, useEffect, useRef } from 'react'
import { Filtro } from 'interfaces/Filtro';
import { getAttributes } from 'data/attributes';
// @ts-ignore
import Select from 'react-select';
// @ts-ignore
import uuid from 'react-uuid';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getChannelOptions } from 'data/channels';
import { getProgramOptions } from 'data/programs';
import { getCmGroupsOptions } from 'data/cm-groups';
import { getPossibleValuesByAttributeUser } from 'data/user-attributes';
import { getPossibleValuesByAttributeInstance } from 'data/instance-attributes';
import { getSequenceOptions } from 'data/sequences';
import { getBotOptions } from 'data/bots';
import ExportToExcel from './ExportToExcel';
import { exportToExcelData } from 'data/messenger-user';
import FileSaver from 'file-saver';

interface Props {
    type: string;
    onSubmit: (filtros: Filtro[]) => void
}

const AdvanceSearch = ({ type, onSubmit }: Props) => {

    const [filtros, setFiltros] = useState<Filtro[]>([]);
    const [filterDate, setFilterDate] = useState<Filtro[]>([]);
    const [attributes, setAttributes] = useState<any[]>([]);
    const [hideFilters, setHideFilters] = useState<boolean>(false);
    const [possibleValues, setPossibleValues] = useState<any[]>([]);

    const [dates, setDates] = useState({
        dateFrom: new Date(),
        dateTo: new Date(),
        dateType: '',
        id: uuid()
    });

    const [initialData] = useState({
        id: uuid(),
        search_by: "attribute",
        data_key: "",
        condition: "is",
        data_value: "",
        connector: "and"
    });

    const [options, setOptions] = useState<any[]>([]);
    const [selectOptions, setSelectOptions] = useState<any[]>([
        { id: 1, name: 'program', values: [] },
        { id: 2, name: 'group', values: [] },
        { id: 3, name: 'channel', values: [] },
        { id: 4, name: 'sequence', values: [] },
        { id: 5, name: 'bot', values: [] }
    ]);

    const ref = useRef<any>([]);
    const ref2 = useRef<any>([]);



    useEffect(() => {
        let mounted = true;

        if (mounted) {
            async function fetchData() {
                try {
                    const resp: any = await getAttributes();
                    const { data } = resp;
                    const options = data.map((item: any) => ({ value: item.id, label: item.name }))
                    setAttributes(options);
                } catch(err:any) {
                    console.log(err)
                }
            }
            fetchData();
        }

        return () => { mounted = false; }
    }, [type]);

    useEffect(() => {
        let mounted = true;

        async function fetchData() {
            const resp1: any = await getProgramOptions();
            let values1 = resp1.data.map((item: any) => ({ value: item.id, label: item.name }));
            setSelectOptions(options => options.map(opt => opt.name === 'program' ? { ...opt, values: values1 } : opt));

            const resp2: any = await getCmGroupsOptions();
            let values2 = resp2.data.map((item: any) => ({ value: item.id, label: item.name }));
            setSelectOptions(options => options.map(opt => opt.name === 'group' ? { ...opt, values: values2 } : opt));

            const resp3: any = await getChannelOptions();
            let values3 = resp3.data.results.map((item: any) => ({ value: item.id, label: item.name }));
            setSelectOptions(options => options.map(opt => opt.name === 'channel' ? { ...opt, values: values3 } : opt));

            const resp4: any = await getSequenceOptions();
            let values4 = resp4.data.results.map((item: any) => ({ value: item.id, label: item.name }));
            setSelectOptions(options => options.map(opt => opt.name === 'sequence' ? { ...opt, values: values4 } : opt));

            const resp5: any = await getBotOptions();
            let values5 = resp5.data.map((item: any) => ({ value: item.id, label: item.name }));
            setSelectOptions(options => options.map(opt => opt.name === 'bot' ? { ...opt, values: values5 } : opt));
        }

        if (mounted) {
            fetchData();
        }
        return () => { mounted = false; }
    }, []);

    const fetchDataByType = async (type: string, index: number) => {
        try {
            let data: any[] = selectOptions.find(item => item.name === type)
            const { values }: any = data

            options[index] = values;

            setOptions([...options])

            if (ref.current[index]) {
                ref.current[index].select.clearValue()
            }

        } catch(err:any) {
            console.log(err);
        }
    }

    const handleRemoveFilter = (item: any) => {
        const data = filtros.filter(i => i.id !== item.id);
        setFiltros(data);
        onSubmit([...data, ...filterDate]);
    }

    const handleAddFilter = () => {
        const idx = filtros.length;
        const nData: any[] = possibleValues;
        nData[idx] = [];
        if (attributes) {
            if (type === 'users') {
                getPossibleValuesByAttributeUser(attributes[0]?.value)
                    .then(resp => {
                        nData[idx] = resp.data.results;
                        setPossibleValues(items => [...items, nData]);
                    })
                    .catch(err => console.log(err))
            } else {
                //  instance
                getPossibleValuesByAttributeInstance(attributes[0]?.value)
                    .then(resp => {
                        nData[idx] = resp.data.results;
                        setPossibleValues(items => [...items, nData]);
                    })
                    .catch(err => console.log(err))
            }
        }
        setFiltros([...filtros, { ...initialData, id: uuid(), data_key: attributes[0]?.value }]);
    }

    const handleChange = (e: any, index: number) => {
        const currentData = filtros[index];
        const data = { ...currentData, [e.target.name]: e.target.value }
        const updateFilters = filtros.map(item => item.id === data.id ? data : item);

        const opciones = ['program', 'group', 'channel', 'sequence', 'bot'];

        let idx = opciones.indexOf(e.target.value);

        if (idx !== -1) {
            fetchDataByType(e.target.value, index);
        }

        setFiltros(updateFilters);
    }

    const handleOptionsChange = (values: any, index: number) => {
        const { value = "" } = values;
        const data = { ...filtros[index], data_value: value }
        const newData = filtros.map(item => item.id === data.id ? data : item);
        setFiltros(newData);
    }

    const handleSelectChange = (values: any, index: number) => {
        const { value } = values;

        // get possible values
        const nData: any[] = possibleValues;
        nData[index] = [];

        if (ref2.current[index]) {
            ref2.current[index].select.clearValue()
        }

        if (type === 'users') {
            getPossibleValuesByAttributeUser(value)
                .then(resp => {
                    nData[index] = resp.data.results;
                    setPossibleValues(items => [...items, nData]);
                })
                .catch(err => console.log(err))
        } else {
            //  instance
            getPossibleValuesByAttributeInstance(value)
                .then(resp => {
                    nData[index] = resp.data.results;
                    setPossibleValues(items => [...items, nData]);
                })
                .catch(err => console.log(err))
        }

        const data = { ...filtros[index], data_key: value }
        const newData = filtros.map(item => item.id === data.id ? data : item)
        setFiltros(newData);

    }

    const handleFilterDateReset = (e: any) => {
        setDates({ ...dates, dateType: '', dateTo: new Date(), dateFrom: new Date() });
        setFilterDate([]);
        onSubmit(filtros);
    }

    const handleFilterByDate = async () => {
        const { dateType, dateFrom, dateTo } = dates;

        if (!dateType) {
            await swal("Afinidata", 'Escoja el campo date para filtrar', 'warning');
            return;
        }

        const idx = filterDate.map(item => item.id).indexOf(dates.id);
        let newData = [];
        if (idx === -1) {
            newData = [...filterDate, {
                id: dates.id,
                search_by: "dates",
                data_key: dateType,
                condition: "",
                data_value: "",
                connector: "",
                date_from: dateFrom.toISOString().slice(0, 10),
                date_to: dateTo.toISOString().slice(0, 10),
            }]
            setFilterDate(newData);
        } else {
            newData = filterDate.map(item => item.id === filterDate[idx].id ? {
                ...filterDate[idx],
                data_key: dateType,
                date_from: dateFrom.toISOString().slice(0, 10),
                date_to: dateTo.toISOString().slice(0, 10)
            } : item);
            setFilterDate(newData);
        }
        onSubmit([...filtros, ...newData]);
    }

    const handleTypeDateSelectChange = (e: any) => {
        setDates({ ...dates, dateType: e.target.value })
    }

    const handlePossibleValueChange = (index: number, values: any) => {
        if (values) {
            const { value } = values;

            const evt: any = {
                target: {
                    name: 'data_value',
                    value: value
                }
            }
            handleChange(evt, index)
        }
    }

    const handleExportToExcel = (listAttr: any[]) => {
        exportToExcelData({
            opcion: "excel",
            filtros,
            attributes_cols: listAttr
        }).then((resp: any) => {
            let url = window.URL.createObjectURL(new Blob([resp.data]));
            FileSaver.saveAs(url, 'archivo.xlsx');
        })
            .catch(err => console.log(err));
    }

    const handleApplyFilter = () => {
        onSubmit([...filtros, ...filterDate])
    }

    const { dateFrom, dateTo } = dates;

    return (
        <>
            <ExportToExcel onFinish={handleExportToExcel} />

            <div className="search mb-2" style={{ border: '1px dashed #eee', padding: '10px', boxShadow: '0 0 5px 0 rgab(0,0,0,.5)' }}>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <button className="btn btn-success btn-sm"
                            type="button"
                            onClick={handleAddFilter}>
                            <i className="fa fa-plus"></i> Agregar filtros
                        </button>
                        {filtros.length > 0 &&
                            <button
                                type="button" className="btn btn-primary btn-sm ml-2"
                                onClick={() => setHideFilters(prev => !prev)} >
                                <span>
                                    {hideFilters ? (
                                        <span><i className="fa fa-eye"></i> Mostrar filtros</span>
                                    ) : (
                                        <span><i className="fa fa-eye-slash"></i> Ocultar filtros</span>
                                    )}
                                </span>
                            </button>
                        }
                    </div>
                </div>

                {!hideFilters &&
                    <>
                        {filtros.map((item: any, index) => (
                            <div className="form-group row" key={item.id}>
                                <div className="col-md-2">
                                    <select name="search_by"
                                        className="form-control"
                                        placeholder="Search by"
                                        defaultValue={item.search_by}
                                        onChange={(e) => handleChange(e, index)}>
                                        <option value="attribute" >Attribute</option>
                                        <option value="bot" >Bot</option>
                                        <option value="channel" >Channel</option>
                                        <option value="group" >Group</option>
                                        <option value="program" >Program</option>
                                        <option value="sequence" >Sequence</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <Select name="data_key"
                                        options={attributes}
                                        defaultValue={attributes[0]}
                                        onChange={(val: any) => handleSelectChange(val, index)}
                                        isDisabled={item.search_by !== 'attribute'}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <select name="condition" className="form-control"
                                        value={item.condition}
                                        onChange={(e) => handleChange(e, index)}>
                                        <option value="is">Is</option>
                                        <option value="is_not">Is not</option>
                                        {item.search_by === 'attribute' &&
                                            <>
                                                <option value="is_set">Is set</option>
                                                <option value="not_set">Not set</option>
                                            </>
                                        }
                                        {item.search_by === 'sequence' &&
                                            <>
                                                <option value="was">Was</option>
                                                <option value="was_not">Was not</option>
                                            </>
                                        }
                                        {item.search_by !== 'sequence' &&
                                            <>
                                                <option value="startswith">Starts with</option>
                                                <option value="gt">Greater than</option>
                                                <option value="lt">Less than</option>
                                            </>
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    {item.search_by === 'attribute' &&
                                        <>
                                            <input type="text" name="data_value" className="form-control" placeholder="Value"
                                                value={item.data_value}
                                                disabled={item.condition === 'is_set' || item.condition === 'not_set'}
                                                onChange={(e) => handleChange(e, index)} />
                                            <hr />

                                            {possibleValues[index] &&
                                                <Select
                                                    options={possibleValues[index].map((item: any) => ({ label: item.value, value: item.value }))}
                                                    onChange={(values: any) => handlePossibleValueChange(index, values)}
                                                    ref={(el: any) => ref2.current[index] = el} />
                                            }
                                        </>
                                    }

                                    {item.search_by !== 'attribute' &&
                                        <Select
                                            name="data_value"
                                            ref={(el: any) => ref.current[index] = el}
                                            options={options[index]}
                                            defaultValue={item.data_value}
                                            onChange={(val: any) => handleOptionsChange(val || "", index)}
                                        />
                                    }
                                </div>
                                <div className="col-md-1">
                                    <select name="connector" className="form-control"
                                        value={item.connector}
                                        onChange={(e) => handleChange(e, index)}
                                        disabled={index === filtros.length - 1}>
                                        <option value="and">And</option>
                                        <option value="or">Or</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleRemoveFilter(item)}><i className="fa fa-trash"></i></button>
                                </div>
                            </div>

                        ))}

                        {filtros.length > 0 &&
                            <div className="row my-3">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={handleApplyFilter}
                                    ><i className="fa fa-search"></i> Aplicar filtros</button>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <p className="mt-4">Filtrar por rango de fecha:</p>
            <div className="form-group row">
                <div className="col-md-3 col-sm-12">
                    <select name="date_type"
                        className="form-control"
                        onChange={handleTypeDateSelectChange}
                        value={dates.dateType}
                    >
                        <option value="">Selecciona campo fecha</option>
                        <option value="created_at">Created at</option>
                        {type === 'users' && (
                            <>
                                <option value="last_user_message">Last message</option>
                                <option value="last_channel_interaction">Last interaction</option>
                                <option value="window">24hr window</option>
                            </>
                        )}
                    </select>
                </div>
                <div className="col-md-3 col-sm-12">
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={(value: any) => setDates({ ...dates, dateFrom: new Date(value) })}
                        className="form-control"
                        placeholderText="Fecha desde"
                        selected={dateFrom}
                    />
                </div>
                <div className="col-md-3 col-sm-12">
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={(value: any) => setDates({ ...dates, dateTo: new Date(value) })}
                        className="form-control"
                        placeholderText="Fecha hasta"
                        selected={dateTo}
                    />
                </div>
                <div className="col-md-3 col-sm-12">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleFilterByDate}
                    ><i className="fa fa-search"></i></button>
                    <button className="btn btn-danger ml-2"
                        type="button"
                        onClick={(e) => handleFilterDateReset(e)}><i className="fa fa-eraser"></i></button>
                </div>
            </div>
        </>
    )
}

export default AdvanceSearch
