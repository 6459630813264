import Loading from "components/Loading";
import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { deleteMilestoneConfig } from "services/milestones-programs.service";
import swal from "sweetalert"

type Params = {
    id: string;
    programId: string;
}

const DeleteConfiguracionMilestone = () => {
    const {id, programId} = useParams<Params>();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const handleDelete = async () => {
        try{
            setLoading(true);
            await deleteMilestoneConfig(id);
            await swal("Imaginary success message",{icon: "success"});
            history.push(`/admin/milestones/detalle-programa/${programId}`);
        } catch(err:any){
            swal("Ocurrió un error",{icon: "error"});
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>Borrar configuración de milestone</h4>
            </div>
            <div className="card-body">
                <h5>¿Esta seguro que quiere borrar la configuracion botnar milestone {id}?</h5>
                {loading &&
                    <Loading text="Borrando..." size="2rem"/>
                }
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <Link className="btn btn-secondary"
                        type="button"
                        to={`/admin/milestones/detalle-programa/${programId}`}>
                            Cancel
                        </Link>
                        <button className={`btn btn-danger ml-2 ${loading && 'disabled'}`}
                        type="button"
                        disabled={loading}
                        onClick={handleDelete}>
                            <span>Borrar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfiguracionMilestone
