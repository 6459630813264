import axios from 'axios';

const URL = process.env.REACT_APP_API_URL_WEBHOOK

export const saveBotChannel = (payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${URL}/bot_channels/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const updateBotChannel = (id: string, payload: object): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${URL}/bot_channels/${id}/`, payload)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const deleteBotChannel = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${URL}/bot_channels/${id}/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getBotChannel = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/bot_channels/${id}`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

export const getDetailedBotChannels = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/bot_channels/?detail=true`);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}
