import { createContext, useState } from 'react'

type Value = {
    messengerUser: any;
    setMessengerUser: (value: any) => void;
}

export const MessengerUserContext = createContext({} as Value);

type Props = {
    children: JSX.Element
}

const UserContextProvider = ({ children }: Props) => {
    const [messengerUser, setMessengerUser] = useState<any>({});

    return (
        <MessengerUserContext.Provider value={{ messengerUser, setMessengerUser }}>
            {children}
        </MessengerUserContext.Provider>
    )
}

export default UserContextProvider
