import { deleteProgram, detailProgram } from 'data/programs';
import { Program } from 'interfaces/Program';
import { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from "react-router-dom"
import Loading from '../../components/Loading';
import swal from 'sweetalert';

interface IProps {
    id: string
}

const DeleteProgram = ({ match }: RouteComponentProps<IProps>) => {
    const id = match.params.id;
    const [program, setProgram] = useState<Program>({ id: '', name: '', description: '' });
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        detailProgram(id)
            .then(resp => {
                setProgram(resp.data)
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, [id])

    const handleClick = async () => {
        try {
            await deleteProgram(id);
            await swal('Afinidata', 'El program fue borrado correctamente', 'success');
            history.push('/admin/programs');
        } catch(err:any) {
            swal('Afinidata', 'Ocurrio un error al borrar el program', 'error');
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4>¿Realmente deseas borrar el programa?</h4>
                            <p className="text-danger">¡Cuidado! Esta acción no se puede deshacer.</p>
                        </div>
                        <div className="card-body">
                            <p><b>Id:</b>{program.id}</p>
                            <p><b>Name:</b> {program.name}</p>
                            <p><b>Description:</b> {program.description}</p>
                            <div className="buttons text-right">
                                <button className="btn btn-danger btn-sm"
                                    onClick={handleClick}>Confimar</button>
                                <a className="btn btn-dark btn-sm ml-2"
                                    href="/admin/programs/">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteProgram
