import { useTranslation } from "react-i18next"

interface IProps {
    text?: string,
    size?: string
}

const Loading = ({text, size = "4rem"}:IProps) => {
    const {t} = useTranslation();

    return (
        <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status" style={{ height:size, width: size}}>
             <span className="sr-only">Loading...</span>
            </div>
            <h4 className="mt-3">{text ? text : t("cargando")}</h4>
        </div>
    )
}

export default Loading
