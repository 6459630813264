import axios from 'axios';
import { Entity } from 'interfaces/Entity';

const URL = process.env.REACT_APP_API_URL_CM

/** get entities list */
export const getEntitiesList = (search = '', page = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${URL}/entities/?name=${search}&page=${page}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/** actualizar entidad */
export const updateEntity = (id: string, payload: Entity): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/entities/${id}/`;
        try {
            const resp = await axios.put(endpoint, payload);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}


/** obtener Entity por id */
export const getEntityById = (id: string): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/entities/${id}`;
        try {
            const resp = await axios.get(endpoint);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}


/** agregar nuevo Entity */
export const addEntity = (payload: Entity): Promise<any> => {
    return new Promise( async (resolve, reject ) => {
        let endpoint = `${URL}/entities/`;
        try {
            const resp = await axios.post(endpoint,payload);
            resolve(resp);
        } catch( err ){
            reject(err);
        }
    })
}


