import FormModal from "components/FormModal"
import { IFormModalField } from "interfaces/FormModal";
import { useContext } from "react";
import { MessengerUserContext } from "../MessengerUserContextProvider";
import * as Yup from "yup";
import { sendPushNotification } from "services/mobile-app.service";

interface IProps {
    showFormModal: boolean,
    onCloseFormModal: () => void
}
const SendPushModal = ({showFormModal, onCloseFormModal}: IProps) => {
    const { messengerUser } = useContext(MessengerUserContext);

    const PushFields: IFormModalField[] = [
        {
            label: "",
            value: messengerUser.user_channel_id,
            name: "user_channel_id",
            validation: Yup.string().required("El user channel id es requerido seleccionelo desde el live chat"),
            params: {
                disabled: true,
                hidden: true
            }
        },
        {
            label: "User Id",
            value: messengerUser.id,
            name: "user_id",
            validation: Yup.string().required("El user id es requerido seleccionelo desde el live chat"),
            params: {
                disabled: true
            }
        },
        {
            label: "Título",
            value: "",
            name: "title",
            validation: Yup.string().required("El título es requerido"),
        },
        {
            label: "Texto",
            value: "",
            name: "message",
            validation: Yup.string().required("El texto es requerido"),
        },
        {
            label: "mandar actividad",
            value: false,
            name: "is_activity",
            type: "checkbox"
        }
    ]

    return (
        <FormModal show={showFormModal} handleClose={onCloseFormModal} title="Mandar Push" fields={PushFields} handleSubmit={async (payload: any) => {
            try {
                await sendPushNotification(payload)
            } catch (err: any) {
                throw new Error(err.response.data)
            }
            return "Push Notification enviada!"
        } } sendText="Enviar Push Notification"></FormModal>
    )
}

export default SendPushModal