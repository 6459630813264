import { IRoute } from '../interfaces/Route';
import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import RetrainList from 'pages/retrain/RetrainList';
import Retrain from 'pages/retrain/Retrain';

export const RETRAIN_ROUTE: IRoute[] = [
    {
        path: '/admin/retrain',
        component: RetrainList,
        codename: 'retrain_main',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/retrain/:id',
        component: Retrain,
        codename: 'retrain_edit',
        exact: true,
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
]
