import Loading from '../../components/Loading';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { t } from 'i18next';
import FormikField from 'components/FormikField';
import { saveImage, saveVideos } from 'services/content-manager.service';
import swal from 'sweetalert';


interface props {
    closeCallback: (isSaved: boolean) => void,
    isShowed: boolean,
    type?: string
}

const AddMediaModal = ({closeCallback, isShowed, type='image'}: props) => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("El nombre es requerido"),
        file: Yup.mixed().required("El archivo es requerido").test(`is-${type}`, `El archivo debe ser ${type === 'image'? "una imagen": "un video"}`, 
                                    value => value && value.type && value.type.startsWith(type))
    });
    const [loading, setLoading] = useState(false);
    
    const formik = useFormik({
        initialValues: {name:'', file: null},
        validationSchema,
        enableReinitialize: true,
        onSubmit: function (values) {
            setLoading(true)
            var formData = new FormData();
            if (values.file) formData.append(type, values.file);
            formData.append("name", values.name)
            const saveFunction = type === 'image'? saveImage: saveVideos
            saveFunction(formData)
                .then((res: any) => {
                    swal({title: "Completado!", content: {element: "a", attributes: {href: res.data.link, text: res.data.link}}})
                    handleCloseModal(true)
                })
                .catch((err) => {
                    swal("error","a ocurrido un error inesperado",  "error")
                })
                .finally(() => setLoading(false))
        }
    });

    const handleCloseModal = (isSaved: boolean = false) => {
        formik.resetForm()
        closeCallback(isSaved)
    }

    return (
        <Modal show={isShowed}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => handleCloseModal()}>
            <div className="modal-header mt-1">
                <div className='h3'>Subir {type === 'image' ?"Imagen": "Video"}</div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            {loading ?
                <Loading size="2rem" />
                :
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-12'>
                                    <FormikField label='Nombre' formName='name' formik={formik}/>
                                </div>
                                <div className='col-12'>
                                    <FormikField label='Archivo' formName='file' formik={formik} type='file'/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={!formik.isValid} className="btn btn-info">
                                {t("guardar_cambios")} <i className="far fa-save"></i>
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => handleCloseModal()}>
                                {t("regresar")} <i className="fas fa-reply"></i>
                            </button>
                        </div>
                    </form>
                </>
            }
        </Modal>)
};

export default AddMediaModal;
