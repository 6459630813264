import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "components/Loading";
import swal from "sweetalert";
import { getDetailProgramAttributeType, updateProgramAttributeTypes } from "data/programs-attribute-types";
import { ProgramAttributeTypes } from "interfaces/Program";
import { Entity } from 'interfaces/Entity';
import { getEntitiesList } from '../../data/entities';

type Props = {
    programId: string,
    programAttributeTypeId: string
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(50),
    entity: Yup.string().required(),
    description: Yup.string().required().max(150),
    weight: Yup.number().required()
});


const EditProgramAttributeType = ({match}:RouteComponentProps<Props>) => {

    let init = { id: "", program: "", entity: "", name: "", description: "", weight: 0, attributes_set:[]};
    const [initialValues, setInitialValues] = useState<ProgramAttributeTypes>(init);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ entities, setEntities ] = useState<Entity[]>([]);
    const history = useHistory();
    const programId = match.params.programId;
    const typeId = match.params.programAttributeTypeId;
    const returnUrl = `admin/programs/configure/${programId}`;

    useEffect(() => {
        getDetailProgramAttributeType(typeId)
        .then( resp => {
            setInitialValues(resp.data.results[0]);
            setLoading(false);
        })
        .catch( err => {console.log(err); history.push("/admin/programs"); });

        getEntitiesList()
        .then( (resp:any) => {
            const { data } = resp;
            setEntities(data.results);
        })
        .catch( err => {console.log(err); history.push("/admin/programs"); });

    }, [programId, typeId, history]);

    const handleSubmit = async (payload: any): Promise<void> => {
        try{
            await updateProgramAttributeTypes(typeId, payload);
            await swal("Afinidata","Tipo de atributo de Programa actualizado.","success")
            history.push(returnUrl);
        }catch(err:any){
            swal("Afinidata",'Ocurrió un error intente de nuevo','error');
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <div className="text-right">
                                <a href={returnUrl} type="button"
                                    className="btn btn-outline-primary btn-sm ml-2">
                                    <i className="fa fa-arrow-left"></i> Volver a configuración
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="font-weight-bold">Editar Tipo de Atributo de Programa</h6>
                            <hr/>
                            { initialValues && !loading ? (
                                    <div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" name="name" placeholder="Enter name"
                                                className={`form-control ${
                                                    formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                                }`}
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="entity">Entity</label>
                                                <select name="entity"
                                                className={`form-control ${
                                                    formik.errors.entity && formik.touched.entity ? "is-invalid" : ""
                                                }`}
                                                value={formik.values.entity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}>
                                                    <option value="">-- select entity --</option>
                                                    {entities.map( (item,i) => (
                                                        <option value={item.id} key={i}>{item.name}</option>
                                                    ))}
                                                </select>
                                                {formik.errors.entity && formik.touched.entity ? (
                                                    <div className="invalid-feedback">{formik.errors.entity}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <input type="text" name="description" placeholder="Enter la description"
                                                className={`form-control ${
                                                    formik.errors.description && formik.touched.description ? "is-invalid" : ""
                                                }`}
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="weight">Weight</label>
                                                <input type="text" name="weight" placeholder="Enter weight"
                                                className={`form-control ${
                                                    formik.errors.weight && formik.touched.weight ? "is-invalid" : ""
                                                }`}
                                                value={formik.values.weight}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <button type="submit"
                                                    className="btn btn-primary">{/* 
                                                // @ts-ignore */}
                                                        <FontAwesomeIcon icon={faSave} /> Actualizar
                                                    </button>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <Loading />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EditProgramAttributeType
