import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faList } from '@fortawesome/free-solid-svg-icons';
import { Program } from '../../interfaces/Program';
import { bulkCreateRisks, getProgramsList } from '../../data/programs';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';
import Loading from 'components/Loading';
import ExcelBulkModal from 'components/ExcelBulkModal';

const CM_URL = process.env.REACT_APP_CM

const ListProgram = (props: any) => {
    const [programs, setPrograms] = useState<Program[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState<string>('');
    const [activePage, setActivePage] = useState(Number(localStorage.getItem('programPostsCurrentPage')) || 1);
    const configureURL = props.match.path ? `${props.match.path}/configure/` : '';

    useEffect(() => {
        let mounted = true;
        getProgramsList(search, 1)
            .then((resp: any) => {
                if(mounted){
                    const { results, count } = resp.data;
                    setPrograms(results);
                    setLoading(false);
                    setCount(count);
                }
            })
            .catch( (err: any) => console.log(err));

            return function cleanup(){
                mounted = false;
            }
        },[search]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        const resp: any = await getProgramsList(search, page);
        const { results, count } = resp.data;
        setCount(count);
        setPrograms(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <>
            <ExcelBulkModal modalId="riskCreateModal" title="Crear Riesgos"
                urlFormat={`${CM_URL}/static/excels/formatRisks.xlsx`}
                successMessage="Riesgos creados satisfactoriamente"
                sendCallback={function (risks: Object[]): Promise<any> {
                    return bulkCreateRisks(risks)
                }}/>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h4 className="card-title">Listado de programas</h4>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <button className="btn btn-outline-primary float-right m-3" title="Crear Riesgos"
                                data-toggle="modal" data-target="#riskCreateModal">
                                    <em className="fas fa-globe-americas"></em> Crear Riesgos
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    { programs && programs.length > 0 ? (
                        <>
                        <div className="table-responsive">
                            <table className="table"
                            id="data">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">Id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { programs.map( item => (
                                        <tr className="p1" key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td className="text-right">
                                                <div className="row">
                                                    <div className="col-xs-4 col-md-12">
                                                        <Link
                                                            to={`/admin/program_attribute_types/${item.id}/activities`}
                                                            title="Configurar programa"
                                                            className="btn btn-outline-secondary btn-sm mb-1"
                                                            style={{ width: '120px' }}>{/* 
                                                        // @ts-ignore */}
                                                            <FontAwesomeIcon icon={faList} /> <span>Actividades</span>
                                                        </Link>
                                                        {configureURL &&
                                                            <Link
                                                                to={configureURL + item.id}
                                                                title="Configurar programa"
                                                                className="btn btn-outline-primary btn-sm mb-1"
                                                                style={{ width: '120px' }}>
                                                                <FontAwesomeIcon icon={faCog} /> <span>Configurar</span>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={35}
                            totalItemsCount={count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay programas.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListProgram

