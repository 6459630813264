"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useIsMounted() {
    var ref = react_1.useRef(false);
    react_1.useEffect(function () {
        ref.current = true;
        return function () {
            ref.current = false;
        };
    }, []);
    return react_1.useCallback(function () { return ref.current; }, [ref]);
}
exports.default = useIsMounted;
