import SearchForm from "components/SearchForm"
import { useCallback, useEffect, useState } from "react"
import Pagination from "react-js-pagination";
import { getArticles, setArticleAsFeatured } from "services/content-manager.service";
import swal from 'sweetalert';

interface ArticleI {
    id: number,
    name: string,
    content: string,
    thumbnail: string,
    slug: string,
    featured: boolean
}

const ArticleLists = () => {
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const [articles, setArticles] = useState<ArticleI[]>([]);


    const fetchArticles = useCallback((page, search?) => {
        async function fetchData(page:number, search?: string){
            try{
                setActivePage(page || 1);
                setArticles([]);
                const res:any = await getArticles(page, {search: search || ""});
                setCount(res.data.count);
                setArticles(res.data.results);
            } catch(e){
                console.log(e)
            }
        }

        fetchData(page, search);
        
    },[])


    useEffect(() => {
        setTimeout(() => {
            fetchArticles(1, search)
        },800)
    },[search, fetchArticles])

    
    useEffect(() => {
        (async() => {
            try{
                const res:any = await getArticles();
                console.log(res.data.results);
                setCount(res.data.count);
                setActivePage(1);
                setArticles(res.data.results);
            } catch(e){
                console.log(e)
            }
        })()
    },[])

    
    const handlePageChange = async (page: number) => {
        fetchArticles(page);
    }

    const handleUpdate = async (item: ArticleI) => {
        try{
            const newData = articles.map( (obj:ArticleI) => obj.id === item.id ? {...obj, featured: !obj.featured } : obj);
            setArticles(newData);
            const { id, featured } = item;
            await setArticleAsFeatured(id, !featured);
            swal('Afinidata', 'Article actualizado!', 'success');
        } catch(e){
            swal('Afinidata', 'Ocurrió un error', 'error');
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <SearchForm search={search} setSearch={setSearch}/>
            </div>
            <div className="card-body">
                <h3>Listado de articulos</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Content</th>
                            <th>Destacado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((item: ArticleI) =>  (
                            <tr>
                                <td><img src={item.thumbnail} alt="" width="60" height="60"/></td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.content}</td>
                                <td className="mx-auto">
                                    <div>
                                        { item.featured && (<span className="badge badge-success"><i className="fa fa-check"></i> Destacado</span>) }
                                        { !item.featured && (<span className="badge badge-warning"><i className="fa fa-times"></i> No Destacado</span>) }
                                    </div>
                                    <button type="button" className={`btn ${item.featured ? 'btn-danger' : 'btn-success'} btn-sm`}
                                    onClick={() =>  handleUpdate(item)}>
                                        { !item.featured && (<span>Agregar</span>) }
                                        { item.featured && (<span>Remover</span>) }
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* TODO: remove this later */}
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={30}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                />
            </div>
        </div>
    )
}

export default ArticleLists