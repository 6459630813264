import {useState, useEffect } from 'react';
import { getAttributes } from 'data/attributes';
import '../people.css';
import Loading from 'components/Loading';


interface IProps {
    onFinish: (values:any[]) => void
}
const ExportToExcel = ({onFinish}:IProps) => {
    const [showList, setShowList] = useState(false);
    const [listAttr, setListAttr] = useState<any>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [loading, setLoading ] = useState(true);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            async function fetchData(){
                try{
                    const resp:any = await getAttributes();
                    const { data } = resp;
                    setListAttr(data);
                    setLoading(false);
                } catch(err:any){
                    console.log(err);
                    setLoading(false);
                }
            }
            fetchData();
        }

        return () => { mounted = false;}
    },[]);

    const handleOnChange = (e:any) => {
        if(e.target.checked){
            const idx  = selected.findIndex( item => item === e.target.value );
            if(idx === -1){
                setSelected([...selected, e.target.value]);
            }
        } else {
            const idx  = selected.findIndex( item => item === e.target.value );
            if(idx > -1){
                const newData = selected.filter( item => item !== e.target.value);
                setSelected(newData);
            }
        }
    }

    const checkAll = () => {
        const inputs = document.querySelectorAll('input[type=checkbox]');
        if (inputs.length > 0){
            inputs.forEach( (input) => (input as HTMLInputElement).checked = true );
            setSelected(listAttr.map((item:any) => item.id))
        }
    }

    const unCheckAll = () => {
        const inputs = document.querySelectorAll('input[type=checkbox]');
        if (inputs.length > 0){
            inputs.forEach( (input) => (input as HTMLInputElement).checked = false );
            setSelected([])
        }
    }

    if(loading){
        return <Loading />
    }

    return (
        <div className="row justify-content-end" style={{position:'relative'}}>
            <div className="col-sm-12">
                <div className="text-right">
                    <button className="btn btn-success mb-1"
                    onClick={() =>  setShowList(!showList)}><i className="fa fa-file-excel" aria-hidden="true"></i> Exportar a excel</button>
                </div>
                <div className='export-to-excel' style={{position:'absolute',zIndex:999,display: showList ? 'block' : 'none'}}>
                    <ul className="list-group list-group-flush">
                        {listAttr.map((item:any) => (
                            <li className="list-group-item" key={item.id}>
                                <label htmlFor="">
                                    <input type="checkbox"
                                    name={item.name}
                                    value={item.id}
                                    onChange={(e) => handleOnChange(e)}
                                    /> {item.name.toLowerCase()}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <div className="mb-4 text-center">
                        <button className="btn btn-primary btn-sm"
                        onClick={checkAll}
                        type="button">Marcar todos</button>
                        <button className="btn btn-primary btn-sm ml-2"
                        onClick={unCheckAll}
                        type="button">Desmarcar todos</button>
                        <button className="btn btn-success btn-sm ml-2"
                        onClick={() => onFinish(selected)}
                        disabled={selected.length === 0}
                        type="button"><i className="fa fa-save"></i> Generar</button>
                    </div>
                    <div className="text-center">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExportToExcel
