import * as Yup from "yup";
import swal from 'sweetalert';
import { useFormik } from 'formik';
import { useEffect, useState } from "react"
import { Usuario } from "interfaces/Usuario";
import Loading from '../../components/Loading';
import { passwordSet, verifyActivationToken } from "data/users"
import { RouteComponentProps, useHistory } from "react-router-dom"

type Props = {
    token: string
}

const validationSchema = Yup.object().shape({
    password: Yup.string().min(6,'Minimo 6 caracteres').required('Clave es requerida'),
    password_confirm: Yup.string()
       .oneOf([Yup.ref('password'), ''], 'Las claves no coinciden')
});

const ActivateUser = ({match}:RouteComponentProps<Props>) => {
    const token = match.params.token;
    const [user, setUser] = useState<Usuario>({email:'',username:''})
    const initialValues = {
        password: '',
        password_confirm: ''
    };
    const [loading, setLoading ] = useState<boolean>(true);
    const history = useHistory();

    const storeNewPassword = async (values: any) => {
        try{
            await passwordSet(Number(user.id), values);
            swal('Afinidata','Su clave se ha creado correctamente','success')
            .then(() =>  history.push("/"))
        } catch(err:any){
            console.log(err)
            swal('Afinidata','Ocurrio un error, intente de nuevo.','error');
        }
    }

    useEffect(() => {
        let mounted = true;

        if(mounted){
            const fetchData = async() =>{
                try{
                    const resp:any = await verifyActivationToken(token);
                    if(!resp.data.ok){
                        swal('Afinidata','Usuario no encontrado o token inválido','error')
                        .then(() =>  history.push("/"))
                    }
                    setUser(resp.data.user);
                    setLoading(false);
                } catch(err:any){
                    console.log(err)
                    swal('Afinidata','Usuario no encontrado o token inválido','error')
                    .then(() =>  history.push("/"))
                }
            }
            fetchData();
        }

        return function cleanUp(){
            mounted = false;
        }
    },[history, token]);


    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            storeNewPassword(values);
        }
    });

    if(loading){
        return <Loading />
    }

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card">
                            <div className="card-header">
                                <img src="/img/logo.png" alt=""
                                width="290" height="auto"
                                style={{ display:'block', margin: '10px auto'}}/>
                            </div>
                            <div className="card-body">
                                { user &&
                                    <>
                                        <h4>Crear contraseña</h4>
                                        <hr/>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="">Username</label>
                                                <p className="static-control">{user.username}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Clave</label>
                                                <input type="password"
                                                placeholder="Ingrese clave"
                                                name="password"
                                                value={formik.values.password}
                                                className={`form-control ${
                                                    formik.errors.password && formik.touched.password ? "is-invalid" : ""
                                                }`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                                {formik.errors.password && formik.touched.password ? (
                                                    <div className="invalid-feedback">{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Confirmar clave</label>
                                                <input type="password"
                                                name="password_confirm"
                                                placeholder="confirme su clave"
                                                value={formik.values.password_confirm}
                                                className={`form-control ${
                                                    formik.errors.password_confirm && formik.touched.password_confirm ? "is-invalid" : ""
                                                }`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}/>
                                                {formik.errors.password_confirm && formik.touched.password_confirm ? (
                                                    <div className="invalid-feedback">{formik.errors.password_confirm}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block"
                                                type="submit">Guardar</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivateUser
