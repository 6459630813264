import swal from 'sweetalert';
// @ts-ignore
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Loading from '../../components/Loading';
import { Sequence } from 'interfaces/Sequence';
import { getDateAttributesList } from 'data/attributes';
import { getSessionsList } from 'data/sessions';
import { getSequenceById, updateHotTriggerPositions, getTagsList } from 'data/sequences';
import "react-datepicker/dist/react-datepicker.css";
import { RouteComponentProps } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { deleteHotTrigger, getHotTriggersList, storeHotTrigger, updateHotTrigger } from 'data/hot-triggers';
import { ReactSortable } from "react-sortablejs";
// @ts-ignore
import Select from 'react-select';
import './style.css';

interface Props {
    id: string;
}

const daysOfWeek = [
    {id: 1, name:'lunes'},
    {id: 2, name: 'martes'},
    {id: 3, name: 'miércoles'},
    {id: 4, name: 'jueves'},
    {id: 5, name: 'viernes'},
    {id: 6, name: 'sábados'},
    {id: 0, name: 'domingos'}
];

const HotTriggersPage = ({ match }: RouteComponentProps<Props>) => {
    const id = match.params.id;

    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes();

    const initialValue = {
        sequence: id,
        position: 1,
        session_id: 1,
        session_start_position: 0,
        trigger_type: 2,
        date: null,
        interval: 4,
        delta: 1,
        repeat: 1,
        tags: [],
        base_date_attribute: null,
        hour: null,
        stop_afters: 0,
        weeks: 1
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingS, setLoadingS] = useState<boolean>(true);
    const [tags, setTags] = useState<any[]>([]);
    const [dateAttributes, setDateAttributes] = useState<any[]>([]);
    const [hotTriggers, setHotTriggers] = useState<any[]>([]);
    const [sequence, setSequence] = useState<Sequence>({ name: '', description: '' });
    const [sessions, setSessions] = useState<any[]>([]);

    const fetchHotTriggers = useCallback(() => {
        setLoading(true);
        getHotTriggersList(id).then(resp => {
            const { results } = resp.data;
            setHotTriggers(results.map((item: any) => ({ ...item, edit: false })));
            setLoading(false);
        })
            .catch(err => {
                console.log("aqui", err)
            });
    }, [id])

    useEffect(() => {
        let mounted = true;

        if (mounted) {

            fetchHotTriggers();

            getSequenceById(id)
                .then(resp => setSequence(resp.data))
                .catch(err => console.log(err));

            getTagsList()
                .then(resp => setTags(resp.data.results))
                .catch(err => console.log(err));

            getDateAttributesList()
                .then((resp: any) => setDateAttributes(resp.data.results))
                .catch(err => console.log(err));

            getSessionsList()
                .then(resp => {
                    setSessions(resp.data)
                    setLoadingS(false);
                })
                .catch(err => console.log(err));
        }

        return function cleanUp() {
            mounted = false;
        }

    }, [fetchHotTriggers, id]);

    const handleAddtriggers = async () => {
        try {
            const lastEl = hotTriggers[hotTriggers.length - 1];
            if (lastEl) {
                initialValue.position = lastEl.position + 1;
            }
            const resp = await storeHotTrigger(initialValue);
            setHotTriggers([...hotTriggers, resp.data]);
            await swal('Afinidata', "Hot trigger agregado!", 'success');
        } catch(err:any) {
            console.log(err)
        }
    }

    const handleTriggerTypeChange = (evt: any, idx: number) => {
        const elemento = hotTriggers[idx];
        setHotTriggers(hotTriggers.map((item: any) => item.id === elemento.id ? { ...item, trigger_type: Number(evt.target.value) } : item))
    }

    const handleChange = (e: any, item: any) => {
        // eslint-disable-next-line
        if(e.target.name === 'date_or_attribute' && (item.base_date_attribute === null || item.base_date_attribute === "" || item.hour === null)){
            const elemento = { ...item, 'base_date_attribute': dateAttributes[0].id, 'hour': time, [e.target.name]: e.target.value };
            setHotTriggers(hotTriggers.map(item => item.id === elemento.id ? elemento : item));
        }else {
            const elemento = { ...item, [e.target.name]: e.target.value };
            setHotTriggers(hotTriggers.map(item => item.id === elemento.id ? elemento : item));
        }

    }

    const handleMultipleChange = (val: any, element: any, item: any) => {
        const elemento = { ...item, [element.name]: val };
        setHotTriggers(hotTriggers.map(h_trigger => h_trigger.id === elemento.id ? elemento : h_trigger));
    }

    const toogleBtnEdit = (idx: number) => {
        const elemento = hotTriggers[idx];
        if (elemento.edit) {
            updateHotTrigger(elemento)
                .then(() => swal("Afinidata", "Hot triggers actualizado!", "success"))
                .catch(err => console.log(err));
        }
        setHotTriggers(hotTriggers.map((item: any) => item.id === elemento.id ? { ...item, edit: !item.edit } : item));
    }

    const handleDelete = async (item: any) => {
        try {
            await deleteHotTrigger(item);
            await swal("Afinidata", "Hot triggers eliminado!", "success");
            setHotTriggers(hotTriggers.filter(i => i.id !== item.id));
        } catch(err:any) {
            console.log(err);
        }
    }

    const handleUpdateList = async () => {
        try {
            let newData = hotTriggers.map((item, i) => ({ ...item, position: i + 1 }))
            await updateHotTriggerPositions(id, hotTriggers)
            await swal('Afinidata', "Actualizado correctamente", "success");
            setHotTriggers(newData);
        } catch(err:any) {
            console.log(err)
        }
    }

    const handleHourChange = (e:any, item:any) => {
        const elemento = {...item, hour: e.target.value};
        setHotTriggers(hotTriggers.map(item => item.id === elemento.id ? elemento : item));
    }

    const isNumeric = (evt:any) => {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
          return;
        } else {
          return true;
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Hot triggers</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>Sequence: {sequence.id}</p>
                                    <p>Name: {sequence.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right mb-4">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleAddtriggers}
                                >
                                    <i className="fa fa-plus"></i>
                                    <span>&nbsp;Añadir nuevo</span>
                                </button>
                            </div>
                            {hotTriggers && hotTriggers.length > 0 &&
                                <ReactSortable
                                    list={hotTriggers} onEnd={handleUpdateList} setList={setHotTriggers}>
                                    {hotTriggers.map((item: any, index: number) => (
                                        <div key={item.id} onMouseEnter={(e) => {
                                            let el = e.target as HTMLInputElement;
                                            el.style.cursor = 'grabbing'
                                        }} className="item-container">
                                            Id: {item.id}
                                            <div className="row mb-2">
                                                <div className="col-md-12 text-right">
                                                    {item.edit &&
                                                        <button className="btn btn-success btn-sm"
                                                            title="Editar"
                                                            onClick={() => toogleBtnEdit(index)}>
                                                            <i className="fa fa-save"></i> Guardar
                                                        </button>
                                                    }
                                                    {!item.edit &&
                                                        <button className="btn btn-warning btn-sm"
                                                            title="Editar"
                                                            onClick={() => toogleBtnEdit(index)}>
                                                            <i className="fa fa-edit"></i> Editar
                                                        </button>
                                                    }
                                                    <button className="btn btn-danger btn-sm ml-2"
                                                        title="Borrar"
                                                        onClick={() => handleDelete(item)}>
                                                        <i className="fa fa-trash"></i> Borrar
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-3">
                                                    <label htmlFor="">Session</label>
                                                    {loadingS && (
                                                        <Loading text="Cargando sessions..." size="2rem" />
                                                    )}
                                                    {sessions && sessions.length > 0 &&
                                                        <select name="session_id" className="form-control"
                                                            disabled={!item.edit}
                                                            defaultValue={item.session_id}
                                                            onChange={(e) => handleChange(e, item)}>
                                                            {sessions.map(s => (
                                                                <option value={s.id} key={s.id}>{s.name}</option>
                                                            ))}
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="">Trigger type</label>
                                                    <div className="form-group">
                                                        <select name="trigger_type" className="form-control"
                                                            disabled={!item.edit}
                                                            defaultValue={item.trigger_type}
                                                            onChange={(e) => handleTriggerTypeChange(e, index)}>
                                                            <option value="1">Recurrent</option>
                                                            <option value="2">Single</option>
                                                            <option value="3">Day of the week user selection</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {item.trigger_type === 2 &&
                                                        <div className="form-group row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="">Duration</label>
                                                                <input type="number"
                                                                    name="delta"
                                                                    min="1"
                                                                    step="1"
                                                                    className="form-control"
                                                                    pattern="^[0-9]"
                                                                    onKeyPress={isNumeric}
                                                                    value={item.delta}
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    disabled={!item.edit} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="">Interval</label>
                                                                <select
                                                                    name="interval"
                                                                    className="form-control"
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    defaultValue={item.interval}
                                                                    disabled={!item.edit}>
                                                                    <option value="2">Minutes</option>
                                                                    <option value="3">Hours</option>
                                                                    <option value="4">Days</option>
                                                                    <option value="5">Weeks</option>
                                                                    <option value="6">Months</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item.trigger_type === 1 &&
                                                        <>
                                                            <div className="form-group row">
                                                                <div className="col-md-4">
                                                                    <label htmlFor="" className="d-block">Hour</label>
                                                                    <input type="time"
                                                                    onChange={e => handleHourChange(e, item) }
                                                                    value={item.hour || time}
                                                                    disabled={!item.edit}
                                                                    className="form-control"/>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="">Recurrency</label>
                                                                    <select
                                                                    name="recurrency"
                                                                    className="form-control"
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    value={item.recurrency || 1}
                                                                    disabled={!item.edit}>
                                                                        <option value="1">Daily</option>
                                                                        <option value="2">Weekly</option>
                                                                        <option value="3">Bi weekly</option>
                                                                        <option value="4">Monthly</option>
                                                                        <option value="5">Quarter</option>
                                                                        <option value="6">Semester</option>
                                                                        <option value="7">Year</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="">Stop afters</label>
                                                                    <select name="stop_afters"
                                                                    className="form-control"
                                                                    value={item.stop_afters}
                                                                    disabled={!item.edit}
                                                                    onChange={(e) => handleChange(e, item)}>
                                                                        <option value="1">1 Week</option>
                                                                        <option value="2">1 Month</option>
                                                                        <option value="3">1 Year</option>
                                                                        <option value="4">Quarter</option>
                                                                        <option value="5">Semester</option>
                                                                        <option value="6">For ever</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    { item.trigger_type === 3 && (
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="">Number</label>
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                        className="form-control"
                                                                        name="delta"
                                                                        min="1"
                                                                        onChange={e => handleChange(e, item) }
                                                                        value={item.delta || 1}
                                                                        disabled={!item.edit}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text" id="basic-addon2">of weeks</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="">Default date</label>
                                                                    <select name="week_day" id="week_day"
                                                                    className="form-control"
                                                                    onChange={e => handleChange(e, item) }
                                                                    value={item.week_day}
                                                                    disabled={!item.edit}>
                                                                        {daysOfWeek.map( day => (
                                                                            <option value={day.id} key={day.id}>{day.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="">Interval</label>
                                                                    <select
                                                                    name="interval"
                                                                    className="form-control"
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    defaultValue={5}
                                                                    disabled={true}>
                                                                        <option value="2">Minutes</option>
                                                                        <option value="3">Hours</option>
                                                                        <option value="4">Days</option>
                                                                        <option value="5">Weeks</option>
                                                                        <option value="6">Months</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="">Default hour</label>
                                                                    <input type="time" className="form-control"
                                                                    name="hour"
                                                                    onChange={e => handleHourChange(e, item) }
                                                                    value={item.hour || time}
                                                                    disabled={!item.edit}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row flex-wrap">

                                                <div className="col-md-6" style={{ minWidth: '150px' }}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Base Date Attribute</label>
                                                        <select name="date_or_attribute" className="form-control"
                                                            disabled={!item.edit}
                                                            defaultValue={item.date_or_attribute}
                                                            onChange={(e) => handleChange(e, item)}>
                                                            <option value="">User suscribe to sequence</option>
                                                            { item.trigger_type !== 3 && (
                                                                <option value="date">Date</option>
                                                            )}
                                                            <option value="attribute">Date attribute</option>
                                                        </select>
                                                    </div>

                                                    { item.date_or_attribute === 'date' && (
                                                        <>
                                                            <div className="form-group">
                                                                    <label htmlFor="">Date</label>
                                                                    <input type="date" className="form-control"
                                                                    name="date"
                                                                    onChange={(e) => handleChange(e, item)}
                                                                    disabled={!item.edit}
                                                                    value={item.date || new Date()}/>
                                                            </div>
                                                        </>
                                                    )}

                                                    { item.date_or_attribute === 'attribute' && (
                                                        <div className="form-group">
                                                            <label htmlFor="">Attribute</label>
                                                            <select name="base_date_attribute" className="form-control"
                                                                disabled={!item.edit}
                                                                defaultValue={item.base_date_attribute}
                                                                onChange={(e) => handleChange(e, item)}>
                                                                {dateAttributes.map(attribute => (
                                                                    <option value={attribute.id} key={attribute.id}>{attribute.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}

                                                    { (item.trigger_type === 2 && (item.date_or_attribute === 'attribute' ||item.date_or_attribute === 'date') ) && (
                                                        <div className="form-group">
                                                            <label htmlFor="">Hour</label>
                                                            <input type="time" className="form-control"
                                                            name="hour"
                                                            onChange={e => handleHourChange(e, item) }
                                                            value={item.hour || time}
                                                            disabled={!item.edit}/>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-6" style={{ minWidth: '250px' }}>
                                                    <label htmlFor="">Tags</label>
                                                    <Select
                                                        name="tags"
                                                        placeholder="Select tag"
                                                        onChange={(val: any, element: any) => handleMultipleChange(val, element, item)}
                                                        value={item.tags}
                                                        isDisabled={!item.edit}
                                                        isMulti={true}
                                                        options={tags} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </ReactSortable>
                            }

                            {hotTriggers && hotTriggers.length === 0 &&
                                <div className="alert alert-info">No hay secuencias</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HotTriggersPage
