import { useFormik } from "formik"
import { Button, Modal } from "react-bootstrap"
import FormikField from "./FormikField"
import * as Yup from "yup";
import Loading from "./Loading";
import { useState } from "react";
import swal from 'sweetalert';
import { IFormModalField } from "interfaces/FormModal";


interface IProps {
    show: boolean,
    sendText?: string,
    title: string,
    fields: IFormModalField[]
    handleClose: () => void,
    handleSubmit: (payload: any) => Promise<string>
}
const FormModal = ({show, handleClose, sendText = "Guardar", title, fields, handleSubmit}: IProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape(
        Object.assign({}, ...fields.map((field) => ({[field.name]: field.validation})))
       );
    
    const formik = useFormik({
        initialValues: Object.assign({}, ...fields.map((field) => ({[field.name]: field.value}))),
        validationSchema,
        enableReinitialize: true,
        onSubmit: function (values, {resetForm}) {
            setIsLoading(true)
            handleSubmit(values)
                .then((res: string) => {
                    resetForm()
                    swal("Completado!", res, "success")
                })
                .catch((err: Error = new Error("a ocurrido un error inesperado")) => {
                    console.log(err.message)
                    swal("error", err.message, "error")
                })
                .finally(() => setIsLoading(false))
        }
    });

    const onClose = () => {
        formik.resetForm()
        handleClose()
    }
    
    
    return (
        <Modal show={show} onHide={onClose} centered>
            { isLoading ? <Loading/> :
                (<>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className='row'>
                            {fields.map((item => 
                                <div className='col-12'  key={`FormModal-${item.name}-field`}>
                                    <FormikField label={item.label} formName={item.name} formik={formik} 
                                        type={item.type} params={item.params}
                                    />
                                </div>
                            ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>
                        Cerrar
                        </Button>
                        <Button variant="primary" type="submit">
                        {sendText}
                        </Button>
                    </Modal.Footer>
                </form>
                </>)}
        </Modal>
    )
}

export default FormModal