import axios from "axios";

const NLU_URL = process.env.REACT_APP_API_URL_NLU

/**
 * Guardar nuevo training text
 * @params payload
 * @returns void
 */
export const storeTrainingText = (payload:any):Promise<any> => {
    return new Promise( async(resolve, reject) => {
        try{
            const resp = await axios.post(`${NLU_URL}/trainingtext/`, payload);
            resolve(resp)
        }catch(err:any){
            reject(err)
        }
    });
}


/**
 * Borrar training text
 * @params payload
 * @returns void
 */
 export const destroyTrainingText = (id:string):Promise<any> => {
    return new Promise( async(resolve, reject) => {
        try{
            const resp = await axios.delete(`${NLU_URL}/trainingtext/${id}`,);
            resolve(resp)
        }catch(err:any){
            reject(err)
        }
    });
}
