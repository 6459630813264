import Loading from 'components/Loading';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { getAuthGroups } from '../../data/auth-groups';
import Pagination from 'react-js-pagination';
import SearchForm from '../../components/SearchForm';

const Index = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [groups, setGroups ] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        let mounted = true;
        if(mounted){
          getAuthGroups(search)
          .then( (resp:any) =>{
            const { count, results } = resp.data;
            setGroups(results);
            setCount(count);
            setLoading(false);
          }).catch( err => console.log(err))
        }

        return function cleanUp(){
          mounted = false;
        }

    },[search]);

    const handlePageChange = async (page: number) => {
      setLoading(true);
      setActivePage(page)
      const resp = await getAuthGroups(search, page)
      const {results, count} = resp.data;
      setCount(count);
      setGroups(results);
      setLoading(false);
    }

    if(loading){
      return <Loading />
    }

    return (
        <>
           <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h4>Listado de roles</h4>
                </div>
                <div className="col-md-6 col-sm-12">
                  <Link to="/admin/groups/add"
                  className="btn btn-primary">
                    <i className="fa fa-users"></i> Crear nuevo grupo
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <SearchForm
              search={search}
              setSearch={setSearch}
              />

              { groups.length > 0 &&
                <div className="table-responsive">
                  <table className="table"
                  width="100%" id="data">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {groups.map( item => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td className="text-right">
                            <Link to={`/admin/groups/${item.id}/edit`} className="btn btn-warning btn-sm" title="Editar grupo"><i className="fa fa-edit"></i></Link>
                            <Link to={`/admin/groups/${item.id}/set_permissions`} className="btn btn-success btn-sm ml-2" title="Asignar permisos al grupo"><i className="fa fa-eye"></i></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                </div>
              }

              {groups.length === 0 &&
                <div className="alert alert-info">No hay grupos</div>
              }
            </div>
          </div>
        </>
    )
}

export default Index
