import { Link, useParams } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import {
    editConfigMilestone,
    getAlertsInMilestoneConfigEdit,
    getMilestoneConfigToEdit
} from 'services/milestones-programs.service';
import '../milestones-admin.css';
import AgregarAlerta from './AgregarAlerta';
import usePrograms from 'hooks/usePrograms';
import swal from 'sweetalert';

type Params = {
    id: string;
    programId: string
}

const EditarConfiguracionMilestones = () => {
    const {id, programId} = useParams<Params>();
    const [milestone, setMilestone] = useState<any>({
        id: "",
        code: "",
        difficulty: 0,
        average: 0,
        minimum: 0,
        maximum: 0,
    });
    const [alerts, setAlerts] = useState<any[]>([]);
    const [addAlert, setAddAlert] = useState(false);

    const [programs] = usePrograms();

    const ref = useRef(false)

    useEffect(() => {
        if(!ref.current){
            async function fetchMilestone(){
                try{
                    const resp = await getMilestoneConfigToEdit(id);
                    setMilestone(resp.data.results[0]);
                } catch(err:any){
                    console.log(err);
                }
            }
            fetchMilestone();
            ref.current = true;
        }

        return () => {ref.current = false}
    },[id])

    useEffect(() => {
        if(!ref.current){
            async function fetchAlerts(){
                try{
                    const resp = await getAlertsInMilestoneConfigEdit(milestone.milestone, programId);
                    setAlerts(resp.data.results)
                } catch(err:any){
                    console.log(err);
                }
            }

            fetchAlerts();
            ref.current = true;
        }
        return () => {ref.current = false}
    },[milestone, programId]);

    const handleInputChange = (e:any) => {
        setMilestone({...milestone, [e.target.name]:e.target.value})
    }

    const handleAddAlert = (values:any) => {
        setAlerts([...alerts, values])
    }

    const handleChangeAlertInput = (e:any, id:string) => {
        const newData = alerts.map((x:any) => x.id === id
        ? {...x, [e.target.name]: e.target.value}
        : x);
       setAlerts(newData);
    }

    const handleRemove = (id: string) => {
        setAlerts(alerts.filter((x:any) => x.id !== id));
    }

    const handleSave = async () => {
        const payload = {
            code: milestone.code,
            average: milestone.average,
            difficulty: milestone.difficulty,
            minimum: milestone.minimum,
            maximum: milestone.maximum,
            alerts: alerts.map((item:any) => (
                {program_id: Number(item.program_id), value: item.value, percentage: item.percentage}
            ))
        }

        try{
            await editConfigMilestone(milestone.id, payload);
            await swal("Actualizado correctamente",{icon: "success"});
        } catch(err:any){
            await swal("Ocurrió un error",{icon: "error"});
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-6">
                        <h3>Editar configuración de milestone</h3>
                    </div>
                    <div className="col-sm-6">
                        <Link
                           to={`/admin/milestones/detalle-programa/${programId}`}
                           className="btn btn-secondary btn-sm"><i className="fa fa-arrow-left"></i> Regresar</Link>
                    </div>
                </div>
            </div>
            <div className="card-body">

                <div className="row mb-3">
                    <div className="col-sm-12">
                        <h4>Configuración botnar</h4>
                        <p>Milestone: {milestone.id}</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Código</label>
                            <input type="text" className="form-control"
                            name="code"
                            value={milestone.code}
                            onChange={(e:any) => handleInputChange(e)}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Average</label>
                            <input type="text" className="form-control"
                            name="average"
                            value={milestone.average}
                            onChange={(e:any) => handleInputChange(e)}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Difficulty</label>
                            <input type="text" className="form-control"
                            name="difficulty"
                            value={milestone.difficulty}
                            onChange={(e:any) => handleInputChange(e)}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Min</label>
                            <input type="text" className="form-control"
                            name="minimum"
                            value={milestone.minimum}
                            onChange={(e:any) => handleInputChange(e)}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Max</label>
                            <input type="text" className="form-control"
                            name="maximum"
                            value={milestone.maximum}
                            onChange={(e:any) => handleInputChange(e)}/>
                        </div>
                    </div>
                </div>

                <div className="text-right mb-4">
                    <button type="button"
                    className={`btn btn-sm ${addAlert ? 'btn-danger' : 'btn-primary'}`}
                    onClick={() => setAddAlert(!addAlert)}>{addAlert ? (
                        <><i className="fa fa-times"></i> Cerrar agregar nueva alerta</>
                    ) : (
                        <><i className="fa fa-plus"></i> Agregar nueva alerta</>
                    )}</button>
                </div>

                { addAlert &&
                    <AgregarAlerta onFinish={handleAddAlert}/>
                }

                <div className="mt-4">
                    <h4>Alertas</h4>
                </div>

                <table className="table" width="100%">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Alert %</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {alerts.length === 0 && (
                            <tr className="text-center">
                                <td colSpan={4}>
                                    <p>No hay datos</p>
                                </td>
                            </tr>
                        )}
                        {alerts.length > 0 && (
                            alerts.map((item:any) => (
                                <tr key={item.id}>
                                    <td>
                                        <select
                                        name="program_id"
                                        className="form-control"
                                        value={item.program_id}
                                        onChange={(e) => handleChangeAlertInput(e,item.id)}>
                                            {programs.map((program:any) => (
                                                <option value={program.id} key={program.id}>{program.name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                        type="text"
                                        name="value"
                                        className="form-control"
                                        value={item.value}
                                        onChange={(e) => handleChangeAlertInput(e, item.id)}/>
                                    </td>
                                    <td>
                                        <input
                                        type="text"
                                        name="percentage"
                                        className="form-control"
                                        value={item.percentage}
                                        onChange={(e) => handleChangeAlertInput(e, item.id)}/>
                                    </td>
                                    <td>
                                        <button className="btn btn-danger btn-sm ml-2"
                                        type="button"
                                        onClick={() => handleRemove(item.id)}><i className="fa fa-times"></i></button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="card-footer text-center mt-4">
                <Link
                to={`/admin/milestones/detalle-programa/${programId}`}
                className="btn btn-secondary btn-sm">Cancelar</Link>
                <button
                className="btn btn-success btn-sm ml-2"
                onClick={handleSave}
                type="button">Actualizar milestone</button>
            </div>
        </div>
    )
}

export default EditarConfiguracionMilestones
