import swal from 'sweetalert';
// @ts-ignore
import Select from 'react-select';
import { getSessionsList } from 'data/sessions';
import { useContext, useEffect, useState } from 'react'
import { sendChatBotToPositionInSession } from 'data/live-chat';
import { MessengerUserContext } from '../MessengerUserContextProvider';

interface SelectSession {
    label: string;
    value: string;
}

interface Props {
    onFinish: () => void
}

const SendToPosition = ({onFinish}:Props) => {
    const [sessions, setSessions] = useState<SelectSession[]>([]);
    const { messengerUser } = useContext(MessengerUserContext);
    const [sessionId, setSessionId] = useState("");
    const [ position, setPosition ] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let mounted  = true;
        if(mounted){
            getSessionsList()
            .then( resp => {
                setSessions(resp.data)
            })
            .catch( err => console.log(err))
        }

        return () => {  mounted = false; }
    },[]);

    const handleSelectChange = (item:SelectSession) => {
        setSessionId(item.value);
    }

    const handleSave = async (e:any) => {
        setLoading(true);
        try {
            e.preventDefault();
            const { id, bot_id, user_channel_id, bot_channel_id } = messengerUser;
            const {data}:any = await sendChatBotToPositionInSession( {
                userId: id,
                sessionId,
                position,
                botId: bot_id,
                userChannelId: user_channel_id,
                botChannelId: bot_channel_id
            });
            if(data.status === 'done'){
                await swal("Afinidata", "Ejecutado correctamente" ,"success");
            } else {
                await swal("Afinidata", data.set_attributes.request_error ,"error");
            }
            onFinish()
        } catch(err:any){
            console.log(err);
        }
        setLoading(false)
    }

    return (
        <div className="row mt-2">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSave}>
                            <div className="form-group">
                                <label htmlFor="">Session</label>
                                <Select
                                onChange={handleSelectChange}
                                options={sessions.map( (item:any) => ({label: item.name, value: item.id}))}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Position</label>
                                <input type="number"
                                value={position}
                                name="position"
                                className="form-control"
                                onChange={(e:any) => setPosition(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block btn-sm"
                                disabled={loading}>{loading ? 'Guardando...' : 'Guardar'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendToPosition
