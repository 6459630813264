import { useState, useEffect } from 'react';
import { getDetailProgramAttributeType } from '../../data/programs-attribute-types';
import { ProgramAttributeTypes }  from '../../interfaces/Program';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import PageNotFound from 'pages/page-not-found/PageNotFound';

type Props = {
    programAttributeTypeId: string
}

const ConfigureProgramAttributes = ({match}:RouteComponentProps<Props>) => {
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ attributeType, setAttributeType ] = useState<ProgramAttributeTypes>({ id: "", program: "", entity: "", name: "", description: "", weight: 0, attributes_set:[]});
    const programAttributeTypeId = match.params.programAttributeTypeId;

    useEffect(() =>{
        getDetailProgramAttributeType(programAttributeTypeId)
        .then( (resp: any) => {
            setAttributeType(resp.data.results[0]);
            setLoading(false);
        })
        .catch( (err: any) => { history.push("/admin/programs"); });
        
    },[programAttributeTypeId, history])
    
    if(loading){
        return <Loading />;
    }

    if(!attributeType){
        return <PageNotFound/>
    }

    return (
        <>
            <div className="card">
                <div className="card-header bg-secondary text-center text-white d-flex">
                    <div className="flex-grow-1 flex-column align-self-center">
                        <h5 className="card-title m-0 font-weight-bold">{attributeType.name}</h5>
                        <p className="m-0">{attributeType.description}</p>
                    </div>
                    <div className="flex-column">
                        <Link to={`/admin/program_attributes/add/${attributeType.id}`} className="btn btn-outline-light btn-sm d-block mt-2">
                            {/* 
                        // @ts-ignore */}
                            <FontAwesomeIcon icon={faPlus} /> Nuevo
                        </Link>
                    </div>
                </div>
                <div className="pt-2 pb-2 d-flex flex-wrap">
                { attributeType.attributes_set && attributeType.attributes_set.length > 0 ? (
                    attributeType.attributes_set.map( (programAttribute) => (
                        <div className="col-xs-12 col-md-4 my-1" key={programAttribute.id}>
                            <div className="card p-2">
                                <div className="card-header text-center">
                                    <h6 className="m-0"> { programAttribute.label } </h6>
                                </div>
                                <div className="info-program-attribute" style={{ padding: "10px"}}>
                                    <p className="mb-1"><b>id: </b>{programAttribute.id}</p>
                                    <p className="mb-1"><b>attribute: </b>{attributeType.name}</p>
                                    <p className="mb-1"><b>weight: </b> { programAttribute.weight}</p>
                                    <p className="mb-1"><b>threshold: </b> { programAttribute.threshold}</p>
                                    <div className="row mt-2">
                                        <div className="col mt-2">
                                            <Link
                                            to={`/admin/program_attributes/edit/${programAttribute.id}/type/${attributeType.id}`}
                                            title="Editar"
                                            className="btn btn-outline-primary btn-block">{/* 
                                        // @ts-ignore */}
                                                <FontAwesomeIcon icon={faEdit} /> <span>Editar</span>
                                            </Link>
                                        </div>
                                        <div className="col mt-2">
                                            <Link
                                            to={`/admin/program_attributes/${programAttribute.id}/delete`}
                                            title="Borrar"
                                            className="btn btn-outline-danger btn-block">{/* 
                                        // @ts-ignore */}
                                                <FontAwesomeIcon icon={faTrash} /> <span>Borrar</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-xs-12 col-md-12">
                        <div className="alert alert-info">Aún no tiene atributos.</div>
                    </div>
                )
                }
                </div>
            </div>
        </>
    )
}

export default ConfigureProgramAttributes
