import { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getSelectIntent, getSelectIntents, setTextIntent } from 'data/intents';
import BotReplyWithText from 'pages/nlu/components/BotReplyWithText';
import BotReplyWithSession from 'pages/nlu/components/BotReplyWithSession';
import { IResponseSession, IResponseText, ITrainingText } from 'interfaces/Intent';
import IntentsContextProvider from 'pages/nlu/IntentsContextProvider';
import swal from 'sweetalert';


const validationSchema = Yup.object().shape({
    name: Yup.string().min(1)
});

interface IntentSelect {
    id: string,
    response:string,
    name: string,
    responsetext: IResponseText[],
    responsesession: IResponseSession[],
    trainingtext: ITrainingText[],
    value: number,
    label: string
}


const IntentModalSelect = (props: { show: boolean,
                                    title:string, 
                                    trainingText?:string,
                                    handleUpdateOpen: (isOpen: boolean) => void }) => {
    const [loading, setLoading] = useState(true);
    const [isShowed, setIsShowed] = useState(true);
    const [intent, setIntent] = useState<IntentSelect>({} as IntentSelect)
    const [loadingIntent, setLoadingIntent] = useState(false)

    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {name:''},
        validationSchema,
        enableReinitialize: true,
        onSubmit: function (values) {
            if (intent && props.trainingText) setTextIntent(intent.value, props.trainingText)
            .then( (resp: any) => {
                swal("Afinidata","Texto de entrenamiento creado","success").then(() => props.handleUpdateOpen(false));
            }).catch((err: any) => {
                swal("Afinidata",`Ocurrio un error. Intente de nuevo \n ${err.response.data.name[0]}`,"error");
            })
        }
    });

    useEffect(() => {
        let mounted = true;
        Modal.setAppElement('#root');

        if (mounted) {
            setLoading(false);
        }
        return () => { mounted = false; };

    }, []);

    useEffect(() => {
        setIsShowed(props.show)
    }, [props.show])

    async function loadIntents(search: string, loadedOptions: any, additional?: {page:number}) {
        const page = additional ? additional.page: 1
        const response = (await getSelectIntents(search, page)).data;
        return {
          options: response.results.map((intent: {id:number, name:string}) => 
                                        {return {value: intent.id, label: intent.name}}),
          hasMore: !!response.next,
          additional: {
            page: page + 1,
          }
        };
      }
    
    async function handleSelectIntentChange(intent: {value:number}) {
        if( intent) {
            setLoadingIntent(true);
            const response = (await getSelectIntent(intent.value)).data;
            response.value = response.id;
            response.label = response.name;
            setIntent(response);
            setLoadingIntent(false);
        }
    }


    return (
        <Modal style={{
            content: { height: "fit-content", maxHeight: "90%", maxWidth: "1140px", margin: "auto", padding: "0", overflow: "unset" },
            overlay: { zIndex: 1000 }
        }} isOpen={isShowed}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => props.handleUpdateOpen(false)}>
            <div className="modal-header mt-1">
                <div className='h3'>{props.title}</div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.handleUpdateOpen(false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            {loading ?
                <Loading size="2rem" />
                :
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label >Intent</label>
                                        <AsyncPaginate
                                            debounceTimeout={400}
                                            placeholder="Seleccionar Intent"
                                            value={intent}
                                            loadOptions={loadIntents}
                                            onChange={handleSelectIntentChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label >Text</label>
                                        <input
                                            className='form-control'
                                            value={props.trainingText}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            {loadingIntent ?  <Loading size="2rem" /> :
                            intent.name && <div className="row">
                                { intent.responsetext? (
                                    <div className="col-sm-12">
                                        <BotReplyWithText intent={intent}
                                        isReadOnly={true}
                                        intentId={intent.id}/>
                                    </div>
                                ):
                                (intent.responsesession &&
                                    <div className="col-sm-12">
                                        <IntentsContextProvider>
                                            <BotReplyWithSession
                                            isReadOnly={true}
                                            intent={intent}
                                            intentId={intent.id}/>
                                        </IntentsContextProvider>
                                    </div>
                                )}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={!intent.name} className="btn btn-info">
                                {t("guardar_cambios")} <i className="far fa-save"></i>
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => props.handleUpdateOpen(false)}>
                                {t("regresar")} <i className="fas fa-reply"></i>
                            </button>
                        </div>
                    </form>
                </>
            }
        </Modal>
    )
};

export default IntentModalSelect
