// import React from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SinglePushModal from "./Push-modal";
import {
  getAllObjects,
  getAllPushCalendar,
  getCreatedPush,
  getCreatedPushGroup,
  getLanguageTemples,
} from "services/nodejs.service";
import { PushDeleteModal } from "./PushDeleteModal";
import PushTestModal from "./PushTestModal";
// import { useEffect, useState } from 'react';
// import Loading from 'components/Loading';

const lang_map = ["", "es", "en", "", "pt"];
const output_map = ["Activity", "Article", "Session", "Milestones"];
const channel_map: any = {
  0: "APP POR PROGRAMA",
  1: "APP BRAZIL",
  2: "APP BOTNAR",
  50: "AFINIAPP",
  100: "WHATSAPP PROGRAMA",
  101: "WHATSAPP BRAZIL",
  102: "WHATSAPP BOTNAR",
};

const example = [
  {
    id: 1,
    date_to_send: "19/12/2023",
    channel: "WHATSAPP NO BRAZIL",
    output: "ARTICULO",
    age_min: 1,
    age_max: 2,
    program_ids: 1,
    post_ids: "[]",
    messages: "[]",
  },
  {
    id: 2,
    fecha: "06/08/2024",
    canal: "WHATSAPP POR PROGRAMA",
    output: "Session",
  },
  {
    id: 3,
    fecha: "07/08/2024",
    canal: "APP POR PROGRAMA",
    output: "Activity",
  },
  {
    id: 4,
    fecha: "19/12/2023",
    canal: "WHATSAPP NO BRAZIL",
    output: "ARTICULO",
  },
  {
    id: 5,
    fecha: "06/08/2024",
    canal: "WHATSAPP POR PROGRAMA",
    output: "Session",
  },
  {
    id: 6,
    fecha: "07/08/2024",
    canal: "APP POR PROGRAMA",
    output: "Activity",
  },
];

function PushList() {
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalTest, setModalTest] = useState(false);
  const [history, setHistory] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [toTest, setToTest] = useState({});
  const [spanishTemplete, setSpanishTemplete] = useState<any[]>([]);
  const [englishTemplete, setEnglishTempletel] = useState<any[]>([]);
  const [imageMedia, setImageMedia] = useState<any[]>([]);
  const [videoMedia, setVideoMedia] = useState<any[]>([]);
  const [portTemplete, setPortTemplete] = useState<any[]>([]);
  const [pushNotifications, setPushNotifications] = useState<any[]>([]);
  const [pushFragments, setPushFragments] = useState<any[]>([]);
  const columns = [
    {
      field: "id",
      headerName: "id",
      editable: false,
      width: 10,
    },
    {
      field: "date_to_send",
      headerName: "Fecha",
      flex: 1,
      type: "date",
      editable: false,
      valueGetter: (el: any) =>
        new Date(el.getTime() + el.getTimezoneOffset() * 60000),
    },
    {
      field: "channel",
      headerName: "Canal",
      valueGetter: (el: any, rw: any) => {console.log('channel_map',el, rw); return channel_map[el]},
      // width: 110,
      flex: 1,

      editable: false,
    },
    {
      field: "output",
      headerName: "Output",
      valueGetter: (el: any) => output_map[el],
      flex: 1,
      // width: 110,
      editable: false,
    },
    {
      field: "age",
      headerName: "Edad",
      // flex:1,
      // type: 'actions',
      sortable: false,
      renderCell: (push: any) => {
        if (push.row.age_max != undefined && push.row.age_min != undefined) {
          return (
            <Typography>
              {push.row.age_min} - {push.row.age_max}
            </Typography>
          );
        }
        return <Typography> N/A </Typography>;
      },
    },
    {
      field: "messages",
      headerName: "Enviando",
      // type: 'actions', #1
      flex: 2,
      // minWidth: 250,
      sortable: false,
      renderCell: (messages: any) => {
        if (messages.value) {
          let toSend: any[] = [];
          Object.keys(messages.value).forEach((element) => {
            toSend.push({ lang: element, id: messages.value[element].id });
          });
          return toSend.map((el: any, key: number) => (
            <Typography key={key}>
              {el.lang}:{el.id}
            </Typography>
          ));
        } else if (messages.row.templete) {
          let toSend: any[] = [];
          messages.row.templete.forEach((element: any) => {
            toSend.push({ lang: element.lang, id: element.name });
          });
          return toSend.map((el: any, key: number) => (
            <Typography key={key}>
              {lang_map[el.lang]}:{el.id}
            </Typography>
          ));
        }
        return <Typography> N/A </Typography>;
      },
    },
    // {
    //   field: "test",
    //   headerName: "Probar",
    //   // flex:1,
    //   // width: 110,
    //   editable: false,
    // },
    {
      field: "delete",
      headerName: "Opciones",
      // flex:1,
      width: 140,
      editable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-primary btn-sm ml-2"
              onClick={(el) => {
                setToTest(tmpt.row);
                setModalTest(true);
              }}
            >
              <i className="fa fa-reply"></i>
            </button>
            <button
              type="button"
              className="btn btn-warning btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModal(true);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModalDelete(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPush = async () => {
    let allObjs = await getAllPushCalendar();
    let reducedObjs: any[] = [];
    allObjs.data.result.forEach((element: any) => {
      let currIndex = reducedObjs.findIndex((el: any) => el.id == element.id);
      if (currIndex != -1) {
        reducedObjs[currIndex].templete.push({
          name: element.templete_name,
          lang: element.templete_lang,
          type: element.object_type,
          object_id: element.object_id,
          url: element.url,
        });
      } else {
        let tempObj: any = {
          id: element.id,
          push_id: element.push_id,
          channel: element.channel,
          date_to_send: new Date(+element.date_to_send),
          output: element.output,
          age_min: element.age_min,
          age_max: element.age_max,
          program_ids: element.program_ids,
          post_ids: JSON.parse(element.post_ids),
          messages: JSON.parse(element.messages),
        };
        if (element.templete_name)
          tempObj.templete = [
            {
              name: element.templete_name,
              lang: element.templete_lang,
              type: element.object_type,
              object_id: element.object_id,
              url: element.url,
            },
          ];
        if (!history && element.date_to_send < Date.now() - 86400000) return;
        reducedObjs.push(tempObj);
      }
    });
    setPushNotifications(reducedObjs);
  };

  useEffect(() => {
    const getTempletes = async () => {
      let allTemps = await getLanguageTemples();
      let tempSpanishTemplete: any[] = [];
      let tempEnglishTemplete: any[] = [];
      let tempPortuTemplete: any[] = [];
      if (allTemps.data?.result) {
        allTemps.data?.result.forEach((element: any) => {
          if (element.language_id == 1)
            tempSpanishTemplete.push({
              id: element.id,
              label: element.name,
              type: element.type,
            });
          if (element.language_id == 2)
            tempEnglishTemplete.push({
              id: element.id,
              label: element.name,
              type: element.type,
            });
          if (element.language_id == 4)
            tempPortuTemplete.push({
              id: element.id,
              label: element.name,
              type: element.type,
            });
        });
        setSpanishTemplete(tempSpanishTemplete);
        setEnglishTempletel(tempEnglishTemplete);
        setPortTemplete(tempPortuTemplete);
      }
    };
    const getMedia = async () => {
      let allObjs = await getAllObjects();
      let tempMediaImages: any[] = [];
      let tempMediaVideos: any[] = [];
      if (allObjs.data?.result) {
        allObjs.data?.result.forEach((element: any) => {
          if (element.type == 1)
            tempMediaImages.push({ id: element.id, label: element.name });
          if (element.type == 2)
            tempMediaVideos.push({ id: element.id, label: element.name });
        });
        setImageMedia(tempMediaImages);
        setVideoMedia(tempMediaVideos);
      }
    };
    getTempletes();
    getMedia();
  }, []);

  async function getPushFunc() {
    let createdFractPush = await getCreatedPush()
    let createdGroupPush = await getCreatedPushGroup();
    let reducedPush: any = {wa:[],app:[]};
    createdFractPush.data.result.forEach((push:any)=> {
        if(push.channel > 99) reducedPush.wa.push({
            label:push.name,
            value:push.id,
            type:'push'
        })
        else reducedPush.app.push({
            label:push.name,
            value:push.id,
            type:'push'
        })

    })
    createdGroupPush.data.result.forEach((push: any) => {
        let pushToAdd = {
            label: push.name,
            value: push.id,
            age: false,
            min: 0,
            max: 0,
            type:'group'
          };
          if (push.channel == 100) {
            reducedPush.wa.push(pushToAdd);
          } else {
            reducedPush.app.push(pushToAdd);
          }
    })
    setPushFragments(reducedPush)
  }

  useEffect(()=> {
    getPushFunc()
    console.log(pushFragments)
  }, [])

  useEffect(() => {
    getPush();
  }, [history]);
  
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControlLabel
          control={
            <Checkbox checked={history} onClick={() => setHistory(!history)} />
          }
          label="Mostrar pushes pasadas"
        />
        <Button
          variant="contained"
          onClick={() => {
            setToEdit({ reset: true });
            setModal(true);
          }}
        >
          Crear nueva push
        </Button>
      </div>
      <DataGrid
        rows={pushNotifications ? pushNotifications : example}
        //@ts-ignore
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "date_to_send", sort: "asc" }],
          },
        }}
        // autosizeOptions={{
        //   columns: ['delete'],
        //   includeOutliers: true,
        //   includeHeaders: false,
        // }}
      />
      <SinglePushModal
        visible={modal}
        close={() => {
          setModal(false);
          getPush();
          setToEdit({});
        }}
        spanishTemplete={spanishTemplete}
        englishTemplete={englishTemplete}
        portTemplete={portTemplete}
        imageMedia={imageMedia}
        videoMedia={videoMedia}
        pushFragments={pushFragments}
        refeshPush={getPush}
        toEdit={toEdit}
      />
      <PushDeleteModal
        visible={modalDelete}
        close={setModalDelete}
        refresh={getPush}
        pushToDelete={toEdit}
      />
      <PushTestModal
        visible={modalTest}
        close={() => setModalTest(false)}
        refresh={getPush}
        toTest={toTest}
      />
    </div>
  );
}

// const horizontal = {
//     display: 'flex',
//     flexDirection: 'column',
//   }

export default PushList;
