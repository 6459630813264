import Loading from "components/Loading";
import { useFormik } from "formik";
import { AreaI, SourceI } from "interfaces/MilestoneAdmin";
import { useEffect, useState} from "react"
import { Link } from "react-router-dom";
import { crearHito, getAreas, getSources } from "services/milestones-services"
import swal from "sweetalert";

const CrearHito = () => {

    const [areas, setAreas] = useState<AreaI[]>([]);
    const [sources, setSources] = useState<SourceI[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try{
                const respAreas = await getAreas();
                const respSources = await getSources();
                setAreas(respAreas.data)
                setSources(respSources.data)
            } catch(err:any){
                console.error(err);
            } finally{
                setLoading(false)
            }
        }

        fetchData();
    },[]);

    const handleSubmit = async(payload: any) => {
        try{
            await crearHito(payload);
            swal("Hito creado",{icon:"success"});
        } catch(err:any){
            console.error(err);
            swal("Ocurrió un error",{icon:"error"});
        }
    }

    const formik = useFormik({
        initialValues:{
            areas: [],
            source: ""
        },
        onSubmit: (values) => {
            handleSubmit(values);
        }
    })

    if(loading){
        return <Loading />;
    }

    return (
       <div className="card">
           <div className="card-header">
               <h3>Crear hito de desarrollo</h3>
           </div>
           <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="">Área</label>
                                    <select
                                    name="areas"
                                    id="areas"
                                    className="form-control"
                                    value={formik.values.areas}
                                    onChange={formik.handleChange}
                                    multiple>
                                        {areas && areas.map((item:AreaI) => (<option value={item.id} key={item.id}>{item.name}</option>))}
                                    </select>
                                </div>
                        </div>
                        <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="">Source</label>
                                    <select name="source" id="source" className="form-control"
                                    value={formik.values.source}
                                    onChange={formik.handleChange}>
                                        {sources && sources.map((item:SourceI) => (<option value={item.id} key={item.id}>{item.name}</option>))}
                                    </select>
                                </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <Link to="/admin/milestones/listado-hitos/" className="btn btn-secondary btn-sm mr-2"><i className="fa fa-arrow-left"></i> Cancelar</Link>
                            <button className="btn btn-primary btn-sm" type="submit"><i className="fa fa-save"></i> Guardar milestone</button>
                        </div>
                    </div>
                </form>
           </div>
       </div>
    )
}

export default CrearHito
