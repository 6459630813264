import axios from 'axios';
import { ArticleTraductionItem } from 'interfaces/Article';

const CM_API = process.env.REACT_APP_API_URL_CM

export const getMaterials = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/articles_materiales/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}

/**
 * description: obtener listado de Traducciones de articles
 * @param: search string
 * @param: page number
 * @param: parameters object
 * */
 export const getArticleTraductionList = (search = '', page = 1, parameters={}) => {
    return new Promise(async (resolve, reject) => {
        try {
            let params = '';
            for (const [key, value] of Object.entries(parameters)) {
                params += `&${key}=${value}`
              }

            const resp = await axios.get(`${CM_API}/articles/get_traduction_list/?size=30&search=${search}&pagenumber=${page}${params}`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


 export const updateArticles = (payload={}) => {
    return new Promise(async (resolve, reject) => {
        let endpoint = `${CM_API}/articles/update_translated/`;
        try {
            const resp = await axios.post(endpoint, payload);
            resolve(resp);
        } catch (err) {
            reject(err);
        }
    })
}

export const getAreas = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/articles_areas/`)
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    })
}


export const getArticleTranslationById = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${CM_API}/article_translate/${id}/`);
            resolve(resp);
        } catch (err: any) {
            reject(err);
        }
    });
}

export const updateArticleTranslation = (id: string, payload: ArticleTraductionItem): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${CM_API}/article_translate/${id}/`, payload);
            resolve(resp);
        } catch (err: any) {
            reject(err);
        }
    });
}