import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import SearchForm from '../../../components/SearchForm';
import Loading from 'components/Loading';
import BotUserRows  from 'pages/user-groups/components/bot-user-rows';
import CodeRows  from 'pages/user-groups/components/code-rows';
import PlatformRows  from 'pages/user-groups/components/platform-rows';

const GroupGeneralTable = (props: any) => {
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState<string>('');
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        let mounted = true;
        props.dataMethod(props.groupId, search, 1, true)
            .then((resp: any) => {
                if(mounted){
                    const { results, count } = resp.data;
                    setTableData(results);
                    setLoading(false);
                    setCount(count);
                }
            })
            .catch( (err: any) => console.log(err));

            return function cleanup(){
                mounted = false;
            }
        },[search, props]);

    const handlePageChange = async (page: number) => {
        setLoading(true);
        setActivePage(page)
        const resp: any = await props.dataMethod(props.groupId, search, page);
        const { results, count } = resp.data;
        setCount(count);
        setTableData(results);
        setLoading(false);
    }

    if( loading ){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex row">
                        <div className="flex-grow-1">
                            <h6 className="font-weight-bold text-primary">{props.tableTitle}</h6>
                        </div>
                        { props.add && (
                            <div className="d-flex">
                                <Link to={`/admin/user-groups/${props.groupId}/${props.dataType}/add`} className="btn btn-primary">
                                    <i className="fa fa-plus"></i> Crear
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <SearchForm
                    search={search}
                    setSearch={setSearch}
                    />
                    { tableData && tableData.length > 0 ? (
                        <>
                        <div className="table-responsive">
                            <table className="table"
                            id="data">
                                <thead>
                                    <tr className="text-center">
                                        {props.headers.map( (title:string) => (
                                            <th scope="col" key={title}>{title}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.dataType === "code" && (
                                        <CodeRows tableData={tableData} groupId={props.groupId}/>
                                    )}
                                    { props.dataType === "platform-user" && (
                                        <PlatformRows tableData={tableData} groupId={props.groupId}/>
                                    )}
                                    { props.dataType === "bot-user" && (
                                        <BotUserRows tableData={tableData} groupId={props.groupId} 
                                        onChangeUser={(user: any) => setTableData(tableData.map((row) =>  {
                                            if (row.user.id === user.user_id) {
                                                row.user.test_user=user.user_type
                                            }
                                            return row
                                        } ))}/>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={35}
                            totalItemsCount={count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                        />
                        </>
                    ) : (
                        <div className="alert alert-info">No hay data.</div>
                    )}
                </div>
            </div>
        </>
    )
}

export default GroupGeneralTable

