import axios from "axios";

const NLU_URL = process.env.REACT_APP_API_URL_NLU

/**
 * Get list of Guick replies
 * @returns void
 *
 */
export const getQuickReplies = (search = "", page = 1): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/quick_replies/?search=${search}&page=${page}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}

/**
 * Get list of quick replies options
 * @returns void
 *
 */
export const getQuickReplyOptions = (search = ""): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/quick_replies/?options=True&search=${search}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}

/**
 * Add new quick reply
 * @params payload
 * @returns void
 *
 */
export const storeQuickReply = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${NLU_URL}/quick_replies/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Get quick reply by id
 * @params id
 * @returns void
 *
 */
export const getQuickReplyById = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/quick_replies/${id}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Get quick reply by label
 * @params label
 * @returns void
 *
 */
export const getQuickReplyByLabel = (label: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.get(`${NLU_URL}/quick_replies/?label=${label}`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}

/**
 * Update quick reply
 * @params id
 * @params payload
 * @retunrs void
 */
export const updateQuickReply = (id: string, payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.put(`${NLU_URL}/quick_replies/${id}/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Add training text
 *
 * @params payload
 * @retunrs void
 *
 */
export const addTrainingText = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${NLU_URL}/quick_replies_trainingtext/`, payload);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Delete training text
 *
 * @params id
 * @retunrs void
 *
 */
export const deleteTrainingText = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${NLU_URL}/quick_replies_trainingtext/${id}/`);
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Copy replies from
 *
 * @params id
 * @retunrs void
 *
 */
export const copyRepliesFrom = (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.post(`${NLU_URL}/quick_replies/copy_trainingtext/`, payload);
            resolve(resp);
        } catch(err:any) {
            reject(err)
        }
    });
}


/**
 * Delete quick reply
 *
 * @params id
 * @retunrs void
 *
 */
export const deleteQuickReply = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await axios.delete(`${NLU_URL}/quick_replies/${id}`);
            if ('data' in resp && 'set_attributes' in resp['data'] && 'request_error' in resp['data']['set_attributes']) {
                throw resp['data']['set_attributes']['request_error'];
            }
            resolve(resp)
        } catch(err:any) {
            reject(err)
        }
    });
}





