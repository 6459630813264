import pushCalendar from 'pages/push-notifications/push-notifications';
import pushList from 'pages/push-notifications/pushNotifications/push-notifications';
import { IRoute } from '../interfaces/Route';
import Templete from 'pages/push-notifications/Templete';
import Objects from 'pages/push-notifications/ObjectsUpload';
import ObjectPost from 'pages/obects/ObjectPost';
import PushEvents from 'pages/push-notifications/pushEvents/push-events';
import PushGrouping from 'pages/push-notifications/pushGrouping/PushGrouping';

export const PUSH_ROUTES:IRoute[] = [
    {
        path: '/admin/push/',
        component: pushList,
        codename:'pushList',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/push/grouping',
        component: PushGrouping,
        codename:'pushGroup',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/push/calendar',
        component: pushCalendar,
        codename:'pushCalendar',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/push/event',
        component: PushEvents,
        codename:'pushList',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/push/templete/',
        component: Templete,
        codename:'templetes',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/push/objects/',
        component: Objects,
        codename:'Objects',
        exact: true,
        requiredRoles: []
    },
    {
        path: '/admin/objects/posts/',
        component: ObjectPost,
        codename:'ObjectPost',
        exact: true,
        requiredRoles: []
    }
]
