import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { deleteObjectService } from "services/nodejs.service";

const object_map = ["", "Imagen", "Video"];

function DeleteObjectModal({ objectToDelete, close, visible, refresh }: any) {
  const [minorModal, setMinorModal] = useState(false);
  const deleteObject = async (id: any) => {
    let responce = await deleteObjectService(id);
    if (responce.status == 205) {
        setMinorModal(true)
    }
    await refresh();
    close();
  };
  return (
    <>
    <Modal open={visible}>
      <Box sx={minorStyle}>
        <Grid container flexDirection={"row"}>
          <Grid container p={1}>
            <Grid item>
              <Typography variant="h6" color={"black"}>
                Seguro que quieres borrar el objecto {objectToDelete.name} de
                tipo {object_map[objectToDelete.type]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => deleteObject(objectToDelete)}
              >
                Si
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={close}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
      <Modal open={minorModal}>
        <Box sx={minorStyle}>
          <Grid container flexDirection={"row"}>
            <Grid container p={1}>
              <Grid item>
                <Typography variant="h6" color={"black"}>
                    Este Objeto esta siendo usado por alguna push pasada o futura.
                  {/* {templeteError} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={() => setMinorModal(false)}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { DeleteObjectModal };
