import { Link } from 'react-router-dom';

const CodeRows = (props: any) => {

    const formatDate = (str_date:string) =>{
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        // @ts-ignore
        return new Date(str_date).toLocaleDateString([], options);
    }

    return (
        <>
            { props.tableData.map( (item:any) => (
                <tr className="p1" key={item.id}>
                    <td>{item.code}</td>
                    <td>{item.exchanges}</td>
                    <td><i className={"fas fa-2x " + (item.available ? "fa-check-circle text-success" : "fa-times-circle text-danger" )}></i></td>
                    <td>{formatDate(item.created_at)}</td>
                    <td><Link to={`/admin/user-groups/${props.groupId}/code/${item.id}/edit`} className="btn btn-warning btn-sm" title="Editar codigo"><i className="fa fa-edit"></i></Link></td>
                </tr>
            ))}
        </>
    )
}

export default CodeRows

