import { getIntents } from 'data/intents';
import { createContext, useEffect, useState } from 'react';
import { Intent } from '../../interfaces/Intent';
import { getSessionsList } from "data/sessions";

type Props = {
    children: JSX.Element
}

interface Value {
    intents: Intent[];
    page: number;
    previous: string;
    next: string;
    showIntentForm: boolean;
    loading: boolean;
    sessions: any[];
    setShowIntentForm: (value: boolean) => void;
    setIntents: (values:any) => void;
    setPage: (values:any) => void;
}

export const IntentsContext = createContext({} as Value)

const IntentsContextProvider = ({children}:Props) => {
    const [page, setPage] = useState(1);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [intents, setIntents] = useState<Intent[]>([]);
    const [showIntentForm, setShowIntentForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true)
        getIntents(page)
        .then((resp:any) => {
            const { results, next, previous } = resp.data;
            let newData = [];
            if(results){
                newData = results.map((intent: any) => intent.responsesession && intent.responsesession.length > 0 ?
                {...intent, response: "session"} : {...intent, response: "text"})
            }
            setIntents(newData);
            setNext(next);
            setPrevious(previous);
            setLoading(false);
        }).catch((err:any) => console.log(err))

    },[page]);

    useEffect(() => {
        getSessionsList()
        .then( (resp:any) => {
            setSessions(resp.data || []);
        }).catch((err:any) => {
            console.log(err);
        });
    },[])

    return (
        <IntentsContext.Provider value={{
            intents,
            setIntents,
            showIntentForm,
            setShowIntentForm,
            page,
            setPage,
            next,
            previous,
            loading,
            sessions
        }}>
            {children}
        </IntentsContext.Provider>
    )
}

export default IntentsContextProvider
