import { MilestoneServiceRequest } from "api/MilestoneServiceRequest";

export const getProgramById = async(id: string):Promise<any> => {
    try{
        const resp = MilestoneServiceRequest.get(`/api/0.1/programs/?id=${id}&detail=true`);
        return Promise.resolve(resp)
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const getMilestonesConfigWithAdvanceSearch = async(payload?:any):Promise<any> => {
    try{
        const resp = MilestoneServiceRequest.post(
            `api/0.1/milestone_config/advance_search/`,
            payload
        );
        return Promise.resolve(resp)
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const getStages = async(id: string) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/stages/?options=true&program_id=${id}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const getConfigurations = async() => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/program_config/?options=true`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const updateProgram = async (id: string, payload: any) => {
    try{
        const resp = await MilestoneServiceRequest.put(`/api/0.1/programs/${id}/`, payload);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const getMilestoneConfigToEdit = async (id: string) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/milestone_config/?id=${id}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const getAlertsInMilestoneConfigEdit = async (id: string, programId: string) => {
    try{
        const resp = await MilestoneServiceRequest.get(`/api/0.1/alerts/?milestone_id=${id}&program_id=${programId}`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}


export const editConfigMilestone = async (id: string, payload: any) => {
    try{
        const resp = await MilestoneServiceRequest.patch(`/api/0.1/milestone_config/${id}/`, payload);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const dowloadFormatUploadMilestones = async (programId?: string) => {
    try{
        let endpoint = "/milestone_upload/";

        if(programId){
            endpoint = endpoint + '?program_id=' + programId;
        }
        const resp = await MilestoneServiceRequest.get(endpoint);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const uploadBulkFile = async (file: any) => {
    try{
        const fd = new FormData();
        fd.append('file', file);
        const resp = await MilestoneServiceRequest.post(`/milestone_upload/`,fd);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

export const deleteMilestoneConfig = async (id: string) => {
    try{
        const resp = await MilestoneServiceRequest.delete(`/api/0.1/milestone_config/${id}/`);
        return Promise.resolve(resp);
    } catch(err:any){
        return Promise.reject(err);
    }
}

