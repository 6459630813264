import { useFormik } from 'formik';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import Select from 'react-select';

import { createUserGroup, getUserGroupsOptions } from '../../data/user-groups';
import { getLicenseOptions } from '../../data/licenses';
import { getProgramOptions } from '../../data/programs';
import { UserGroup } from 'interfaces/UserGroup';
import { Option } from 'interfaces/shared';

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    parent: Yup.number(),
    country: Yup.string().max(100),
    region: Yup.string().max(100),
    license: Yup.number()
});

const AddGroup = () => {
    const history = useHistory();
    const [groupOptions, setGroupOptions] = useState<Option[]>([])
    const [licenseOptions, setLicenseOptions] = useState<Option[]>([])
    const [programOptions, setProgramOptions] = useState<Option[]>([])
    const [programs, setPrograms] = useState<any[]>([])
    const [initialValues] = useState<UserGroup>({name: '', license: '', bots: [], programs:[]})

    useEffect(()=> {
        let mounted = true;

        if(mounted){

            getUserGroupsOptions()
            .then((resp:any) => {
                setGroupOptions(resp.data);
            })
            .catch(err=> console.log(err));

            getLicenseOptions()
            .then((resp:any) => {
                setLicenseOptions(resp.data);
            })
            .catch(err=> console.log(err));

            getProgramOptions()
            .then((resp:any) => {
                setProgramOptions(resp.data);
            })
            .catch(err=> console.log(err));
        }

        return function cleanUp(){
            mounted = false;
        }
    },[])

    const handleSubmit = async (payload:any):Promise<any> => {
        try {
            await createUserGroup(payload);
            await swal('Afinidata','Grupo creado correctamente','success');
            history.push('/admin/user-groups');
        } catch(err:any){
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values);
            resetForm({});
        },
    });

    const handleMultipleChange = (val: any, element: any, field: string) => {
        // @ts-ignore
        formik.values[field] = val;
        if (field === 'programs'){
            setPrograms(val)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h4>Crear nuevo role</h4>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Parent</label>
                                    <select name="parent" className="form-control"
                                        defaultValue={formik.values.parent}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {groupOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name"
                                     className={`form-control ${
                                        formik.errors.name && formik.touched.name ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.name && formik.touched.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <input type="text" name="country"
                                     className={`form-control ${
                                        formik.errors.country && formik.touched.country ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.country && formik.touched.country ? (
                                        <div className="invalid-feedback">{formik.errors.country}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Region</label>
                                    <input type="text" name="region"
                                     className={`form-control ${
                                        formik.errors.region && formik.touched.region ? "is-invalid" : ""
                                      }`}
                                    value={formik.values.region}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                    {formik.errors.region && formik.touched.region ? (
                                        <div className="invalid-feedback">{formik.errors.region}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">License</label>
                                    <select name="license" className="form-control"
                                        defaultValue={formik.values.license}
                                        onChange={formik.handleChange}>
                                        <option value=''> ----------------------</option>
                                        {licenseOptions.map(item => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Programs</label>
                                    <Select
                                        name="programs"
                                        placeholder="add programs"
                                        onChange={(val: any, element: any) => handleMultipleChange(val, element, 'programs')}
                                        isMulti={true}
                                        hidden={true}
                                        value={programs}
                                        defaultValue={programs}
                                        options={programOptions.map(item => ({ value: item.id, label: item.name }))} />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button>
                                    <a href="/admin/user-groups/" className="btn btn-secondary ml-2"><i className="fa fa-arrow-left"></i> Cancelar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddGroup
