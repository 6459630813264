import axios from 'axios';
import { Usuario } from 'interfaces/Usuario';

const URL = process.env.REACT_APP_API_URL_AUTH;

interface ILogin {
  username: string,
  password: string,
}

/** login de usuario */
export const getNonce = () => {
  return new Promise( async (resolve, reject ) => {
    console.log('?', `${URL}/nonce/get_nonce/`)
    let endpoint = `${URL}/nonce/get_nonce/`;
    try {
      const resp = await axios.get(endpoint);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}


/** login de usuario */
export const login = (payload: ILogin) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/token/`;
    try {
      const resp = await axios.post(endpoint, payload);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

export const setToken = (payload: any) => {
  localStorage.setItem('token', payload.access);
  localStorage.setItem('refresh', payload.refresh);
}

/** obtener token de localstorage */
export const getToken = ():string|undefined => {
  return localStorage.getItem('token') || undefined;
}

export const getRefreshToken = (): string|undefined => {
  return localStorage.getItem('refresh') || undefined;
}

/** verificar token en el backend */
export const verifyToken = ():Promise<any> => {
  return new Promise( async (resolve, reject) => {
    const token = getToken()
    if(!!token){
      try{
        const resp = axios.post(`${URL}/token/verify/`,{token});
        resolve(resp);
      }catch(err:any){
        reject(err);
      }
    }
  })
}

/** refresh token */
export const refreshToken = async ():Promise<any|void> => {
  return new Promise( async (resolve, reject) => {
    try{
      const resp = await axios.post(`${URL}/token/refresh/`,{refresh: getRefreshToken()})
      resolve(resp)
    } catch(err:any){
      reject(err)
    }
  })
}

/** remover token del localstorage */
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem('@appUser');
}

/**
 * listado de usuarios
 * */
export const getUsers = (search = '', page = 1): Promise<any> => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/users/?search=${search}&page=${page}`;
    try {
      const resp = await axios.get(endpoint);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

/** obtener usuario por id */
export const getUserById = (id: string): Promise<any> => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/users/${id}`;
    try {
      const resp = await axios.get(endpoint);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

/** crear un nuevo usuario */
export const createUser = (payload: Usuario): Promise<any> => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/users/`;
    try {
      const resp = await axios.post(endpoint, payload);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

/** actualizar usuario */
export const updateUser = (id: string, payload: Usuario): Promise<any> => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/users/${id}/`;
    try {
      const resp = await axios.put(endpoint, payload);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

export const verifyActivationToken = (token: string) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/activate-user/${token}`;
    try {
      const resp = await axios.get(endpoint);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

export const passwordSet = (id: number, values: any) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/password-set/${id}`;
    try {
      const resp = await axios.post(endpoint, values);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}


export const passworForget = (payload: any) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/password-forget/`;
    try {
      const resp = await axios.post(endpoint, payload);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  })
}

export const verifyPasswordResetToken = (token: string) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/password-reset/${token}`;
    try {
      const resp = await axios.get(endpoint);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  });
}

export const passwordChange = (token: string, payload: any) => {
  return new Promise( async (resolve, reject ) => {
    let endpoint = `${URL}/password-change/${token}`;
    try {
      const resp = await axios.post(endpoint, payload);
      resolve(resp);
    } catch( err ){
      reject(err);
    }
  });
}

export const decodeToken = () => {
  const token = getToken();

  let identity = null;

  if(token){
    // const parts = token.split('.').map(part => Buffer.from(part.replace(/-/g, '+').replace(/_/g, '/'), 'base64').toString());
    // identity = JSON.parse(parts[1]);
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  return identity;
}

export const verifyRouteAccessPermissions = (codename: any): Promise<any> => {
  return new Promise(async(resolve, reject) => {
    try{
      await axios.get(`${URL}/check-permission/${codename}`);
      resolve(true);
    } catch(err:any){
      reject(false);
    }
  })
}

export const deleteUser = (id: string) => {
  return new Promise(async(resolve, reject) => {
    try{
      await axios.get(`${URL}/deactivate-user/${id}`);
      resolve(true);
    } catch(err:any){
      reject(false);
    }
  })
}
