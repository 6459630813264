import { CSSProperties, useState} from 'react';
import {Intent} from '../../../interfaces/Intent';
import validator from 'validator';
import { destroyTrainingText, storeTrainingText } from 'data/training-text';
import swal from 'sweetalert';
import '../style.css';

interface Props {
    intent:Intent;
}

interface ITag {
    id: string;
    text: string;
}

const styles:CSSProperties = {
    width: '100%',
    maxHeight: '250px',
    height: '250px',
    overflowX: 'auto',
}

const TrainingText = ({intent}:Props) => {
    const [text, setText] = useState("");
    const { trainingtext } = intent;
    const trainingText = trainingtext ? trainingtext.map( (item:any) => ({id: item.id, text: item.text})) : []
    const [error, setError] = useState("");
    const [tags, setTags] = useState<ITag[]>(trainingText || []);

    const handleSubmit = async(e:any) => {
        try{
            setError("")
            e.preventDefault();

            if(tagAlreadyExist(text) || validateText()) return ;

            const resp:any = await storeTrainingText({
                text,
                intent:[ Number(intent.id) ]
            });

            const { data } = resp;

            setTags([...tags, {id: data.id, text}]);
            setText("")
            setError("");
            swal("Afinidata","Texto agregado correctamente","success");
        } catch(err:any){
            swal("Afinidata","Ha ocurrido un error, intente de nuevo","error");
            console.log(err)
        }
    }

    const showMessageError = (text: string) => {
        setError(text);
        setTimeout(() => {
            setError("");
        }, 2000)

        return true
    }

    const tagAlreadyExist = (text : string) => {
        let existentTag = tags.find(tag => tag.text === text);
        if (existentTag) {
            return showMessageError("Texto ya existente");
        }
        return false
    }


    const validateText = () => {
        if (validator.isEmpty(validator.trim(text))) {
            return showMessageError("El texto es requerido");
        }
        return false
    }

    const handleDelete = async (id:string) => {
        try{
            await destroyTrainingText(id);
            const newData = tags.filter((tag:ITag) => tag.id !== id)
            setTags(newData)
            swal("Afinidata","Texto eliminado correctamente","success");
        } catch(err:any){
            swal("Afinidata","Ha ocurrido un error, intente de nuevo","error");
            console.log(err)
        }
    }

    return (
        <div>
            <p>If user says something similar to</p>
            <hr/>
            { error && (
                <div className="alert alert-danger animate__animated animate__fadeIn">{error}</div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type="text" name="texto"
                    className="form-control"
                    placeholder="Enter text..."
                    autoComplete="off"
                    value={text}
                    onChange={(e:any) => setText(e.target.value)}
                    />
                </div>
                <div style={styles} className="training-text">
                    <ul className="list-group">
                        {tags.map( (item:ITag) => (
                            <li className="list-group-item" key={item.id}>
                                <div className="row">
                                    <div className="col-sm-10">
                                        {item.text}
                                    </div>
                                    <div className="col-sm-2 text-right">
                                        <button className="btn btn-danger btn-sm"
                                        type="button"
                                        onClick={() => handleDelete(item.id)}>
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </form>
        </div>
    )
}

export default TrainingText
