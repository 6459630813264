import Loading from "components/Loading";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { getVideos } from "services/content-manager.service";
import AddMediaModal from "./AddMediaModal";


interface ImageI {
  id: string,
  name: string,
  size: number,
  url: string,
  modified: Date
}

const VideoList = () => {
  const [ videos, setVideos ] = useState<Array<ImageI>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  
  useEffect(() => {
      handleGetVideos(1)
  },[]);
  
  const handleGetVideos = (page: number) => {
    setLoading(true);
    setActivePage(page)
    getVideos(page)
      .then((resp) => {
        const {content, count} = resp.data;
        setCount(count);
        setVideos(content);
      }).catch((err) => alert("Sucedio un error inesperado, fabor contacta a tu proveedor de servicio"))
      .finally(() => setLoading(false))
    
  }
  
  const handlePageChange = async (page: number) => {
    handleGetVideos(page)
  }

  const handleMediaModalClose = (isSaved: boolean) => {
    setShowAddModal(false)
    if (isSaved) handleGetVideos(activePage)
  }
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  }

  if(loading){
    return <Loading />
  }
  return (
        <>
          <AddMediaModal closeCallback={handleMediaModalClose} isShowed={showAddModal} type='video'/>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h4>Listado de Videos</h4>
                </div>
                <div className="col-md-6 col-sm-12">
                  <button className="btn btn-primary float-right" onClick={() => setShowAddModal(true)}>
                    <em className="fas fa-plus mr-3"></em>Agregar Video
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {videos.length > 0 &&
                <>
                  <table className="table"
                  width="100%" id="data">
                    <thead>
                      <tr>
                        <th>Preview</th>
                        <th>Nombre</th>
                        <th>Tamaño</th>
                        <th>Fecha</th>
                        <th>Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {videos.map( item => (
                        <tr key={item.id}>
                          <td><video className="img-thumbnail" style={{maxWidth: "10rem"}} src={item.url}  controls/></td>
                          <td><a href={item.url} target="_blank" rel="noreferrer">{item.name}</a></td>
                          <td>{item.size}</td>
                          <td>{item.modified}</td>
                          <td>
                            <button className="btn btn-outline-primary" type="button"
                              onClick={() => handleCopyToClipboard(item.url)}>
                                
                                <i className="fa fa-copy"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={30}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                </>
                }
                {videos.length === 0 &&
                  <div className="alert alert-info">No hay usuarios</div>
                }
              </div>
            </div>
          </div>
        </>
    )
}

export default VideoList;
