import { ROLE_PARTNER_PROGRAM_MANAGER } from '../data/auth-groups';
import ListBot from '../pages/bots/ListBot';
import ConfigureBot from '../pages/bots/ConfigureBot';
import AddChannel from '../pages/channels/AddChannel';
import DeleteBot from '../pages/bots/DeleteBot';
import EditChannel from '../pages/channels/EditChannel';
import DeleteChannel from '../pages/channels/DeleteChannel';
import { IRoute } from '../interfaces/Route';
import AddBot from 'pages/bots/AddBot';

export const BOT_ROUTES:IRoute[] = [
    {
        path:'/admin/bots',
        component: ListBot,
        exact: true,
        codename: 'webhook_list_bot',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path:'/admin/bots/add',
        component: AddBot,
        exact: true,
        codename:'webhook_add_bot',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/bots/:id/delete',
        component: DeleteBot,
        exact: true,
        codename:'webhook_delete_bot',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path:'/admin/bots/configure/:id',
        component: ConfigureBot,
        exact: true,
        codename:'webhook_change_bot',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/channels/add/:botid',
        component: AddChannel,
        exact: true,
        codename:'webhook_add_channel',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/channels/:channelid/bot/:botid',
        component: EditChannel,
        exact: true,
        codename:'webhook_change_channel',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    },
    {
        path: '/admin/channels/:id/delete',
        component: DeleteChannel,
        exact: true,
        codename:'webhook_delete_channel',
        requiredRoles: [ROLE_PARTNER_PROGRAM_MANAGER]
    }
];
