import * as Yup from "yup";
import swal from "sweetalert";
import { useFormik } from 'formik';
import Logo from "../../components/Logo"
import { passworForget } from "data/users";
import { useHistory } from "react-router-dom";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Email es requerido')
});


const PasswordForget = () => {
    const history = useHistory();

    const handleSubmit = async (values:any) => {
        try{
            await passworForget(values)
            swal('Afinidata',"Se han enviado instrucciones para cambiar su clave al correo suministrado",'success')
            .then( () => {
                history.push("/")
            })
        } catch(err:any){
            swal("Afinidata",err.response.data.message, 'error');
        }
    }

    const initialValues = {
        email: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    return (
        <>
           <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card">
                            <div className="card-header">
                                <Logo/>
                            </div>
                            <div className="card-body">
                                <>
                                    <h4>¿Olvido su clave?</h4>
                                    <hr/>
                                    <p>Escriba su dirección de correo para iniciar el proceso de recuperación de clave</p>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="">Correo electrónico</label>
                                            <input type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={formik.values.email}
                                            className={`form-control ${
                                                formik.errors.email && formik.touched.email ? "is-invalid" : ""
                                            }`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}/>
                                            {formik.errors.email && formik.touched.email ? (
                                                <div className="invalid-feedback">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block"
                                            type="submit">Enviar</button>
                                        </div>
                                    </form>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordForget;
